import {
  ClientAccountResponse,
  endpoints,
  SessionEnrollClientRequest
} from '@api'
import { LayoutGrid } from '@blueprinthq/joy'
import {
  Box,
  Flex,
  GridItem,
  HStack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { HighRiskToggle } from '@components'
import { datadogLogs } from '@datadog/browser-logs'
import { AddClientDialog } from '@handlers/client-list/add-client-dialog'
import { useAudioCapture, useExperienceManager, usePanelManager } from '@hooks'
import { trackEvent } from '@lib/clinician-tracking'
import { useStoreActions } from 'easy-peasy'
import moment from 'moment'
import queryString from 'query-string'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { UAParser } from 'ua-parser-js'
import {
  useSessionControllerPostDiscardSession,
  useSessionControllerPostMarkComplete,
  useSessionControllerTrackEvent
} from '~/clinician-api'
import { SessionRecapResponse } from '~/clinician-api/models'
import {
  AINotetakerPanel,
  DictationTipsPanel,
  Footer,
  InSessionAssistPanel,
  InSessionAssistWaitlistPanel,
  PanelBody,
  PanelHeader,
  PsychotherapyNoteEditor,
  SessionAgenda,
  StartRecordingDialog
} from './components'
import { AINotetakerPanelV2 } from './components/ai-notetaker-panel-v2'
import { EndSessionModalV2 } from './components/end-session-modal-v2'
import { FloatingAssistDrawer } from './components/floating-assist-drawer'
import { MobileAssistDrawer } from './components/mobile-assist-drawer'
import { MobileDictationTipsDrawer } from './components/mobile-dictation-tips-drawer'
import { MobileRecordingWarning } from './components/mobile-recording-warning'
import AssistUpgradePanel from './components/shared/assist/upgrade-panel'
import { SessionWrapper } from './session-wrapper'

type SessionActivityV2PropsT = {
  client: ClientAccountResponse | undefined
  recap?: SessionRecapResponse
  sessionId: string
  aiNotetakerEnabled?: boolean
  refetchRecap: () => void
  isDictation: boolean
}

type RouteParams = {
  id: string
  sessionId: string
}

function determineGridColumns({
  isAssistOpen,
  isSessionAgendaOpen,
  isSessionNotesOpen,
  isDictationTipsOpen,
  isDictation
}: {
  isAssistOpen: boolean
  isSessionAgendaOpen: boolean
  isSessionNotesOpen: boolean
  isDictationTipsOpen: boolean
  isDictation: boolean
}) {
  const columns = {
    aiNotetaker: {
      start: { base: 1, sm: 1, md: 1 },
      end: { base: 13, sm: 13, md: 13 }
    },
    sessionAgenda: {
      start: { base: 1, sm: 1, md: 4 },
      end: { base: 13, sm: 13, md: 10 }
    },
    psychotherapyNotes: {
      start: { base: 1, sm: 1, md: 10 },
      end: { base: 13, sm: 13, md: 13 }
    },
    dictationTips: {
      start: { base: 1, sm: 1, md: 10 },
      end: { base: 13, sm: 13, md: 13 }
    }
  }

  if (isDictation && !isDictationTipsOpen) {
    columns.aiNotetaker.end.md = 13
  } else if (isDictation && isDictationTipsOpen) {
    columns.aiNotetaker.end.md = 8
    columns.dictationTips.start.md = 8
  }

  if (isSessionAgendaOpen && !isSessionNotesOpen) {
    columns.aiNotetaker.end.md = 4
    columns.sessionAgenda.start.md = 4
    columns.sessionAgenda.end.md = 13
  } else if (!isSessionAgendaOpen && isSessionNotesOpen) {
    columns.aiNotetaker.end.md = 10
    columns.psychotherapyNotes.start.md = 10
  } else if (isSessionAgendaOpen && isSessionNotesOpen) {
    columns.aiNotetaker.end.md = 4
    columns.sessionAgenda.start.md = 4
    columns.sessionAgenda.end.md = 10
    columns.psychotherapyNotes.start.md = 10
  }

  return columns
}

const LOCAL_STORAGE_STOP_RECORDING_AFTER = 'stopRecordingAfter'

export const SessionActivityV2 = ({
  client,
  recap,
  sessionId,
  aiNotetakerEnabled = false,
  isDictation = false
}: SessionActivityV2PropsT) => {
  const queryClient = useQueryClient()
  const { data: user }: { data: any } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const history = useHistory()
  const location = useLocation()
  const toast = useToast()
  const { mutate: trackSessionEvent } = useSessionControllerTrackEvent()

  const handleTrackEvent = useCallback(
    (event: string, payload: object) => {
      if (!sessionId) {
        console.warn('No session ID found, unable to track event', {
          event,
          payload
        })
        return
      }
      trackSessionEvent({ id: sessionId, data: { eventType: event, payload } })
    },
    [sessionId, trackSessionEvent]
  )

  const [isEndingSession, setIsEndingSession] = useState(false)
  const [isEndModalOpen, setEndModalOpen] = useState(false)
  const [recordingDuration, setRecordingDuration] = useState(0)
  const [noteType, setNoteType] = useState('soap')
  const [wakeLockEnabled, setWakeLockEnabled] = useState(false)

  const {
    documentationAutomationFreeTierSessionLimitReached,
    isEvidenceBasedCareEnabled,
    isAdmin,
    isSafari,
    isExtension,
    isWidget,
    isExtensionMinimized,
    isPlusPlanEnabled,
    interventionSuggestionsEnabled,
    isSessionRecordingV2Enabled,
    isIntegratedUI,
    showRulaUI
  } = useExperienceManager()

  const {
    startRecording,
    testAudioInputs,
    hasMicAccess,
    isMuted,
    disableEchoCancelation,
    isRecording,
    isRecordingLoading,
    startContentShare,
    stopRecording,
    logData: baseLogData,
    hasDetectedAudio,
    showMicWarning,
    isUsingHeadphones,
    setIsUsingHeadphones,
    isTelehealth,
    setIsTelehealth,
    isUploadComplete,
    setRecordingCutoffTime,
    selectedAudioInputLabel,
    stopRecordingAfter,
    setStopRecordingAfter,
    isStopRecordingAfterEnabled,
    setIsStopRecordingAfterEnabled,
    uploadQueueSize
  } = useAudioCapture()

  const deviceInfo = useMemo(() => new UAParser().getResult(), [])

  const logDataRef = useRef({})

  logDataRef.current = useMemo(
    () => ({
      ...baseLogData,
      isHidden: document.hidden,
      clientId: client?.id,
      recordingDuration,
      wakeLockEnabled,
      deviceInfo
    }),
    [baseLogData, client?.id, recordingDuration, wakeLockEnabled, deviceInfo]
  )

  const {
    isSessionAgendaOpen,
    isSessionNotesOpen,
    toggleSessionNotes,
    toggleSessionAgenda,
    isDictationTipsOpen,
    toggleDictationTips,
    isAssistOpen,
    toggleAssist
  } = usePanelManager()

  const {
    isOpen: isStartRecordingOpen,
    onOpen: openStartRecording,
    onClose: closeStartRecording
  } = useDisclosure()

  const {
    isOpen: isMobileRecordingWarningOpen,
    onOpen: openMobileRecordingWarning,
    onClose: closeMobileRecordingWarning
  } = useDisclosure()

  const isSmallScreen = useBreakpointValue({ base: true, sm: true, md: false })

  const { mutateAsync: endSession } = useSessionControllerPostMarkComplete()

  const {
    mutateAsync: discardSession
  } = useSessionControllerPostDiscardSession()

  const { id: clientId } = useParams<RouteParams>()

  const { data: lastSession }: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId(), clientId],
    () =>
      endpoints.getClinicianClientLastSession.request({
        id: clientId
      })
  )

  useEffect(() => {
    if (isRecording) {
      // eslint-disable-next-line
      parent.postMessage({ type: 'BP_SESSION_RECORDING_STARTED' }, '*')
    }
  }, [isRecording])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        datadogLogs.logger.warn('[Session] Page is hidden', {
          ...logDataRef.current
        })
      } else {
        datadogLogs.logger.info('[Session] Page is visible', {
          ...logDataRef.current
        })
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    // set the default recording duration for a given client ID
    const config = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_STOP_RECORDING_AFTER) || '{}'
    )
    const clientConfig = config[clientId] || {}
    setStopRecordingAfter(clientConfig.duration || '60')
    setIsStopRecordingAfterEnabled(clientConfig.isEnabled || false)
  }, [])

  useEffect(() => {
    // update recording duration for client ID
    const config = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_STOP_RECORDING_AFTER) || '{}'
    )

    const clientConfig = config[clientId] || {}

    clientConfig.duration = stopRecordingAfter
    clientConfig.isEnabled = isStopRecordingAfterEnabled

    localStorage.setItem(
      LOCAL_STORAGE_STOP_RECORDING_AFTER,
      JSON.stringify({
        ...config,
        [clientId]: clientConfig
      })
    )
  }, [stopRecordingAfter, isStopRecordingAfterEnabled])

  useEffect(() => {
    if (lastSession && !recap?.recordingStartedAt) {
      setIsTelehealth(lastSession.isTelehealth)
      setIsUsingHeadphones(lastSession.isUsingHeadphones)
    }
  }, [lastSession, recap?.recordingStartedAt])

  useEffect(() => {
    if (isIntegratedUI && recap?.isUsingHeadphones) {
      startContentShare()
    }
  }, [recap?.isUsingHeadphones])

  useEffect(() => {
    const requestWakeLock = async () => {
      try {
        if ('wakeLock' in navigator) {
          // @ts-ignore
          await navigator.wakeLock.request('screen')
          setWakeLockEnabled(true)
        }
      } catch (err) {
        datadogLogs.logger.warn('[Session] Unable to request wake lock', {
          err,
          ...logDataRef.current
        })
        setWakeLockEnabled(false)
      }
    }

    requestWakeLock()
  }, [])

  useEffect(() => {
    const clientNoteType = lastSession?.noteType
    const defaultNoteType =
      user?.clinic?.organization?.default_note_type || 'soap'

    setNoteType(clientNoteType || defaultNoteType)
  }, [user, lastSession])

  useEffect(() => {
    const fetchData = async () => {
      if (recap?.chimeMeetingId) {
        await startRecording({
          isTelehealth: isTelehealth || false,
          isUsingHeadphones,
          noteType
        })
      }
    }
    fetchData()

    if (recap?.recordingStartedAt) {
      setRecordingCutoffTime(
        moment(recap.recordingStartedAt)
          .add(4, 'hours')
          .toString()
      )
    }
  }, [recap?.recordingStartedAt])

  const openDocAutomationPlanModal = useStoreActions(
    (actions: any) => actions.modals.manageScribePlan.openModal
  )

  const openDocAutomationSessionLimitRecachedModal = useStoreActions(
    (actions: any) => actions.modals.docAutomationSessionLimitReached.openModal
  )

  useEffect(() => {
    if (!hasMicAccess && isStartRecordingOpen) {
      closeStartRecording()
    }
  }, [hasMicAccess, isStartRecordingOpen])

  useEffect(() => {
    if (recap?.isTelehealth) {
      setIsTelehealth(true)
    }
  }, [recap?.isTelehealth])

  useEffect(() => {
    if (isTelehealth && !isMobile && !isSafari && !isDictation) {
      disableEchoCancelation(true)
    } else {
      disableEchoCancelation(false)
    }
  }, [isTelehealth, isMobile])

  useEffect(() => {
    let interval: undefined | NodeJS.Timeout

    if (recap?.recordingStartedAt) {
      const startingSeconds = moment
        .utc()
        .diff(moment(recap.recordingStartedAt), 'seconds')
      setRecordingDuration(startingSeconds)
    }

    if (isRecording && !(isMuted && isSessionRecordingV2Enabled)) {
      interval = setInterval(() => {
        setRecordingDuration(prev => prev + 1)
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [isRecording, isMuted, recap?.recordingStartedAt])

  useEffect(() => {
    if (isMuted) {
      datadogLogs.logger.info('[Session] Microphone muted', logDataRef.current)
    } else {
      datadogLogs.logger.info('[Session] Microphone unmuted', logDataRef.current)
    }
  }, [isMuted])

  useEffect(() => {
    if (hasDetectedAudio) {
      handleTrackEvent('audio_detected', {
        selectedAudioInputLabel,
        deviceInfo
      })
      datadogLogs.logger.info('[Session] Audio detected', logDataRef.current)
    }
  }, [hasDetectedAudio])

  useEffect(() => {
    if (showMicWarning) {
      datadogLogs.logger.info('[Session] Showing Mic Warning', logDataRef.current)
    } else {
      datadogLogs.logger.info('[Session] Hiding Mic Warning', logDataRef.current)
    }
  }, [showMicWarning])

  useEffect(() => {
    ;(window as any).Intercom?.('update', {
      hide_default_launcher: true
    })

    return () => {
      ;(window as any).Intercom?.('update', {
        hide_default_launcher: false
      })
    }
  }, [])

  const handleBeforeUnload = useMemo(() => {
    return () => {
      datadogLogs.logger.info('[Session] Page refresh attempt', logDataRef.current)
      return 'Did you finish the session?'
    }
  }, [])

  useEffect(() => {
    window.onbeforeunload = handleBeforeUnload
    return () => {
      window.onbeforeunload = () => {}
    }
  }, [handleBeforeUnload])

  const handleSessionLimitReached = async () => {
    if (isAdmin) {
      if (isEvidenceBasedCareEnabled) {
        openDocAutomationPlanModal({
          clinicId: user.clinic_id
        })
      } else {
        const queryParams = queryString.stringify({
          redirectPath: location.pathname,
          prepGenerationSessionId: sessionId
        })

        history.push(`/settings/plans?${queryParams}`)
      }
    } else {
      openDocAutomationSessionLimitRecachedModal()
    }
  }

  const handleOpenStartRecordingModal = async () => {
    if (documentationAutomationFreeTierSessionLimitReached && !isRecording) {
      handleSessionLimitReached()

      return
    }

    await testAudioInputs()

    openStartRecording()
  }

  const handleStartRecording = async () => {
    if (documentationAutomationFreeTierSessionLimitReached && !isRecording) {
      handleSessionLimitReached()

      return
    }

    closeStartRecording()

    await startRecording({
      noteType,
      isTelehealth: isTelehealth || false,
      isUsingHeadphones
    })

    if (isMobile && !isIntegratedUI) {
      openMobileRecordingWarning()
    }

    trackEvent('Session -> Started Recording')
  }

  const handleCloseStartRecording = async () => {
    await stopRecording()
    closeStartRecording()
  }

  const columns = determineGridColumns({
    isAssistOpen,
    isSessionAgendaOpen,
    isSessionNotesOpen,
    isDictationTipsOpen,
    isDictation
  })

  const handleEndSession = useCallback(
    async ({ noteType, useDemoNotesWithEBC, treatmentApproach }: any) => {
      setIsEndingSession(true)
      handleTrackEvent('generate_note_clicked', { uploadQueueSize })
      try {
        await stopRecording()
        await endSession(
          {
            id: sessionId,
            data: {
              noteType,
              useDemoNotesWithEBC,
              shouldGenerateTxPlan: true,
              // shouldn't be getting passed from the frontend...
              assistEnabled: isPlusPlanEnabled,
              interventionSuggestionsEnabled,
              treatmentApproach
            }
          },
          {
            onSuccess() {
              trackEvent('Completed Session', { sessionId })
              queryClient.invalidateQueries(
                endpoints.getPaywallValidation.getCacheId()
              )
            }
          }
        )
      } catch (err) {
        datadogLogs.logger.info('[Session] Error ending session', logDataRef.current)
      }

      setIsEndingSession(false)
      if (isExtension) {
        history.push(
          `/extension/patient/${client?.id}/completed-session/${sessionId}`
        )
      } else if (isWidget)
        history.push(
          `/widget/patient/${client?.id}/completed-session/${sessionId}`
        )
      else {
        history.push(
          `/patient/${client?.id}/completed-session/${sessionId}${
            client?.is_demo ? '?isDemo=true' : ''
          }`
        )
      }
    },
    [
      client?.id,
      client?.is_demo,
      endSession,
      history,
      isExtension,
      isPlusPlanEnabled,
      isWidget,
      queryClient,
      sessionId,
      stopRecording
    ]
  )

  useEffect(() => {
    // auto-stop recording if enabled
    const interval = setInterval(() => {
      if (
        recap?.recordingStartedAt &&
        isRecording &&
        isStopRecordingAfterEnabled &&
        stopRecordingAfter
      ) {
        const cutoff = moment
          .utc(recap.recordingStartedAt)
          .add(stopRecordingAfter, 'minutes')
        if (cutoff < moment.utc()) {
          datadogLogs.logger.info('[Session] Timer auto-ending', logDataRef.current)
          handleEndSession({
            noteType
          })
        }
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [recap?.recordingStartedAt, isRecording, isStopRecordingAfterEnabled])

  const handleDiscardSession = async () => {
    setIsEndingSession(true)
    try {
      await stopRecording()
      await discardSession({ id: sessionId })
    } catch (err) {
      datadogLogs.logger.info('[Session] Error resetting meeting', logDataRef.current)
    }
    setIsEndingSession(false)

    if (isExtension) {
      history.replace('/extension/start-session')
    } else if (isWidget) history.replace('/widget/start-session')
    else {
      history.replace('/sessions')
    }
    toast({
      title: 'Session discarded',
      status: 'success',
      isClosable: true,
      duration: 2000
    })
  }

  const onOpenEndModal = () => setEndModalOpen(true)
  const onCloseEndModal = () => setEndModalOpen(false)

  return (
    <SessionWrapper
      isDictation={isDictation}
      isExtensionMinimized={isExtensionMinimized}
      hasStartedRecording={isRecording}
    >
      <Box
        h={{ base: '100%', md: '100dvh' }}
        overflowY={{ base: 'auto', md: 'hidden' }}
        style={{
          margin: 'auto 16px'
        }}
        display="flex"
        flexDirection="column"
        data-testid="session-layout-parent"
      >
        {!isEvidenceBasedCareEnabled ||
        isPlusPlanEnabled ||
        isDictation ||
        isIntegratedUI ? (
          <Flex
            h="100%"
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row'
            }}
            gap={isSessionRecordingV2Enabled ? '16px' : '32px'}
            data-testid="session-layout"
          >
            <Flex
              margin="auto 0"
              justifyContent="center"
              flex="1"
              height="100%"
              data-testid="ai-notetaker-panel"
              minWidth={{ md: '350px' }}
              overflowX={isSessionRecordingV2Enabled ? 'hidden' : 'visible'}
            >
              {isIntegratedUI || !isSessionRecordingV2Enabled ? (
                <AINotetakerPanel
                  sessionId={sessionId}
                  hasStartedRecording={isRecording}
                  isRecordingLoading={isRecordingLoading || isEndingSession}
                  startRecording={handleOpenStartRecordingModal}
                  aiNotetakerEnabled={aiNotetakerEnabled}
                  recordingDuration={recordingDuration}
                  isDictation={isDictation}
                  recordingStartedAt={recap?.recordingStartedAt}
                  client={client}
                />
              ) : (
                <AINotetakerPanelV2
                  sessionId={sessionId}
                  hasStartedRecording={isRecording}
                  isRecordingLoading={isRecordingLoading || isEndingSession}
                  startRecording={handleStartRecording}
                  recordingDuration={recordingDuration}
                  isDictation={isDictation}
                  recordingStartedAt={recap?.recordingStartedAt}
                  onOpenEndModal={onOpenEndModal}
                  client={client}
                />
              )}
            </Flex>
            {!isIntegratedUI &&
              !isDictation &&
              !isPlusPlanEnabled &&
              isAssistOpen && (
                <Flex
                  bg="white"
                  borderRadius="8px"
                  border="1px solid #E4E5E6"
                  overflow="hidden"
                  flexDirection="column"
                  w={{
                    base: 'auto',
                    sm: 'auto',
                    md: '400px'
                  }}
                  maxWidth={{
                    base: '400px',
                    sm: '400px',
                    md: 'none'
                  }}
                  margin={{
                    base: '0 auto',
                    sm: '0 auto',
                    md: '0'
                  }}
                >
                  <PanelHeader title="" onClose={toggleAssist} p="16px" />
                  <PanelBody
                    px="32px"
                    height={{
                      base: 'auto',
                      md: 'auto'
                    }}
                  >
                    {!isEvidenceBasedCareEnabled ? (
                      <AssistUpgradePanel sessionId={sessionId} />
                    ) : (
                      <InSessionAssistWaitlistPanel />
                    )}
                  </PanelBody>
                </Flex>
              )}
            {!isIntegratedUI &&
              !isDictation &&
              isPlusPlanEnabled &&
              isAssistOpen &&
              !isSmallScreen && (
                <InSessionAssistPanel
                  client={client}
                  toggleAssist={toggleAssist}
                />
              )}
            {!isSmallScreen && isDictationTipsOpen && (
              <GridItem
                colStart={columns.dictationTips.start}
                colEnd={columns.dictationTips.end}
                borderRadius="8px"
                overflow="hidden"
                backgroundColor="white"
                w={{
                  base: 'auto',
                  sm: 'auto',
                  md: '490px'
                }}
              >
                <PanelHeader title="Tips" onClose={toggleDictationTips} />
                <PanelBody>
                  <DictationTipsPanel />
                </PanelBody>
              </GridItem>
            )}
          </Flex>
        ) : (
          <LayoutGrid style={{ gap: '16px', height: '100%', margin: 'auto 0' }}>
            <GridItem
              colStart={columns.aiNotetaker.start}
              colEnd={columns.aiNotetaker.end}
              margin="auto 0"
            >
              <AINotetakerPanel
                sessionId={sessionId}
                hasStartedRecording={isRecording}
                isRecordingLoading={isRecordingLoading || isEndingSession}
                startRecording={handleOpenStartRecordingModal}
                aiNotetakerEnabled={aiNotetakerEnabled}
                recordingDuration={recordingDuration}
                isDictation={isDictation}
                recordingStartedAt={recap?.recordingStartedAt}
                client={client}
              />
            </GridItem>
            {!isPlusPlanEnabled &&
              isEvidenceBasedCareEnabled &&
              isSessionAgendaOpen &&
              !isIntegratedUI &&
              !isDictation && (
                <GridItem
                  colStart={columns.sessionAgenda.start}
                  colEnd={columns.sessionAgenda.end}
                  bg="white"
                  borderRadius="8px"
                  overflow="hidden"
                >
                  <PanelHeader
                    title="Session plan"
                    onClose={toggleSessionAgenda}
                  />
                  <PanelBody>
                    <HStack gap="8px">
                      <Text
                        fontSize="32px"
                        as="h2"
                        fontWeight="bold"
                        lineHeight="normal"
                      >
                        {`${client?.first_name} ${client?.last_name}`}
                      </Text>
                      <HighRiskToggle
                        clientId={client?.id}
                        isHighRisk={client?.is_high_risk}
                        isInert
                      />
                    </HStack>
                    <Box h="24px" />
                    <SessionAgenda
                      client={client}
                      recap={recap}
                      sessionId={sessionId}
                    />
                  </PanelBody>
                </GridItem>
              )}
            {!isPlusPlanEnabled &&
              isEvidenceBasedCareEnabled &&
              isSessionNotesOpen &&
              !isIntegratedUI &&
              !isDictation && (
                <GridItem
                  colStart={columns.psychotherapyNotes.start}
                  colEnd={columns.psychotherapyNotes.end}
                  borderRadius="8px"
                  overflow="hidden"
                  bg="#FEFAF3"
                >
                  <PanelHeader
                    title="Psychotherapy note"
                    onClose={toggleSessionNotes}
                  />
                  <PanelBody>
                    <PsychotherapyNoteEditor
                      recap={recap}
                      sessionId={sessionId}
                      v2Enabled
                    />
                  </PanelBody>
                </GridItem>
              )}
            {isDictationTipsOpen && (
              <GridItem
                colStart={columns.dictationTips.start}
                colEnd={columns.dictationTips.end}
                borderRadius="8px"
                overflow="hidden"
                bg="white"
              >
                <PanelHeader title="Tips" onClose={toggleDictationTips} />
                <PanelBody>
                  <DictationTipsPanel />
                </PanelBody>
              </GridItem>
            )}
          </LayoutGrid>
        )}
      </Box>
      <Box minH="16px" style={{ marginTop: 0 }} />
      {isIntegratedUI ||
      isEvidenceBasedCareEnabled ||
      !isSessionRecordingV2Enabled ? (
        <>
          <Box minH="60px" style={{ marginTop: 0 }} />
          <Footer
            aiNotetakerEnabled={aiNotetakerEnabled}
            onSessionEnd={onOpenEndModal}
            isRecordingLoading={isRecordingLoading || isEndingSession}
            hasStartedRecording={isRecording}
            startRecording={handleOpenStartRecordingModal}
            onShareAudio={startContentShare}
            isDictation={isDictation}
          />
        </>
      ) : (
        isSessionRecordingV2Enabled &&
        client &&
        !isDictation &&
        !isSmallScreen &&
        !showRulaUI &&
        !isAssistOpen && (
          <FloatingAssistDrawer
            clientFirstName={client?.first_name}
            clientLastName={client?.last_name}
          />
        )
      )}
      {isSessionRecordingV2Enabled &&
        !isRecording &&
        !isDictation &&
        !!client &&
        isSmallScreen && (
          <MobileAssistDrawer client={client} sessionId={sessionId} />
        )}
      {isSessionRecordingV2Enabled &&
        !isRecording &&
        isDictation &&
        !!client &&
        isSmallScreen && (
          <MobileDictationTipsDrawer />
        )}
      <StartRecordingDialog
        isOpen={isStartRecordingOpen}
        onClose={handleCloseStartRecording}
        startRecording={handleStartRecording}
        onShareAudio={startContentShare}
        isDictation={isDictation}
        client={client}
      />
      <EndSessionModalV2
        isOpen={isEndModalOpen}
        onClose={onCloseEndModal}
        noteType={noteType}
        treatmentApproach={lastSession?.treatmentApproach}
        onEnd={handleEndSession}
        onDiscard={handleDiscardSession}
        isEnding={isEndingSession}
        isUploadingAudio={!isUploadComplete}
      />
      <MobileRecordingWarning
        isOpen={isMobileRecordingWarningOpen}
        onClose={closeMobileRecordingWarning}
      />
    </SessionWrapper>
  )
}
