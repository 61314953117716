import React, { useEffect } from 'react'
import {
  Button,
  VStack,
  Stack,
  Flex,
  Text,
  Image
} from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import AssistUpgradePanel from '@handlers/sessions/components/shared/assist/upgrade-panel'
import { AssessmentSuggestionModule, WorksheetSuggestionModule, InterventionSuggestionModule } from './suggestion-modules'
import {
  useSessionControllerGetSuggestedContent,
  useSessionControllerGetDemoSuggestedContent
} from '~/clinician-api'
import Waitlist from '../in-session-assist-waitlist-panel'
import Setup from './setup'
import TreatmentPlanGate from './treatment-plan-gate'
import { useTreatmentPlan } from '@hooks'
import { SettingsOutlined } from '@components/icons'
import {
  Patient
} from '@handlers/sessions/types'
import { DisclaimerBanner } from '../shared/disclaimer-banner'

const SuggestionLoader = () => {
  return (
    <Stack spacing="0" gap="24px" flex="1">
      <VStack gap="16px" spacing="0" flex="1">
        <Flex justifyContent="center">
          <svg
            width="168"
            height="133"
            viewBox="0 0 168 133"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="168" height="133" fill="white" />
            <g opacity="0.6">
              <path
                d="M10 107.377C34.9404 106.809 60.5931 106.195 85.5619 106.035C106.342 105.785 141.493 106.854 101.613 109.378C85.505 110.39 63.2999 111.084 48.0564 111.766C44.3049 111.993 24.7401 112.664 30.2581 113.778C36.8209 114.642 45.2736 114.836 54.9421 115.256C76.2449 116.029 100.331 116.791 121.69 117.769C176.206 120.179 140.894 122.806 108.204 125"
                fill="#F6F7FE"
              />
              <path
                d="M10 107.377C34.9404 106.809 60.5931 106.195 85.5619 106.035C106.342 105.785 141.493 106.854 101.613 109.378C85.505 110.39 63.2999 111.084 48.0564 111.766C44.3049 111.993 24.7401 112.664 30.2581 113.778C36.8209 114.642 45.2736 114.836 54.9421 115.256C76.2449 116.029 100.331 116.791 121.69 117.769C176.206 120.179 140.894 122.806 108.204 125"
                stroke="#C0CCF8"
                stroke-miterlimit="10"
              />
            </g>
            <path
              d="M130.653 87.7339C138.211 84.7855 142.496 77.6737 140.224 71.8493C137.952 66.0249 129.983 63.6935 122.425 66.6419C114.867 69.5903 110.581 76.702 112.853 82.5264C115.125 88.3508 123.094 90.6823 130.653 87.7339Z"
              fill="#F6F7FE"
              stroke="#C0CCF8"
              stroke-miterlimit="10"
            />
            <path
              d="M128.908 83.2621C133.256 81.5661 135.719 77.4717 134.411 74.117C133.102 70.7623 128.517 69.4177 124.169 71.1137C119.821 72.8096 117.358 76.904 118.666 80.2587C119.975 83.6134 124.56 84.9581 128.908 83.2621Z"
              fill="white"
              stroke="#C0CCF8"
              stroke-miterlimit="10"
            />
            <path
              d="M122.93 66.26C122.93 66.54 122.93 66.82 122.91 67.1C122.87 69.51 122.63 71.87 122.2 74.16C121.77 76.45 121.16 78.67 120.38 80.8C120.2 81.31 120 81.81 119.8 82.3C118.36 85.84 116.47 89.15 114.18 92.14C112.39 94.49 110.36 96.65 108.13 98.57C103.96 102.18 99.0902 104.98 93.7402 106.77H72.2402C67.4202 104.99 63.0202 102.17 59.2602 98.57C57.2502 96.64 55.4302 94.49 53.8102 92.14C51.4702 88.73 49.5602 84.91 48.2302 80.8C47.5302 78.66 46.9802 76.44 46.6002 74.16C46.1602 71.6 45.9302 68.96 45.9302 66.26H122.93Z"
              fill="#F6F7FE"
            />
            <path
              d="M102.53 102.68C99.79 104.35 96.84 105.73 93.74 106.77H66.69C63.54 105.72 60.56 104.32 57.79 102.61C71.5 102.62 88.6 102.64 102.53 102.68Z"
              fill="#C0CCF8"
            />
            <path
              opacity="0.6"
              d="M59.0801 56.86C64.3601 47.7 71.4201 45.75 80.4101 39.84C92.8501 31.22 92.9801 21.96 88.0201 15.3"
              stroke="#C0CCF8"
              stroke-miterlimit="10"
            />
            <path
              opacity="0.6"
              d="M74.02 56.86C80.55 47.42 86.7 45.7 96 39.27C108.03 30.59 107.45 21.93 102.96 15.29"
              stroke="#C0CCF8"
              stroke-miterlimit="10"
            />
            <path
              opacity="0.6"
              d="M88.9702 56.86C95.5002 47.42 101.65 45.7 110.95 39.27C122.98 30.59 122.4 21.93 117.91 15.29"
              stroke="#C0CCF8"
              stroke-miterlimit="10"
            />
            <path
              d="M80.21 68.1C103.798 68.1 122.92 67.0479 122.92 65.75C122.92 64.4522 103.798 63.4 80.21 63.4C56.6219 63.4 37.5 64.4522 37.5 65.75C37.5 67.0479 56.6219 68.1 80.21 68.1Z"
              fill="white"
              stroke="#C0CCF8"
              stroke-miterlimit="10"
            />
            <path
              d="M37.7499 109.3C38.3399 110.45 40.6199 111.4 42.8199 111.4H117.61C119.81 111.4 122.09 110.46 122.68 109.3C123.27 108.14 121.95 107.2 119.75 107.2H40.6899C38.4899 107.2 37.1699 108.14 37.7599 109.3H37.7499Z"
              fill="#F6F7FE"
            />
            <path
              d="M37.7499 109.3C38.3399 110.45 40.6199 111.4 42.8199 111.4H117.61C119.81 111.4 122.09 110.46 122.68 109.3C123.27 108.14 121.95 107.2 119.75 107.2H40.6899C38.4899 107.2 37.1699 108.14 37.7599 109.3H37.7499Z"
              stroke="#C0CCF8"
              stroke-miterlimit="10"
            />
            <path
              d="M59.0801 113.99H101.34"
              stroke="#C0CCF8"
              stroke-miterlimit="10"
            />
            <path
              d="M123.59 66.69C123.49 72.63 122.27 78.31 120.15 83.51C120.09 83.41 120.06 83.29 120.04 83.18C119.86 82.41 119.67 81.64 119.5 80.87C119.32 80.1 119.15 79.32 119.1 78.52C119.07 77.69 119.18 76.86 119.3 76.04C119.58 74.05 119.85 72.04 120.38 70.11C120.68 69 122.18 66.29 123.59 66.69Z"
              fill="#F6F7FE"
            />
            <path
              d="M122.93 66.26C122.93 66.54 122.93 66.82 122.91 67.1C122.87 69.51 122.63 71.87 122.2 74.16C121.77 76.45 121.16 78.67 120.38 80.8C120.2 81.31 120 81.81 119.8 82.3C118.36 85.84 116.47 89.15 114.18 92.14C112.39 94.49 110.36 96.65 108.13 98.57C106.38 100.09 104.51 101.46 102.53 102.68C99.79 104.35 96.84 105.73 93.74 106.77H66.69C63.54 105.72 60.56 104.32 57.79 102.61C55.85 101.41 54 100.05 52.29 98.57C50.06 96.64 48.04 94.49 46.24 92.14C43.64 88.73 41.53 84.91 40.05 80.8C39.27 78.66 38.66 76.44 38.24 74.16C37.75 71.6 37.5 68.96 37.5 66.26"
              stroke="#C0CCF8"
              stroke-miterlimit="10"
            />
          </svg>
        </Flex>
        <VStack spacing="0" gap="4px">
          <Text fontWeight="bold">Generating suggestions...</Text>
          <Text color="dark_gray">This could take a minute</Text>
        </VStack>
      </VStack>
    </Stack>
  )
}
interface AssistPanelProps {
  sessionId: string
  client: Patient
  toggleOverlay: () => void
  account: any
  session: any
}

const AssistPanel = ({
  sessionId,
  client,
  toggleOverlay,
  account,
  session
}: AssistPanelProps) => {
  const [showDemoLoading, setShowDemoLoading] = React.useState<boolean>(true)

  const {
    initialSelectedFocuses,
    treatmentPlan,
    canGenerateQuery,
    generateMutation
  } = useTreatmentPlan()

  const {
    data: suggestions,
    isLoading
  } = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      }
    }
  })

  const {
    isPlusPlanEnabled,
    isEvidenceBasedCareEnabled,
    isDemoClinician,
  } = useExperienceManager()
  const showDemoExperience = (client.is_demo && isDemoClinician) ?? false

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId,
    { demoContext: 'post-session' },
    {
      query: {
        enabled: showDemoExperience
      }
    }
  )

  useEffect(() => {
    if (showDemoExperience) {
      setTimeout(() => {
        setShowDemoLoading(false)
      }, 2000)
    } else {
      setShowDemoLoading(false)
    }
  }, [showDemoExperience])

  const assessmentSuggestion = showDemoExperience
    ? demoData?.suggestedAssessments[0]
    : suggestions?.suggestedAssessments[0]
  const worksheetSuggestion = showDemoExperience
    ? demoData?.suggestedWorksheets[0]
    : suggestions?.suggestedWorksheets[0]
  const interventionSuggestion = showDemoExperience
    ? demoData?.suggestedInterventions[0]
    : suggestions?.suggestedInterventions[0]

  if (!isPlusPlanEnabled) {
    if (isEvidenceBasedCareEnabled) {
      return <Waitlist />
    }

    return <AssistUpgradePanel sessionId={session?.id} />
  }

  if (
    isLoading ||
    suggestions?.isGenerationComplete === false ||
    treatmentPlan?.isLoading ||
    showDemoLoading
  ) {
    return <SuggestionLoader />
  }

  if (!treatmentPlan || !!treatmentPlan?.fileKey) {
    return (
      <TreatmentPlanGate
        treatmentPlan={treatmentPlan}
        canGenerateQuery={canGenerateQuery}
        generateMutation={generateMutation}
      />
    )
  }

  const hasFocusOfTreatment = initialSelectedFocuses?.filter(f => f).length > 0
  const hasAgeRange = !!client.age_range
  const hasProviderType = !!account?.license_level
  const hasTreatmentApproach = !!session?.treatmentApproach
  const hasTreatmentSetting = !!account?.treatment_setting
  const hasHighRisk = !!client?.high_risk_timestamp

  if (
    !hasFocusOfTreatment ||
    !hasAgeRange ||
    !hasProviderType ||
    !hasTreatmentApproach ||
    !hasTreatmentSetting ||
    !hasHighRisk
  ) {
    return (
      <Setup
        toggleOverlay={toggleOverlay}
        client={client}
        hasFocusOfTreatment={hasFocusOfTreatment}
        hasAgeRange={!!client.age_range}
        hasProviderType={!!account?.license_level}
        hasTreatmentApproach={!!session?.treatmentApproach}
        hasTreatmentSetting={!!account?.treatment_setting}
        hasHighRisk={!!client?.high_risk_timestamp}
      />
    )
  }

  const showDisclaimer =
    (assessmentSuggestion || worksheetSuggestion || interventionSuggestion) &&
    isPlusPlanEnabled

  return (
    <Stack spacing="0" gap="32px" flex="1">
      {showDisclaimer && <DisclaimerBanner />}
      <AssessmentSuggestionModule
        sessionId={sessionId}
        client={client}
        suggestions={suggestions}
        showDemoExperience={showDemoExperience}
        demoData={demoData}
      />
      <WorksheetSuggestionModule
        sessionId={sessionId}
        client={client}
        suggestions={suggestions}
        showDemoExperience={showDemoExperience}
        demoData={demoData}
      />
      <InterventionSuggestionModule
        sessionId={sessionId}
        client={client}
        suggestions={suggestions}
        showDemoExperience={showDemoExperience}
        demoData={demoData}
      />
      <VStack spacing="0">
        <Button
          variant="outline"
          h="35px"
          borderColor="pale_gray"
          borderRadius="4px"
          textDecoration="none"
          pl="8px"
          pr="8px"
          onClick={toggleOverlay}
        >
          <Flex align="center" justify="space-between" gap="8px">
            <SettingsOutlined />
            <Text>Assist setup for {client.first_name}</Text>
          </Flex>
        </Button>
      </VStack>
    </Stack>
  )
}

export default AssistPanel
