import React, { useMemo, useState, useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box
} from '@chakra-ui/react'
import { TextField, Select } from '@blueprinthq/joy'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { TREATMENT_MODALITIES } from '../../../../constants'
import { InterventionList } from '../shared/assist/interventions/intervention-list'
import { StoreModel } from 'src/store/types'
import {
  useInterventionsControllerGetSavedForLaterInterventions,
  useInterventionsControllerV1GetAllInterventions
} from '~/clinician-api'
import { useRouteMatch } from 'react-router-dom'

interface SaveInterventionModalProps {
  isOpen: boolean
  patientId?: string
}

export const SaveInterventionModal = ({
  isOpen,
  patientId
}: SaveInterventionModalProps) => {
  const handleCloseModal = useStoreActions(
    //@ts-ignore
    actions => actions.modals.saveInterventions.closeModal
  )
  const [searchText, setSearchText] = useState('')
  const [modalityOption, setModalityOption] = useState(TREATMENT_MODALITIES[0])

  let match: any = useRouteMatch(
    '/patient/:patientId/completed-session/:sessionId'
  )
  const clientId = match?.params?.patientId || patientId

  const {
    data: savedForLaterInterventions,
    isLoading: savedForLaterLoading
  } = useInterventionsControllerGetSavedForLaterInterventions(clientId, {
    query: {
      initialData: [],
      enabled: !!clientId
    }
  })

  const {
    data: allInterventions,
    isLoading: allInterventionsLoading
  } = useInterventionsControllerV1GetAllInterventions({ isGuidedOnly: 'true' })

  const filteredInterventions = useMemo(() => {
    const sortedInterventions = allInterventions?.sort(
      (a, b) => a.name.localeCompare(b.name) ?? []
    )

    let newInterventions = sortedInterventions
    if (searchText.length) {
      newInterventions = newInterventions?.filter(
        (i: any) =>
          i.name.toLowerCase().includes(searchText.toLowerCase()) ||
          i?.shortDescription?.toLowerCase().includes(searchText.toLowerCase())
      )
    }
    if (modalityOption.value !== '') {
      newInterventions = newInterventions?.filter((i: any) =>
        i.treatmentModality
          .toLowerCase()
          .includes(modalityOption.value.toLowerCase())
      )
    }

    const finalInterventions = newInterventions?.map(ni => {
      const isAlreadySaved = savedForLaterInterventions?.some(
        si => si.interventionId === ni.id
      )
      return { ...ni, isAlreadySaved }
    })

    return finalInterventions
  }, [searchText, modalityOption, allInterventions, savedForLaterInterventions])

  const _handleCloseModal = useCallback(() => {
    setSearchText('')
    setModalityOption(TREATMENT_MODALITIES[0])
    handleCloseModal()
  }, [handleCloseModal])

  return (
    <Modal
      size="2xl"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={_handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent h="80vh">
        <ModalHeader
          fontSize="20px"
          borderBottom="1px solid #E4E5E6"
          textAlign="center"
        >
          Intervention Library
        </ModalHeader>
        <ModalCloseButton mr="xsmall" mt="10px" _focus={{ outline: 'none' }} />
        <ModalBody p="0" overflow="hidden">
          <Flex px="24px" align="center" justify="space-evenly">
            <Box w="50%" mt="medium" mb="small" mr="12px">
              <TextField
                showClear
                onClear={() => setSearchText('')}
                label="Search interventions"
                onChange={e => setSearchText(e.currentTarget.value)}
                value={searchText}
              />
            </Box>
            <Box w="50%" mt="medium" mb="small" ml="12px">
              <Select
                value={modalityOption}
                label="Treatment Approach"
                options={TREATMENT_MODALITIES}
                valueKey="value"
                labelKey="label"
                onChange={option => setModalityOption(option)}
                menuListProps={{
                  height: '30vh',
                  overflowY: 'scroll'
                }}
              />
            </Box>
          </Flex>

          {/* All interventions */}
          <InterventionList interventionList={filteredInterventions} clientId={patientId || ''} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const SaveInterventionModalConnected = () => {
  const { isOpen, patientId } = useStoreState(
    //@ts-ignore
    (state: StoreModel) => state.modals.saveInterventions
  )

  return isOpen ? (
    <SaveInterventionModal isOpen={isOpen} patientId={patientId} />
  ) : null
}
