import { isNil, omitBy } from 'lodash'
import queryString from 'query-string'
import { api } from './api'
import { authedApi } from './authed-api'
import { DateTime } from 'luxon'

interface IRequestParams {
  [key: string]: any
}

class Endpoint<RequestType = IRequestParams, ResponseType = unknown> {
  private cacheId: string
  request: (params: RequestType) => Promise<ResponseType>

  constructor(
    cacheId: string,
    request: (params: RequestType) => Promise<ResponseType>
  ) {
    this.cacheId = cacheId
    this.request = request
  }

  getCacheId(): string {
    return this.cacheId
  }
}

interface OrganizationProgramsResponse {
  programs: Program[]
}

export interface Program {
  id: string
  name: string
  organization_id: string
  created_at: Date
  updated_at: Date
}

export interface ClientAccountRequest {
  id: string
}

export interface ClientSearchResult {
  id: string
  firstName: string
  lastName: string
  status?: string
  isDemo?: boolean
  dateOfBirth?: string
}

export interface PatientSearchResult {
  id: string
  first_name: string
  last_name: string
  status?: string
  is_demo?: boolean
  email?: string
  phone_number?: string
  date_of_birth?: string
}

export interface SessionEnrollClientRequest {
  firstName: string
  lastName: string
  dateOfBirth: string | null
  pronoun: string | null
}

export interface ClientAccountResponse {
  // Timestamps
  created_at: string
  updated_at: string
  deleted_at: string | null
  enrollment_timestamp: string | null
  high_risk_timestamp: string | null
  report_status_updated_at: string | null
  last_assessment_date: string | null
  last_checkin_date: string | null
  date_of_invite: string | null
  archive_date: string | null

  // Basic Info
  id: string
  user_id: string
  first_name: string
  last_name: string
  preferred_name: string | null
  email: string | null
  phone_number: string | null
  date_of_birth: string | null
  pronoun: string | null
  gender: string | null
  race: string | null
  ethnicity: string | null
  age_range: string | null

  // Status & Flags
  status: string
  completed_onboarding: boolean
  is_high_risk: boolean
  is_demo: boolean
  is_deidentified: boolean
  is_archived: boolean
  is_sms_enabled: boolean
  safety_net_enabled: boolean
  has_overdue_assessments: boolean
  is_using_deprecated_child_flow: boolean

  // Medical
  medical_record: string | null
  diagnosis_code: string | null
  assessments: any | null

  // Platform & Technical
  platform: string | null
  os_player_id: string | null
  notification_preference: string
  cognito_id: string | null
  ehr_settings: any | null

  // Enrollment & Creation
  sign_up_source: string
  creation_source: string
  at_creation_contract_type: string
  at_enrollment_contract_type: string | null
  enrollment_methods: string[] | null
  enrollment_error: string | null

  // Archive
  archive_reason: string | null

  // Clinic Related
  clinician_id: string
  clinician_first_name: string
  clinician_last_name: string
  clinic_id: string
  clinic_name: string
  clinic_display_id: string
  organization_id: string

  // Risk Related
  high_risk_source: string | null

  // Relationships
  patient_links: any | null
  report_status: string | null
  day_of_appt: string | null

  assigneeUsers: Array<{
    id: string
    type: string
    info: {
      firstName: string
      lastName: string
      title: string
    }
  }>
  assessmentDeliveryMethods: string[]
  programs: Array<{
    id: string
    name: string
    startDate: string
    endDate: string
  }>

  // Computed
  isChildAge: boolean
}

export interface OrganizationProvider {
  id: string
  first_name: string
  last_name: string
}

interface OrganizationProvidersRequest {
  clinicId: string | string[]
  cliniciansOnly?: boolean
  organizationId: string
  includeCounts?: boolean
}

interface OrganizationClinicV2 {
  name: string
  id: string
  display_id: string
  organization_id: string
}

interface InsightAssessmentsRequest {
  clinicIds: string[]
}

interface InsightAssessmentsResponse {
  assessments: InsightAssessment[]
}

interface InsightAssessment {
  id: string
  name: string
  disorder: string
  count?: number
}

export interface Organization {
  id: string
  name: string
}

interface AllOrganizationsResponse {
  organizations: Organization[]
}

export interface PreferredClientListResponse {
  clientsFromEHR: {
    id: string
    firstName: string
    lastName: string
    status?: string
    isDemo?: boolean
    dateOfBirth?: string
  }[]
  clientsWithSessionLastWeek: {
    id: string
    firstName: string
    lastName: string
    status?: string
    isDemo?: boolean
    dateOfBirth?: string
  }[]
}

export const endpoints = {
  getPatientList: new Endpoint(
    'patient_list',
    ({ page, status, clinician_id, clinic_id, sortBy, order }) => {
      const queryParams = {
        page,
        status,
        clinician_id,
        clinic_id,
        sort_by: sortBy,
        sort_order: order
      }
      const searchQuery = queryString.stringify(queryParams)

      return authedApi.GET(`v2/clinicians/clients/list?${searchQuery}`)
    }
  ),
  getClientList: new Endpoint<
    { status: string; limit: number; clinicianId: string },
    ClientSearchResult[]
  >('client_list', ({ status, limit, clinicianId }) => {
    const queryParams = {
      status,
      limit,
      clinicianId
    }
    const searchQuery = queryString.stringify(queryParams)

    return authedApi.GET(`v1/clinicians/clients/list?${searchQuery}`)
  }),
  getPreferredClientList: new Endpoint<
    { clinicianId: string; preferredClientInfo?: { clientNames: string[] } },
    PreferredClientListResponse
  >('preferred_client_list', ({ clinicianId, preferredClientInfo }) => {
    const queryParams = {
      clinicianId,
      clientNames: preferredClientInfo?.clientNames
    }

    const searchQuery = queryString.stringify(queryParams, {
      arrayFormat: 'bracket'
    })

    return authedApi.GET(`v1/clinicians/clients/list/preferred?${searchQuery}`)
  }),
  getFormConstants: new Endpoint('form_constants', ({ keys }) => {
    const searchQuery = queryString.stringify({ form_constant_keys: keys })

    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      `/public/form_constants?${searchQuery}`

    return api.GET(path)
  }),
  getPatientSearch: new Endpoint<
    { search: string; include_discharged: boolean },
    PatientSearchResult[]
  >('patient_search', ({ search, include_discharged }) => {
    const queryParams = omitBy(
      {
        q: search,
        include_discharged
      },
      isNil
    )
    const searchQuery = queryString.stringify(queryParams)
    return authedApi.GET(`/clinicians/clients/search?${searchQuery}`)
  }),
  getPatientCounts: new Endpoint(
    'patient_counts',
    ({ clinic_id, clinician_id }) => {
      const queryParams = queryString.stringify({
        clinic_id,
        clinician_id
      })
      return authedApi.GET(`/clinicians/clients/counts?${queryParams}`)
    }
  ),
  getClinicianAssessments: new Endpoint(
    'clinician_assessments',
    ({ clinicianId }) => {
      const queryParams = queryString.stringify(
        {
          clinicianId
        },
        { skipNull: true }
      )
      return authedApi.GET(`/clinicians/assessments?${queryParams}`)
    }
  ),
  postClinicianAssessments: new Endpoint(
    'clinician_assessments',
    ({ data }) => {
      return authedApi.POST(`/clinicians/assessments`, JSON.stringify(data))
    }
  ),
  postClinicianUserAssessments: new Endpoint(
    'clinician_user_assigned_assessments',
    ({ data, clientId }) => {
      return authedApi.POST(
        `/clinicians/clients/${clientId}/assessments`,
        JSON.stringify(data)
      )
    }
  ),
  acceptTreatmentPlan: new Endpoint(
    'accept_treatment_plan',
    ({ clientId, treatmentPlanId, data }) => {
      return authedApi.PATCH(
        `/clinicians/clients/${clientId}/treatment-plan/${treatmentPlanId}/accept`,
        JSON.stringify(data)
      )
    }
  ),
  trackTxPlanEvent: new Endpoint(
    'track_tx_plan_event',
    ({ clientId, treatmentPlanId, data }) => {
      return authedApi.POST(
        `/clinicians/clients/${clientId}/treatment-plan/${treatmentPlanId}/track-event`,
        JSON.stringify(data)
      )
    }
  ),
  updateTxPlan: new Endpoint(
    'update_tx_plan',
    ({ clientId, treatmentPlanId, data }) => {
      return authedApi.PATCH(
        `/clinicians/clients/${clientId}/treatment-plan/${treatmentPlanId}`,
        JSON.stringify(data)
      )
    }
  ),
  generateTreatmentPlan: new Endpoint(
    'generate_treatment_plan',
    ({ clientId }) => {
      return authedApi.POST(
        `/clinicians/clients/${clientId}/treatment-plan/generate`
      )
    }
  ),
  regenerateTxPlan: new Endpoint(
    'regenerate_tx_plan',
    ({ clientId, treatmentPlanId, data }) => {
      return authedApi.POST(
        `/clinicians/clients/${clientId}/treatment-plan/${treatmentPlanId}/regenerate`,
        JSON.stringify(data)
      )
    }
  ),
  canGenerateTxPlan: new Endpoint('can_generate_tx_plan', ({ clientId }) => {
    return authedApi.GET(
      `/clinicians/clients/${clientId}/treatment-plan/can-generate`
    )
  }),
  getClientTreatmentPlan: new Endpoint(
    'client_treatment_plan',
    ({ clientId }) => {
      return authedApi.GET(`/clinicians/clients/${clientId}/treatment-plan`)
    }
  ),
  createTreatmentPlan: new Endpoint(
    'create_treatment_plan',
    ({ clientId, uploadId }) => {
      return authedApi.POST(
        `/clinicians/clients/${clientId}/treatment-plan`,
        JSON.stringify({ uploadId })
      )
    }
  ),
  deleteTreatmentPlan: new Endpoint(
    'create_treatment_plan',
    ({ clientId, treatmentPlanId }) => {
      return authedApi.DELETE(
        `/clinicians/clients/${clientId}/treatment-plan/${treatmentPlanId}`
      )
    }
  ),
  createTreatmentPlanPresignedUrl: new Endpoint(
    'create_treatment_plan_presigned_url',
    ({ clientId, contentType }) => {
      return authedApi.POST(
        `/clinicians/clients/${clientId}/treatment-plan-presigned-url`,
        JSON.stringify({
          fileType: contentType
        })
      )
    }
  ),
  getDiagnoses: new Endpoint('diagnoses', () => authedApi.GET('/diagnoses')),
  getFocusOfTreatments: new Endpoint(
    'focusOfTreatments',
    async ({ isTxPlanEnabled }) => {
      const query = queryString.stringify(
        { isTxPlanEnabled },
        { skipNull: true }
      )
      return authedApi.GET(`/focus-of-treatments?${query}`)
    }
  ),
  patchClinicianUserAssessments: new Endpoint(
    'clinician_user_assessment_cadences',
    ({ id, assessment_id, data }) => {
      return authedApi.PATCH(
        `/clinicians/clients/${id}/assessments/${assessment_id}`,
        JSON.stringify(data)
      )
    }
  ),
  patchClinicianUserAssessmentsV2: new Endpoint(
    'clinician_user_assessment_cadences_v2',
    ({ id, assessment_id, data }) => {
      return authedApi.PATCH(
        `/v2/clinicians/clients/${id}/assessments/${assessment_id}`,
        JSON.stringify(data)
      )
    }
  ),
  postClinicianUserCheckinModulesAssign: new Endpoint(
    'clinician_user_assigned_checkin_modules',
    ({ data, clientId }) => {
      return authedApi.POST(
        `/clinicians/clients/${clientId}/check_in_modules/assign`,
        JSON.stringify(data)
      )
    }
  ),
  postClientAssignCheckInModules: new Endpoint(
    'clinician_user_assigned_checkin_modules',
    ({ checkInModuleIds, clientId }) => {
      return authedApi.POST(
        `/clinicians/clients/${clientId}/assign-check-in-modules`,
        JSON.stringify({ checkInModuleIds })
      )
    }
  ),
  deleteClientUnassignCheckInModules: new Endpoint(
    'clinician_user_assigned_checkin_modules',
    ({ checkInModuleIds, clientId }) => {
      return authedApi.DELETE(
        `/clinicians/clients/${clientId}/unassign-check-in-modules`,
        JSON.stringify({ checkInModuleIds })
      )
    }
  ),
  getUserAccount: new Endpoint('user_account', () =>
    authedApi.GET('/clinicians/account')
  ),
  postUserAccount: new Endpoint('user_account', ({ data }) =>
    authedApi.POST('/clinicians/account', JSON.stringify(data))
  ),
  getClinicianUserAccount: new Endpoint<
    ClientAccountRequest,
    ClientAccountResponse
  >('clinician_user_account', ({ id }) =>
    authedApi.GET(`/clinicians/clients/${id}`)
  ),
  createProgramEnrollment: new Endpoint<
    {
      clientId: string
      programId: string
      startDate: string
      endDate: string | null
    },
    null
  >(
    'create_program_enrollment',
    ({ clientId, programId, startDate, endDate }) =>
      authedApi.POST(`/clinicians/clients/${clientId}/program_enrollment`, {
        client_id: clientId,
        program_id: programId,
        start_date: startDate,
        end_date: endDate
      })
  ),
  updateProgramEnrollment: new Endpoint<
    {
      clientId: string
      programId: string
      startDate: string
      endDate: string | null
    },
    null
  >(
    'update_program_enrollment',
    ({ clientId, programId, startDate, endDate }) =>
      authedApi.PATCH(
        `/clinicians/clients/${clientId}/program_enrollment/${programId}`,
        {
          start_date: startDate,
          end_date: endDate || null
        }
      )
  ),
  deleteProgramEnrollment: new Endpoint<
    { clientId: string; programId: string },
    null
  >('delete_program_enrollment', ({ clientId, programId }) =>
    authedApi.DELETE(
      `/clinicians/clients/${clientId}/program_enrollment/${programId}`
    )
  ),
  getClinicianUserActiveAssessments: new Endpoint(
    'clinician_user_active_assessments',
    ({ id }) => authedApi.GET(`/clinicians/clients/${id}/assessments/active`)
  ),
  getClinicianUserNextDeliveryDate: new Endpoint(
    'clinician_user_next_delivery_date',
    ({ id }) =>
      authedApi.GET(`/clinicians/clients/${id}/next-assessment-delivery`)
  ),
  postReinviteToDownload: new Endpoint('reinvite_to_download', ({ userId }) =>
    authedApi.POST(`/clinicians/clients/${userId}/reinvite-to-download`)
  ),
  getPatientClinicianList: new Endpoint(
    'patient_clinician_list',
    ({ patientId }) => {
      return authedApi.GET(`/clinicians/clients/${patientId}/clinicians`)
    }
  ),
  postTransferPatientToClinician: new Endpoint(
    'transfer_patient_clinician',
    ({ data, clientId }) => {
      return authedApi.POST(
        `/clinicians/clients/${clientId}/transfer-patient`,
        JSON.stringify(data)
      )
    }
  ),
  postManageAssignedClinicians: new Endpoint(
    'manage_assigned_clinicians',
    ({ data, clientId }) =>
      authedApi.POST(
        `/clinicians/clients/${clientId}/assign-clinicians`,
        JSON.stringify(data)
      )
  ),
  patchNextAssessmentDeliveryDate: new Endpoint(
    'next_assessment_delivery',
    ({ patientId, data }) => {
      return authedApi.PATCH(
        `/clinicians/clients/${patientId}/set-clinician-override-date`,
        JSON.stringify(data)
      )
    }
  ),
  getInsightsUtilizationEmbed: new Endpoint(
    'insights_utilization_report',
    async () =>
      await authedApi.GET('/clinicians/insights/utilization-report/embed')
  ),
  getInsightsEHRIntegrationIssuesEmbed: new Endpoint(
    'insights_ehr_integration_issues_report',
    async () =>
      await authedApi.GET(
        '/clinicians/insights/ehr-integration-issues-report/embed'
      )
  ),
  getInsightsAssessments: new Endpoint<
    InsightAssessmentsRequest,
    InsightAssessmentsResponse
  >('insights_assessments', async ({ clinicIds }) => {
    const queryParams = {
      clinicId: clinicIds
    }
    const searchQuery = queryString.stringify(queryParams, {
      skipNull: true
    })

    return authedApi.GET(
      `/clinicians/insights/filters/assessments?${searchQuery}`
    )
  }),
  getInsightsOutcomes: new Endpoint(
    'insights_outcomes',
    async ({ assessment_id, clinician_ids, age_range, clinic_ids }) => {
      const queryParams = {
        assessment_id,
        clinician_ids,
        age_range,
        clinic_ids
      }
      const searchQuery = queryString.stringify(queryParams, {
        skipNull: true
      })

      return authedApi.GET(`/clinicians/insights/outcomes?${searchQuery}`)
    }
  ),
  getClientSatisfaction: new Endpoint(
    'client_satisfaction',
    async ({
      clinic_ids,
      clinician_ids,
      age_range,
      treatment_start_date,
      treatment_end_date
    }) => {
      const queryParams = {
        clinic_ids,
        clinician_ids,
        age_range,
        treatment_start_date,
        treatment_end_date
      }
      const searchQuery = queryString.stringify(queryParams, {
        skipNull: true
      })

      return authedApi.GET(
        `/clinicians/insights/client-satisfaction-outcomes?${searchQuery}`
      )
    }
  ),
  getInsightsFilters: new Endpoint('insights_filters', async () =>
    authedApi.GET('/clinicians/insights/filters')
  ),
  getClinicianSettings: new Endpoint('clinician_settings', async () =>
    authedApi.GET('/clinicians/settings')
  ),
  patchClinicianSettings: new Endpoint('clinician_settings', ({ data }) => {
    return authedApi.PATCH(`/clinicians/settings`, JSON.stringify(data))
  }),
  getClinicianArchiveReasons: new Endpoint('clinician_archive_reasons', () =>
    authedApi.GET('/v2/constants/discharge-reasons')
  ),
  postCliniciansUsersArchive: new Endpoint(
    'clinician_users_archive',
    ({ data, clientId }) =>
      authedApi.POST(
        `/clinicians/clients/${clientId}/archive`,
        JSON.stringify(data)
      )
  ),
  postCliniciansUsersUnarchive: new Endpoint(
    'clinician_users_unarchive',
    ({ clientId, clinicianId }) =>
      authedApi.POST(
        `/clinicians/clients/${clientId}/unarchive`,
        JSON.stringify({ clinicianId })
      )
  ),
  getAllAssessmentsForClientByAssignee: new Endpoint(
    'all_client_assessments_by_assignee',
    ({ id, clinicianId }) => {
      const queryParams = queryString.stringify(
        {
          clinicianId,
          includeFreeTextQuestions: true
        },
        { skipNull: true }
      )
      return authedApi.GET(
        `/clinicians/clients/${id}/all-assessments-by-assignee?${queryParams}`
      )
    }
  ),
  getCheckInModulesForUser: new Endpoint(
    'check_in_modules_for_user',
    ({ id }) => authedApi.GET(`/clinicians/clients/${id}/check_in_modules`)
  ),
  patchCliniciansUsersPreEnroll: new Endpoint(
    'clinician_users_pre_enroll',
    ({ patientId, data }) =>
      authedApi.PATCH(
        `/clinicians/clients/${patientId}/pre-enroll`,
        JSON.stringify(data)
      )
  ),
  postCliniciansUsersPreEnrollInvite: new Endpoint(
    'clinician_users_pre_enroll_invite',
    ({ patientId }) =>
      authedApi.POST(`/clinicians/clients/${patientId}/pre-enroll/invite`)
  ),
  postSendPatientAssessmentLink: new Endpoint(
    'send_patient_assessment_link',
    ({ patient_id, is_reminder, assigneeUserId, clinicAssessmentIds }) =>
      authedApi.POST(
        `/clinicians/clients/${patient_id}/patient-link/send`,
        JSON.stringify({ is_reminder, assigneeUserId, clinicAssessmentIds })
      )
  ),
  getPatientAssessmentScores: new Endpoint(
    'patient_assessment_scores',
    ({ patientId, ids, includeFreeTextQuestions = false }) => {
      type Query = {
        includeFreeTextQuestions: boolean
        ids?: string[]
      }
      let query: Query = {
        includeFreeTextQuestions
      }
      if (ids) {
        query = {
          ...query,
          ids
        }
      }

      return authedApi.GET(
        `/clinicians/clients/${patientId}/assessment_scores?${queryString.stringify(
          query
        )}`
      )
    }
  ),
  patchClinicianUserInfo: new Endpoint(
    'patient_account_info',
    ({ patientId, data }) =>
      authedApi.PATCH(`/clinicians/clients/${patientId}`, JSON.stringify(data))
  ),
  getPrograms: new Endpoint<
    { organizationId: string },
    OrganizationProgramsResponse
  >('organization_programs', ({ organizationId }) => {
    return authedApi.GET(`/v2/organizations/${organizationId}/programs`)
  }),
  createProgram: new Endpoint<
    { organizationId: string; name: string },
    OrganizationProgramsResponse
  >('organization_programs', ({ organizationId, name }) => {
    return authedApi.POST(`/v2/organizations/${organizationId}/programs`, {
      name
    })
  }),
  updateProgram: new Endpoint<
    { organizationId: string; programId: string; name: string },
    OrganizationProgramsResponse
  >('organization_programs', ({ organizationId, programId, name }) => {
    return authedApi.PATCH(
      `/v2/organizations/${organizationId}/programs/${programId}`,
      { name }
    )
  }),
  deleteProgram: new Endpoint<
    { organizationId: string; programId: string },
    OrganizationProgramsResponse
  >('organization_programs', ({ organizationId, programId }) => {
    return authedApi.DELETE(
      `/v2/organizations/${organizationId}/programs/${programId}`
    )
  }),
  getOrganizationProviders: new Endpoint<
    OrganizationProvidersRequest,
    OrganizationProvider[]
  >(
    'organization_providers',
    ({
      organizationId,
      clinicId,
      cliniciansOnly = false,
      includeCounts = true
    }) => {
      const queryParams = {
        clinicId,
        cliniciansOnly,
        includeCounts
      }
      const searchQuery = queryString.stringify(queryParams)

      return authedApi.GET(
        `/organizations/${organizationId}/providers?${searchQuery}`
      )
    }
  ),
  getOrganizationProvidersV2: new Endpoint(
    'organization_providers_v2',
    ({ organizationId, clinicId, page, perPage, clinicianSearchQuery }) => {
      const queryParams = {
        page,
        perPage,
        clinicId,
        clinicianSearchQuery
      }
      const searchQuery = queryString.stringify(queryParams)

      return authedApi.GET(
        `/v2/organizations/${organizationId}/providers?${searchQuery}`
      )
    }
  ),
  patchOrganizationProviders: new Endpoint(
    'organization_providers',
    ({ data, organizationId, providerId }) =>
      authedApi.PATCH(
        `/organizations/${organizationId}/providers/${providerId}`,
        JSON.stringify(data)
      )
  ),
  postOrganizationProviders: new Endpoint(
    'organization_providers',
    ({ data, organizationId }) =>
      authedApi.POST(
        `/organizations/${organizationId}/providers`,
        JSON.stringify(data)
      )
  ),
  getOrganizationSupervisors: new Endpoint(
    'get_organization_supervisors',
    ({ providerId }) => {
      return authedApi.GET(`/supervisors/${providerId}/clinicians`)
    }
  ),
  postOrganizationSupervisors: new Endpoint(
    'clinician_supervisors',
    ({ data, supervisorClinicianId }) =>
      authedApi.POST(
        `/supervisors/clinician-supervisors/${supervisorClinicianId}`,
        JSON.stringify(data)
      )
  ),
  removeOrganizationSupervisors: new Endpoint(
    'remove_clinician_supervisors',
    ({ supervisorClinicianId, roleId }) =>
      authedApi.POST(
        `/supervisors/remove-clinician-supervisors/${supervisorClinicianId}/${roleId}`
      )
  ),
  removeExistingOrganizationSupervisors: new Endpoint(
    'remove_existing_clinician_supervisors',
    ({ supervisorClinicianId, data }) =>
      authedApi.POST(
        `/supervisors/${supervisorClinicianId}/remove-clinicians`,
        JSON.stringify(data)
      )
  ),
  deleteOrganizationProvider: new Endpoint(
    'organization_provider',
    ({ data, providerId, organizationId }) =>
      authedApi.PATCH(
        `/organizations/${organizationId}/providers/${providerId}/archive`,
        JSON.stringify(data)
      )
  ),
  patchProgressNoteSettings: new Endpoint(
    'organization_progress_note_settings',
    ({ data, organizationId }) =>
      authedApi.PATCH(
        `/v2/organizations/${organizationId}/progress-note-settings`,
        JSON.stringify(data)
      )
  ),
  trackProviderLogin: new Endpoint('provider_login', () =>
    authedApi.PATCH(`/clinicians/track-login`)
  ),
  getOrganizationRoles: new Endpoint('organization_roles', () =>
    authedApi.GET('/organizations/roles')
  ),
  getEhrAppointments: new Endpoint(
    'ehr_appointments',
    ({ page, perPage, visitType, organizationId }) => {
      const queryParams = omitBy(
        {
          page,
          perPage,
          visitType,
          organizationId
        },
        isNil
      )
      const searchQuery = queryString.stringify(queryParams)
      return authedApi.GET(`/organizations/ehr-appointments?${searchQuery}`)
    }
  ),
  updateEhrAppointment: new Endpoint(
    'update_ehr_appointment',
    ({ ehrAppointmentId, visitTypeOverride }) =>
      authedApi.PUT(
        `/organizations/ehr-appointments/${ehrAppointmentId}`,
        JSON.stringify({ visitTypeOverride })
      )
  ),
  getPublicClinician: new Endpoint('public_clinician', ({ email }) => {
    // + is a special character in query strings that gets decoded to a space, so we have to use encodeURIComponent
    const encodedEmail = encodeURIComponent(email)
    const url = new URL(
      `${process.env.REACT_APP_NODE_API_ROOT_URL}/clinician?email=${encodedEmail}`
    )
    return api.GET(url.toString())
  }),
  patchClinicianAccount: new Endpoint(
    'clinician_account',
    ({ id, password, firstName, lastName, license_level }) => {
      const body = JSON.stringify({
        password,
        first_name: firstName,
        last_name: lastName,
        license_level
      })
      const url = new URL(
        `${process.env.REACT_APP_NODE_API_ROOT_URL}/public/clinicians/${id}/set-account`
      )
      return api.PATCH(url.toString(), body)
    }
  ),
  getOrganizationClinics: new Endpoint(
    'organization_clinics',
    ({ organizationId }) =>
      authedApi.GET(`/organizations/${organizationId}/clinics`)
  ),
  getOrganizationClinicsV2: new Endpoint<
    IRequestParams,
    OrganizationClinicV2[]
  >('organization_clinics_v2', ({ organizationId }) =>
    authedApi.GET(`/v2/organizations/${organizationId}/clinics`)
  ),
  postOrganizationProviderInvite: new Endpoint(
    'organization_provider_invite',
    ({ providerId, organizationId }) =>
      authedApi.POST(
        `/organizations/${organizationId}/providers/${providerId}/invite`
      )
  ),
  getClinicClinicians: new Endpoint(
    'clinic_clinicians',
    ({ clinicDisplayId, showVisibleOnly }) =>
      authedApi.GET(
        `/clinics/clinicians?clinic_display_id=${clinicDisplayId}&show_visible_only=${showVisibleOnly}`
      )
  ),
  putClinicName: new Endpoint('clinic_name', ({ data, clinicId }) =>
    authedApi.PUT(`/clinicians/clinics/${clinicId}`, JSON.stringify(data))
  ),
  putClinicURL: new Endpoint('clinic_url', ({ data, clinicId }) =>
    authedApi.PUT(`/clinicians/clinic-url/${clinicId}`, JSON.stringify(data))
  ),
  getAllOrganizations: new Endpoint<void, AllOrganizationsResponse>(
    'all_organizations',
    () => authedApi.GET(`/organizations/list`)
  ),
  getAllClinics: new Endpoint('all_clinics', () =>
    authedApi.GET(`/clinics/all`)
  ),
  getClinicClaims: new Endpoint(
    'clinic_claims',
    ({ dateStart, dateEnd, clinicId, tz }) => {
      const queryParams = {
        dateStart,
        dateEnd,
        tz
      }
      const searchQuery = queryString.stringify(queryParams)

      return authedApi.GET(`/clinics/${clinicId}/claims?${searchQuery}`)
    }
  ),
  putChangeClaimStatus: new Endpoint(
    'change_claim_status',
    ({ claimId, data }) =>
      authedApi.PUT(`/clinics/claims/${claimId}`, JSON.stringify(data))
  ),
  postClinicianEnrollClient: new Endpoint('enroll_client', ({ data }) =>
    authedApi.POST('/clinicians/v2/enroll', JSON.stringify(data))
  ),
  postSessionEnrollClient: new Endpoint<
    {
      data: {
        firstName: string
        lastName: string
        dateOfBirth: string | null
        pronoun: string | null
      }
    },
    { patientId: string }
  >('session_enroll_client', ({ data }) =>
    authedApi.POST('/v2/clinicians/session-enroll', JSON.stringify(data))
  ),
  postClinicianEnrollClientV2: new Endpoint('enroll_client_v2', ({ data }) =>
    authedApi.POST('/v2/clinicians/enroll', JSON.stringify(data))
  ),
  getLicenseLevels: new Endpoint('clinician_license_levels', () =>
    authedApi.GET('/license-levels')
  ),
  getInsurancePayers: new Endpoint(
    'insurance_payers',
    ({ organizationId, page, search, shortList = null }) => {
      const queryParams = {
        search,
        page,
        shortList
      }
      const searchQuery = queryString.stringify(queryParams)

      return authedApi.GET(
        `/organizations/${organizationId}/billing/insurance-payers?${searchQuery}`
      )
    }
  ),
  getBillingOptions: new Endpoint('billing_options', () =>
    authedApi.GET('/public/billing_rule_options')
  ),
  patchOrgBillingPreferences: new Endpoint(
    'org_billing_preferences',
    ({ organizationId, data }) => {
      return authedApi.PATCH(
        `/organizations/${organizationId}/billing/preferences`,
        JSON.stringify(data)
      )
    }
  ),
  getOrgBillingPreferences: new Endpoint(
    'org_billing_preferences',
    ({ organizationId }) =>
      authedApi.GET(`/organizations/${organizationId}/billing/preferences`)
  ),
  getOrgBillingRules: new Endpoint('org_billing_rules', ({ organizationId }) =>
    authedApi.GET(`/organizations/${organizationId}/billing/rules`)
  ),
  postOrgBillingRule: new Endpoint(
    'org_billing_rule',
    ({ organizationId, data }) =>
      authedApi.POST(
        `/organizations/${organizationId}/billing/rules`,
        JSON.stringify(data)
      )
  ),
  postForgotPasswordRequestCode: new Endpoint('request_code', ({ data }) =>
    authedApi.POST('/public/forgot-password/request-code', JSON.stringify(data))
  ),
  postForgotPasswordConfirm: new Endpoint('reset_confirm', ({ data }) =>
    authedApi.POST('/public/forgot-password/confirm', JSON.stringify(data))
  ),
  getClinicianCheckInModules: new Endpoint('clinician_check_in_modules', () =>
    authedApi.GET('/clinicians/check_in_modules')
  ),
  postClinicianAvatar: new Endpoint('clinician-avatar', ({ image }) => {
    const formData = new FormData()
    formData.append('image', image)
    const queryParams = {
      type: 'avatar'
    }
    const searchQuery = queryString.stringify(queryParams)

    return authedApi.POST(`/clinicians/upload?${searchQuery}`, formData, {
      isFormData: true
    })
  }),
  deleteClinicianAvatar: new Endpoint('clinician-avatar', () =>
    authedApi.DELETE('/clinicians/avatar')
  ),
  getClientLifestyle: new Endpoint('client-lifestyle', ({ clientId }) => {
    return authedApi.GET(`/clinicians/clients/${clientId}/lifestyle`)
  }),
  getClientSymptoms: new Endpoint('client-symptoms', ({ clientId }) => {
    return authedApi.GET(`/clinicians/clients/${clientId}/symptoms`)
  }),
  getClientLifestyleGraph: new Endpoint(
    'client-lifestyle-graph',
    ({ clientId }) => {
      return authedApi.GET(`/clinicians/clients/${clientId}/lifestyle/graph`)
    }
  ),
  patchCliniciansClientsDecline: new Endpoint(
    'clinician_clieint_decline',
    ({ clientId }) => authedApi.PATCH(`/clinicians/clients/${clientId}/decline`)
  ),
  getCaseloadOverview: new Endpoint(
    'caseload_overview',
    ({ overrideClinicianId }) => {
      const queryParams = omitBy(
        {
          overrideClinicianId
        },
        isNil
      )
      const searchQuery = queryString.stringify(queryParams)
      return authedApi.GET(
        `/clinicians/dashboard/caseload-overview?${searchQuery}`
      )
    }
  ),
  getCaseloadBreakdown: new Endpoint(
    'caseload-breakdown',
    ({ overrideClinicianId }) => {
      const queryParams = omitBy(
        {
          overrideClinicianId
        },
        isNil
      )
      const searchQuery = queryString.stringify(queryParams)
      return authedApi.GET(
        `/clinicians/dashboard/caseload-breakdown?${searchQuery}`
      )
    }
  ),
  patchClientHighRisk: new Endpoint(
    'client-high-risk',
    ({ clientId, data }) => {
      return authedApi.PATCH(
        `clinicians/clients/${clientId}/high-risk`,
        JSON.stringify(data)
      )
    }
  ),
  getClinicianDashboardNotifications: new Endpoint(
    'clinician_dashboard_notifications',
    ({ page = 1, eventTypes, overrideClinicianId }) => {
      const queryParams = omitBy(
        {
          page,
          eventTypes,
          overrideClinicianId
        },
        isNil
      )
      const searchQuery = queryString.stringify(queryParams)
      return authedApi.GET(`/clinicians/dashboard/notifications?${searchQuery}`)
    }
  ),
  getClinicianDashboardSessions: new Endpoint(
    'clinician_dashboard_sessions',
    ({ overrideClinicianId, includeRecordingDrafts }) => {
      const queryParams = omitBy(
        {
          overrideClinicianId,
          includeRecordingDrafts
        },
        isNil
      )
      const searchQuery = queryString.stringify(queryParams)
      return authedApi.GET(`/clinicians/dashboard/sessions?${searchQuery}`)
    }
  ),
  getClinicianDashboardAudit: new Endpoint('clinician_dashboard_audit', () => {
    return authedApi.GET(`/clinicians/dashboard/audit-reports`)
  }),
  getUnreadNotificationCount: new Endpoint('notification_unread_count', () =>
    authedApi.GET('clinicians/dashboard/notifications/unread-count')
  ),
  putClinicianNotificationRead: new Endpoint(
    'clinician_notification_read',
    ({ id }) =>
      authedApi.PUT(
        `/clinicians/dashboard/notifications/${id}`,
        JSON.stringify({ is_read: true })
      )
  ),
  getFeatureWaitlist: new Endpoint('feature_waitlist', () =>
    authedApi.GET('/clinicians/feature-waitlists')
  ),
  postJoinWaitlist: new Endpoint('join_waitlist', ({ feature }) =>
    authedApi.POST(`/clinicians/feature-waitlists`, JSON.stringify({ feature }))
  ),
  getAllInterventions: new Endpoint(
    'all_interventions',
    ({ clientId, excludeNonGuided = true }) => {
      const queryParams = {
        excludeNonGuided
      }
      const searchQuery = queryString.stringify(queryParams)

      return authedApi.GET(
        `/clinicians/clients/${clientId}/interventions?${searchQuery}`
      )
    }
  ),
  getInterventionOverview: new Endpoint(
    'intervention_overview',
    ({ clientId, interventionId }) =>
      authedApi.GET(
        `/clinicians/clients/${clientId}/interventions/${interventionId}/overview`
      )
  ),
  getClientIntervention: new Endpoint(
    'client_intervention',
    ({ clientId, interventionId, clientInterventionId }) =>
      authedApi.GET(
        `/clinicians/clients/${clientId}/interventions/${interventionId}/activity${
          clientInterventionId
            ? '?patientInterventionId=' + clientInterventionId
            : ''
        }`
      )
  ),
  deletePartiallyCompletedIntervention: new Endpoint(
    'delete_intervention',
    ({ clientId, interventionId, clientInterventionId }) =>
      authedApi.DELETE(
        `/clinicians/clients/${clientId}/interventions/${interventionId}/submissions/${clientInterventionId}`
      )
  ),
  postClientIntervention: new Endpoint(
    'submit_client_intervention',
    ({ clientId, interventionId, data }) =>
      authedApi.POST(
        `/clinicians/clients/${clientId}/interventions/${interventionId}`,
        JSON.stringify(data)
      )
  ),
  putClientIntervention: new Endpoint(
    'update_client_intervention',
    ({ clientId, interventionId, clientInterventionId, data }) =>
      authedApi.PUT(
        `/clinicians/clients/${clientId}/interventions/${interventionId}/submissions/${clientInterventionId}`,
        JSON.stringify(data)
      )
  ),
  getAssessmentFrequencies: new Endpoint('get_assessment_frequencies', () =>
    authedApi.GET('v2/constants/assessment-frequencies')
  ),
  getClinicianOutcomes: new Endpoint(
    'clinician_outcomes',
    async ({
      assessment_id,
      clinician_ids,
      age_range,
      clinic_ids,
      start_date,
      end_date,
      baseline_score_min,
      baseline_score_max,
      program_ids
    }) => {
      const queryParams = {
        assessment_id,
        clinician_ids,
        age_range,
        clinic_ids,
        start_date,
        end_date,
        baseline_score_min,
        baseline_score_max,
        program_ids
      }
      const searchQuery = queryString.stringify(queryParams, {
        skipNull: true
      })

      return authedApi.GET(
        `/clinicians/insights/clinician-outcomes?${searchQuery}`
      )
    }
  ),
  getPopulationOutcomes: new Endpoint(
    'population_outcomes',
    async ({
      assessment_id,
      clinician_ids,
      age_range,
      clinic_ids,
      start_date,
      end_date,
      baseline_score_min,
      baseline_score_max,
      program_ids
    }) => {
      const queryParams = {
        assessment_id,
        clinician_ids,
        age_range,
        clinic_ids,
        start_date,
        end_date,
        baseline_score_min,
        baseline_score_max,
        program_ids
      }
      const searchQuery = queryString.stringify(queryParams, {
        skipNull: true
      })

      return authedApi.GET(
        `/clinicians/insights/population-outcomes?${searchQuery}`
      )
    }
  ),
  postClientParticipant: new Endpoint(
    'client-participant',
    ({ clientId, data }) =>
      authedApi.POST(
        `/clinicians/clients/${clientId}/participant`,
        JSON.stringify(data)
      )
  ),
  patchClientParticipantMarkInactive: new Endpoint(
    'client-participant',
    ({ clientId, participantId }) =>
      authedApi.PATCH(
        `/clinicians/clients/${clientId}/participant/${participantId}/mark-inactive`
      )
  ),
  patchClientParticipant: new Endpoint(
    'client-participant',
    ({ clientId, participantId, data }) =>
      authedApi.PATCH(
        `/clinicians/clients/${clientId}/participant/${participantId}`,
        JSON.stringify(data)
      )
  ),
  getClientParticipants: new Endpoint('client-participants', ({ clientId }) =>
    authedApi.GET(`/clinicians/clients/${clientId}/participant/list`)
  ),
  postClientAssignAssessments: new Endpoint(
    'client-assign-assessments',
    ({ clientId, data }) =>
      authedApi.POST(
        `/clinicians/clients/${clientId}/assign-assessments`,
        JSON.stringify(data)
      )
  ),
  deleteClientUnassignAssessments: new Endpoint(
    'client-unassign-assessments',
    ({ data, clientId }) => {
      return authedApi.DELETE(
        `/clinicians/clients/${clientId}/unassign-assessments`,
        JSON.stringify(data)
      )
    }
  ),
  getPTOSchedule: new Endpoint('get-pto-schedule', ({ clientId }) =>
    authedApi.GET(`/clinicians/clients/${clientId}/post-treatment-schedule`)
  ),
  deletePTOSchedule: new Endpoint('delete-pto-schedule', ({ clientId }) =>
    authedApi.POST(
      `/clinicians/clients/${clientId}/post-treatment-schedule/cancel`
    )
  ),
  getSSORedirectUrl: new Endpoint(
    'get-sso-redirect',
    ({ email, redirectUrl, state }) => {
      const searchQuery = queryString.stringify(
        {
          email,
          redirectUrl,
          state
        },
        {
          skipNull: true
        }
      )
      const path =
        process.env.REACT_APP_NODE_API_ROOT_URL + `/v1/auth/sso?${searchQuery}`
      return api.GET(path)
    }
  ),
  validateSSOCode: new Endpoint('validate-sso-code', ({ code }) => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL + '/v1/auth/sso/validate-code'
    return api.POST(path, JSON.stringify({ code }))
  }),
  refreshAccessToken: new Endpoint(
    'refresh-access-token',
    ({ refreshToken }) => {
      const path =
        process.env.REACT_APP_NODE_API_ROOT_URL + '/v1/auth/refresh-token'
      return api.POST(path, JSON.stringify({ refreshToken }))
    }
  ),
  getReferralURL: new Endpoint('referral_link', () =>
    authedApi.GET('/clinicians/referral_link')
  ),
  getStates: new Endpoint('states', () => {
    const path = process.env.REACT_APP_NODE_API_ROOT_URL + '/states'
    return api.GET(path)
  }),
  postHubspotTrialLead: new Endpoint('hubspot_lead', ({ data }) => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      '/clinician-signup/trial/hubspot-lead'
    return api.POST(path, JSON.stringify({ data }))
  }),
  postHubspotDemoLead: new Endpoint('hubspot_lead', ({ data }) => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      '/clinician-signup/demo/hubspot-lead'
    return api.POST(path, JSON.stringify({ data }))
  }),
  getPaywallValidation: new Endpoint('paywall_validation', () => {
    return authedApi.GET('/clinicians/validate-paywall')
  }),
  getLegacyBilling: new Endpoint('legacy_billing', ({ clinicId }) => {
    return authedApi.GET(`/clinicians/view-billing/${clinicId}`)
  }),
  getPrices: new Endpoint('list_prices', () => {
    return authedApi.GET('/clinicians/payment/list-prices')
  }),
  getPlan: new Endpoint('get-plan', ({ planId }) =>
    authedApi.GET(`/clinicians/payment/get-plan/${planId}/`)
  ),
  getDefaultCard: new Endpoint('default-card', () =>
    authedApi.GET(`/clinicians/payment/default-card`)
  ),
  getClinicBilling: new Endpoint('billing-clinic', ({ clinicId }) =>
    authedApi.GET(`/clinicians/billing/clinic/${clinicId}`)
  ),
  getClinicInvoices: new Endpoint(
    'billing-clinic-invoices',
    async ({ clinicId, amountOfInvoicesToLoad }) => {
      const queryParams = {
        amountOfInvoicesToLoad
      }
      const searchQuery = queryString.stringify(queryParams, {
        skipNull: true
      })

      return authedApi.GET(
        `/clinicians/billing/clinic/invoices/${clinicId}?${searchQuery}`
      )
    }
  ),
  getClinicFailedInvoices: new Endpoint(
    'billing-clinic-failed-invoices',
    async ({ clinicId }) => {
      return authedApi.GET(
        `/clinicians/billing/clinic/invoices/amountdue/${clinicId}`
      )
    }
  ),
  updateClinicBilling: new Endpoint('billing-update', ({ data }) => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      '/clinicians/billing/default-card/'
    return api.POST(path, JSON.stringify({ data }))
  }),
  postRetryInvoicePayments: new Endpoint(
    'billing-retry-invoice-payment',
    ({ data }) => {
      const path =
        process.env.REACT_APP_NODE_API_ROOT_URL +
        '/clinicians/billing/retry-invoice-payment'
      return api.POST(path, JSON.stringify({ data }))
    }
  ),
  updateClinicInvoice: new Endpoint('update-invoice-email', ({ data }) => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      '/clinicians/billing/update-invoice-email/'
    return api.POST(path, JSON.stringify({ data }))
  }),
  postUpsertSubscription: new Endpoint('upsert_subscription', ({ data }) => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      '/clinicians/payment/upsert-subscription'
    return api.POST(path, JSON.stringify({ data }))
  }),
  postUpgradeToGroupTrial: new Endpoint('upgrade_group_trial', ({ data }) => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      '/clinicians/payment/upgrade-group-trial'
    return api.POST(path, JSON.stringify({ data }))
  }),
  getClinicians: new Endpoint('get_clinicians', () => {
    return authedApi.GET('/clinicians/payment/get-clinicians')
  }),
  postSelfServeSignup: new Endpoint('self_serve_signup', ({ data }) => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL + '/clinician-signup-self-serve'
    return api.POST(path, JSON.stringify({ data }))
  }),
  postDeleteAccount: new Endpoint('delete_account', data => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL + '/clinicians/cancel-account'
    return api.POST(path, JSON.stringify({ data }))
  }),
  patchIndividualBillingPlan: new Endpoint(
    'patch_individual_billing_plan',
    data => {
      const path =
        process.env.REACT_APP_NODE_API_ROOT_URL +
        '/clinicians/individual-billing-plan'
      return api.PATCH(path, JSON.stringify(data))
    }
  ),
  getAccountDeletionReasons: new Endpoint('deletion_reasons', () => {
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      '/clinicians/account-cancellation-reasons'
    return api.GET(path)
  }),
  getClientCheckInReminders: new Endpoint(
    'get-client-check-in-reminders',
    ({ clientId }) => {
      return authedApi.GET(`/clinicians/clients/${clientId}/check-in-reminders`)
    }
  ),
  getClientUserCommPreferences: new Endpoint(
    'get-client-user-comm-prefs',
    ({ clientId }) => {
      return authedApi.GET(
        `/clinicians/clients/${clientId}/user-comm-preferences`
      )
    }
  ),
  postClinicLogo: new Endpoint('clinic-logo', ({ image, clinicId }) => {
    const formData = new FormData()
    formData.append('image', image)
    const queryParams = {
      type: 'clinic-logo'
    }
    const searchQuery = queryString.stringify(queryParams)

    return authedApi.POST(
      `/clinicians/clinics/${clinicId}/upload?${searchQuery}`,
      formData,
      {
        isFormData: true
      }
    )
  }),
  deleteClinicLogo: new Endpoint('clinic-logo', ({ clinicId }) =>
    authedApi.DELETE(`/clinicians/clinics/${clinicId}/clinic-logo`)
  ),
  getClinicSafetyNetResources: new Endpoint(
    'getClinicSafetyNetResources',
    ({ clinicId }) =>
      authedApi.GET(`/clinics/${clinicId}/safety-net/support-resources`)
  ),
  getOrganizationWorkflows: new Endpoint(
    'getOrganizationWorkflows',
    ({ organizationId }) =>
      authedApi.GET(`/organizations/${organizationId}/workflows`)
  ),
  getOrganizationWorkflow: new Endpoint(
    'getOrganizationWorkflow',
    ({ organizationId, workflowId }) =>
      authedApi.GET(`/organizations/${organizationId}/workflows/${workflowId}`)
  ),
  updateOrganizationWorkflow: new Endpoint(
    'updateOrganizationWorkflow',
    ({ organizationId, workflowId, data }) =>
      authedApi.PATCH(
        `/organizations/${organizationId}/workflows/${workflowId}`,
        data
      )
  ),
  upsertOrganizationWorkflowTrigger: new Endpoint(
    'updateOrganizationWorkflowTrigger',
    ({ organizationId, workflowId, data }) =>
      authedApi.PUT(
        `/organizations/${organizationId}/workflows/${workflowId}/trigger`,
        data
      )
  ),
  deleteOrganizationWorkflow: new Endpoint(
    'deleteOrganizationWorkflow',
    ({ organizationId, workflowId }) =>
      authedApi.DELETE(
        `/organizations/${organizationId}/workflows/${workflowId}`
      )
  ),
  createOrUpdateWorkflowAction: new Endpoint(
    'createOrUpdateWorkflowAction',
    ({ organizationId, workflowId, data }) =>
      authedApi.PUT(
        `/organizations/${organizationId}/workflows/${workflowId}/actions`,
        data
      )
  ),
  deleteWorkflowAction: new Endpoint(
    'deleteWorkflowAction',
    ({ organizationId, workflowId, actionId }) =>
      authedApi.DELETE(
        `/organizations/${organizationId}/workflows/${workflowId}/actions/${actionId}`
      )
  ),
  bulkClinicianUpload: new Endpoint('bulk-clinician-upload', ({ clinicians }) =>
    authedApi.POST(
      '/v2/admin/clinicians/bulk-upload',
      JSON.stringify({ clinicians })
    )
  ),
  getOrganizationAssessments: new Endpoint(
    'getOrganizationAssessments',
    ({ organizationId }) =>
      authedApi.GET(`/organizations/${organizationId}/assessments`)
  ),
  getOrganizationAssessmentCollections: new Endpoint(
    'getOrganizationAssessmentCollections',
    ({ organizationId }) =>
      authedApi.GET(`/organizations/${organizationId}/assessment-collections`)
  ),
  getInterventions: new Endpoint('getInterventions', () =>
    authedApi.GET('/interventions')
  ),
  getInterventionRecommendations: new Endpoint(
    'getInterventionRecommendations',
    ({ clientId }) =>
      authedApi.GET(
        `/clinicians/clients/${clientId}/intervention-recommendations`
      )
  ),
  dismissInterventionRecommendation: new Endpoint(
    'dismissInterventionRecommendation',
    ({ clientId, interventionRecommendationId }) =>
      authedApi.PATCH(
        `/clinicians/clients/${clientId}/intervention-recommendations/${interventionRecommendationId}/dismiss`
      )
  ),
  selectInterventionRecommendation: new Endpoint(
    'selectInterventionRecommendation',
    ({ clientId, interventionRecommendationId }) =>
      authedApi.PATCH(
        `/clinicians/clients/${clientId}/intervention-recommendations/${interventionRecommendationId}/select`
      )
  ),
  readdInterventionRecommendation: new Endpoint(
    'readdInterventionRecommendation',
    ({ clientId, interventionId }) =>
      authedApi.PATCH(
        `/clinicians/clients/${clientId}/intervention-recommendations/${interventionId}/readd`
      )
  ),
  createSupportResource: new Endpoint(
    'createSupportResource',
    ({ clinicId, resource }) =>
      authedApi.POST(
        `/clinics/${clinicId}/safety-net/support-resources`,
        JSON.stringify({ resource })
      )
  ),
  deleteSupportResource: new Endpoint(
    'deleteSupportResource',
    ({ clinicId, resourceId }) =>
      authedApi.DELETE(
        `/clinics/${clinicId}/safety-net/support-resources/${resourceId}`
      )
  ),
  createMeeting: new Endpoint(
    'create-meeting',
    ({
      sessionId,
      startRecording,
      noteType,
      isTelehealth,
      isUsingHeadphones
    }) =>
      authedApi.POST(
        '/v1/meetings',
        JSON.stringify({
          sessionId,
          startRecording,
          noteType,
          isTelehealth,
          isUsingHeadphones
        })
      )
  ),
  createSetupMeeting: new Endpoint('create-setup-meeting', ({ sessionId }) =>
    authedApi.POST('/v1/meetings/setup-meeting', JSON.stringify({ sessionId }))
  ),
  startRecording: new Endpoint('start-recording', ({ meetingId, noteType }) =>
    authedApi.POST(
      `/v1/meetings/${meetingId}/start-recording`,
      JSON.stringify({ noteType })
    )
  ),
  stopRecording: new Endpoint(
    'stop-recording',
    ({ meetingId, mediaPipelineId }) =>
      authedApi.POST(
        `/v1/meetings/${meetingId}/end-recording`,
        JSON.stringify({ mediaPipelineId })
      )
  ),
  startAudioCapture: new Endpoint(
    'start-audio-capture',
    ({ sessionId, isTelehealth, isUsingHeadphones }) =>
      authedApi.POST(
        `/v1/sessions/${sessionId}/start-recording`,
        JSON.stringify({
          isTelehealth,
          isUsingHeadphones
        })
      )
  ),
  getPresignedUrl: new Endpoint(
    'get-presigned-url',
    ({ sessionId, timestamp }) =>
      authedApi.POST(
        `/v1/sessions/${sessionId}/presigned-url`,
        JSON.stringify({
          timestamp
        })
      )
  ),
  toggleShowSafetyPlanning: new Endpoint(
    'toggleShowSafetyPlanning',
    ({ clinicId, showClientSafetyPlanning }) =>
      authedApi.PATCH(`/v1/clinics/${clinicId}`, { showClientSafetyPlanning })
  ),
  getOrganizationPrograms: new Endpoint(
    'getOrganizationPrograms',
    ({ organizationId }) =>
      authedApi.GET(`/v2/organizations/${organizationId}/programs`)
  ),
  getOrganizationAppointmentTypes: new Endpoint(
    'getOrganizationPrograms',
    ({ organizationId }) =>
      authedApi.GET(`/v2/organizations/${organizationId}/appointment-types`)
  ),
  getProducts: new Endpoint('getProducts', () =>
    authedApi.GET(
      '/clinicians/products/search?pricingGroup=doc_automation_self_serve_paid'
    )
  ),
  selectDocumentationAutomationPlan: new Endpoint(
    'selectDocumentationAutomationPlan',
    ({ priceId, paymentMethodId, clinicId }) =>
      authedApi.POST(
        '/clinicians/payment/documentation-automation/select-plan',
        JSON.stringify({ priceId, paymentMethodId, clinicId })
      )
  ),
  tryDocAutomationFree: new Endpoint('tryDocAutomationFree', () =>
    authedApi.POST('/clinicians/payment/documentation-automation/try-for-free')
  ),
  startEbcFreeTrial: new Endpoint(
    'startEbcFreeTrial',
    ({ paymentMethodId, clinicId }) =>
      authedApi.POST(
        '/clinicians/payment/evidence-based-care/start-free-trial',
        JSON.stringify({ paymentMethodId, clinicId })
      )
  ),
  startEBCSubscription: new Endpoint(
    'startEBCSubscription',
    ({ paymentMethodId, clinicId }) =>
      authedApi.POST(
        '/clinicians/payment/evidence-based-care/start-subscription',
        JSON.stringify({ paymentMethodId, clinicId })
      )
  ),
  patchSessionTimestampOverrides: new Endpoint(
    'patchSessionTimestampOverrides',
    ({ sessionId, data }) => {
      return authedApi.PATCH(
        `/sessions/${sessionId}/timestamp-overrides`,
        JSON.stringify(data)
      )
    }
  ),
  getSessionTranscript: new Endpoint('getSessionTranscript', ({ sessionId }) =>
    authedApi.GET(`/sessions/${sessionId}/transcript`)
  ),
  deleteSessionTranscript: new Endpoint('deleteSessionTranscript', ({ id }) =>
    authedApi.DELETE(`/sessions/session-transcripts/${id}`)
  ),
  postRegenerateProgressNote: new Endpoint(
    'postRegenerateProgressNote',
    ({ progressNoteId, userInstructions }) =>
      authedApi.POST(
        `/progress-notes/${progressNoteId}/regenerate`,
        JSON.stringify({ userInstructions })
      )
  ),
  postRegenerateWithPreferences: new Endpoint(
    'postRegenerateWithPreferences',
    ({ progressNoteId, preferences, saveAsDefault = false }) =>
      authedApi.POST(
        `/progress-notes/${progressNoteId}/regenerate-with-preferences`,
        JSON.stringify({ preferences, saveAsDefault })
      )
  ),
  generateProgressNote: new Endpoint(
    'generateProgressNote',
    ({ sessionId, noteType }) =>
      authedApi.POST(
        `/progress-notes/generate`,
        JSON.stringify({ sessionId, noteType })
      )
  ),
  postChangeNoteType: new Endpoint(
    'postChangeNoteType',
    ({ progressNoteId, noteType, treatmentApproach }) =>
      authedApi.POST(
        `/progress-notes/${progressNoteId}/change-note-type`,
        JSON.stringify({ noteType, treatmentApproach })
      )
  ),
  getProgressNoteSettings: new Endpoint(
    'getProgressNoteSettings',
    ({ organizationId }) =>
      authedApi.GET(`/organizations/${organizationId}/progress-note-settings`)
  ),
  getProgressNoteTypes: new Endpoint(
    'getProgressNoteTypes',
    ({ organizationId, individualOnly = false }) => {
      const queryParams = {
        individualOnly
      }
      const searchQuery = queryString.stringify(queryParams)
      return authedApi.GET(
        `/organizations/${organizationId}/progress-note-types?${searchQuery}`
      )
    }
  ),
  getSessionSummaries: new Endpoint('getSessionSummaries', ({ sessionId }) =>
    authedApi.GET(`/sessions/${sessionId}/summaries`)
  ),
  deleteSessionSummary: new Endpoint('getSessionSummaries', ({ id }) =>
    authedApi.DELETE(`/sessions/summaries/${id}`)
  ),
  updateSessionSummary: new Endpoint('updateSessionSummary', ({ id, data }) =>
    authedApi.PATCH(`/sessions/summaries/${id}`, JSON.stringify(data))
  ),
  deleteSessionProgressNotes: new Endpoint(
    'deleteSessionProgressNotes',
    ({ id }) => authedApi.DELETE(`/sessions/${id}/progress-notes`)
  ),
  getClinicianClientLastSession: new Endpoint(
    'getClinicianClientLastSession',
    ({ id }) => authedApi.GET(`/clinicians/clients/${id}/last-session`)
  ),
  getSuggestedContent: new Endpoint('getSuggestedContent', ({ sessionId }) =>
    authedApi.GET(`/sessions/${sessionId}/suggested-content`)
  )
}
