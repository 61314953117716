import {
  IconButton,
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useBreakpointValue,
  VStack,
  Link,
  ModalFooter,
  Button,
  Flex,
  Input,
  Badge
} from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import {
  useClinicControllerGetCheckIns,
  useClinicControllerAssignCheckIn,
  getClinicControllerGetAssignedCheckInsQueryKey,
  useCheckinsControllerV1GetAllCheckIns
} from '~/clinician-api'
import { AddCircleIcon, CheckCircle } from '@blueprinthq/joy'
import { buildCheckInPreviewUrl } from '@utilities'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import flagsmith from 'flagsmith'

import { FlagsmithFeatures } from '../../../constants/flagsmith'
import { useExperienceManager } from '@hooks'

interface AddWorksheetsModalProps {
  isOpen: boolean
  onClose: () => void
  clinicId?: string | null
  existingCheckInIds: string[]
  type?: 'treatment_activity' | 'symptom'
}

export const AddWorksheetsModal = ({
  isOpen,
  onClose,
  clinicId,
  existingCheckInIds,
  type
}: AddWorksheetsModalProps) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [selectedCheckInIds, setSelectedCheckInIds] = useState<string[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const experienceManager = useExperienceManager()
  

  const { data: clinicCheckIns = [] } = useClinicControllerGetCheckIns(
    clinicId as string,
    {
      type
    },
    {
      query: {
        enabled: !!clinicId && !experienceManager.isSuperAdmin
      }
    }
  )

  const { data: superAdmincheckIns = [] } = useCheckinsControllerV1GetAllCheckIns({
    type,
  }, {
    query: {
      enabled: experienceManager.isSuperAdmin
    }
  })

  const checkIns = experienceManager.isSuperAdmin ? superAdmincheckIns : clinicCheckIns

  const { mutate: assignCheckIns } = useClinicControllerAssignCheckIn({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(
          getClinicControllerGetAssignedCheckInsQueryKey(clinicId!)
        )
      }
    }
  })

  const modalSize = useBreakpointValue({
    base: 'full',
    md: '6xl'
  })

  const toggleCheckIn = (checkInId: string) => {
    setSelectedCheckInIds(ids => {
      if (ids.includes(checkInId)) {
        return ids.filter(id => id !== checkInId)
      }
      return [...ids, checkInId]
    })
  }

  const checkInList = useMemo(() => {
    return checkIns?.filter(ci =>
      `${ci.title.toLowerCase()} ${ci.subtitle.toLowerCase()}`.includes(
        searchText.toLowerCase()
      )
    )
  }, [checkIns, searchText])

  const _onClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
    setSearchText('')
    setSelectedCheckInIds([])
  }, [onClose])

  const flavorText = useMemo(() => {
    return type === 'treatment_activity' ? 'Worksheet' : 'Symptom Tracker'
  }, [type])

  const onAssign = () => {
    assignCheckIns({
      clinicId: clinicId!,
      data: {
        checkInIds: selectedCheckInIds
      }
    })
    _onClose()
  }

  const canEditCheckIns = flagsmith.hasFeature(FlagsmithFeatures.EDIT_CHECK_INS)

  return (
    <Modal size={modalSize} isOpen={isOpen} onClose={_onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton color="white" mt="xsmall" />
        <ModalHeader
          bg="primary"
          color="white"
          fontSize="lg"
          borderTopRadius="md"
        >
          Manage {flavorText}s
        </ModalHeader>
        <ModalBody>
          <Box>
            <Flex h="100px" align="center">
              <Input
                placeholder="Search"
                onChange={e => setSearchText(e.currentTarget.value)}
                value={searchText}
              />
            </Flex>
          </Box>
          <VStack
            divider={
              <StackDivider
                borderBottom="1px solid"
                borderBottomColor="pale_gray"
              />
            }
            align="flex-start"
          >
            {checkInList?.map(ci => (
              <HStack justify="space-between" w="100%">
                <Box>
                  <Flex alignItems="center" mb="xsmall">
                    <Text fontWeight="bold">
                      {ci.title}
                    </Text>
                    {experienceManager.isSuperAdmin && ci.isCore && (
                      <Badge ml="xsmall" colorScheme="purple">Core</Badge>
                    )}
                  </Flex>
                  <Text>{ci.subtitle}</Text>
                  <Text>{ci.questionCount} questions</Text>
                </Box>
                <HStack>
                  {canEditCheckIns && (
                    <Box
                      borderRight="1px solid"
                      borderColor="pale_gray"
                      pr="xsmall"
                      mr="xsmall"
                    >
                      <Button
                        variant="link"
                        mr="xsmall"
                        onClick={() => history.push(`/library/checkin-editor?worksheetId=${ci.id}`)}
                      >
                        Edit
                      </Button>
                    </Box>
                  )}
                  <Link
                    as="a"
                    target="_blank"
                    mr="xsmall"
                    color="primary"
                    textDecoration="underline"
                    href={
                      ci.title === 'Lifestyle correlates'
                        ? ''
                        : buildCheckInPreviewUrl(ci.id)
                    }
                  >
                    Preview
                  </Link>
                  <IconButton
                    isDisabled={existingCheckInIds.includes(ci.id)}
                    background="transparent"
                    aria-label="Add worksheet"
                    icon={
                      selectedCheckInIds.includes(ci.id) ||
                      existingCheckInIds.includes(ci.id) ? (
                        <CheckCircle fill="primary" />
                      ) : (
                        <AddCircleIcon />
                      )
                    }
                    onClick={() => toggleCheckIn(ci.id)}
                  />
                </HStack>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter
          justifyContent="space-between"
          position="sticky"
          bottom="0px"
          background="white"
          zIndex={1}
          w="100%"
          borderTop="1px solid"
          borderColor="pale_gray"
        >
          {canEditCheckIns && (
            <Button
              onClick={() =>
                history.push(
                  `/library/checkin-editor?type=${
                    type === 'treatment_activity' ? 'worksheet' : 'symptom'
                  }`
                )
              }
            >
              Create {flavorText}
            </Button>
          )}
          <Button onClick={onAssign} isDisabled={!selectedCheckInIds.length}>
            Add {selectedCheckInIds.length || ''} {flavorText}s
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
