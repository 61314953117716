import React from 'react'

const HourGlassFull = ({ color = '#2D54E8' }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / Hourglass Full">
        <path
          id="Vector"
          d="M6.5 2V8H6.51L6.5 8.01L10.5 12L6.5 16L6.51 16.01H6.5V22H18.5V16.01H18.49L18.5 16L14.5 12L18.5 8.01L18.49 8H18.5V2H6.5ZM16.5 16.5V20C16.5 20 14 19 12.5 19C11 19 8.5 20 8.5 20V16.5L12.5 12.5L16.5 16.5ZM12.5 7.5H8.5V4H16.5V7.5H12.5Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default HourGlassFull
