import React from 'react'
import { Stack, useToast } from '@chakra-ui/react'
import { CompletedInterventions } from '@handlers/sessions/components/in-session-assist-panel/interventions/completed-intervention-list'
import { SavedForLater } from '@handlers/sessions/components/in-session-assist-panel/interventions/saved-for-later'
import { InProgress } from '@handlers/sessions/components/in-session-assist-panel/interventions/in-progress'

import { useQueryClient } from 'react-query'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import { useStoreActions } from 'easy-peasy'

import {
  useInterventionsControllerGetSavedForLaterInterventions,
  usePatientInterventionsControllerGetAllPatientInterventions,
  usePatientInterventionsControllerDeletePatientIntervention,
  getPatientInterventionsControllerGetAllPatientInterventionsQueryKey,
  useInterventionsControllerRemoveInterventionFromSavedForLater,
  getInterventionsControllerGetSavedForLaterInterventionsQueryKey
} from '~/clinician-api'

export const AssistInterventions = ({ patient }: { patient: any }) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const openSaveInterventionModal = useStoreActions(
    // @ts-ignore
    actions => actions?.modals?.saveInterventions.openModal
  )

  let match: any = useRouteMatch('/patient/:patientId/settings/interventions')
  const [baseUrl] = match?.url.split('/settings')

  const {
    data: allPatientInterventions,
    isLoading: allPatientInterventionsLoading
  } = usePatientInterventionsControllerGetAllPatientInterventions(
    patient?.id ?? '',
    {},
    {
      query: {
        initialData: [],
        enabled: !!patient?.id
      }
    }
  )

  const {
    data: savedForLaterInterventions
  } = useInterventionsControllerGetSavedForLaterInterventions(
    patient?.id ?? '',
    {
      query: {
        initialData: [],
        enabled: !!patient?.id
      }
    }
  )

  const {
    mutateAsync: deleteClientIntervention
  } = usePatientInterventionsControllerDeletePatientIntervention()

  const handleDiscard = async (patientInterventionId: string) => {
    deleteClientIntervention(
      { patientId: patient?.id ?? '', patientInterventionId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            getPatientInterventionsControllerGetAllPatientInterventionsQueryKey(
              patient?.id ?? ''
            )
          )
        },
        onError: () => {
          toast({
            title: 'Error',
            description: 'There was an error discarding this activity',
            status: 'error',
            isClosable: true,
            duration: 2000
          })
        }
      }
    )
  }

  const {
    mutateAsync: removeInterventionSavedForLater
  } = useInterventionsControllerRemoveInterventionFromSavedForLater({
    mutation: {
      onSuccess: async () => {
        toast({
          title: 'Intervention removed from Saved for Later',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        queryClient.invalidateQueries(
          getInterventionsControllerGetSavedForLaterInterventionsQueryKey(
            patient?.id ?? ''
          )
        )
      }
    }
  })

  const handleRemoveFromSaved = async (interventionId: string) => {
    await removeInterventionSavedForLater({
      patientId: patient?.id ?? '',
      interventionId
    })
  }

  const inProgressInterventions =
    allPatientInterventions?.filter(pi => !pi.isComplete) || []
  // @ts-ignore
  const completedInterventions =
    allPatientInterventions
      ?.filter(pi => pi.isComplete)
      .sort(
        (a, b) =>
          moment(b.completedAt).valueOf() - moment(a.completedAt).valueOf()
      ) || []

  const handleResumeInterventionClick = (clientIntervention: any) => {
    const url = `${baseUrl}/interventions/${
      clientIntervention.interventionId
    }/form?clientInterventionId=${encodeURIComponent(
      clientIntervention.id
    )}&isPlus=true&name=${encodeURIComponent(clientIntervention.name)}`
    window.open(url, '_blank')
  }
  const handleViewInterventionOverviewClick = ({
    interventionId
  }: {
    interventionId: string
  }) => {
    const url = `${baseUrl}/interventions/${interventionId}/summary?isPlus=true`
    window.open(url, '_blank')
  }
  const handleCompletedInterventionClick = (clientIntervention: any) => {
    const url = `${baseUrl}/interventions/${
      clientIntervention.interventionId
    }/form?clientInterventionId=${
      clientIntervention.id
    }&isPlus=true&name=${encodeURIComponent(clientIntervention.name)}`
    window.open(url, '_blank')
  }

  return (
    <Stack px="24px" spacing="2" flex="1" pb="24px" gap="24px">
      <InProgress
        interventions={inProgressInterventions}
        onResume={handleResumeInterventionClick}
        onDiscard={handleDiscard}
      />
      <SavedForLater
        onInterventionClick={handleViewInterventionOverviewClick}
        interventions={savedForLaterInterventions || []}
        onRemoveFromSavedClick={handleRemoveFromSaved}
        onBrowseLibraryClick={() =>
          openSaveInterventionModal({ patientId: patient?.id })
        }
      />
      <CompletedInterventions
        interventions={completedInterventions}
        onClick={handleCompletedInterventionClick}
      />
    </Stack>
  )
}
