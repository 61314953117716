import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import InfoIcon from '@material-ui/icons/Info'
import moment from 'moment'
import { useExperienceManager } from '@hooks'

export const PlusFreeTrialStatus = () => {
  const { daysRemainingInPlusPlanFreeTrial } = useExperienceManager()
  return (
    <Flex
      bg="#E5FAEE"
      p="12px"
      borderRadius="4px"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Flex alignItems="center">
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.33398 8.02604C1.33398 4.34604 4.32065 1.35938 8.00065 1.35938C11.6807 1.35938 14.6673 4.34604 14.6673 8.02604C14.6673 11.706 11.6807 14.6927 8.00065 14.6927C4.32065 14.6927 1.33398 11.706 1.33398 8.02604ZM7.33398 7.35938V4.69271H8.66732V7.35938H11.334V8.69271H8.66732V11.3594H7.33398V8.69271H4.66732V7.35938H7.33398Z"
            fill="#2D54E8"
          />
        </svg>
        <Flex flexDirection={'column'} ml={'8px'}>
          <Text fontSize="14px" fontWeight="bold">
            Blueprint Plus
          </Text>
          <Text fontSize="14px" color="#282828">
            Free Blueprint Plus trial added: {daysRemainingInPlusPlanFreeTrial} days left
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const PlusFreeTrialStatusV2 = () => {
  const {
    daysRemainingInPlusPlanFreeTrial,
    plusPlanFreeTrialEndDate
  } = useExperienceManager()
  const isFreeTrialExpired = moment(plusPlanFreeTrialEndDate).isBefore(moment())

  return (
    <Flex
      bg={isFreeTrialExpired ? '#FFF2F2' : '#E5FAEE'}
      p="12px"
      borderRadius="4px"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Flex alignItems="center">
        <InfoIcon style={{ fill: '#2D54E8' }} />
        <Flex flexDirection={'column'} ml={'8px'}>
          {isFreeTrialExpired ? (
            <Text>
              Free trial ended
            </Text>
          ) : (
            <Text>
              {daysRemainingInPlusPlanFreeTrial} days left of free trial
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
