import React from 'react'
import { Container, Stack, Flex, Box, Text, useToast } from '@chakra-ui/react'
import { useAssistPanelContext } from '../context'
import { useTreatmentPlan, useExperienceManager } from '@hooks'
import { CompletedInterventions } from './completed-intervention-list'
import { SavedForLater } from './saved-for-later'
import { Suggestions } from './suggestions'
import { InProgress } from './in-progress'
import { endpoints } from '@api'

import { useQuery, useQueryClient } from 'react-query'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import AvailableSoonSVG from './available-soon-svg'

import {
  useInterventionsControllerGetSavedForLaterInterventions,
  usePatientInterventionsControllerGetAllPatientInterventions,
  usePatientInterventionsControllerDeletePatientIntervention,
  getPatientInterventionsControllerGetAllPatientInterventionsQueryKey,
  useInterventionsControllerRemoveInterventionFromSavedForLater,
  getInterventionsControllerGetSavedForLaterInterventionsQueryKey,
  getSessionControllerGetDemoSuggestedContentQueryKey,
  getSessionControllerGetSuggestedContentQueryKey
} from '~/clinician-api'

export const Interventions = () => {
  const { client } = useTreatmentPlan()
  const { setOverlayPanel } = useAssistPanelContext()
  const { interventionSuggestionsFullReleaseEnabled, isSessionRecordingV2Enabled } = useExperienceManager()

  const queryClient = useQueryClient()
  const toast = useToast()

  let match: any = useRouteMatch('/patient/:patientId/session/:sessionId')

  const lastSessionQuery: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId(), client?.id],
    async () => {
      return await endpoints.getClinicianClientLastSession.request({
        id: client?.id
      })
    },
    {
      enabled: !!client?.id
    }
  )

  const sessionId = lastSessionQuery?.data?.id || match?.params.sessionId

  const {
    data: allPatientInterventions
  } = usePatientInterventionsControllerGetAllPatientInterventions(
    client?.id ?? '',
    {},
    {
      query: {
        initialData: [],
        enabled: !!client?.id
      }
    }
  )

  const {
    data: savedForLaterInterventions
  } = useInterventionsControllerGetSavedForLaterInterventions(
    client?.id ?? '',
    {
      query: {
        initialData: [],
        enabled: !!client?.id
      }
    }
  )

  const {
    mutateAsync: deleteClientIntervention
  } = usePatientInterventionsControllerDeletePatientIntervention()

  const handleDiscard = async (patientInterventionId: string) => {
    deleteClientIntervention(
      { patientId: client?.id ?? '', patientInterventionId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            getPatientInterventionsControllerGetAllPatientInterventionsQueryKey(
              client?.id ?? ''
            )
          )
          queryClient.invalidateQueries(
            getSessionControllerGetDemoSuggestedContentQueryKey(sessionId)
          )
          queryClient.invalidateQueries(
            getSessionControllerGetSuggestedContentQueryKey(sessionId)
          )
        },
        onError: () => {
          toast({
            title: 'Error',
            description: 'There was an error discarding this activity',
            status: 'error',
            isClosable: true,
            duration: 2000
          })
        }
      }
    )
  }

  const {
    mutateAsync: removeInterventionSavedForLater
  } = useInterventionsControllerRemoveInterventionFromSavedForLater({
    mutation: {
      onSuccess: async () => {
        toast({
          title: 'Intervention removed from Saved for Later',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        queryClient.invalidateQueries(
          getInterventionsControllerGetSavedForLaterInterventionsQueryKey(
            client?.id ?? ''
          )
        )
        queryClient.invalidateQueries(
          getSessionControllerGetDemoSuggestedContentQueryKey(sessionId)
        )
        queryClient.invalidateQueries(
          getSessionControllerGetSuggestedContentQueryKey(sessionId)
        )
      }
    }
  })

  const handleRemoveFromSaved = async (interventionId: string) => {
    await removeInterventionSavedForLater({
      patientId: client?.id ?? '',
      interventionId
    })
  }

  const handleBrowseLibraryClick = () => {
    setOverlayPanel('INTERVENTION_LIBRARY')
  }

  const handleViewInterventionOverview = ({
    interventionId,
    interventionName,
    contentSuggestionId
  }: {
    interventionId: string
    interventionName: string
    contentSuggestionId?: string | null
  }) => {
    setOverlayPanel('INTERVENTION_OVERVIEW', {
      interventionId,
      interventionName,
      contentSuggestionId
    })
  }

  const handleResumeInterventionClick = (patientIntervention: any) => {
    setOverlayPanel('INTERVENTION_ACTIVITY', {
      interventionName: patientIntervention.name,
      interventionId: patientIntervention.interventionId,
      patientInterventionId: patientIntervention.id,
      isReadOnly: false
    })
  }

  const handleCompletedInterventionClick = (patientIntervention: any) => {
    setOverlayPanel('INTERVENTION_ACTIVITY', {
      interventionName: patientIntervention.name,
      interventionId: patientIntervention.interventionId,
      patientInterventionId: patientIntervention.id,
      isReadOnly: true
    })
  }

  const inProgressInterventions =
    allPatientInterventions?.filter(pi => !pi.isComplete) || []
  // @ts-ignore
  const completedInterventions =
    allPatientInterventions
      ?.filter(pi => pi.isComplete)
      .sort((a, b) => moment(b.completedAt).valueOf() - moment(a.completedAt).valueOf()) || []


  return (
    <Container
      h={isSessionRecordingV2Enabled ? "calc(100vh - 150px)" : "calc(100vh - 208px)"}
      pt="24px"
      overflowY="scroll"
    >
      <Stack
        flex="1"
        gap="24px"
        spacing="0"
      >
        {interventionSuggestionsFullReleaseEnabled ? (
          <Flex flexDirection="column" pb="40px" gap="24px">
            <InProgress
              interventions={inProgressInterventions}
              onResume={handleResumeInterventionClick}
              onDiscard={handleDiscard}
            />
            <SavedForLater
              onInterventionClick={handleViewInterventionOverview}
              onRemoveFromSavedClick={handleRemoveFromSaved}
              interventions={savedForLaterInterventions || []}
              onBrowseLibraryClick={handleBrowseLibraryClick}
            />
            <Suggestions
              sessionId={sessionId}
            />
            <CompletedInterventions
              interventions={completedInterventions}
              onClick={handleCompletedInterventionClick}
            />
          </Flex>
        ) : (
          <Flex
            border="1px solid"
            borderColor="pale_gray"
            h="100%"
            py="48px"
            px="16px"
            borderRadius="8px"
            flexDirection="column"
            justifyContent="center"
            gap="40px"
            overflowY="hidden"
          >
            <Flex justifyContent="center">
              <AvailableSoonSVG />
            </Flex>
            <Flex flexDirection="column" alignItems="center" gap="8px">
              <Text fontStyle="italic" fontWeight="bold">
                Available to use soon!
              </Text>
              <Text textAlign="center" maxW="464px">
                AI-surfaced Digital Intervention suggestions enable therapists to
                go deeper with clients every session.
              </Text>
            </Flex>
          </Flex>
        )}
      </Stack>
    </Container>
  )
}
