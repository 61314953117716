import React from 'react'
import {
  Button,
  Stack,
  Flex,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'
import { Pagination } from '@handlers/sessions/components/shared/pagination'
import {
  getSessionControllerGetSuggestedContentQueryKey,
  getSessionControllerGetDemoSuggestedContentQueryKey,
  getInterventionsControllerGetSavedForLaterInterventionsQueryKey,
  useInterventionsControllerSaveInterventionForLater
} from '~/clinician-api'
import { useExperienceManager } from '@hooks'
import { useStoreActions } from 'easy-peasy'
import { useLocation } from 'react-router-dom'

interface InterventionSuggestionModuleProps {
  sessionId: string
  client: any
  showDemoExperience: boolean
  suggestions: any
  demoData: any
}

export const InterventionSuggestionModule = ({
  sessionId,
  client,
  showDemoExperience,
  suggestions,
  demoData
}: InterventionSuggestionModuleProps) => {

  const [interventionIdx, setInterventionIdx] = React.useState<number>(0)

  const {
    interventionSuggestionsFullReleaseEnabled
  } = useExperienceManager()

  const openSaveInterventionModal = useStoreActions(
    // @ts-ignore
    actions => actions?.modals?.saveInterventions.openModal
  )

  const location = useLocation()
  const [baseUrl] = location.pathname.split('/patient')

  const toast = useToast()
  const queryClient = useQueryClient()

  const buildInterventionPreviewUrl = (interventionSuggestion: any) => {
    const {
      interventionId: id,
      interventionName: name
    } = interventionSuggestion
    return `${baseUrl}/patient/${client.id}/interventions/${id}/form?name=${name}&isPlus=true&isReadOnly=true`
  }


  const {
    mutate: saveInterventionForLater
  } = useInterventionsControllerSaveInterventionForLater({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'Intervention Saved for Later',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        queryClient.invalidateQueries({
          queryKey: getSessionControllerGetSuggestedContentQueryKey(sessionId)
        })
        queryClient.invalidateQueries(
          getInterventionsControllerGetSavedForLaterInterventionsQueryKey(
            client?.id ?? ''
          )
        )
        queryClient.invalidateQueries(
          getSessionControllerGetDemoSuggestedContentQueryKey(sessionId)
        )
      }
    }
  })


  const interventionSuggestion = showDemoExperience
    ? demoData?.suggestedInterventions[interventionIdx]
    : suggestions?.suggestedInterventions[interventionIdx]

  const interventionCount = showDemoExperience
    ? demoData?.suggestedInterventions?.length
    : suggestions?.suggestedInterventions.length || 0

  const handleBrowseLibraryClick = () => {
    openSaveInterventionModal({ patientId: client.id })
  }

  const onInterventionSaveForLaterClick = () => {
    if (interventionSuggestion) {
      saveInterventionForLater({
        patientId: client.id,
        interventionId: interventionSuggestion.interventionId,
        data: {
          contentSuggestionId: interventionSuggestion.contentSuggestionId
        }
      })
    }
  }

  return (
    <Stack spacing={2}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap="12px" alignItems="center">
          <Text fontWeight="bold">Interventions</Text>
          {interventionSuggestionsFullReleaseEnabled && (
            <Button
              variant="link"
              textDecoration="none"
              onClick={handleBrowseLibraryClick}
              fontSize="sm"
              m="0"
              _focus={{
                outline: 'none'
              }}
            >
              Browse all
            </Button>
          )}
        </Flex>
        <Flex justifyContent="center">
          <Pagination
            current={interventionCount === 0 ? 0 : interventionIdx + 1}
            total={interventionCount || 0}
            onNext={() => setInterventionIdx(prev => prev + 1)}
            onPrev={() => setInterventionIdx(prev => prev - 1)}
            isDisabled={interventionCount === 0}
          />
        </Flex>
      </Flex>
      {interventionCount > 0 ? (
        <ContentSuggestionCard
          isDemo={showDemoExperience}
          type="intervention"
          suggestion={{
            ...interventionSuggestion,
            name: interventionSuggestion?.interventionName
          }}
          truncateRationale
          sessionId={sessionId}
          onAssign={onInterventionSaveForLaterClick}
          previewUrl={buildInterventionPreviewUrl(interventionSuggestion)}
        />) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          border="1px solid #E4E5E6"
          w="100%"
          h="64px"
        >
          <Text fontSize="12px" color="dark_gray">No suggestions at this time.</Text>
        </Flex>
      )}
    </Stack>
  )
}