import React, { useState, useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
  Container,
  GridItem,
  Flex,
  Text,
  Box,
  Link,
  Button,
  VStack,
  useToast
} from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import { usePermissions, useExperienceManager } from '@hooks'
import { useQueryParams } from '@hooks/use-query-params'
import { LayoutGrid } from '@blueprinthq/joy'
import { ClinicianOverride, Loading } from '@components'
import { endpoints } from '@api'
import { useHistory } from 'react-router-dom'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { Check, ExtensionPromotion } from '@components/icons'
import { UploadModalV2 } from '../sessions/components/upload-modal-v2'

import {
  ConsentModule,
  SampleSessionsModule,
  SessionContainer,
  SessionTypeCTAGroup
} from './components'
import { SessionNotesRemainingBanner } from '../sessions/components'
import SessionNotesRemainingWarning from '../sessions/components/session-notes-remaining-warning'
import { useClinicControllerStartPlusPlanFreeTrial } from '~/clinician-api'

const SessionList = () => {
  const {
    documentationAutomationFreeTierSessionLimitReached,
    isAdmin,
    isDocumentationAutomationEnabled,
    isPlusPlanEnabled
  } = useExperienceManager()
  const queryClient = useQueryClient()
  const [overrideClinicianId, setOverrideClinicianId] = useState(null)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [
    hasLessThanNumberRecordedSessions,
    setHasLessThanNumberRecordedSessions
  ] = useState(true)
  const { hasPermission } = usePermissions()
  const history = useHistory()
  const queryParams = useQueryParams()
  const signup = queryParams.get('signup') === 'true'

  const openDocAutomationSessionLimitReachedModal = useStoreActions(
    actions => actions.modals.docAutomationSessionLimitReached.openModal
  )
  const openDocAutomationPlanModal = useStoreActions(
    actions => actions.modals.manageScribePlan.openModal
  )

  const getClinicianDashboardQueryKey = useMemo(() => {
    const key = [endpoints.getClinicianDashboardSessions.getCacheId()]
    if (overrideClinicianId) key.push(overrideClinicianId)

    return key
  }, [overrideClinicianId])

  const {
    data: sessionsData,
    isLoading: isSessionsLoading,
    refetch: refetchSessions
  } = useQuery([...getClinicianDashboardQueryKey], () =>
    endpoints.getClinicianDashboardSessions.request({
      overrideClinicianId,
      includeRecordingDrafts: true
    })
  )

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const toast = useToast()

  const {
    mutateAsync: startPlusPlanFreeTrial
  } = useClinicControllerStartPlusPlanFreeTrial()

  useEffect(() => {
    const startFreeTrial = async () => {
      if (
        window.location.href?.includes('startFreePlusTrial') &&
        user?.clinic?.id
      ) {
        if (!isPlusPlanEnabled) {
          toast({
            status: 'error',
            isClosable: true,
            duration: 4000,
            render: () => (
              <Box color="white" p={3} bg="error" display="flex">
                <Text mr={2}>Sorry, our free trial offer has ended</Text>
                <Link
                  onClick={() => {
                    toast.closeAll()
                    history.push(`/settings/plans`)
                  }}
                  color="white"
                  fontWeight="bold"
                >
                  View plans
                </Link>
              </Box>
            )
          })
        }

        history.replace('/sessions')
      }
    }
    startFreeTrial()
  }, [
    startPlusPlanFreeTrial,
    user?.clinic?.id,
    toast,
    history,
    queryClient,
    isPlusPlanEnabled
  ])

  const isCurrentUserSelected =
    !overrideClinicianId || overrideClinicianId === user?.id

  const hasRecordedSessions =
    !isSessionsLoading && sessionsData.nodes.some(s => s.hasAudio)

  const alwaysShowSampleSessions = flagsmith.hasFeature(
    FlagsmithFeatures.ALWAYS_SHOW_SAMPLE_SESSIONS
  )

  // once num recorded sessions >= this number, hide the sample sessions module
  const numberRecordedSessions = 5

  useEffect(() => {
    if (isCurrentUserSelected) {
      setHasLessThanNumberRecordedSessions(
        !isSessionsLoading &&
          sessionsData.nodes.filter(s => s.hasAudio && !s.excludeFromDAUsage)
            .length < numberRecordedSessions
      )
    }
  }, [isSessionsLoading, sessionsData, isCurrentUserSelected])

  const hasSupervisorRole = user?.roles?.some(role =>
    ['supervisor_clinician', 'supervisor', 'clinicadmin'].includes(role.name)
  )

  const hasAdminRole = user?.roles?.some(role => role.name === 'orgadmin')

  const showDropdown =
    hasPermission('*:any:*:*') || hasSupervisorRole || hasAdminRole

  useEffect(() => {
    if (!isSessionsLoading && !hasRecordedSessions && isCurrentUserSelected) {
      if (isDocumentationAutomationEnabled) {
        history.replace('/sessions/get-started')
      } else if (signup) {
        history.replace('/start-session/new-client?signup=true')
      }
    }
  }, [
    isDocumentationAutomationEnabled,
    isSessionsLoading,
    hasRecordedSessions,
    isCurrentUserSelected,
    user,
    signup,
    history
  ])

  if (!user) return null

  return (
    <Container mt={'24px'}>
      <LayoutGrid>
        {isDocumentationAutomationEnabled && (
          <GridItem
            display={{ base: 'none', md: 'block' }}
            colSpan={{
              base: 0,
              md: 3
            }}
            colStart={{
              md: 1
            }}
          >
            <Box
              border="1px solid"
              borderColor="pale_gray"
              padding="small"
              borderRadius="8px"
            >
              <Flex direction="column" alignItems="center">
                <ExtensionPromotion />
                <Text as="b" mb="xsmall">
                  Blueprint Chrome extension
                </Text>
                <Flex direction="column" alignItems="start" mb="xsmall">
                  <Flex alignItems="center">
                    <Check fill="#2D54E8" />
                    <Text ml="xxsmall">On top of your EHR</Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Check fill="#2D54E8" />
                    <Text ml="xxsmall">Best for telehealth sessions</Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Check fill="#2D54E8" />
                    <Text ml="xxsmall">No extra tabs</Text>
                  </Flex>
                </Flex>
                <Button
                  variant="outline"
                  borderRadius="24px"
                  isFullWidth
                  onClick={() =>
                    window.open(
                      'https://chromewebstore.google.com/detail/blueprint-ai-notetaker/fmjbhmhmfanpmdhpjjemafjlgomdccnm',
                      '_blank'
                    )
                  }
                >
                  Add to Chrome
                </Button>
              </Flex>
            </Box>
          </GridItem>
        )}
        <GridItem
          colSpan={{
            base: 12,
            sm: 6,
            md: 6
          }}
          colStart={{
            base: 0,
            sm: 2,
            md: 4
          }}
        >
          <Flex direction="column" align="space-between" justify="center">
            <Flex align="center" justify="space-between" mb={2}>
              <Text as="h1" fontSize="xl" fontWeight="bold">
                Sessions
              </Text>
              {!isDocumentationAutomationEnabled && (
                <Button
                  data-click-event-name="Sessions Index -> Clicked Start Session"
                  onClick={() => history.push('/start-session/select-client')}
                  w="146px"
                >
                  New Session
                </Button>
              )}
            </Flex>
            <VStack spacing={4} align="stretch">
              {showDropdown && (
                <ClinicianOverride
                  user={user}
                  isSuperAdmin={hasPermission('*:any:*:*')}
                  canViewOrgDropdown={hasAdminRole}
                  onChange={id => setOverrideClinicianId(id)}
                />
              )}
              <SessionNotesRemainingBanner />
              <SessionNotesRemainingWarning />
              <GridItem
                display={{ base: 'block', md: 'none' }}
                colSpan={{
                  base: 12,
                  md: 3
                }}
                colStart={{
                  base: 0,
                  md: 10
                }}
                mb={8}
              >
                {isDocumentationAutomationEnabled && (
                  <>
                    <Text fontWeight="bold" pb="8px">
                      Generate session notes
                    </Text>
                    <SessionTypeCTAGroup
                      setUploadModalOpen={setUploadModalOpen}
                      openDocAutomationSessionLimitReachedModal={
                        openDocAutomationSessionLimitReachedModal
                      }
                      openDocAutomationPlanModal={openDocAutomationPlanModal}
                      isAdmin={isAdmin}
                      documentationAutomationFreeTierSessionLimitReached={
                        documentationAutomationFreeTierSessionLimitReached
                      }
                    />
                    <Flex direction="column" alignItems="center">
                      {(alwaysShowSampleSessions ||
                        hasLessThanNumberRecordedSessions) && (
                        <SampleSessionsModule />
                      )}
                      {isAdmin && (
                        <Box w="100%">
                          <Text fontWeight="bold" mb="xsmall">
                            More resources
                          </Text>
                          <ConsentModule />
                        </Box>
                      )}
                    </Flex>
                  </>
                )}
              </GridItem>
            </VStack>
          </Flex>
          <Box mb="64px" mt={4}>
            {isSessionsLoading ? (
              <Loading />
            ) : (
              <SessionContainer
                data={sessionsData}
                isLoading={isSessionsLoading}
                refetchSessions={refetchSessions}
              />
            )}
          </Box>
        </GridItem>
        <GridItem
          display={{ base: 'none', md: 'block' }}
          colSpan={{
            base: 12,
            md: 3
          }}
          colStart={{
            base: 0,
            md: 10
          }}
        >
          {isDocumentationAutomationEnabled && (
            <>
              <Text fontWeight="bold" pb="8px">
                Generate session notes
              </Text>
              <SessionTypeCTAGroup
                setUploadModalOpen={setUploadModalOpen}
                openDocAutomationSessionLimitReachedModal={
                  openDocAutomationSessionLimitReachedModal
                }
                openDocAutomationPlanModal={openDocAutomationPlanModal}
                isAdmin={isAdmin}
                documentationAutomationFreeTierSessionLimitReached={
                  documentationAutomationFreeTierSessionLimitReached
                }
              />
              <Flex direction="column" alignItems="center">
                {(alwaysShowSampleSessions ||
                  hasLessThanNumberRecordedSessions) && (
                  <SampleSessionsModule />
                )}
                {isAdmin && (
                  <Box w="100%">
                    <Text fontWeight="bold" mb="xsmall">
                      More resources
                    </Text>
                    <ConsentModule />
                  </Box>
                )}
              </Flex>
            </>
          )}
        </GridItem>
      </LayoutGrid>
      <UploadModalV2
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
      />
    </Container>
  )
}

export default SessionList
