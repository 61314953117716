import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  Portal
} from '@chakra-ui/react'
import MoreHoriz from '@material-ui/icons/MoreHoriz'

export const SessionMenu = ({
  isOpen,
  onOpen,
  onClose,
  onDelete
}: {
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  onDelete: () => void
}) => {

  const {
    isOpen: isOpenInternal,
    onOpen: onOpenInternal,
    onClose: onCloseInternal
  } = useDisclosure()

  return (
    <>
      <Menu
        isOpen={isOpen || isOpenInternal}
        onOpen={onOpen || onOpenInternal}
        onClose={onClose || onCloseInternal}
        placement="bottom-end"
        autoSelect={false}
      >
        <MenuButton
          m={0}
          w="24px"
          as={IconButton}
          aria-label="Options"
          icon={<MoreHoriz />}
          variant="ghost"
          _focus={{
            outline: 'none'
          }}
          _active={{
            outline: 'none',
            bg: '#E4E5E6'
          }}
          _hover={{
            bg: '#E4E5E6'
          }}
        />
        <Portal>
          <MenuList>
            <MenuItem color="#C6162C" onClick={onDelete}>
              Delete Session
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </>
  )
}
