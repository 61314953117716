import React from 'react'

const CoolKidsAloneTime = () => (
  <svg
    width="108"
    height="98"
    viewBox="0 0 108 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.3499 73.1543H12.9543C11.3701 73.1543 9.94779 71.8723 9.78596 70.2847L2.87858 4.6836C2.70824 3.096 6.43022 1.02448 8.0144 1.02448L38.8464 1.81403C40.4305 1.81403 41.8529 3.096 42.0147 4.6836L52.3971 70.7262C52.5674 72.3138 47.9256 73.1543 46.3414 73.1543H46.3499Z"
      fill="#C0CCF8"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M49.9953 72.3647H16.5997C15.0155 72.3647 13.5931 71.0828 13.4313 69.4952L6.52393 3.89405C6.35359 2.30644 7.5034 1.02448 9.0961 1.02448H42.5002C44.0844 1.02448 45.5068 2.30644 45.6686 3.89405L52.5675 69.4952C52.7378 71.0828 51.588 72.3647 49.9953 72.3647Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M44.8254 65.1738H20.2535C19.2655 65.1738 18.3797 64.3758 18.2775 63.3824L12.6647 9.99824C12.5625 9.01341 13.278 8.20688 14.266 8.20688H38.8379C39.8258 8.20688 40.7116 9.00492 40.8138 9.99824L46.4266 63.3824C46.5288 64.3673 45.8134 65.1738 44.8254 65.1738Z"
      fill="white"
    />
    <path
      d="M23.8477 27.8184C24.0266 29.5164 22.7916 30.8918 21.0967 30.8918C19.4018 30.8918 17.8772 29.5164 17.6984 27.8184C17.5195 26.1205 18.7545 24.7451 20.4494 24.7451C22.1443 24.7451 23.6689 26.1205 23.8477 27.8184Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M24.8442 37.3016C25.023 38.9996 23.7881 40.375 22.0932 40.375C20.3983 40.375 18.8737 38.9996 18.6948 37.3016C18.516 35.6037 19.751 34.2283 21.4459 34.2283C23.1408 34.2283 24.6653 35.6037 24.8442 37.3016Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M25.8492 46.8442C26.0281 48.5422 24.7931 49.9175 23.0982 49.9175C21.4033 49.9175 19.8787 48.5422 19.6998 46.8442C19.521 45.1462 20.756 43.7709 22.4509 43.7709C24.1458 43.7709 25.6703 45.1462 25.8492 46.8442Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M35.2947 18.556H18.4564L18.3372 17.3929H35.167L35.2947 18.556Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M40.4902 62.2533H23.984C22.2636 62.2533 20.722 60.861 20.5431 59.146C20.3643 57.4311 21.6078 56.0388 23.3282 56.0388H39.8344C41.5548 56.0388 43.0964 57.4311 43.2753 59.146C43.4542 60.861 42.2107 62.2533 40.4902 62.2533Z"
      fill="#C0CCF8"
    />
    <path
      opacity="0.7"
      d="M0.408569 81.6611C13.1587 81.1517 26.2751 80.5999 39.0422 80.4641C49.6631 80.2433 67.6342 81.1942 47.2527 83.461C39.0167 84.3694 27.6633 84.9891 19.8702 85.6004C17.9538 85.8042 7.95473 86.407 10.7739 87.4088C14.1296 88.1813 18.4478 88.3511 23.3963 88.7332C34.2897 89.4293 46.5969 90.1 57.5244 90.983C85.3924 93.1479 67.3446 95.4996 50.634 97.4607"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M87.6323 58.4584C89.8385 58.4584 91.6269 56.6757 91.6269 54.4766C91.6269 52.2776 89.8385 50.4949 87.6323 50.4949C85.4262 50.4949 83.6378 52.2776 83.6378 54.4766C83.6378 56.6757 85.4262 58.4584 87.6323 58.4584Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M63.597 36.979C77.8121 36.0112 71.0495 53.0673 60.846 52.2523C50.37 57.3292 42.9175 44.6454 38.2075 37.6922C40.8393 36.3508 42.7897 40.723 45.1319 41.4192C48.2918 40.5278 49.6801 37.3356 53.5298 37.3186C57.618 37.0554 61.9618 35.7395 66.0755 37.1149"
      fill="white"
    />
    <path
      d="M44.902 41.1051C43.7266 40.1202 39.1614 36.529 37.7391 35.8753C36.3167 35.2216 34.6559 34.9584 33.455 35.4933C32.8588 35.7565 32.3818 36.2998 32.5521 36.9366C32.6884 37.4544 33.1994 37.9044 33.6764 38.2949C38.4375 42.2597 43.2071 46.2245 47.9681 50.1807"
      fill="white"
    />
    <path
      d="M44.902 41.1051C43.7266 40.1202 39.1614 36.529 37.7391 35.8753C36.3167 35.2216 34.6559 34.9584 33.455 35.4933C32.8588 35.7565 32.3818 36.2998 32.5521 36.9366C32.6884 37.4544 33.1994 37.9044 33.6764 38.2949C38.4375 42.2597 43.2071 46.2245 47.9681 50.1807"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M41.8017 39.1185C44.5698 41.7758 46.171 41.4532 46.8013 42.3616C48.0789 44.1869 43.5903 43.0662 40.8819 41.0626C38.1735 39.059 38.199 37.3696 38.3523 36.9026C38.5056 36.4357 39.417 36.8262 41.8017 39.1185Z"
      fill="#F6F7FE"
    />
    <path
      d="M58.7423 53.4408C52.9848 53.9078 49.5779 52.2862 46.2648 48.6186"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M68.0345 29.1683C72.6763 34.4915 74.7375 39.8231 72.6337 41.0796C70.53 42.3361 65.0535 39.0335 60.4117 33.7104C55.7699 28.3873 53.7087 23.0556 55.8124 21.7991C57.9162 20.5426 63.3927 23.8452 68.0345 29.1683Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M63.0094 30.0513C67.6512 35.3744 69.7123 40.7061 67.6086 41.9626C65.5049 43.2191 60.0284 39.9165 55.3865 34.5934C50.7447 29.2702 48.6836 23.9386 50.7873 22.6821C52.891 21.4256 58.3675 24.7281 63.0094 30.0513Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M57.7884 32.0804C62.4303 37.4035 64.2274 42.8964 61.8 44.3482C59.3726 45.8 53.6321 42.6502 48.9817 37.3271C44.3314 32.0039 42.5428 26.511 44.9702 25.0592C47.3976 23.6075 53.1381 26.7572 57.7884 32.0804Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M43.5478 27.1647C51.0429 35.1367 55.8891 42.4125 54.356 43.4398C52.8229 44.4671 45.4982 38.8383 37.9861 30.8748C30.474 22.9113 26.5476 16.2213 27.1779 14.5997C28.0977 12.2481 29.5542 12.2905 43.5478 27.1647Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M72.0802 45.5028L57.8225 52.057L38.6846 32.1483L56.4768 23.7688C58.1206 22.9962 60.3521 23.5141 61.6212 24.9659L70.6749 33.1416C71.8417 34.4745 73.5025 37.6497 73.8006 40.4938C74.0391 42.7606 73.2044 44.8321 72.0802 45.5028Z"
      fill="white"
    />
    <path
      d="M70.8025 32.785C75.027 39.475 75.6743 43.8388 68.537 49.6374"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M34.988 27.6062C36.5807 29.2787 42.2702 35.6885 43.7947 37.4035C44.9786 38.7279 47.917 43.0408 44.7061 40.9438"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M71.9268 35.2895C74.9844 38.5072 88.3052 55.818 87.6323 54.4851C86.0141 51.2589 78.996 51.0382 81.219 53.8823L72.5911 68.9688C69.0054 62.8985 64.1251 57.5839 58.359 53.5003"
      fill="white"
    />
    <path
      d="M71.9268 35.2895C74.9844 38.5072 88.3052 55.818 87.6323 54.4851C86.0141 51.2589 78.996 51.0382 81.219 53.8823L72.5911 68.9688C69.0054 62.8985 64.1251 57.5839 58.359 53.5003"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M71.3647 65.8021C70.1042 64.0022 68.5966 62.3722 67.038 60.8355C65.6667 59.4771 64.2273 58.1867 62.7709 56.9217C62.0725 56.3104 61.3656 55.6992 60.6502 55.0964C60.3095 54.8077 59.9773 54.5106 59.6366 54.2219C59.4833 54.0861 59.3044 53.9927 59.1682 53.8484C59.0404 53.7125 58.9723 53.5258 58.8445 53.3814C58.4527 52.923 57.7543 52.9315 57.1496 52.9315C55.5654 52.923 54.5604 52.9654 53.0188 52.6089C52.4822 52.4815 51.392 51.9551 50.881 51.7599C50.5744 51.6495 50.2763 51.5052 50.0378 51.2929C49.859 51.1316 49.6205 50.8345 49.6205 50.5798C49.6205 50.359 49.7823 50.2487 49.9271 50.0789C50.2167 49.7308 50.6425 49.544 51.0599 49.3912C51.4772 49.2384 51.9031 49.4082 52.3204 49.4337C53.2232 49.4931 54.1175 49.3827 55.0033 49.2214C56.8004 48.8903 58.5635 48.3045 60.3946 48.1856C61.1101 48.1432 61.834 48.1687 62.5324 48.3215C63.4097 48.5083 64.2444 48.8818 65.1302 49.0092C66.7229 49.2384 68.3326 48.6526 69.755 47.9055C71.1773 47.1584 72.5145 46.216 74.022 45.6387C75.1207 45.2227 76.2961 45.0104 77.4715 45.0104C77.6674 45.0104 77.8803 45.0189 78.0421 45.1378C78.1613 45.2312 78.238 45.367 78.3061 45.5028C78.5957 46.0971 78.7575 46.7848 79.0982 47.3451C79.4389 47.9055 79.6689 48.4743 79.9329 49.0431C80.1799 49.578 80.4184 50.1043 80.6654 50.6307C80.7761 50.8684 80.8783 51.2165 81.0742 51.4033C81.2786 51.6071 81.3808 51.8872 81.4575 52.1589C83.1609 57.7282 79.8903 64.3418 74.4223 66.3879C73.6643 66.6765 72.8296 66.8803 72.0461 66.685C70.8111 66.3794 72.0886 66.8209 71.3647 65.7766V65.8021Z"
      fill="#F6F7FE"
    />
    <path
      d="M79.5866 60.2163C83.4993 50.8238 82.8465 41.6267 78.1287 39.674C73.4108 37.7212 66.4144 43.7523 62.5018 53.1448C58.5892 62.5373 59.2419 71.7344 63.9598 73.6872C68.6776 75.6399 75.674 69.6088 79.5866 60.2163Z"
      stroke="#C0CCF8"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M79.5866 60.2163C83.4993 50.8238 82.8465 41.6267 78.1287 39.674C73.4108 37.7212 66.4144 43.7523 62.5018 53.1448C58.5892 62.5373 59.2419 71.7344 63.9598 73.6872C68.6776 75.6399 75.674 69.6088 79.5866 60.2163Z"
      stroke="white"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M81.5944 64.7338C87.7004 56.5861 89.3325 47.5107 85.2399 44.4632C81.1473 41.4158 72.8798 45.5504 66.7738 53.6982C60.6679 61.8459 59.0357 70.9213 63.1283 73.9688C67.2209 77.0162 75.4885 72.8816 81.5944 64.7338Z"
      stroke="#C0CCF8"
      stroke-width="4"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M81.5944 64.7338C87.7004 56.5861 89.3325 47.5107 85.2399 44.4632C81.1473 41.4158 72.8798 45.5504 66.7738 53.6982C60.6679 61.8459 59.0357 70.9213 63.1283 73.9688C67.2209 77.0162 75.4885 72.8816 81.5944 64.7338Z"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="8 1 3 8 1 3"
    />
    <path
      d="M84.8354 67.1188C89.3336 57.9905 89.2631 48.7707 84.6779 46.5257C80.0928 44.2807 72.7293 49.8607 68.2312 58.989C63.733 68.1173 63.8035 77.3372 68.3887 79.5822C72.9738 81.8272 80.3373 76.2471 84.8354 67.1188Z"
      stroke="#C0CCF8"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M84.8354 67.1188C89.3336 57.9905 89.2631 48.7707 84.6779 46.5257C80.0928 44.2807 72.7293 49.8607 68.2312 58.989C63.733 68.1173 63.8035 77.3372 68.3887 79.5822C72.9738 81.8272 80.3373 76.2471 84.8354 67.1188Z"
      stroke="white"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M90.5538 94.9817L74.465 80.5404C70.1894 76.703 70.3427 66.252 74.8141 57.2018C79.2856 48.1516 86.3719 43.9237 90.6475 47.7611L106.736 62.2023"
      fill="white"
    />
    <path
      d="M90.5538 94.9817L74.465 80.5404C70.1894 76.703 70.3427 66.252 74.8141 57.2018C79.2856 48.1516 86.3719 43.9237 90.6475 47.7611L106.736 62.2023"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
  </svg>
)

export default CoolKidsAloneTime
