import React, { useMemo } from 'react'
import { Box, Table, Thead, Th, Tbody, Tr, Td, Text } from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'

function getPointChangeColor(value, isOpposite) {
  if (!value) return 'black'

  if (isOpposite) {
    return value < 0 ? 'pink' : 'success'
  }

  return value < 0 ? 'success' : 'pink'
}

function getPointChangeText(value, isOpposite) {
  if (!value) return '-'

  if (isOpposite) {
    return value < 0 ? `↓ ${Math.abs(value)}` : `↑ ${Math.abs(value)}`
  }

  return value < 0 ? `↓ ${Math.abs(value)}` : `↑ ${Math.abs(value)}`
}

const PointChangeIndicator = ({ value, isOpposite }) => {
  return (
    <Text color={getPointChangeColor(value, isOpposite)}>
      {getPointChangeText(value, isOpposite)}
    </Text>
  )
}

export const AssessmentResultTable = ({ answers, isReverseValence }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Question',
        accessor: 'index',
        Cell: ({ row, value }) => {
          return `${value + 1}. ${row.original.key}`
        },
        id: 'index',
        style: { width: 400 },
        sortType: (rowA, rowB) => {
          const a = rowA.original.index
          const b = rowB.original.index
          if (a > b) return 1
          if (a < b) return -1
          return 0
        }
      },
      {
        Header: 'Answer',
        accessor: 'value',
        sortType: 'alphanumeric'
      },
      {
        Header: 'Score',
        accessor: 'score',
        sortType: (rowA, rowB) => {
          // Handle free text entries for proper sorting
          if (rowA.original.isFreeText && rowB.original.isFreeText) return 0
          if (rowA.original.isFreeText) return -1
          if (rowB.original.isFreeText) return 1
          return rowA.original.score - rowB.original.score
        },
        Cell: ({ row, value }) => {
          if (!row.original.isFreeText) {
            return `${value} / ${row.original.maxScore}`
          } else {
            return `-`
          }
        }
      },
      {
        Header: 'Since Last',
        accessor: 'sinceLast',
        sortType: (rowA, rowB) => {
          // Handle free text entries for proper sorting
          if (rowA.original.isFreeText && rowB.original.isFreeText) return 0
          if (rowA.original.isFreeText) return -1
          if (rowB.original.isFreeText) return 1
          return rowA.original.sinceLast - rowB.original.sinceLast
        },
        Cell: ({ row, value }) => {
          if (!row.original.isFreeText) {
            return (
              <PointChangeIndicator
                value={value}
                isOpposite={isReverseValence || row.original.isReverseValence}
              />
            )
          } else {
            return `-`
          }
        }
      },
      {
        Header: 'Since Baseline',
        accessor: 'sinceBaseline',
        sortType: (rowA, rowB) => {
          // Handle free text entries for proper sorting
          if (rowA.original.isFreeText && rowB.original.isFreeText) return 0
          if (rowA.original.isFreeText) return -1
          if (rowB.original.isFreeText) return 1
          return rowA.original.sinceBaseline - rowB.original.sinceBaseline
        },
        Cell: ({ row, value }) => {
          if (!row.original.isFreeText) {
            return (
              <PointChangeIndicator
                value={value}
                isOpposite={isReverseValence || row.original.isReverseValence}
              />
            )
          } else {
            return `-`
          }
        }
      }
    ],
    [isReverseValence]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data: answers,
      disableSortRemove: true,
      initialState: {
        sortBy: [{ id: 'index', desc: false }]
      }
    },
    useSortBy
  )

  return (
    <Box mb="24px">
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup, i) => (
            <Tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <Th
                  key={j}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  borderColor="pale_gray"
                  style={column.style}
                  lineHeight="16px"
                  fontSize="12px"
                  px="5px"
                  textTransform="none"
                  pl={j === 0 ? '12px' : '5px'}
                  pr={j === headerGroup.headers.length - 1 ? '12px' : '5px'}
                  color="dark_gray"
                  cursor="pointer"
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Tr
                key={i}
                {...row.getRowProps()}
                bg={i % 2 === 0 ? 'transparent' : 'rgba(45, 84, 232, 0.04)'}
              >
                {row.cells.map((cell, j) => (
                  <Td
                    key={j}
                    {...cell.getCellProps()}
                    borderColor="pale_gray"
                    verticalAlign="middle"
                    py="12px"
                    fontSize="12px"
                    lineHeight="16px"
                    px="4px"
                    width={cell.column.Header === 'Question' ? '395px' : 'auto'}
                    fontWeight={
                      cell.column.Header === 'Question' ? 'bold' : 'normal'
                    }
                    borderBottom={i === rows.length - 1 ? 'none' : '1px solid'}
                    pl={j === 0 ? '12px' : '5px'}
                    pr={j === row.cells.length - 1 ? '12px' : '5px'}
                  >
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}

export default AssessmentResultTable
