import React from 'react'
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  Divider,
  Menu,
  MenuButton,
  IconButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@blueprinthq/joy'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Clipboard } from '@components/icons'

interface SavedForLaterProps {
  onInterventionClick: ({
    interventionId,
    interventionName,
    contentSuggestionId,
  }: {
    interventionId: string
    interventionName: string
    contentSuggestionId?: string
  }) => void
  onRemoveFromSavedClick: (interventionId: string) => void
  onBrowseLibraryClick?: () => void
  interventions: any[]
}

export const SavedForLater = ({
  onInterventionClick,
  onRemoveFromSavedClick,
  interventions,
  onBrowseLibraryClick
}: SavedForLaterProps) => {
  return (
    <Box>
      <Flex justifyContent="space-between">
        <Text fontWeight="bold" mb="xsmall">
          Saved for Later
        </Text>
        <Text
          color="primary"
          _hover={{ cursor: 'pointer' }}
          onClick={onBrowseLibraryClick}
        >
          Browse library
        </Text>
      </Flex>
      <Box border="1px solid" borderColor="pale_gray" rounded="md">
        {interventions?.length === 0 ? (
          <Text p="small">No saved interventions.</Text>
        ) : (
          <VStack
            flex={1}
            alignItems="flex-start"
            divider={
              <Divider
                color="pale_gray"
                mt="0px !important"
                mb="0px !important"
              />
            }
          >
            {interventions &&
              interventions.map(s => (
                <HStack
                  key={s.interventionId}
                  px="small"
                  py="xsmall"
                  alignItems="center"
                  justify="space-between"
                  w="100%"
                  _hover={{
                    bg: 'gray.200',
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    onInterventionClick({
                      interventionId: s.interventionId,
                      interventionName: s.interventionName,
                      contentSuggestionId: s.contentSuggestionId
                    })
                  }
                  role="group"
                >
                  <HStack>
                    <Clipboard fill="black" />
                    <Text fontWeight="bold">{s.interventionName}</Text>
                  </HStack>
                  <HStack spacing={2}>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MoreHorizIcon />}
                        variant="ghost"
                        height="auto"
                        _focus={{
                          outline: 'none',
                          outlineStyle: 'none'
                        }}
                        opacity="0"
                        transition="opacity 0.2s"
                        _groupHover={{ opacity: '1', pointerEvents: 'auto' }}
                        onClick={(e: any) => e.stopPropagation()}
                      />
                      <MenuList>
                        <MenuItem
                          onClick={(e: any) => {
                            e.stopPropagation()
                            onRemoveFromSavedClick(s.interventionId)
                          }}
                        >
                          Remove from Saved
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    <ArrowRightIcon />
                  </HStack>
                </HStack>
              ))}
          </VStack>
        )}
      </Box>
    </Box>
  )
}
