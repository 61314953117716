import React from 'react'
import { Flex, GridItem } from '@chakra-ui/react'
import { Button, LayoutGrid } from '@blueprinthq/joy'

import { StickyFooter } from '@components'

export function Footer({
  hidden,
  showPlusVersion,
  handleSaveDraft,
  handleSubmit
}) {
  if (hidden) return null

  return (
    <StickyFooter>
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 1,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 9,
            md: 10
          }}
        >
          <Flex
            width={'full'}
            height={'full'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Button
              id={'pendo-intervention-save-draft-tag'}
              onClick={() => handleSaveDraft(false)}
              variant={'ghost'}
              textColor={'primary'}
              _focus={{
                boxShadow: 'none'
              }}
            >
              {showPlusVersion
                ? 'Save & Continue Later'
                : 'Save as draft and close'}
            </Button>
            <Button
              id={'pendo-intervention-submit-tag'}
              onClick={
                showPlusVersion ? () => handleSaveDraft(true) : handleSubmit
              }
              textColor={'white'}
              background={'primary'}
              marginLeft={'24px'}
              paddingLeft={'62px'}
              paddingRight={'62px'}
              _focus={{
                boxShadow: 'none'
              }}
            >
              {showPlusVersion ? 'Complete Intervention' : 'Submit'}
            </Button>
          </Flex>
        </GridItem>
      </LayoutGrid>
    </StickyFooter>
  )
}
