import React from 'react'
import { HStack, Text, Input } from '@chakra-ui/react'
import { Checkbox } from '@blueprinthq/joy'

type Props = {
  isEnabled: boolean
  setIsEnabled: (isEnabled: boolean) => void
  value: string
  onChange: (minutes: string) => void
  useV2Styles?: boolean
}

const StopRecordingAfter = ({
  isEnabled,
  setIsEnabled,
  value,
  onChange,
  useV2Styles,
}: Props) => {
  const handleBlur = (e: any) => {
    const value = e.target.value.trim()
    if (!value || value === '0') {
      onChange('60')
    } else if (parseInt(value) > 180) {
      onChange('180')
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\D/g, '').slice(0, 3)
    onChange(newValue)
  }

  return (
    <HStack spacing={2} alignItems="center" h="100%">
      <Checkbox
        isChecked={isEnabled}
        onChange={e => setIsEnabled(e.target.checked)}
      >
        <Text 
          color={useV2Styles ? '#757575' : '#1a202c'}
          fontSize={useV2Styles ? '14px' : 'sm'}
        >
          Stop recording after
        </Text>
      </Checkbox>
      <Input
        type="tel"
        w="56px"
        height={useV2Styles ? '32px' : undefined}
        fontSize={useV2Styles ? '14px' : 'sm'}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        color={useV2Styles ? '#282828' : '#1a202c'}
        border={useV2Styles ? 'none' : undefined}
        borderBottom={useV2Styles ? '1px solid #E4E5E6' : undefined}
      />
      <Text 
        color={useV2Styles ? '#757575' : '#1a202c'} 
        fontSize={useV2Styles ? '14px' : 'sm'}
      >
        min
      </Text>
    </HStack>
  )
}

export default StopRecordingAfter
