// @ts-nocheck
import React from 'react'
import {
  Text,
  HStack,
  Stack,
  Box
} from '@chakra-ui/react'
import { Select } from '@blueprinthq/joy'
import { AssessmentResultsProvider, useAssessmentResults } from './context'
import { formatScoreToInterpretation } from '@utilities'
import Table from './table'
import { AssessmentResultSubScores } from '@containers/assessment-result-modal/components'

export function Results() {
  const {
    assessmentOptions,
    onSelectAssessment,
    assessmentDateOptions,
    onSelectScore,
    state,
    selectedAssessment,
    selectedAssessmentScore,
    formattedAssessmentScoreAnswers,
    clientId,
  } = useAssessmentResults()

  return (
    <Stack flex="1" spacing="0" overflowY="hidden">
      <HStack
        h="100px"
        align="center"
        flex="1"
        borderBottom="1px solid"
        borderColor="pale_gray"
        p="24px"
        gap="24px"
      >
       <Select
          options={assessmentOptions}
          valueKey="assessmentId"
          labelKey="name"
          label="Assessment"
          value={assessmentOptions.find(
            ao => ao.assessmentId === state.selectedAssessmentId
          )}
          onChange={option => onSelectAssessment(option.assessmentId)}
        />
        <Select
          options={assessmentDateOptions}
          valueKey="assessmentScoreId"
          labelKey="date"
          label="Date"
          value={assessmentDateOptions.find(
            ad => ad.assessmentScoreId === state.selectedAssessmentScoreId
          )}
          onChange={option => onSelectScore(option.assessmentScoreId)}
        />
      </HStack>
      <Stack px="24px" h="calc(100vh - 262px)" overflowY="scroll">
      <Box mt="medium">
        {selectedAssessment && (
          <Box>
            <Text>
              <Text fontWeight="bold" as="span">
                {selectedAssessment?.disorder}:
              </Text>
              {` ${formatScoreToInterpretation(
                selectedAssessment,
                selectedAssessmentScore?.total_score
              )} `}
              <Text color="medium_gray" as="span" fontSize="12px">
                ({selectedAssessmentScore?.total_score} out of{' '}
                {selectedAssessment?.max_score})
              </Text>
            </Text>
          </Box>
        )}
        {selectedAssessmentScore && (
          <Box mt={2}>
            <AssessmentResultSubScores
              handleCloseModal={() => {}}
              clientId={clientId}
              assessmentScore={selectedAssessmentScore}
            />
          </Box>
        )}
        <Box mt="16px">
          <Box
            mt="16px"
            overflowX={{
              base: 'auto',
              md: 'initial'
            }}
          >
            {selectedAssessment && (
              <Table
                answers={formattedAssessmentScoreAnswers}
                isReverseValence={selectedAssessment.is_reverse_valence}
              />
            )}
          </Box>
        </Box>
      </Box>
      </Stack>
    </Stack>
  )
}

const Wrapped = ({
  client,
  preselectedAssessmentId,
  preselectedAssessmentScoreId
}: any) => (
  <AssessmentResultsProvider 
    preselectedAssessmentId={preselectedAssessmentId}
    preselectedAssessmentScoreId={preselectedAssessmentScoreId}
    client={client}
  >
    <Results />
  </AssessmentResultsProvider>
)


export default Wrapped