import React, { useState, useEffect, useRef } from 'react'
import { Flex, Box, Button, VStack } from '@chakra-ui/react'
import { Add } from '@material-ui/icons'
import { motion, AnimatePresence } from 'framer-motion'

const MotionContainer = motion(Box)

interface Props {
  onInPersonClick: () => void
  onDictationClick: () => void
  onUploadClick: () => void
}

const FloatingActionButton: React.FC<Props> = ({
  onInPersonClick,
  onDictationClick,
  onUploadClick
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const fabButtonRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        fabButtonRef.current &&
        !fabButtonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleButtons = () => {
    setIsOpen(prev => !prev)
  }

  return (
    <>
      <Box
        position="fixed"
        bottom="0"
        left={0}
        right={0}
        w="100%"
        height="121px"
        bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 67%)"
      ></Box>
      <Flex
        position="fixed"
        bottom="16px"
        left="0"
        right="0"
        direction="column"
        alignItems="center"
        zIndex={1000}
      >
        <AnimatePresence>
          {isOpen && (
            <MotionContainer
              variants={{
                hidden: { opacity: 0, y: 220, scale: 0 },
                visible: { opacity: 1, y: 0, scale: 1 }
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{
                duration: 0.45,
                ease: [0.57, 0.1, 0, 1]
              }}
              display="flex"
              flexDirection="column"
            >
              <VStack spacing={4} align="stretch" mb={4}>
                <Button m={0} width="200px" onClick={onInPersonClick}>
                  In-person session
                </Button>
                <Button m={0} width="200px" onClick={onDictationClick}>
                  Dictate summary
                </Button>
                <Button m={0} width="200px" onClick={onUploadClick}>
                  Upload recording
                </Button>
              </VStack>
            </MotionContainer>
          )}
        </AnimatePresence>
        <Box w="100%" bg="white" px="16px">
          <Button
            m={0}
            ref={fabButtonRef}
            size="lg"
            onClick={toggleButtons}
            isFullWidth
            isDisabled={isOpen}
            leftIcon={<Add style={{ width: 14, height: 14 }} />}
            h="48px"
            boxShadow="0px 25px 50px -12px rgba(0, 0, 0, .65)"
          >
            Record new session
          </Button>
        </Box>
      </Flex>
    </>
  )
}

export default FloatingActionButton
