import React, { useState } from 'react'
import { Flex, Text, Button, useToast, Box } from '@chakra-ui/react'

import { useHistory } from 'react-router-dom'
import { useSessionControllerCreateSampleSession } from '~/clinician-api'
import { useQueryClient } from 'react-query'
import { useExperienceManager } from '@hooks'

import { endpoints } from '@api'

export const SampleSessionsModule = ({ containerProps = {} }) => {
  const toast = useToast()
  const history = useHistory()
  const [loadingStates, setLoadingStates] = useState({})
  const queryClient = useQueryClient()
  const { showRulaUI } = useExperienceManager()

  const {
    mutateAsync: executeCreateSampleSession
  } = useSessionControllerCreateSampleSession()

  const createSampleSession = async sampleType => {
    setLoadingStates(prevStates => ({
      ...prevStates,
      [sampleType]: true
    }))

    try {
      const response = await executeCreateSampleSession({
        data: { sampleType }
      })

      queryClient.invalidateQueries(
        endpoints.getClinicianDashboardSessions.getCacheId()
      )

      history.push(
        `/patient/${response.patientId}/completed-session/${response.sessionId}?isDemo=true`
      )
    } catch (err) {
      toast({
        title: 'Error',
        description: 'There was an error creating the sample progress note.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }

    setLoadingStates(prevStates => ({
      ...prevStates,
      [sampleType]: false
    }))
  }

  const SampleButton = ({ title, type }) => {
    return (
      <Flex
        flexDirection="row"
        bg="#F6F7FE"
        alignItems="center"
        justifyContent="center"
        width="auto"
        padding="0px 4px"
        border="2px solid"
        borderColor="rgb(246, 247, 254)"
        borderRadius="4px"
        _hover={{
          cursor: 'pointer',
          border: '2px solid',
          borderColor: 'primary'
        }}
      >
        <Button
          id={`pendo-sessions-sample-${type}`}
          data-click-event-name="Clicked Sample Session Link"
          variant="link"
          isLoading={loadingStates[type]}
          color="primary"
          onClick={() => createSampleSession(type)}
          ml={0}
          mr="xxsmall"
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z"
              fill="#2D54E8"
            />
          </svg>
          <Text ml="4px">{title}</Text>
        </Button>
      </Flex>
    )
  }

  const Samples = () => (
    <Flex
      flexDirection="row"
      gap="8px"
      flexWrap="wrap"
      justifyContent="left"
      mb="large"
    >
      {showRulaUI ? (
        <SampleButton title="Med Management" type="med_management" />
      ) : (
        <>
          <SampleButton title="Intake" type="intake" />
          <SampleButton title="CBT" type="cbt" />
          <SampleButton title="DBT" type="dbt" />
          <SampleButton title="EMDR" type="emdr" />
        </>
      )}
    </Flex>
  )

  return (
    <Flex alignItems="left" direction="column" width="100%" {...containerProps}>
      <Text fontWeight="bold" mb="xsmall" align="left">
        Explore sample notes
      </Text>
      <Box>
        <Samples />
      </Box>
    </Flex>
  )
}
