import { createStore, action } from 'easy-peasy'
import { authModel } from './model/auth'
import { patientListModel } from './model/patient-list'
import { insightsModel } from './model/insights'
import { quickEnrollModel } from './model/quick-enroll'
import { modalsModel } from './model/modals'
import { snackbarModel } from './model/snackbar'
import { patientProfileModal } from './model/patient-profile'
import { billingModel } from './model/billing'
import { enrollModel } from './model/enroll'
import { clinicianSignupModel } from './model/clinician-signup'
import { chimeModel } from './model/chime'
import { workflowsModel } from './model/workflows'
import { StoreModel } from './types'

export const store = createStore<StoreModel>({
  ...authModel,
  ...patientListModel,
  ...insightsModel,
  ...quickEnrollModel,
  ...modalsModel,
  ...snackbarModel,
  ...patientProfileModal,
  ...billingModel,
  ...enrollModel,
  ...clinicianSignupModel,
  ...chimeModel,
  ...workflowsModel,
  reset: action(() => ({
    ...authModel,
    ...patientListModel,
    ...insightsModel,
    ...quickEnrollModel,
    ...modalsModel,
    ...snackbarModel,
    ...patientProfileModal,
    ...billingModel,
    ...enrollModel,
    ...clinicianSignupModel,
    ...chimeModel,
    ...workflowsModel
  }))
})
