import React from 'react'

const HourGlassEmpty = ({ color = '#2D54E8' }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8V2H18V8H17.99L18 8.01L14 12L18 16L17.99 16.01H18V22H6V16.01H6.01L6 16L10 12L6 8.01L6.01 8H6ZM16 17.4775V16.5L12 12.5L8 16.5V17.4775C9.16667 17.1172 12.4 16.6127 16 17.4775ZM8 4V7.5L12 11.5L16 7.5V4H8Z"
        fill={color}
      />
    </svg>
  )
}

export default HourGlassEmpty
