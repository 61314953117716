import React from 'react'
import {
  DialogContainer,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '@handlers/sessions/components/dialog'
import { VStack, HStack, Text, Box, Button, Flex } from '@chakra-ui/react'
import {
  RadioSelectedIcon,
  RadioOutlineIcon,
  CloseCircle
} from '@blueprinthq/joy'
import { useClinicControllerGetCurrentPlan } from '~/clinician-api'
import { useStoreState } from 'easy-peasy'
import { UnassignOption } from '../types'
import { StoreModel } from 'src/store/types'

const Option = ({
  onClick,
  isSelected,
  title,
  description
}: {
  onClick: () => void
  isSelected: boolean
  title: string
  description: string
}) => {
  return (
    <VStack
      p={'24px'}
      borderRadius={'4px'}
      border={'1px solid #E4E5E6'}
      alignItems={'flex-start'}
      alignSelf={'stretch'}
      spacing={1}
      onClick={onClick}
      _hover={{ cursor: 'pointer' }}
    >
      <HStack spacing={2}>
        {isSelected ? (
          <RadioSelectedIcon fill="primary" />
        ) : (
          <RadioOutlineIcon fill="pale_gray" />
        )}
        <Text fontSize={'16px'} fontWeight={'bold'}>
          {title}
        </Text>
      </HStack>
      <Text fontSize={'16px'} pl="large" pb="xsmall">
        {description}
      </Text>
    </VStack>
  )
}

interface Props {
  isOpen: boolean
  onClose: () => void
  value: UnassignOption | null
  onChange: (value: UnassignOption) => void
  onConfirm: () => void
  isSubmitting: boolean
  isFreeTrial?: boolean
}

const BeforeYouDowngradeModal = ({
  isOpen,
  onClose,
  value,
  onChange,
  onConfirm,
  isSubmitting,
  isFreeTrial = false
}: Props) => {
  const [
    isFreeTrialDowngradeConfirmed,
    setIsFreeTrialDowngradeConfirmed
  ] = React.useState(false)

  const { user } = useStoreState((state: StoreModel) => state.auth)

  const clinicId = user?.clinic?.id

  const { data: currentPlan } = useClinicControllerGetCurrentPlan(
    // @ts-ignore
    clinicId, {
      enabled: !!clinicId
    }
  )

  const handleClose = () => {
    onClose()
    setIsFreeTrialDowngradeConfirmed(false)
  }

  const handleConfirmFreeTrialDowngrade = () => {
    if ((currentPlan?.assignedPatientCount || 0) > 0) {
      setIsFreeTrialDowngradeConfirmed(true)
    } else {
      onConfirm()
    }
  }

  if (!currentPlan) return null

  return (
    <DialogContainer isOpen={isOpen} onClose={handleClose} size="2xl">
      {isFreeTrial && !isFreeTrialDowngradeConfirmed ? (
        <>
          <DialogHeader
            text="End Free Trial: Back to Standard"
            onClose={onClose}
            hideBorder
          />
          <DialogBody p={0}>
            <Box px={6}>
              <Text>
                We hope you enjoyed your 60-day trial of Blueprint Plus!
              </Text>
              <br />
              <Text mb={6}>
                By ending your trial and remaining on the Standard Plan, you
                will no longer have access to the following features:
              </Text>
              <Flex alignItems="center" justifyContent="center" mb={6}>
                <VStack align="flex-start">
                  <Flex alignItems="center">
                    <CloseCircle w="13px" h="13px" />
                    <Text ml={2}>Pre-session insights</Text>
                  </Flex>
                  <Flex alignItems="center">
                    <CloseCircle w="13px" h="13px" />
                    <Text ml={2}>In-session guidance</Text>
                  </Flex>
                  <Flex alignItems="center">
                    <CloseCircle w="13px" h="13px" />
                    <Text ml={2}>Post-session suggestions</Text>
                  </Flex>
                </VStack>
              </Flex>
              <Text>
                If you ever want to add these features back, you are able to
                upgrade to Plus at any time in your account settings.
              </Text>
            </Box>
          </DialogBody>
          <DialogFooter borderTop="none">
            <Button isFullWidth onClick={handleConfirmFreeTrialDowngrade} isLoading={isSubmitting}>
              I Understand
            </Button>
          </DialogFooter>
        </>
      ) : (
        <>
          <DialogHeader
            text="Before you downgrade"
            onClose={onClose}
            hideBorder
          />
          <DialogBody p={0}>
            <VStack spacing={4} px={6}>
              <Text>
                You have{' '}
                <Text as="span" fontWeight="bold">
                  {currentPlan.assignedPatientCount} clients
                </Text>{' '}
                who have assessments and worksheets assigned to them. How do you
                want to manage these active assignments?
              </Text>
              <Box>
                <VStack spacing={2}>
                  <Option
                    title="Unassign everything immediately"
                    description="We will unassign all Assessments and Worksheets immediately."
                    onClick={() => onChange(UnassignOption.UnassignAll)}
                    isSelected={value === UnassignOption.UnassignAll}
                  />
                  <Option
                    title="Keep everything assigned"
                    description="Existing clients will continue to receive assessment and worksheet reminders. You can unassign at any time on a client’s profile."
                    onClick={() => onChange(UnassignOption.KeepAssigned)}
                    isSelected={value === UnassignOption.KeepAssigned}
                  />
                </VStack>
              </Box>
            </VStack>
          </DialogBody>
          <DialogFooter borderTop="none">
            <HStack spacing={4} w="100%">
              <Button onClick={onClose} variant="outline" isFullWidth>
                Cancel
              </Button>
              <Button isFullWidth isLoading={isSubmitting} onClick={onConfirm} bg="#EB5164">
                Confirm and Downgrade
              </Button>
            </HStack>
          </DialogFooter>
        </>
      )}
    </DialogContainer>
  )
}

export default BeforeYouDowngradeModal
