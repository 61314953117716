import React from 'react'
import { useQueryClient } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { Box, Flex, Text, Link, useDisclosure, useToast } from '@chakra-ui/react'
import { HourGlassEmpty, HourGlassFull } from '@components/icons'
import { BeforeYouDowngradeModal } from '@handlers/settings/update-your-plan/components'
import { UnassignOption } from '@handlers/settings/update-your-plan/types'
import { useHistory } from 'react-router-dom'
import { useExperienceManager } from '@hooks'
import moment from 'moment'
import { useClinicControllerCancelPlusPlanFreeTrial } from '~/clinician-api'
import { endpoints } from '@api'
import { StoreModel } from 'src/store/types'

export const PlusPlanFreeTrialEndingBanner = () => {
  const history = useHistory()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { user } = useStoreState((state: StoreModel) => state.auth)

  const [
    downgradeOption,
    setDowngradeOption
  ] = React.useState<UnassignOption | null>(null)

  const {
    daysRemainingInPlusPlanFreeTrial,
    plusPlanFreeTrialEndDate,
    isAdmin,
    documentationAutomationSessionsAllotted
  } = useExperienceManager()

  const {
    isOpen: isDowngradeOpen,
    onClose: onCloseDowngrade,
    onOpen: onOpenDowngrade
  } = useDisclosure()

  const { mutate: cancelTrial, isLoading: isCanceling } = useClinicControllerCancelPlusPlanFreeTrial()

  const isFreeTrialExpired = moment(plusPlanFreeTrialEndDate).isBefore(moment())

  const handleDowngrade = () => {
    if (user?.clinic.id) {
      cancelTrial(
        {
          clinicId: user.clinic.id,
          data: {
            cancellationFeedback: null,
            unassignMeasures: downgradeOption === UnassignOption.UnassignAll
          }
        },
        {
          onSuccess: async () => {
            onCloseDowngrade()
            toast({
              title: 'Plan successfully updated',
              status: 'success',
              isClosable: true,
              duration: 1200
            })

            queryClient.invalidateQueries(
              endpoints.getPaywallValidation.getCacheId()
            )
          },
          onError: (error: any) => {
            onCloseDowngrade()
            toast({
              title: error?.error?.message || 'Error updating plan',
              status: 'error',
              isClosable: true,
              duration: 3000
            })
          }
        }
      )
    }
  }

  if (!isAdmin || !user) return null

  const upgradeUrl = `/settings/update-your-plan?plan=plus&sessionCount=${documentationAutomationSessionsAllotted}`

  return (
    <Box>
      {isFreeTrialExpired ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="100%"
          bg="#FFF2F2"
          p={3}
        >
          <Box h="24px" w="24px" mr={2}>
            <HourGlassEmpty />
          </Box>
          <Flex wrap="wrap">
            <Text color="black" mr={2}>
              Your 60 day Blueprint Plus trial ended on{' '}
              {moment(plusPlanFreeTrialEndDate).format('MM/DD/YY')}. You have{' '}
              {daysRemainingInPlusPlanFreeTrial} days left to{' '}
              <Link
                _focus={{
                  outline: 'none'
                }}
                color="primary"
                onClick={() => history.push(upgradeUrl)}
              >
                Upgrade to Plus
              </Link>
              <Text as="span"> or </Text>
              <Link
                _focus={{
                  outline: 'none'
                }}
                color="primary"
                onClick={onOpenDowngrade}
              >
                Go Back to Standard
              </Link>
            </Text>
          </Flex>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="100%"
          bg="#E5FAEE"
          p={3}
        >
          <Box h="24px" w="24px" mr={2}>
            <HourGlassFull />
          </Box>
          <Flex wrap="wrap">
            <Text color="black" mr={2}>
              <Text fontWeight="bold" as="span">
                Ending Soon:{' '}
              </Text>
              Your free trial of Blueprint Plus ends in{' '}
              {daysRemainingInPlusPlanFreeTrial} days.{' '}
              <Link
                _focus={{
                  outline: 'none'
                }}
                color="primary"
                onClick={() => history.push(upgradeUrl)}
              >
                Continue with Plus
              </Link>
              <Text as="span"> or </Text>
              <Link
                _focus={{
                  outline: 'none'
                }}
                color="primary"
                onClick={onOpenDowngrade}
              >
                Go Back to Standard
              </Link>
            </Text>
          </Flex>
        </Box>
      )}
      <BeforeYouDowngradeModal
        isOpen={isDowngradeOpen}
        value={downgradeOption}
        onChange={setDowngradeOption}
        onClose={onCloseDowngrade}
        onConfirm={handleDowngrade}
        isSubmitting={isCanceling}
        isFreeTrial
      />
    </Box>
  )
}
