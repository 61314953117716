import React, { useEffect } from 'react'
import {
  Button,
  Stack,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'
import { Pagination } from '@handlers/sessions/components/shared/pagination'
import { AssignWorksheetsModal } from '../../shared/assist/worksheets/assign-worksheets-modal'
import {
  usePatientControllerAssistEnroll,
  getSessionControllerGetSuggestedContentQueryKey,
} from '~/clinician-api'
import { endpoints } from '@api'
import { buildCheckInPreviewUrl } from '@utilities'
import {
  AssignableWorksheet
} from '@handlers/sessions/types'

interface WorksheetSuggestionModuleProps {
  sessionId: string
  client: any
  showDemoExperience: boolean
  suggestions: any
  demoData: any
}

export const WorksheetSuggestionModule = ({
  sessionId,
  client,
  showDemoExperience,
  suggestions,
  demoData
}: WorksheetSuggestionModuleProps) => {
  const [worksheetIdx, setWorksheetIdx] = React.useState<number>(0)
  const [
    selectedWorksheet,
    setSelectedWorksheet
  ] = React.useState<AssignableWorksheet | null>(null)

  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    isOpen: isAssignWorksheetsModalOpen,
    onOpen: openAssignWorksheetsModal,
    onClose: closeAssignWorksheetsModal
  } = useDisclosure()

  useEffect(() => {
    if (!isAssignWorksheetsModalOpen) {
      setSelectedWorksheet(null)
    }
  }, [isAssignWorksheetsModalOpen])

  const {
    mutateAsync: enrollInAssist,
    isLoading: isEnrolling
  } = usePatientControllerAssistEnroll()

  const handleWorksheetEnroll = (values: any) => {
    if (selectedWorksheet) {
      enrollInAssist(
        {
          patientId: client.id,
          data: {
            email: values.email,
            phone: values.phone,
            assessments: [],
            checkIns: [
              {
                daysOfWeek: values.checkIn.daysOfWeek,
                timeOfDay: values.checkIn.timeOfDay,
                frequencyType: values.checkIn.frequency,
                suggestionId: selectedWorksheet.contentSuggestionId,
                checkInId: selectedWorksheet.checkInId
              }
            ]
          }
        },
        {
          onSuccess: () => {
            closeAssignWorksheetsModal()
            toast({
              title: 'Assigned!',
              status: 'success',
              isClosable: true,
              duration: 1200
            })
            const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
              sessionId
            )
            queryClient.invalidateQueries({ queryKey: suggestionListKey })
            queryClient.invalidateQueries([
              endpoints.getClinicianUserAccount.getCacheId(),
              client.id
            ])
          },
          onError: () => {
            toast({
              title: 'Error',
              description: 'There was an error assigning this measure',
              status: 'error',
              isClosable: true,
              duration: 2000
            })
          }
        }
      )
    }
  }

  const worksheetSuggestion = showDemoExperience
    ? demoData?.suggestedWorksheets[worksheetIdx]
    : suggestions?.suggestedWorksheets[worksheetIdx]

  const worksheetCount = showDemoExperience
    ? demoData?.suggestedWorksheets?.length
    : suggestions?.suggestedWorksheets.length || 0

  const handleBrowseLibraryClick = () => {
    openAssignWorksheetsModal()
  }

  const handleSetWorksheet = (worksheet: any | null) => {
    setSelectedWorksheet(worksheet ? { checkInId: worksheet.id } : null)
  }

  const onWorksheetAssignClick = () => {
    if (worksheetSuggestion) {
      setSelectedWorksheet({
        checkInId: worksheetSuggestion.checkInId,
        contentSuggestionId: worksheetSuggestion.contentSuggestionId
      })
      openAssignWorksheetsModal()
    }
  }

  return (
    <Stack spacing={2}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap="12px" alignItems="center">
          <Text fontWeight="bold">Worksheets</Text>
          <Button
            variant="link"
            textDecoration="none"
            onClick={handleBrowseLibraryClick}
            fontSize="sm"
            m="0"
            _focus={{ outline: 'none' }}
          >
            Browse all
          </Button>
        </Flex>
        <Flex justifyContent="center">
          <Pagination
            current={worksheetCount === 0 ? 0 : worksheetIdx + 1}
            total={worksheetCount || 0}
            onNext={() => setWorksheetIdx(prev => prev + 1)}
            onPrev={() => setWorksheetIdx(prev => prev - 1)}
            isDisabled={worksheetCount === 0}
          />
        </Flex>
      </Flex>
      {worksheetCount > 0 ? (
        <ContentSuggestionCard
          isDemo={showDemoExperience}
          type="worksheet"
          suggestion={{
            ...worksheetSuggestion,
            name: worksheetSuggestion?.worksheetName
          }}
          sessionId={sessionId}
          truncateRationale
          onAssign={onWorksheetAssignClick}
          previewUrl={buildCheckInPreviewUrl(worksheetSuggestion.checkInId)}
        />) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          border="1px solid #E4E5E6"
          w="100%"
          h="64px"
        >
          <Text fontSize="12px" color="dark_gray">No suggestions at this time.</Text>
        </Flex>
      )}
      {isAssignWorksheetsModalOpen && (
        <AssignWorksheetsModal
          onEnroll={handleWorksheetEnroll}
          isEnrolling={isEnrolling}
          sessionId={sessionId}
          worksheet={selectedWorksheet}
          client={client}
          isOpen={isAssignWorksheetsModalOpen}
          onClose={closeAssignWorksheetsModal}
          setWorksheet={handleSetWorksheet}
        />
      )}
    </Stack>
  )
}