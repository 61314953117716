/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type ClinicControllerGetClientListSortBy = typeof ClinicControllerGetClientListSortBy[keyof typeof ClinicControllerGetClientListSortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClinicControllerGetClientListSortBy = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phoneNumber: 'phoneNumber',
  lastSessionDate: 'lastSessionDate',
} as const;
