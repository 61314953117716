import React from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { HStack, Stack, useToast } from '@chakra-ui/react'
import {
  AssignAssessmentsForm,
  AssignAssessmentsFormFields
} from '../../shared/assist/assign-assessments-form'
import { Button } from '@blueprinthq/joy'
import { useTreatmentPlan, useExperienceManager } from '@hooks'
import { useCadenceOptions } from '@handlers/sessions/components/shared/use-cadence-options'
import { useAssistPanelContext } from '../context'
import {
  useContentSuggestionControllerAccept,
  getSessionControllerGetSuggestedContentQueryKey
} from '~/clinician-api'
import { AssignAssessmentsFormValues } from '@handlers/sessions/types'
import { endpoints } from '@api'

const Assign = () => {
  const toast = useToast()
  const { setOverlayPanel, data } = useAssistPanelContext()
  const { cadenceMapping } = useCadenceOptions()
  const { client } = useTreatmentPlan()
  const onCancel = () => setOverlayPanel(null)
  const queryClient = useQueryClient()
  const { isSessionRecordingV2Enabled } = useExperienceManager()

  const { assessment, sessionId } = data
  const { mutate: acceptSuggestion } = useContentSuggestionControllerAccept()

  const { mutate: assignAssessment, isLoading: isAssigning } = useMutation(
    endpoints.postClientAssignAssessments.request,
    {
      onSuccess: async () => {
        if (assessment.contentSuggestionId) {
          await acceptSuggestion({
            contentSuggestionId: assessment.contentSuggestionId
          })
        }

        toast({
          title: 'Assessment assigned',
          status: 'success',
          isClosable: true,
          duration: 1200
        })

        const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
          sessionId
        )
        queryClient.invalidateQueries({ queryKey: suggestionListKey })
        queryClient.invalidateQueries([
          endpoints.getAllAssessmentsForClientByAssignee.getCacheId(),
          client?.id
        ])
        queryClient.invalidateQueries([
          endpoints.getClinicianUserActiveAssessments.getCacheId(),
          client?.id
        ])
        queryClient.invalidateQueries([
          endpoints.getClinicianUserNextDeliveryDate.getCacheId(),
          client?.id
        ])
        setOverlayPanel(null)
      }
    }
  )

  const handleSubmit = async (values: AssignAssessmentsFormValues) => {
    assignAssessment({
      clientId: client?.id,
      data: {
        assignments: [
          {
            clinicAssessmentId: values.clinicAssessmentId,
            cadence: cadenceMapping[values.cadence],
            assigneeUserId: client?.user_id
          }
        ],
        administerNow: !values.nextAdministrationDate
      }
    })
  }

  if (!client) {
    return null
  }

  return (
    <AssignAssessmentsForm
      client={client}
      assessment={assessment}
      onSubmit={handleSubmit}
    >
      {({ formikProps, allAssessments }: any) => (
        <Stack flex="1" spacing="0" overflowY="hidden">
          <Stack
            p="24px"
            h={
              isSessionRecordingV2Enabled
                ? 'calc(100vh - 203px)'
                : 'calc(100vh - 262px)'
            }
            overflowY="scroll"
          >
            <AssignAssessmentsFormFields
              formikProps={formikProps}
              client={{
                id: client.id || '',
                user_id: client.user_id || '',
                first_name: client.first_name || '',
                last_name: client.last_name || '',
                enrollment_timestamp: client.enrollment_timestamp || '',
                assessmentDeliveryMethods:
                  client.assessmentDeliveryMethods || [],
                email: client.email || '',
                phone_number: client.phone_number || '',
                age_range: client.age_range || '',
                high_risk_timestamp: client.high_risk_timestamp || '',
                is_demo: client.is_demo
              }}
              assessments={allAssessments}
            />
          </Stack>
          <HStack
            p="24px"
            borderTop="1px solid"
            borderColor="pale_gray"
            position="sticky"
          >
            <Button
              variant="outline"
              m="0"
              size="lg"
              isFullWidth
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              m="0"
              size="lg"
              isFullWidth
              isLoading={isAssigning}
              onClick={formikProps.submitForm}
            >
              Assign
            </Button>
          </HStack>
        </Stack>
      )}
    </AssignAssessmentsForm>
  )
}

export default Assign
