import React, { useState } from 'react'
import { Box } from '@chakra-ui/react'
import { motion, AnimatePresence } from 'framer-motion'
import { DictationTipsPanel } from './dictation-tips-panel'
import { AssistDrawerHeader } from './assist-drawer-header'

const slideAnimation = {
  initial: { y: '100%' },
  animate: {
    y: 0,
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 300
    }
  },
  exit: {
    y: '100%',
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 300
    }
  }
}

const backdropAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
}

export const MobileDictationTipsDrawer = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDrawer = () => {
    setIsOpen(prev => !prev)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <AssistDrawerHeader
        title="Dictation Tips"
        toggleAssistFullScreen={toggleDrawer}
        isExpanded={false}
        showShadow={true}
      />

      <AnimatePresence>
        {isOpen && (
          <>
            <Box
              position="fixed"
              top={0}
              left={0}
              right={0}
              bottom={0}
              mt="0px !important"
              bg="blackAlpha.600"
              zIndex="modal"
              as={motion.div}
              {...backdropAnimation}
              onClick={handleClose}
            />
            <Box
              data-testid="mobile-dictation-tips-drawer"
              as={motion.div}
              position="fixed"
              top={0}
              left={0}
              right={0}
              bottom={0}
              zIndex="modal"
              bg="white"
              {...slideAnimation}
            >
              <AssistDrawerHeader
                title="Dictation Tips"
                toggleAssistFullScreen={handleClose}
                isExpanded={true}
                showShadow={false}
              />
              <Box px="24px" pb="32px" pt="8px">
                <DictationTipsPanel />
              </Box>
            </Box>
          </>
        )}
      </AnimatePresence>
    </>
  )
}