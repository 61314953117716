import React from 'react'
import { Box, Flex, Text, Image } from '@chakra-ui/react'
import {
  RadioButtonLoading,
  RadioButtonNotLoading,
  RadioButtonLoadingComplete
} from '@components/icons'
import InfoIcon from '@material-ui/icons/Info'

const Status = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR'
}

const StepText = {
  PROCESS_AUDIO: {
    [Status.NOT_STARTED]: 'Process audio',
    [Status.STARTED]: 'Processing audio',
    [Status.COMPLETED]: 'Processed audio'
  },
  TRANSCRIBING_AUDIO: {
    [Status.NOT_STARTED]: 'Transcribe audio',
    [Status.STARTED]: 'Transcribing audio',
    [Status.COMPLETED]: 'Transcribed audio',
    [Status.ERROR]: 'Transcribing audio'
  },
  SUMMARIZE_CONVERSATION: {
    [Status.NOT_STARTED]: 'Summarize conversation',
    [Status.STARTED]: 'Summarizing conversation',
    [Status.COMPLETED]: 'Summarized conversation'
  },
  REQUEST_NOTE_GENERATION: {
    [Status.NOT_STARTED]: 'Request progress note',
    [Status.STARTED]: 'Requesting progress note',
    [Status.COMPLETED]: 'Requested progress note'
  },
  GENERATE_NOTE: {
    [Status.NOT_STARTED]: 'Generate summary and note',
    [Status.STARTED]: 'Generating summary and note',
    [Status.COMPLETED]: 'Generated summary and note'
  },
  DELETE_AUDIO: {
    [Status.NOT_STARTED]: 'Delete audio',
    [Status.STARTED]: 'Deleting audio',
    [Status.COMPLETED]: 'Deleted audio'
  }
}

type Step = keyof typeof StepText

type ProgressNoteGenerationStepStatusesProps = {
  progressNoteGenerationStepStatuses: { step: Step; status: string }[]
}

const RadioButton = ({ status }: { status: any }) => {
  switch (status) {
    case Status.STARTED:
      return <RadioButtonLoading />
    case Status.COMPLETED:
      return <RadioButtonLoadingComplete />
    case Status.ERROR:
      return <InfoIcon style={{ color: '#EB5164' }} />
    default:
      return <RadioButtonNotLoading />
  }
}

const ProgressNoteGenerationSteps = ({
  progressNoteGenerationStepStatuses
}: ProgressNoteGenerationStepStatusesProps) => {
  return (
    <Flex
      p="0px 32px 48px 32px"
      flexDirection="column"
      backgroundColor="white"
      flex="1"
      alignContent="center"
      alignItems="center"
    >
      <Image
        height="260"
        width="260"
        src="https://res.cloudinary.com/hellojoy/image/upload/v1734112184/smdn2p6niu3rjkwbrd6g.gif"
        alt="Generating Note"
      />
      <Text textAlign="center" fontWeight="bold" mb="xxsmall">
        Generating note...
      </Text>
      <Text textAlign="center" color="#757575">
        This process could take up to 5 minutes. You’re free to close this tab
        or switch tasks while we finish up.
      </Text>
      <Box h="40px" />
      <Flex flexDirection="column" alignSelf="center" gap="16px">
        {progressNoteGenerationStepStatuses.map((step, index) => (
          <Flex key={index}>
            <RadioButton status={step.status} />
            <Box w="8px" />
            <Text>{StepText?.[step.step]?.[step.status]}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default ProgressNoteGenerationSteps
