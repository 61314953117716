import React from 'react'

const Check = ({ fill = '#44CD7D', width = '14px', height = '14px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Checkmark" transform="translate(0.000000, 1.000000)" fill={fill}>
        <path d="M5.02493098,12 C4.69432341,12 4.37600012,11.8679655 4.14313275,11.6317352 L0.852011767,8.29269498 C0.375327984,7.80909888 0.383873575,7.03333012 0.870972299,6.56007407 C1.35780397,6.08655288 2.13919151,6.09556728 2.61614234,6.57863313 L4.90288925,8.89878089 L11.27977,0.488609795 C11.6896913,-0.0517240119 12.4628003,-0.160957343 13.0081159,0.246546613 C13.5526303,0.653520309 13.6615866,1.42160031 13.2519323,1.96219925 L6.01087862,11.5116316 C5.79456833,11.7969108 5.46369371,11.9742824 5.10451181,11.9973487 C5.07807388,11.9992046 5.05163596,12 5.02493098,12 Z"></path>
      </g>
    </g>
  </svg>
)
export default Check
