import React from 'react'
import ArchivePatientModal from './components/archive-patient'
import UnarchivePatientModal from './components/unarchive-patient'
import { useQuery } from 'react-query'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { endpoints } from '../../api'

export const ArchivePatientModalConnected = () => {
  const user = useStoreState(state => state.auth.user)
  const { isOpen, patientId, reason, sendEndOfCareAssessments } = useStoreState(
    state => state.modals.archivePatient
  )
  const handleCloseModal = useStoreActions(
    actions => actions.modals.archivePatient.closeModal
  )

  const { data: patient, isLoading } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), patientId],
    () => endpoints.getClinicianUserAccount.request({ id: patientId }),
    {
      enabled: isOpen && !!patientId
    }
  )

  if (!isOpen || !user || !patientId || !patient || isLoading) return null

  return patient.is_archived ? (
    <UnarchivePatientModal
      isOpen={isOpen}
      patient={patient}
      onClose={handleCloseModal}
    />
  ) : (
    <ArchivePatientModal
      isOpen={isOpen}
      patient={patient}
      reason={reason}
      sendEOCAssessments={sendEndOfCareAssessments}
      handleCloseModal={handleCloseModal}
    />
  )
}
