import React, { createContext, useCallback, useState, useContext } from 'react'
import { PanelKey } from '../overlay-panel'
import { SessionPanelTab } from '@handlers/sessions/types'


type InterventionLibraryData = {
  scrollTop: number
  search: string
  approach: {
    label: string
    value: string
  }
}
interface AssistPanelContextType {
  switchTab: (tab: SessionPanelTab) => void
  activeTab: string
  panel: PanelKey | null
  data?: any
  interventionLibraryData: InterventionLibraryData
  saveInterventionLibraryData: (state: Partial<InterventionLibraryData>) => void
  setOverlayPanel: (panel: PanelKey | null, data?: any) => void
}

export const AssistPanelContext = createContext<AssistPanelContextType>({
  switchTab: (tab: SessionPanelTab) => {},
  activeTab: SessionPanelTab.PREP,
  panel: null,
  data: null,
  interventionLibraryData: {
    scrollTop: 0,
    search: '',
    approach: {
      label: 'All treatment approaches',
      value: ''
    }
  },
  saveInterventionLibraryData: () => {},
  setOverlayPanel: () => {}
})

export const useAssistPanelContext = () => useContext(AssistPanelContext)

export const AssistPanelProvider: React.FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState(SessionPanelTab.PREP)
  const [panel, setPanel] = useState<PanelKey | null>(null)
  const [panelData, setPanelData] = useState<any>(null)
  const [interventionLibraryData, setInterventionLibraryData] = useState<InterventionLibraryData>({
    scrollTop: 0,
    search: '',
    approach: {
      label: 'All treatment approaches',
      value: ''
    }
  })

  const switchTab = useCallback((tab: SessionPanelTab) => {
    setActiveTab(tab)
  }, [])

  const setOverlayPanel = (panel: PanelKey | null, data?: any) => {
    setPanel(panel)
    setPanelData(data)
  }

  const saveInterventionLibraryData = (state: Partial<InterventionLibraryData>) => {
    setInterventionLibraryData({
      ...interventionLibraryData,
      ...state
    })
  }

  return (
    <AssistPanelContext.Provider
      value={{ switchTab, activeTab, panel, data: panelData, setOverlayPanel, interventionLibraryData, saveInterventionLibraryData }}
    >
      {children}
    </AssistPanelContext.Provider>
  )
}

export default AssistPanelProvider
