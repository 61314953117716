import React from 'react'
import {
  Text,
  Divider,
  VStack,
  Box,
  HStack
} from '@chakra-ui/react'
import { timeToRelativeOrDate } from '@utilities'

import { ArrowRightIcon } from '@blueprinthq/joy'

interface CompletedInterventionsProps {
  interventions: any[]
  onClick: (ci: any) => void
}

export const CompletedInterventions = ({
  interventions: completedInterventions,
  onClick
}: CompletedInterventionsProps) => {
  return (
    <Box>
      <Text fontWeight="bold" mb="xsmall">
        Completed
      </Text>
      <Box
        border="1px solid"
        borderColor="pale_gray"
        rounded="md"
      >
        {completedInterventions?.length === 0 ? (
          <Text p="small">No completed interventions.</Text>
        ) : (
        <VStack
          flex={1}
          alignItems="flex-start"
          divider={
            <Divider
              color="pale_gray"
              mt="0px !important"
              mb="0px !important"
            />
          }
        >
          {completedInterventions.map(ci => (
            <HStack
              key={ci.id}
              p="small"
              alignItems="center"
              justify="space-between"
              w="100%"
              _hover={{
                bg: 'gray.200',
                cursor: 'pointer'
              }}
              onClick={() => {
                onClick(ci)
              }}
            >
              <Box>
                <Text>{ci.name}</Text>
              </Box>
              <HStack gap="medium">
                <Text fontSize="small" color="gray">
                  {timeToRelativeOrDate(ci.completedAt)}
                </Text>
                <ArrowRightIcon />
              </HStack>
            </HStack>
          ))}
        </VStack>)}
      </Box>
    </Box >
  )
}
