import React, { useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  GridItem,
  Flex,
  Text,
  Box,
  Button,
  VStack,
  Divider,
  HStack,
  Image,
  Container,
  useBreakpointValue
} from '@chakra-ui/react'
import { useStoreActions } from 'easy-peasy'
import { usePermissions, useExperienceManager } from '@hooks'
import { LayoutGrid } from '@blueprinthq/joy'
import { ClinicianOverride, Loading } from '@components'
import { endpoints } from '@api'
import { useHistory } from 'react-router-dom'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { Check, Mic, PersonDictate, Upload } from '@components/icons'
import { Add } from '@material-ui/icons'
import { UploadModalV2 } from '../sessions/components/upload-modal-v2'
import ChromeImage from '../../images/brand/chrome-image.png'
import {
  ConsentModule,
  SampleSessionsModule,
  SessionContainer
} from './components'
import SessionNotesRemainingWarning from '../sessions/components/session-notes-remaining-warning'
import FloatingRecordButton from './components/floating-record-button'
import EmptyState from './components/empty-state-v2'
import WelcomeBanner from './components/welcome-banner'

const Header = ({
  showDropdown,
  isAdmin,
  isSuperAdmin,
  onClinicianChange,
  user
}) => {
  return (
    <Flex
      direction={['column', null, 'row']}
      justify={['center', null, 'flex-start']}
      align={['space-between', null, 'center']}
    >
      <Flex align="center" justify="space-between">
        <Text as="h1" fontSize="xl" fontWeight="bold">
          Sessions
        </Text>
      </Flex>

      {showDropdown && (
        <ClinicianOverride
          user={user}
          isSuperAdmin={isSuperAdmin}
          canViewOrgDropdown={isAdmin}
          onChange={id => onClinicianChange(id)}
          containerProps={{
            mt: { base: 2, md: 0 },
            ml: { base: 0, md: 4 },
            flex: 1
          }}
          hideIfOnlyOneClinician
        />
      )}
    </Flex>
  )
}

const ChromeExtensionCTA = () => {
  return (
    <Flex
      border="1px solid"
      borderColor="pale_gray"
      borderRadius="8px"
      width="100%"
      direction="column"
      cursor="pointer"
      onClick={() =>
        window.open(
          'https://chromewebstore.google.com/detail/blueprint-ai-notetaker/fmjbhmhmfanpmdhpjjemafjlgomdccnm',
          '_blank'
        )
      }
    >
      <Box p={3} pb={0}>
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="flex-start"
          gap="16px"
          width="100%"
        >
          <Flex p="12px" borderRadius="8px" bg="#F9F9F9">
            <Image
              src="https://upload.wikimedia.org/wikipedia/commons/e/e1/Google_Chrome_icon_%28February_2022%29.svg" // TODO try and render an SVG
              alt="chrome-icon"
              h="24px"
              w="24px"
            />
          </Flex>
          <Flex direction="column" mb={4}>
            <Text fontWeight="bold">Get the chrome extension</Text>
            <Flex direction="column" alignItems="start" mb="xsmall" flex={1}>
              <Flex alignItems="center">
                <Check height="12px" width="12px" fill="#2D54E8" />
                <Text fontSize="12px" ml="xxsmall">
                  On top of your EHR
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Check height="12px" width="12px" fill="#2D54E8" />
                <Text fontSize="12px" ml="xxsmall">
                  Best for telehealth sessions
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Check height="12px" width="12px" fill="#2D54E8" />
                <Text fontSize="12px" fontWeight="430" ml="xxsmall">
                  No extra tab
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Box>
          <Button
            m={0}
            size="sm"
            variant="outline"
            borderRadius="24px"
            isFullWidth
            _hover={{
              bg: 'rgba(45, 84, 232, 0.04)'
            }}
          >
            Add to Chrome
          </Button>
        </Box>
      </Box>
      <Flex flex={1} mt={4}>
        <Image
          src={ChromeImage}
          alt="CTA background"
          objectFit="cover"
          width="100%"
        />
      </Flex>
    </Flex>
  )
}

const DesktopStartRecording = ({
  onInPersonClick,
  onDictationClick,
  onUploadClick
}) => {
  const showLeftIcon = useBreakpointValue({ base: false, lg: true })

  return (
    <VStack w="100%" spacing={4} align="stretch">
      <Text fontWeight="bold">Generate session notes</Text>
      <Box
        id="pendo-record-new-session"
        p={4}
        bg="rgba(45, 84, 232, 0.04)"
        borderRadius="24px"
      >
        <Flex
          direction="column"
          align="center"
          gap="14px"
          justifyContent="center"
          w="100%"
        >
          <Flex w="100%" justify="center" opacity="0.40">
            <Mic fill="#8198F1" />
          </Flex>
          <Flex w="100%" justify="center" py={3}>
            <Text
              color="rgba(45, 84, 232, 0.30)"
              fontSize="65px"
              fontWeight="300"
              lineHeight={1}
            >
              00:00
            </Text>
          </Flex>
          <Box w="100%">
            <Button
              data-click-event-name="Sessions Index -> Clicked Start Session"
              onClick={onInPersonClick}
              isFullWidth
              leftIcon={<Add style={{ width: 14, height: 14 }} />}
              h="48px"
            >
              Record new session
            </Button>
          </Box>
        </Flex>
      </Box>
      <Flex w="100%" justify="center">
        <HStack spacing="8px" align="center">
          <Text lineHeight={1}>or</Text>
          <Button
            h="22px"
            p={0}
            m={0}
            variant="ghost"
            color="primary"
            leftIcon={showLeftIcon ? <PersonDictate /> : undefined}
            onClick={onDictationClick}
          >
            Dictate a note
          </Button>
          <Box h="22px">
            <Divider orientation="vertical" />
          </Box>
          <Button
            h="22px"
            p={0}
            m={0}
            variant="ghost"
            color="primary"
            leftIcon={showLeftIcon ? <Upload fill="#627FEE" /> : undefined}
            onClick={onUploadClick}
          >
            Upload recording
          </Button>
        </HStack>
      </Flex>
    </VStack>
  )
}

const SessionDashboardV2 = () => {
  const {
    documentationAutomationFreeTierSessionLimitReached,
    isAdmin,
    showRulaUI
  } = useExperienceManager()
  const [overrideClinicianId, setOverrideClinicianId] = useState(null)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [
    hasLessThanNumberRecordedSessions,
    setHasLessThanNumberRecordedSessions
  ] = useState(true)
  const { hasPermission } = usePermissions()
  const history = useHistory()

  const openDocAutomationSessionLimitReachedModal = useStoreActions(
    actions => actions.modals.docAutomationSessionLimitReached.openModal
  )
  const openDocAutomationPlanModal = useStoreActions(
    actions => actions.modals.manageScribePlan.openModal
  )

  const getClinicianDashboardQueryKey = useMemo(() => {
    const key = [endpoints.getClinicianDashboardSessions.getCacheId()]
    if (overrideClinicianId) key.push(overrideClinicianId)

    return key
  }, [overrideClinicianId])

  const {
    data: sessionsData,
    isLoading: isSessionsLoading,
    refetch: refetchSessions
  } = useQuery([...getClinicianDashboardQueryKey], () =>
    endpoints.getClinicianDashboardSessions.request({
      overrideClinicianId,
      includeRecordingDrafts: true
    })
  )

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const isCurrentUserSelected =
    !overrideClinicianId || overrideClinicianId === user?.id

  const alwaysShowSampleSessions = flagsmith.hasFeature(
    FlagsmithFeatures.ALWAYS_SHOW_SAMPLE_SESSIONS
  )

  // once num recorded sessions >= this number, hide the sample sessions module
  const numberRecordedSessions = 5

  useEffect(() => {
    if (isCurrentUserSelected) {
      setHasLessThanNumberRecordedSessions(
        !isSessionsLoading &&
          sessionsData.nodes.filter(s => s.hasAudio && !s.excludeFromDAUsage)
            .length < numberRecordedSessions
      )
    }
  }, [isSessionsLoading, sessionsData, isCurrentUserSelected])

  const hasSupervisorRole = user?.roles?.some(role =>
    ['supervisor_clinician', 'supervisor', 'clinicadmin'].includes(role.name)
  )

  const hasAdminRole = user?.roles?.some(role => role.name === 'orgadmin')

  const showDropdown =
    hasPermission('*:any:*:*') || hasSupervisorRole || hasAdminRole

  const handleInPersonClick = () => {
    history.push('/start-session/select-client?signup=true')
  }

  const handleDictationClick = () => {
    history.push('/start-session/dictate/select-client')
  }

  const handleUploadClick = () => {
    console.log('click')
    if (documentationAutomationFreeTierSessionLimitReached) {
      if (isAdmin) {
        openDocAutomationPlanModal()
      } else {
        openDocAutomationSessionLimitReachedModal()
      }
    } else {
      setUploadModalOpen(true)
    }
  }

  if (!user) return null

  return (
    <Container p={8} position="relative" border="none" centerContent maxW="l">
      <Box mb={'medium'} w={'100%'}>
        <LayoutGrid gridColumnGap={{ base: 0, md: 8, lg: 16 }}>
          <GridItem
            colSpan={{
              base: 12,
              md: 8
            }}
          >
            <VStack spacing={4} align="stretch" w="100%" mb={8}>
              <Header
                isAdmin={hasAdminRole}
                isSuperAdmin={hasPermission('*:any:*:*')}
                showDropdown={showDropdown}
                user={user}
                history={history}
                onClinicianChange={setOverrideClinicianId}
              />
              <SessionNotesRemainingWarning />
              {hasLessThanNumberRecordedSessions && !showRulaUI && (
                <WelcomeBanner />
              )}
            </VStack>
            <GridItem
              display={{ base: 'block', md: 'none' }}
              colSpan={{
                base: 12,
                md: 3
              }}
              colStart={{
                base: 0,
                md: 10
              }}
              mb={8}
            >
              <VStack align="stretch">
                {(alwaysShowSampleSessions ||
                  hasLessThanNumberRecordedSessions) && (
                  <SampleSessionsModule />
                )}
                {isAdmin && (
                  <VStack spacing={4} align="stretch" w="100%">
                    <Text fontWeight="bold">More tools</Text>
                    <ConsentModule />
                  </VStack>
                )}
              </VStack>
            </GridItem>
            <Box mb="64px">
              {isSessionsLoading ? (
                <Loading />
              ) : sessionsData?.nodes?.length === 0 ? (
                <EmptyState />
              ) : (
                <SessionContainer
                  data={sessionsData}
                  isLoading={isSessionsLoading}
                  refetchSessions={refetchSessions}
                />
              )}
            </Box>
          </GridItem>
          <GridItem
            display={{ base: 'none', md: 'block' }}
            colSpan={{
              base: 12,
              md: 4
            }}
          >
            <DesktopStartRecording
              onInPersonClick={handleInPersonClick}
              onDictationClick={handleDictationClick}
              onUploadClick={handleUploadClick}
            />
            <Flex direction="column" alignItems="center" mt="71px">
              {(alwaysShowSampleSessions ||
                hasLessThanNumberRecordedSessions) && <SampleSessionsModule />}
              <VStack spacing={4} align="stretch" w="100%">
                <Text fontWeight="bold">More tools</Text>
                {isAdmin && <ConsentModule />}
                <Box display={{ base: 'none', md: 'block' }}>
                  <ChromeExtensionCTA />
                </Box>
              </VStack>
            </Flex>
          </GridItem>
        </LayoutGrid>
        <Box display={{ base: 'block', md: 'none' }}>
          <FloatingRecordButton
            onInPersonClick={handleInPersonClick}
            onDictationClick={handleDictationClick}
            onUploadClick={handleUploadClick}
          />
        </Box>
        <UploadModalV2
          open={uploadModalOpen}
          onClose={() => setUploadModalOpen(false)}
        />
      </Box>
    </Container>
  )
}

export default SessionDashboardV2
