/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */

export type ClinicControllerGetClientListSortDirection = typeof ClinicControllerGetClientListSortDirection[keyof typeof ClinicControllerGetClientListSortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClinicControllerGetClientListSortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;
