import React, { useMemo } from 'react'
import { useMutation } from 'react-query'
import {
  useHistory,
  Link as RLink,
  Redirect,
  useLocation
} from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import {
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Button,
  GridItem,
  useToast,
  Link,
  Circle
} from '@chakra-ui/react'
import { PersonAddIcon, TextField, LayoutGrid, Select } from '@blueprinthq/joy'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { BPDateTimePicker } from '@components'
import { endpoints } from '@api'
import moment from 'moment'
import { useSessionControllerPostSession } from '~/clinician-api'
import { useExperienceManager, useExtension } from '@hooks'
import qs from 'query-string'
import { useComponentRenderTracker } from '@hooks/utilities/use-component-render-tracker'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { CLIENT_PRONOUNS } from '@constants/clientPronouns'

const pronounTypes = Object.keys(CLIENT_PRONOUNS)

const EnrollClient = ({ isDictation = false }) => {
  useComponentRenderTracker('Viewed Sessions New Client Page')
  const toast = useToast()
  const { user } = useStoreState(state => state.auth)
  const {
    isEvidenceBasedCareEnabled,
    isExtension,
    isWidget,
    isPlusPlanEnabled,
    isSessionRecordingV2Enabled,
    isDateOfBirthEnabled
  } = useExperienceManager()
  const { settings } = useExtension()
  const location = useLocation()
  const { signup } = qs.parse(location.search)

  const initialValues = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    pronoun: null
  }

  const baseSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    pronoun: Yup.string()
      .optional()
      .nullable()
  })

  const validationSchema = useMemo(() => {
    let schema = baseSchema

    if (isEvidenceBasedCareEnabled || isDateOfBirthEnabled) {
      schema = schema.shape({
        dateOfBirth: Yup.date().max(
          moment(),
          'Date of birth must be in the past'
        )
      })

      schema = schema.shape({
        pronoun: Yup.string().required('Pronouns are required')
      })
    }

    return schema
  }, [isEvidenceBasedCareEnabled, isDateOfBirthEnabled])

  const history = useHistory()

  const { mutateAsync: enrollClient, isLoading: isEnrolling } = useMutation(
    endpoints.postSessionEnrollClient.request,
    {
      onError: () =>
        toast({
          title: 'Error creating client',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
    }
  )

  const { mutateAsync: createSessionDraft } = useSessionControllerPostSession()

  const handleSubmit = async values => {
    const { patientId } = await enrollClient({
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: values.dateOfBirth
          ? moment(values.dateOfBirth).format('YYYY-MM-DD')
          : null,
        pronoun: CLIENT_PRONOUNS[values.pronoun]
      }
    })

    const sessionDraft = await createSessionDraft({
      data: {
        patientId: patientId,
        isExtension,
        isChime: false,
        extensionInitiatedFrom: isExtension ? document.referrer : null,
        resumeInProgress: true,
        isAssistEnabled: isPlusPlanEnabled
      }
    })

    if (isWidget)
      return history.push(
        `/widget/patient/${patientId}/session/${sessionDraft.id}`
      )

    if (isExtension)
      return history.push(
        `/extension/patient/${patientId}/session/${sessionDraft.id}`
      )

    if (isDictation)
      return history.push(
        `/patient/${patientId}/dictate/session/${sessionDraft.id}`
      )

    history.push(`/patient/${patientId}/session/${sessionDraft.id}`)
  }

  if (!user.organization.canEnrollClients) {
    return <Redirect to="/start-session/select-client" />
  }

  const isIntegratedUI = isExtension || isWidget

  return (
    <LayoutGrid>
      <GridItem
        colSpan={{ base: 12, md: 6 }}
        colStart={{ base: 1, md: 4 }}
        pl={{ base: isIntegratedUI ? 'xsmall' : '24px', md: 0 }}
        pr={{ base: isIntegratedUI ? 'xsmall' : '24px', md: 0 }}
        id="bp-extension-loaded"
      >
        {!isIntegratedUI && (
          <Text
            as="h1"
            fontSize={isSessionRecordingV2Enabled ? '24px' : 'lg'}
            color={isSessionRecordingV2Enabled ? '#282828' : 'white'}
            fontWeight={isSessionRecordingV2Enabled ? 'normal' : 'bold'}
            textAlign="center"
            mb={6}
          >
            {`Add a ${settings?.patientReference?.toLowerCase() ||
              'client'} to ${
              isSessionRecordingV2Enabled ? 'start' : 'begin'
            } a session`}
          </Text>
        )}
        <Box
          bg="white"
          borderRadius="8px"
          p={'16px'}
          w="100%"
          id="pendo-session-enroll-client"
        >
          <VStack spacing={4} w="100%">
            {!isIntegratedUI && (
              <Circle
                size="64px"
                bg={isSessionRecordingV2Enabled ? '#F6F7FE' : 'primary'}
                color="white"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  maxH="32px"
                  w="32px"
                >
                  <PersonAddIcon
                    fill={isSessionRecordingV2Enabled ? 'primary' : 'white'}
                    size="xl"
                    mt="2"
                  />
                </Box>
              </Circle>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isValid, dirty }) => (
                <Form style={{ width: '100%' }}>
                  <VStack spacing={4}>
                    {isIntegratedUI ? (
                      <>
                        <VStack
                          spacing="16px"
                          w="100%"
                          pb={
                            (errors.firstName && touched.firstName) ||
                            (errors.lastName && touched.lastName) ||
                            (errors.dateOfBirth && touched.dateOfBirth) ||
                            (errors.pronoun && touched.pronoun)
                              ? 'small'
                              : '0'
                          }
                        >
                          <Field name="firstName">
                            {({ field }) => (
                              <TextField
                                {...field}
                                autoFocus={signup !== 'true'}
                                label="First Name"
                                isInvalid={
                                  errors.firstName && touched.firstName
                                }
                                errorText={errors.firstName}
                                isRequired
                              />
                            )}
                          </Field>
                          <Field name="lastName">
                            {({ field }) => (
                              <TextField
                                {...field}
                                label="Last Name"
                                isInvalid={errors.lastName && touched.lastName}
                                errorText={errors.lastName}
                                isRequired
                              />
                            )}
                          </Field>
                          {(isEvidenceBasedCareEnabled ||
                            isDateOfBirthEnabled) && (
                            <Field name="dateOfBirth">
                              {({ field }) => (
                                <BPDateTimePicker
                                  {...field}
                                  showDate
                                  isRequired
                                  label="Date of Birth"
                                  isInvalid={
                                    errors.dateOfBirth && touched.dateOfBirth
                                  }
                                />
                              )}
                            </Field>
                          )}
                          <Field name="pronoun">
                            {({ field, form }) => (
                              <Select
                                label="Pronouns"
                                isRequired={isDateOfBirthEnabled}
                                options={pronounTypes}
                                value={field.value}
                                onChange={value =>
                                  form.setFieldValue('pronoun', value)
                                }
                              />
                            )}
                          </Field>
                        </VStack>
                      </>
                    ) : (
                      <>
                        <HStack
                          spacing="16px"
                          w="100%"
                          pb={
                            (errors.firstName && touched.firstName) ||
                            (errors.lastName && touched.lastName)
                              ? 'small'
                              : '0'
                          }
                        >
                          <Field name="firstName">
                            {({ field }) => (
                              <TextField
                                {...field}
                                autoFocus={signup !== 'true'}
                                label="First Name"
                                isInvalid={
                                  errors.firstName && touched.firstName
                                }
                                errorText={errors.firstName}
                                isRequired
                              />
                            )}
                          </Field>
                          <Field name="lastName">
                            {({ field }) => (
                              <TextField
                                {...field}
                                label="Last Name"
                                isInvalid={errors.lastName && touched.lastName}
                                errorText={errors.lastName}
                                isRequired
                              />
                            )}
                          </Field>
                        </HStack>
                        <HStack
                          direction={{ base: 'column', md: 'row' }}
                          pb={
                            errors.dateOfBirth && touched.dateOfBirth
                              ? 'small'
                              : '0'
                          }
                          spacing="16px"
                          width="100%"
                        >
                          {(isEvidenceBasedCareEnabled ||
                            isDateOfBirthEnabled) && (
                            <Field name="dateOfBirth">
                              {({ field }) => (
                                <BPDateTimePicker
                                  {...field}
                                  showDate
                                  isRequired
                                  label="Date of Birth"
                                  isInvalid={
                                    errors.dateOfBirth && touched.dateOfBirth
                                  }
                                  errorText={
                                    (errors.dateOfBirth &&
                                      errors.dateOfBirth.includes('Invalid') &&
                                      'Invalid date') ||
                                    errors.dateOfBirth
                                  }
                                />
                              )}
                            </Field>
                          )}
                          <Field name="pronoun">
                            {({ field, form }) => (
                              <Select
                                label="Pronouns"
                                isRequired={isDateOfBirthEnabled}
                                options={pronounTypes}
                                value={field.value}
                                onChange={value =>
                                  form.setFieldValue('pronoun', value)
                                }
                              />
                            )}
                          </Field>
                        </HStack>
                      </>
                    )}
                    <Button
                      type="submit"
                      isFullWidth
                      isLoading={isEnrolling}
                      isDisabled={!isValid || !dirty}
                      id="pendo-new-client-start-session"
                    >
                      {isIntegratedUI
                        ? 'Add client & begin session'
                        : isSessionRecordingV2Enabled
                        ? 'Start Session'
                        : 'Begin Session'}
                    </Button>
                    {isEvidenceBasedCareEnabled && !isExtension && !isWidget && (
                      <Link as={RLink} to="/new-client" color="primary">
                        or go to full enrollment form →
                      </Link>
                    )}
                  </VStack>
                </Form>
              )}
            </Formik>
          </VStack>
        </Box>
        {signup === 'true' && !isExtension && !isWidget && (
          <Flex py={6} justifyContent="center">
            <Link
              as={RLink}
              to="/dashboard"
              color={isSessionRecordingV2Enabled ? 'primary' : 'white'}
            >
              I'll start a session later
            </Link>
          </Flex>
        )}
      </GridItem>
    </LayoutGrid>
  )
}

export default EnrollClient
