import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { SessionGetStarted } from './session-get-started'
import SessionList from './session-list'
import SessionDashboardV2 from './session-dashboard-v2'
import { useComponentRenderTracker } from '@hooks/utilities/use-component-render-tracker'
import { useExperienceManager } from '@hooks'

const SessionDashboard = () => {
  useComponentRenderTracker('Viewed Session Dashboard')
  const {
    isDashboardV2Enabled,
    isDocumentationAutomationEnabled
  } = useExperienceManager()

  return (
    <Switch>
      <Route
        path="/sessions"
        component={
          isDashboardV2Enabled && isDocumentationAutomationEnabled
            ? SessionDashboardV2
            : SessionList
        }
        exact
      />
      <Route path="/sessions/get-started" component={SessionGetStarted} exact />
    </Switch>
  )
}

export default SessionDashboard
