import React, { useState } from 'react'
import { Box, VStack, Flex, Text, Image, CloseButton } from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import WelcomeImage from '../../../images/brand/welcome-banner.png'
import { StoreModel } from 'src/store/types'

const WelcomeBanner = () => {
  const user = useStoreState((state: StoreModel) => state.auth.user)

  const localStorageKey = 'welcomeBannerDismissed'

  const [isVisible, setIsVisible] = useState(() => {
    const storedValue = localStorage.getItem(localStorageKey)
    return storedValue === null
  })

  const handleClose = () => {
    localStorage.setItem(localStorageKey, 'true')
    setIsVisible(false)
  }

  if (!isVisible || !user) return null

  return (
    <Box position="relative">
      <CloseButton
        position="absolute"
        top="10px"
        right="10px"
        onClick={handleClose}
      />
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        bg="#F6F7FE"
        borderRadius="8px"
      >
        <Box w={{ base: 'auto', sm: '60%' }} p={{ base: 6, md: 8 }}>
          <VStack spacing={4} align="stretch">
            <Text color="#2D54E8" fontSize={{ base: '16px', sm: '24px' }}>
              Hi {user.first_name}! Welcome to Blueprint!
            </Text>
            <Text>
              Blueprint is here to assist you in getting hours of{' '}
              <Text as="span" fontWeight="bold">
                time
              </Text>{' '}
              and{' '}
              <Text as="span" fontWeight="bold">
                focus
              </Text>{' '}
              back each week — so you can deliver exceptional care every session{' '}
              <Text as="span" fontStyle="italic">
                without
              </Text>{' '}
              spending nights & weekends on documentation
            </Text>
          </VStack>
        </Box>
        <Flex flex={1}>
          <Image
            src={WelcomeImage}
            alt="CTA background"
            objectFit="cover"
            width="100%"
            h={{ base: '180px', sm: '221px' }}
            borderBottomRadius={{ base: '8px', sm: '0px' }}
            borderRightRadius={{ base: '0px', sm: '8px' }}
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default WelcomeBanner
