import React, { useEffect } from 'react'
import {
  Button,
  Stack,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'
import { Pagination } from '@handlers/sessions/components/shared/pagination'
import { AssignAssessmentsModal } from '../assign-assessments-modal'
import {
  getSessionControllerGetSuggestedContentQueryKey,
} from '~/clinician-api'
import { buildAssessmentPreviewUrl } from '@utilities'
import {
  AssignableAssessment,
} from '@handlers/sessions/types'

interface AssessmentSuggestionModuleProps {
  sessionId: string
  client: any
  showDemoExperience: boolean
  suggestions: any
  demoData: any
}

export const AssessmentSuggestionModule = ({
  sessionId,
  client,
  // toggleOverlay,
  showDemoExperience,
  suggestions,
  demoData
}: AssessmentSuggestionModuleProps) => {
  const [assessmentIdx, setAssessmentIdx] = React.useState<number>(0)
  const [
    selectedAssessment,
    setSelectedAssessment
  ] = React.useState<AssignableAssessment | null>(null)

  const queryClient = useQueryClient()

  const {
    isOpen: isAssignAssessmentsModalOpen,
    onOpen: openAssignAssessmentsModal,
    onClose: closeAssignAssessmentsModal
  } = useDisclosure()

  useEffect(() => {
    if (!isAssignAssessmentsModalOpen) {
      setSelectedAssessment(null)
    }
  }, [isAssignAssessmentsModalOpen])

  const assessmentSuggestion = showDemoExperience
    ? demoData?.suggestedAssessments[assessmentIdx]
    : suggestions?.suggestedAssessments[assessmentIdx]

  const assessmentCount = showDemoExperience
    ? demoData?.suggestedAssessments?.length
    : suggestions?.suggestedAssessments.length || 0

  const handleBrowseLibraryClick = () => {
    openAssignAssessmentsModal()
  }

  const onAssessmentAssigned = () => {
    const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
      sessionId
    )
    queryClient.invalidateQueries({ queryKey: suggestionListKey })
  }

  const onAssessmentAssignClick = () => {
    if (assessmentSuggestion) {
      setSelectedAssessment({
        clinicAssessmentId: assessmentSuggestion.clinicAssessmentId,
        cadenceValue: assessmentSuggestion.cadenceValue,
        cadenceUnit: assessmentSuggestion.cadenceUnit,
        contentSuggestionId: assessmentSuggestion.contentSuggestionId
      })
      openAssignAssessmentsModal()
    }
  }

  return (
    <Stack spacing={2}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap="12px" alignItems="center">
          <Text fontWeight="bold">Assessments</Text>
          <Button
            variant="link"
            textDecoration="none"
            onClick={handleBrowseLibraryClick}
            fontSize="sm"
            m="0"
            _focus={{
              outline: 'none'
            }}
          >
            Browse all
          </Button>
        </Flex>
        <Flex justifyContent="center">
          <Pagination
            current={assessmentCount === 0 ? 0 : assessmentIdx + 1}
            total={assessmentCount || 0}
            onNext={() => setAssessmentIdx(prev => prev + 1)}
            onPrev={() => setAssessmentIdx(prev => prev - 1)}
            isDisabled={assessmentCount === 0}
          />
        </Flex>
      </Flex>
      {assessmentCount > 0 ? (
        <ContentSuggestionCard
          isDemo={showDemoExperience}
          type="assessment"
          suggestion={{
            ...assessmentSuggestion,
            name: assessmentSuggestion.assessmentName
          }}
          sessionId={sessionId}
          truncateRationale
          onAssign={onAssessmentAssignClick}
          previewUrl={buildAssessmentPreviewUrl(
            assessmentSuggestion.assessmentId
          )}
        />) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          border="1px solid #E4E5E6"
          w="100%"
          h="64px"
        >
          <Text fontSize="12px" color="dark_gray">No suggestions at this time.</Text>
        </Flex>
      )}
      {isAssignAssessmentsModalOpen && (
        <AssignAssessmentsModal
          onSuccess={onAssessmentAssigned}
          client={client}
          isOpen={isAssignAssessmentsModalOpen}
          onClose={closeAssignAssessmentsModal}
          assessment={selectedAssessment}
          selectAssessment={setSelectedAssessment}
        />
      )}
    </Stack>
  )
}