import React from 'react'
import { Container, Stack, Box, Text, Button } from '@chakra-ui/react'
import { useAssistPanelContext } from '../context'
import { Suggestions } from './suggestions'
import { RecentWorksheets } from './recent-worksheets'
import { AssignedWorksheets } from './assigned'
import { useTreatmentPlan, useExperienceManager } from '@hooks'

export const Worksheets = () => {
  const { client } = useTreatmentPlan()
  const { setOverlayPanel } = useAssistPanelContext()
  const { isSessionRecordingV2Enabled } = useExperienceManager()
  const handleAssignWorksheet = (worksheet: any) => {
    if (!!client?.enrollment_timestamp) {
      setOverlayPanel('ASSIGN_WORKSHEETS', {
        worksheet: {
          checkInId: worksheet.id
        }
      })
    } else {
      setOverlayPanel('ENROLL_CLIENT', {
        checkIn: {
          checkInId: worksheet.id
        }
      })
    }
  }

  const handleBrowseLibraryClick = () => {
    setOverlayPanel('WORKSHEET_LIBRARY', {
      onAssign: handleAssignWorksheet
    })
  }

  return (
    <Container
      h={isSessionRecordingV2Enabled ? "calc(100vh - 150px)" : "calc(100vh - 208px)"}
      pt="24px"
      overflowY="scroll"
    >
      <Stack
        spacing="0"
        flex="1"
        gap="24px"
      >
        <RecentWorksheets />
        <Suggestions />
        <Box pb="40px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="xsmall"
          >
            <Text fontWeight="bold">Assigned</Text>
            <Button
              variant="link"
              textDecoration="none"
              onClick={handleBrowseLibraryClick}
            >
              Browse library
            </Button>
          </Box>
          <AssignedWorksheets patient={client} />
        </Box>
      </Stack>
    </Container>
  )
}
