import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import { GridItem, Container, Box, useToast } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { BlueForm } from '@blueprinthq/joy-business'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import { useQuery, useQueryClient, useMutation } from 'react-query'

import { endpoints } from '@api'
import { Loading } from '@components'
import { SubmitInterventionModal, Footer } from './components'

import {
  useInterventionsControllerV1GetInterventionActivity,
  usePatientInterventionsControllerGetPatientInterventionById,
  usePatientInterventionsControllerCreatePatientIntervention,
  usePatientInterventionsControllerUpdatePatientIntervention,
  getPatientInterventionsControllerGetAllPatientInterventionsQueryKey,
  getPatientInterventionsControllerGetPatientInterventionByIdQueryKey
} from '~/clinician-api'

export function InterventionForm() {
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false)

  const history = useHistory()

  let formikRef = useRef(null)

  let match = useRouteMatch(
    '/patient/:clientId/interventions/:interventionId/form'
  )

  const location = useLocation()
  const clientInterventionId =
    new URLSearchParams(location.search).get('clientInterventionId') || null
  const isReadOnlyRaw = new URLSearchParams(location.search).get('isReadOnly')
  const isReadOnly = isReadOnlyRaw === 'true'
  const isPlus = new URLSearchParams(location.search).get('isPlus')
  const showPlusVersion = isPlus === 'true'
  const name = new URLSearchParams(location.search).get('name')

  const handleCloseSubmitModal = () => {
    setIsSubmitModalOpen(false)
  }

  const {
    data: interventionItems,
    isLoading: isInterventionItemsLoading
  } = useInterventionsControllerV1GetInterventionActivity(
    match.params.interventionId
  )

  const { data: legacyClientIntervention, isLoading } = useQuery(
    [
      endpoints.getClientIntervention.getCacheId(),
      {
        clientId: match.params.clientId,
        interventionId: match.params.interventionId,
        clientInterventionId
      }
    ],
    () =>
      endpoints.getClientIntervention.request({
        clientId: match.params.clientId,
        interventionId: match.params.interventionId,
        clientInterventionId
      }),
    {
      enabled: !showPlusVersion && !!clientInterventionId
    }
  )

  const {
    data: plusClientIntervention
  } = usePatientInterventionsControllerGetPatientInterventionById(
    match.params.clientId,
    clientInterventionId,
    {
      query: {
        enabled: showPlusVersion && !!clientInterventionId
      }
    }
  )

  const clientIntervention =
    plusClientIntervention || legacyClientIntervention || null

  const turnOffUnloadAlert = () => {
    window.onbeforeunload = () => null
  }

  const turnOnUnloadAlert = () => {
    window.onbeforeunload = e => {
      e.preventDefault()
      return e.returnValue
    }
  }

  useEffect(() => {
    if ((clientIntervention && clientIntervention.isComplete) || isReadOnly)
      turnOffUnloadAlert()
    else turnOnUnloadAlert()
  }, [clientIntervention, isReadOnly])

  const toast = useToast()
  const queryClient = useQueryClient()
  const { mutateAsync: executeSaveInterventionProgress } = useMutation(
    endpoints[
      clientInterventionId ? 'putClientIntervention' : 'postClientIntervention'
    ].request,
    {
      onError: () => {
        toast({
          title: 'Error',
          description: 'There was an error saving this activity',
          status: 'error',
          isClosable: true,
          duration: 2000,
          position: 'bottom-left'
        })
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([
            endpoints.getInterventionOverview.getCacheId(),
            {
              clientId: match.params.clientId,
              interventionId: match.params.interventionId
            }
          ]),
          queryClient.invalidateQueries([
            endpoints.getClientIntervention.getCacheId(),
            {
              clientId: match.params.clientId,
              interventionId: match.params.interventionId,
              clientInterventionId
            }
          ])
        ])

        turnOnUnloadAlert()

        // replace so you cant navigate back
        history.replace({
          pathname: `/patient/${match.params.clientId}/interventions/${match.params.clientId}/complete`,
          search: `?type=draft`
        })
      }
    }
  )

  const {
    mutateAsync: createClientIntervention
  } = usePatientInterventionsControllerCreatePatientIntervention()
  const {
    mutateAsync: updateClientIntervention
  } = usePatientInterventionsControllerUpdatePatientIntervention()

  const initialValues = useMemo(() => {
    if (!interventionItems || !clientIntervention) {
      return {}
    }

    const interventionQuestionItems = interventionItems?.filter(
      ii => ii.type === 'question'
    )

    return interventionQuestionItems.reduce((acc, ii) => {
      acc[ii.id] = clientIntervention?.responses[ii.id] || null
      return acc
    }, {})
  }, [interventionItems, clientIntervention])

  const handleSaveDraft = useCallback(
    async isComplete => {
      if (!formikRef.current.values) return

      const formattedResponses = Object.entries(formikRef.current.values).map(
        ([interventionItemId, value]) => ({
          interventionItemId,
          response: { value }
        })
      )

      const body = {
        clientId: match.params.clientId,
        interventionId: match.params.interventionId,
        data: {
          isComplete: false,
          responses: formattedResponses
        }
      }

      if (clientInterventionId) {
        body.clientInterventionId = clientInterventionId
      }

      if (showPlusVersion) {
        const plusMutationOptions = {
          onError: () => {
            toast({
              title: 'Error',
              description: 'There was an error saving this activity',
              status: 'error',
              isClosable: true,
              duration: 2000
            })
          },
          onSuccess: async () => {
            queryClient.invalidateQueries(
              getPatientInterventionsControllerGetAllPatientInterventionsQueryKey(
                match.params.clientId
              )
            )
            queryClient.invalidateQueries(
              getPatientInterventionsControllerGetPatientInterventionByIdQueryKey(
                match.params.clientId,
                clientInterventionId
              )
            )
            turnOnUnloadAlert()
            toast({
              title: 'Success',
              description: isComplete
                ? 'Intervention completed'
                : 'Intervention saved as draft',
              status: 'success',
              isClosable: true,
              duration: 2000
            })

            history.replace({
              pathname: `/patient/${match.params.clientId}/interventions/${match.params.clientId}/complete`,
              search: `?type=draft`
            })
          }
        }

        const plusBody = {
          isComplete,
          responses: formattedResponses
        }

        if (clientInterventionId) {
          await updateClientIntervention(
            {
              patientId: match.params.clientId,
              patientInterventionId: clientInterventionId,
              data: plusBody
            },
            plusMutationOptions
          )
        } else {
          await createClientIntervention(
            {
              patientId: match.params.clientId,
              data: { ...plusBody, interventionId: match.params.interventionId }
            },
            plusMutationOptions
          )
        }
      } else {
        await executeSaveInterventionProgress(body)
      }
    },
    [formikRef.current, clientInterventionId]
  )

  const renderContent = useCallback(() => {
    if (isLoading || isInterventionItemsLoading) {
      return <Loading />
    }

    return (
      <LayoutGrid w="100%" h="100%">
        <GridItem
          colStart={{
            base: 1,
            sm: 1,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 9,
            md: 10
          }}
        >
          <BlueForm
            title={clientIntervention?.name || name}
            form={interventionItems || []}
            isDisabled={clientIntervention?.isComplete || isReadOnly}
            initialValues={initialValues}
            innerRef={formikRef}
            enableReinitialize
          />
        </GridItem>
      </LayoutGrid>
    )
  }, [
    isLoading,
    isInterventionItemsLoading,
    name,
    clientIntervention,
    interventionItems,
    initialValues,
    isReadOnly
  ])

  return (
    <Container>
      <Box
        mt={{
          sm: '16px',
          md: '32px'
        }}
        mb={'144px'} // footer height (80px) + 64px requested by troyer
      >
        {renderContent()}
      </Box>
      <Footer
        hidden={
          (clientIntervention && clientIntervention.isComplete) || isReadOnly
        }
        handleSaveDraft={handleSaveDraft}
        handleSubmit={() => setIsSubmitModalOpen(true)}
        showPlusVersion={showPlusVersion}
      />
      <SubmitInterventionModal
        isOpen={isSubmitModalOpen}
        handleClose={handleCloseSubmitModal}
        homeworkModules={clientIntervention && clientIntervention.homework}
        clientResponses={formikRef.current && formikRef.current.values}
        clientInterventionId={clientInterventionId}
        turnOffUnloadAlert={turnOffUnloadAlert}
      />
    </Container>
  )
}
