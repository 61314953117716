import React from 'react'
import { Container, Stack, Text, HStack, Button } from '@chakra-ui/react'
import AssignedAssessments from './assigned/selector'
import Suggestions from './suggestions'
import Progress from './progress'
import { useTreatmentPlan } from '@hooks'
import { useAssistPanelContext } from '../context'
import { useExperienceManager } from '@hooks'
import { useQuery } from 'react-query'
import { endpoints } from '@api'

const Assessments = () => {
  const { client } = useTreatmentPlan()
  const { setOverlayPanel } = useAssistPanelContext()
  const { isSessionRecordingV2Enabled } = useExperienceManager()
  const patientId = client?.id

  const { isLoading, data: assessmentScores } = useQuery(
    [endpoints.getPatientAssessmentScores.getCacheId(), patientId],
    () =>
      endpoints.getPatientAssessmentScores.request({
        patientId,
        includeFreeTextQuestions: true
      })
  )

  const handleViewResults = (data = {}) => {
    setOverlayPanel('ASSESSMENT_RESULTS', data)
  }

  const handleAssignAssessment = (assessment: any) => {
    if (!!client?.enrollment_timestamp) {
      setOverlayPanel('ASSIGN_ASSESSMENTS', {
        assessment: {
          clinicAssessmentId: assessment.clinicAssessmentId,
          cadenceValue: assessment.cadenceValue,
          cadenceUnit: assessment.cadenceUnit
        }
      })
    } else {
      setOverlayPanel('ENROLL_CLIENT', {
        assessment: {
          clinicAssessmentId: assessment.clinicAssessmentId,
          cadenceValue: assessment.cadenceValue,
          cadenceUnit: assessment.cadenceUnit
        }
      })
    }
  }

  return (
    <Container
      h={isSessionRecordingV2Enabled ? "calc(100vh - 150px)" : "calc(100vh - 208px)"}
      pt="24px"
      overflowY="scroll"
    >
    <Stack
      spacing="0"
      flex="1"
      gap="24px"
    >
      <Progress
        isLoading={isLoading}
        allAssessmentScores={assessmentScores}
        patient={client}
        onViewResults={handleViewResults}
      />
      <Suggestions />
      <Stack spacing="0">
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">Assigned</Text>
          <Button
            variant="link"
            textDecoration="none"
            onClick={() =>
              setOverlayPanel('LIBRARY', {
                onAssign: handleAssignAssessment
              })
            }
          >
            Browse library
          </Button>
        </HStack>
        <AssignedAssessments patient={client} user_id={client?.id} />
      </Stack>
      </Stack>
    </Container>
  )
}

export default Assessments
