import moment from 'moment'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowRightIcon, ArrowUpIcon, ArrowDownIcon } from '@blueprinthq/joy'
import { Pagination } from '@material-ui/lab'
import {
  Box,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Text
} from '@chakra-ui/react'

import { useClinicControllerGetClientList } from '~/clinician-api'
import { useExperienceManager } from '@hooks'
import {
  ClientListDto,
  ClinicControllerGetClientListSortBy
} from '~/clinician-api/models'

type ClientTableProps = {
  clinicId: string
  clinicianId: string | null
  status: string[]
}

interface CellProps {
  children: React.ReactNode
  width?: string
}

const Cell = ({ children, width }: CellProps) => (
  <Td verticalAlign="middle" borderColor="#E4E5E6" width={width}>
    {children}
  </Td>
)

type SortDirection = 'asc' | 'desc' | null
type SortField =
  | 'firstName'
  | 'lastName'
  | 'dateOfBirth'
  | 'email'
  | 'phoneNumber'
  | 'lastSessionDate'
  | null

const HeaderCell = ({
  children,
  sortable = false,
  sortDirection = null,
  onSort = () => {},
  style = {}
}: {
  children?: React.ReactNode
  sortable?: boolean
  sortDirection?: SortDirection
  onSort?: () => void
  style?: React.CSSProperties
}) => (
  <Th
    verticalAlign="middle"
    borderColor="#E4E5E6"
    cursor={sortable ? 'pointer' : 'default'}
    onClick={sortable ? onSort : undefined}
    style={{
      ...style,
      position: 'relative'
    }}
  >
    <Box display="inline-flex" alignItems="center">
      {children}
      {sortable && (
        <>
          {sortDirection === 'asc' && <ArrowUpIcon boxSize={3} />}
          {sortDirection === 'desc' && <ArrowDownIcon boxSize={3} />}
        </>
      )}
    </Box>
  </Th>
)

export const ClientTable = ({
  clinicId,
  clinicianId,
  status
}: ClientTableProps) => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [sortField, setSortField] = React.useState<SortField>('firstName')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc')

  useEffect(() => {
    setCurrentPage(1)
  }, [clinicId, clinicianId])

  const {
    isEvidenceBasedCareEnabled,
    isPlusPlanEnabled
  } = useExperienceManager()

  const history = useHistory()

  const {
    data: clientList = {
      clients: [],
      pageCount: 1,
      currentPage: 1
    } as ClientListDto,
    isLoading
  } = useClinicControllerGetClientList(
    clinicId,
    {
      clinicianId: clinicianId!,
      page: currentPage,
      pageSize: 50,
      statuses: status,
      sortBy: sortField as ClinicControllerGetClientListSortBy,
      sortDirection: sortDirection || undefined
    },
    {
      query: { placeholderData: { clients: [], pageCount: 1, currentPage: 1 } }
    }
  )

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }

  if (isLoading || clientList === undefined) return <div>Loading</div>

  const navToClient = (clientId: string) => {
    history.push(`/patient/${clientId}/settings/sessions`)
  }

  return (
    <Box maxW="100%">
      <TableContainer>
        <Table
          border="1px solid"
          borderBottom="none"
          borderColor="#E4E5E6"
          borderRadius="4px"
          style={{
            borderCollapse: 'separate',
            width: '100%'
          }}
          sx={{
            tableLayout: { xs: 'auto', md: 'fixed' }
          }}
          marginBottom="medium"
          size="sm"
        >
          <Thead>
            <Tr height="32px">
              <HeaderCell
                sortable
                sortDirection={sortField === 'firstName' ? sortDirection : null}
                onSort={() => handleSort('firstName')}
                style={{ minWidth: '120px' }}
              >
                First Name
              </HeaderCell>
              <HeaderCell
                sortable
                sortDirection={sortField === 'lastName' ? sortDirection : null}
                onSort={() => handleSort('lastName')}
                style={{ minWidth: '120px' }}
              >
                Last Name
              </HeaderCell>
              {isEvidenceBasedCareEnabled && (
                <HeaderCell
                  sortable
                  sortDirection={
                    sortField === 'dateOfBirth' ? sortDirection : null
                  }
                  onSort={() => handleSort('dateOfBirth')}
                  style={{ minWidth: '100px' }}
                >
                  DOB
                </HeaderCell>
              )}
              {isPlusPlanEnabled && (
                <HeaderCell
                  sortable
                  sortDirection={sortField === 'email' ? sortDirection : null}
                  onSort={() => handleSort('email')}
                  style={{ minWidth: '180px' }}
                >
                  Email
                </HeaderCell>
              )}
              {isPlusPlanEnabled && (
                <HeaderCell
                  sortable
                  sortDirection={
                    sortField === 'phoneNumber' ? sortDirection : null
                  }
                  onSort={() => handleSort('phoneNumber')}
                  style={{ minWidth: '120px' }}
                >
                  Phone
                </HeaderCell>
              )}
              <HeaderCell
                sortable
                sortDirection={
                  sortField === 'lastSessionDate' ? sortDirection : null
                }
                onSort={() => handleSort('lastSessionDate')}
                style={{ minWidth: '140px' }}
              >
                Last Session Date
              </HeaderCell>
              <HeaderCell style={{ width: '40px' }}></HeaderCell>
            </Tr>
          </Thead>
          <Tbody>
            {clientList.clients?.map((client, i) => (
              <Tr
                cursor="pointer"
                key={i}
                onClick={() => {
                  navToClient(client.id)
                }}
                height="38px"
              >
                <Cell>{client.firstName}</Cell>
                <Cell>{client.lastName}</Cell>
                {isEvidenceBasedCareEnabled && (
                  <Cell>{moment(client.dateOfBirth).format('MM/DD/YYYY')}</Cell>
                )}
                {isPlusPlanEnabled && <Cell>{client.email}</Cell>}
                {isPlusPlanEnabled && <Cell>{client.phoneNumber}</Cell>}
                <Cell>
                  {client.lastSessionDate ? (
                    <Tooltip
                      label={moment(client.lastSessionDate).format(
                        'MM/DD/YYYY h:mm A'
                      )}
                      placement="top"
                    >
                      <Text as="span">
                        {moment(client.lastSessionDate).format('MM/DD/YYYY')}
                      </Text>
                    </Tooltip>
                  ) : null}
                </Cell>
                <Cell width="40px">
                  <ArrowRightIcon size="sm" />
                </Cell>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Box display="flex" flexDir="column" alignItems="center">
        <Pagination
          page={currentPage}
          count={clientList.pageCount}
          onChange={(evt, newPage) => {
            setCurrentPage(newPage)
          }}
          color="primary"
          variant="outlined"
          size="large"
          showFirstButton
          showLastButton
        />
      </Box>
    </Box>
  )
}
