import React from 'react'

const CoolKidsLivingRoom = () => (
  <svg
    width="324"
    height="244"
    viewBox="0 0 324 244"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="324" height="244" fill="white" />
    <path
      d="M229 29H79L77 185L80.5 187.5H229V29Z"
      fill="#C0CCF8"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <g opacity="0.6">
      <path
        d="M252.389 202.41C214.515 201.687 175.559 200.905 137.642 200.703C106.085 200.385 52.7072 201.745 113.268 204.956C137.729 206.244 171.449 207.126 194.597 207.995C200.294 208.284 230.005 209.137 221.625 210.555C211.659 211.655 198.823 211.901 184.141 212.436C151.791 213.42 115.215 214.389 82.7785 215.633C-0.00758743 218.701 53.6159 222.043 103.259 224.835"
        fill="#F6F7FE"
      />
      <path
        d="M252.389 202.41C214.515 201.687 175.559 200.905 137.642 200.703C106.085 200.385 52.7072 201.745 113.268 204.956C137.729 206.244 171.449 207.126 194.597 207.995C200.294 208.284 230.005 209.137 221.625 210.555C211.659 211.655 198.823 211.901 184.141 212.436C151.791 213.42 115.215 214.389 82.7785 215.633C-0.00758872 218.701 53.6159 222.043 103.259 224.835"
        stroke="#C0CCF8"
        stroke-miterlimit="10"
      />
    </g>
    <path
      d="M234.361 26.7305H76.8364V184.745H234.361V26.7305Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M187.069 74.1696H76.8364V184.745H187.069V74.1696Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M129.869 131.548H76.8364V184.745H129.869V131.548Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M83.8603 131.548L76.822 138.608"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="6.9 6.9"
    />
    <path
      d="M91.5042 131.548L87.4226 135.642"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M87.019 136.047L81.5239 141.574"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="1.2 3.2 0.4 1.2"
    />
    <path
      d="M80.9036 142.182L76.822 146.276"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M99.1626 131.548L95.0955 135.642"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M94.2013 136.525L82.2305 148.547"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="2.61 6.96 0.87 2.61"
    />
    <path
      d="M80.9036 149.878L76.822 153.973"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M106.821 131.548L102.74 135.642"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M102.206 136.192L94.8359 143.585"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="1.61 4.28 0.54 1.61"
    />
    <path
      d="M94.0139 144.41L81.7258 156.721"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="4.28 0.54 1.61 4.28 0.54 1.61"
    />
    <path
      d="M80.9036 157.546L76.822 161.64"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M114.48 131.548L110.398 135.642"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M109.662 136.38L99.7107 146.363"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="2.17 5.78 0.72 2.17"
    />
    <path
      d="M98.6002 147.477L82.0142 164.114"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="5.78 0.72 2.17 5.78 0.72 2.17"
    />
    <path
      d="M80.9036 165.214L76.822 169.308"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M122.138 131.548L118.056 135.642"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M117.133 136.568L104.6 149.141"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="2.73 7.29 0.91 2.73"
    />
    <path
      d="M103.201 150.544L82.3027 171.507"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="7.29 0.91 2.73 7.29 0.91 2.73"
    />
    <path
      d="M80.9036 172.896L76.822 176.991"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M129.782 131.548L125.7 135.642"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M124.59 136.771L109.46 151.933"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="3.29 8.79 1.1 3.29"
    />
    <path
      d="M107.787 153.611L82.5911 178.9"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="8.79 1.1 3.29 8.79 1.1 3.29"
    />
    <path
      d="M80.9036 180.578L76.822 184.673"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M129.854 139.158L125.772 143.252"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M124.849 144.178L112.258 156.808"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="2.74 7.31 0.91 2.74"
    />
    <path
      d="M110.859 158.212L89.8745 179.247"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="7.31 0.91 2.74 7.31 0.91 2.74"
    />
    <path
      d="M88.4754 180.651L84.3938 184.745"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M129.854 146.84L125.772 150.934"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M125.037 151.672L115.028 161.713"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="2.18 5.81 0.73 2.18"
    />
    <path
      d="M113.917 162.827L97.2444 179.537"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="5.81 0.73 2.18 5.81 0.73 2.18"
    />
    <path
      d="M96.1339 180.651L92.0522 184.745"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M129.854 154.508L125.772 158.602"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M125.224 159.152L117.811 166.588"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="1.62 4.31 0.54 1.62"
    />
    <path
      d="M116.989 167.427L104.615 179.826"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="4.31 0.54 1.62 4.31 0.54 1.62"
    />
    <path
      d="M103.792 180.651L99.7107 184.745"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M129.854 162.19L125.772 166.285"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M124.878 167.182L112.792 179.305"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="2.63 7.02 0.88 2.63"
    />
    <path
      d="M111.451 180.651L107.369 184.745"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M129.854 169.858L125.787 173.952"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M125.369 174.372L119.729 180.029"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="1.23 3.27 0.41 1.23"
    />
    <path
      d="M119.109 180.651L115.028 184.745"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M129.854 177.54L122.672 184.745"
      stroke="#E0E5FC"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-dasharray="7.04 7.04"
    />
    <path
      d="M96.3793 165.46H76.8943V185.006H96.3793V165.46Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M187.054 103.032C202.945 103.032 215.827 90.1101 215.827 74.1697C215.827 58.2292 202.945 45.3069 187.054 45.3069C171.163 45.3069 158.281 58.2292 158.281 74.1697C158.281 90.1101 171.163 103.032 187.054 103.032Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M199.642 67.6303L182.975 84.3495L174.368 75.734L177.905 72.2002L182.971 77.2711L196.101 64.1003L199.642 67.6303Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M234.361 38.8254C241.02 38.8254 246.418 33.4103 246.418 26.7305C246.418 20.0507 241.02 14.6357 234.361 14.6357C227.701 14.6357 222.303 20.0507 222.303 26.7305C222.303 33.4103 227.701 38.8254 234.361 38.8254Z"
      fill="#C0CCF8"
      stroke="white"
      stroke-miterlimit="10"
    />
    <path
      d="M229.067 32.0256L236.293 28.6547L239.654 21.4065L232.428 24.7774L229.067 32.0256ZM234.36 25.6599C234.952 25.6599 235.413 26.1374 235.413 26.7161C235.413 27.2948 234.937 27.7722 234.36 27.7722C233.784 27.7722 233.308 27.2948 233.308 26.7161C233.308 26.1374 233.784 25.6599 234.36 25.6599Z"
      fill="white"
    />
    <path
      d="M97.1001 173.938C101.768 173.938 105.552 170.142 105.552 165.46C105.552 160.778 101.768 156.982 97.1001 156.982C92.4324 156.982 88.6484 160.778 88.6484 165.46C88.6484 170.142 92.4324 173.938 97.1001 173.938Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path d="M93.8982 165.402L95.9029 167.413L100.287 163.029" fill="white" />
    <path
      d="M93.8982 165.402L95.9029 167.413L100.287 163.029"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M126.278 148.952C134.609 148.952 141.364 142.177 141.364 133.819C141.364 125.462 134.609 118.686 126.278 118.686C117.946 118.686 111.191 125.462 111.191 133.819C111.191 142.177 117.946 148.952 126.278 148.952Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path d="M120.552 133.704L124.129 137.292L131.946 129.45" fill="white" />
    <path
      d="M120.552 133.704L124.129 137.292L131.946 129.45"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M245.536 164.574L234.5 161.5L231.933 145.931C229.476 141.969 233.019 139.422 236.345 140.103L239.752 141.41C242.332 143.616 241.694 146.803 243.038 152.005L245.522 164.588L245.536 164.574Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M237.689 164.792C239.06 163.376 239.807 161.442 240.133 159.495C240.458 157.547 240.391 155.559 240.323 153.585C240.309 153.054 240.268 152.482 239.929 152.073C239.495 151.542 238.707 151.487 238.028 151.556C235.327 151.787 232.666 153.217 231.309 155.573C229.951 157.929 230.168 161.197 232.096 163.117C232.748 163.771 233.616 164.247 234.526 164.288"
      fill="#F6F7FE"
    />
    <path
      d="M228.607 222.613C228.716 224.342 228.906 226.058 229.136 227.774C228.498 227.801 227.874 227.828 227.236 227.855C227.182 227.855 227.127 227.855 227.087 227.828C227.046 227.801 227.032 227.747 227.019 227.692C226.924 227.311 226.829 226.916 226.734 226.535C226.367 226.548 226 226.562 225.634 226.589C225.403 226.929 225.159 227.27 224.901 227.583C224.86 227.638 224.806 227.692 224.752 227.719C224.67 227.76 224.575 227.76 224.494 227.76C223.761 227.76 223.014 227.774 222.281 227.787C213.307 227.855 214.896 227.284 217.258 227.052C220.312 226.739 222.837 224.478 225.444 222.831"
      fill="white"
    />
    <path
      d="M228.607 222.613C228.716 224.342 228.906 226.058 229.136 227.774C228.498 227.801 227.874 227.828 227.236 227.855C227.182 227.855 227.127 227.855 227.087 227.828C227.046 227.801 227.032 227.747 227.019 227.692C226.924 227.311 226.829 226.916 226.734 226.535C226.367 226.548 226 226.562 225.634 226.589C225.403 226.929 225.159 227.27 224.901 227.583C224.86 227.638 224.806 227.692 224.752 227.719C224.67 227.76 224.575 227.76 224.494 227.76C223.761 227.76 223.014 227.774 222.281 227.787C213.307 227.855 214.896 227.284 217.258 227.052C220.312 226.739 222.837 224.478 225.444 222.831"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M251.59 227.42C253.396 227.651 254.618 228.223 247.748 228.169C245.047 228.141 242.386 228.332 242.481 227.91C245.006 215.954 244.423 224.492 247.029 224.437C249.635 224.383 250.694 227.311 251.59 227.42Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M228.919 158.964C217.638 159.672 214.855 162.926 215.167 138.074"
      stroke="#C0CCF8"
      stroke-width="4"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M228.919 158.964C217.638 159.672 214.855 162.926 215.167 138.074"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M228.919 160.121C225.702 159.467 220.177 159.903 217.57 157.901C213.905 155.083 215.235 150.122 215.127 146.5"
      stroke="#C0CCF8"
      stroke-width="6"
      stroke-linejoin="round"
    />
    <path
      d="M230.888 181.61L244.898 183.189L244.504 165.187C244.504 161.537 241.599 157.043 238.246 157.043L234.078 157.493C229.001 159.195 228.566 159.971 227.589 162.94L230.874 181.623L230.888 181.61Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M242.454 179.227C243.961 179.404 245.468 179.553 246.975 179.703L246.106 167.883C246.404 163.076 244.259 156.567 240.323 156.771H229.557C223.448 159.331 227.885 161.024 226.5 165L225.5 177.5C232.138 177.677 235.883 178.437 242.454 179.227Z"
      fill="#C0CCF8"
    />
    <path
      d="M238.259 157.03L236.997 152.277L233.223 157.221H228.919V162.368H239.454L238.259 157.03Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M231.892 146.258C230.195 148.246 230.589 155.3 233.779 155.314C236.969 155.328 237.838 153.421 238.15 148.778C238.164 148.492 235.788 149.023 233.277 145.319C231.77 143.099 233.576 144.27 231.879 146.258H231.892Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M244.096 179.485L243.377 169.654C243.594 166.113 241.476 157.67 238.639 157.084C238.3 157.738 237.906 158.364 237.39 158.923C236.372 160.012 234.458 161.006 232.91 160.067C231.729 159.345 231.295 158.01 231.281 156.798L230.344 156.771C225.634 158.746 227.874 164.629 226.801 167.706L226.801 178.5C233.182 178.595 237.716 179.322 244.096 179.485Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M226.669 173.198L225.861 177.067L221 223.238L230.11 222.979L233.174 184.135C228.884 178.194 240.974 224 240.974 224L250 222.903L241.448 173L233.369 173.503L226.669 173.198Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M245.264 180.561L241.192 157.03H236.237L239.018 179.5C240.796 179.541 243.486 180.52 245.264 180.561Z"
      fill="#C0CCF8"
    />
    <path
      d="M247.5 196.5L244 177L239 178.5L244 196.5C245.708 196.004 245.792 196.996 247.5 196.5Z"
      fill="#C0CCF8"
    />
    <path
      d="M248.5 175C247 178 244 181.5 241.053 185.042"
      stroke="#C0CCF8"
      stroke-width="4"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M248.5 175C247 178 244 181.5 241.053 185.042"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M241.11 160.557C245.861 164.56 252 168.5 246 178.5"
      stroke="#C0CCF8"
      stroke-width="5"
      stroke-linejoin="round"
    />
    <path
      d="M213.593 138.619C210.898 137.231 212.195 133.174 215.5 134.5C216.355 129.189 217.262 129.059 217.642 135.5C220.5 132 218.5 139.381 216.5 139"
      fill="white"
    />
    <path
      d="M213.593 138.619C210.898 137.231 212.195 133.174 215.5 134.5C216.355 129.189 217.262 129.059 217.642 135.5C220.5 132 218.5 139.381 216.5 139"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M241.7 181.485C240.626 182.018 239.764 182.954 239.222 184.121C238.956 184.697 238.775 185.354 238.851 186.06C238.927 186.766 239.319 187.538 239.922 187.883C240.448 188.187 241.027 188.107 241.521 187.946C242.225 187.718 242.89 187.359 243.466 186.891C243.802 186.621 244.102 186.302 244.301 185.891C244.561 185.358 244.615 184.683 244.661 184.037"
      fill="white"
    />
    <path
      d="M241.7 181.485C240.626 182.018 239.764 182.954 239.222 184.121C238.956 184.697 238.775 185.354 238.851 186.06C238.927 186.766 239.319 187.538 239.922 187.883C240.448 188.187 241.027 188.107 241.521 187.946C242.225 187.718 242.89 187.359 243.466 186.891C243.802 186.621 244.102 186.302 244.301 185.891C244.561 185.358 244.615 184.683 244.661 184.037"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M227.313 195L231 157H226.015V176.5L222 194.933C223.776 194.933 225.537 194.978 227.313 195Z"
      fill="#C0CCF8"
    />
    <path d="M212 146.5L218 144.5V150.5L210 154L212 146.5Z" fill="#C0CCF8" />
    <path
      d="M238.5 150C239.328 150 240 149.776 240 149.5C240 149.224 239.328 149 238.5 149C237.672 149 237 149.224 237 149.5C237 149.776 237.672 150 238.5 150Z"
      fill="white"
    />
    <path
      d="M158 151C157.498 152.158 158.485 153.853 157.983 155.01C157.195 156.794 154.711 156.958 153.313 158.306C152.335 159.259 152.009 160.839 152.539 162.105C152.715 162.541 153 162.95 153.028 163.426C153.123 164.679 151.643 165.319 150.652 166.082C149.417 167.062 148.806 168.955 149.661 170.29C150.598 171.733 152.634 171.842 154.358 171.801C158.743 171.679 163.114 171.57 167.499 171.447C167.635 171.447 167.771 171.447 167.866 171.352C167.974 171.256 167.988 171.107 167.988 170.957C168.395 164.529 168.816 158.115 169.223 151.688C169.318 150.217 169.332 148.528 168.246 147.534C167.676 147.003 166.888 146.785 166.128 146.663C164.784 146.445 163.386 146.499 162.069 146.826C161.472 146.976 160.861 147.194 160.413 147.616C159.538 148.429 158.474 149.707 158 151Z"
      fill="#C0CCF8"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M164.227 155.501C161.702 152.192 163.461 148.809 166 149C169.5 149 170.371 152.007 170.073 156.174C169.774 160.341 169.535 159.94 166.983 159.749C165.5 159.749 167.5 157.5 164.227 155.514V155.501Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M159.5 231.5C157 231.5 156.5 231.5 155.873 230.254C155.721 229.997 155.578 229.701 155.414 229.372C154.582 227.724 153.889 226.33 153.964 226.054C154.038 225.777 154.782 226.82 155.661 228.38C156.1 229.161 156.142 229.602 156.415 230.067C156.687 230.531 163.189 231.459 163.5 231.5L159.5 231.5Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M193.598 224.552C195.634 223.654 197.209 223.381 189.824 227.494C186.919 229.114 184.462 230.449 184.055 230.462C183.647 230.476 185.561 229.182 188.331 227.562C191.1 225.941 192.594 225.002 193.598 224.552Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M167.934 163.753C165.422 171.787 194.84 176.717 178.441 158.061"
      stroke="#C0CCF8"
      stroke-width="4"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M167.934 163.753C165.422 171.787 194.84 176.717 178.441 158.061"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M178.794 171.243H169.291V163.617L178.794 167.226V171.243Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M172.437 170.041C173.229 170.015 173.897 170.634 174.3 171.313C174.704 171.991 174.962 172.766 175.438 173.396C176.305 174.539 177.762 175.009 179.13 175.417C179.714 175.592 180.312 175.768 180.927 175.74C181.543 175.713 182.166 175.453 182.485 174.921C182.934 174.168 182.596 173.208 182.259 172.399C181.383 170.285 180.605 168.138 179.913 165.944C179.596 164.905 179.166 166.558 176.676 166.338"
      fill="white"
    />
    <path
      d="M172.437 170.041C173.229 170.015 173.897 170.634 174.3 171.313C174.704 171.991 174.962 172.766 175.438 173.396C176.305 174.539 177.762 175.009 179.13 175.417C179.714 175.592 180.312 175.768 180.927 175.74C181.543 175.713 182.166 175.453 182.485 174.921C182.934 174.168 182.596 173.208 182.259 172.399C181.383 170.285 180.605 168.138 179.913 165.944C179.596 164.905 179.166 166.558 176.676 166.338"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M174.518 185.051H160.969L161.227 168.111C161.227 164.679 163.182 160.444 165.436 160.444L168.232 160.866C171.653 162.473 171.938 167.757 172.604 170.562L174.518 185.051Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M175.224 180.367L175.373 185.051L192.5 222.5L173.5 228L172.5 209.59C167.639 167.51 181.5 229 162 228L148.779 220.757L160.562 209.59L161.363 180.87L169.943 180.639L175.224 180.367Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M175.346 182.736H161.132V184.997H175.346V182.736Z"
      fill="#C0CCF8"
    />
    <path
      d="M166.264 162.595C161.499 164.366 145.616 177.262 162.232 183.907"
      stroke="#C0CCF8"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M166.264 162.595C161.499 164.366 145.616 177.262 162.232 183.907"
      stroke="white"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M165.11 166.749C162.395 166.422 161.92 170.603 161.797 172.918L161.865 177.017C161.282 182.859 154.277 180.979 149.539 178.964C152.81 176.009 159.245 158.456 165.436 160.43"
      fill="white"
    />
    <path
      d="M165.11 166.749C162.395 166.422 161.92 170.603 161.797 172.918L161.865 177.017C161.282 182.859 154.277 180.979 149.539 178.964C152.81 176.009 159.245 158.456 165.436 160.43"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M166.59 166.368C168.082 166.368 169.291 165.136 169.291 163.617C169.291 162.098 168.082 160.866 166.59 160.866C165.098 160.866 163.888 162.098 163.888 163.617C163.888 165.136 165.098 166.368 166.59 166.368Z"
      fill="white"
    />
    <path
      d="M159.965 145.757C161.358 145.394 162.181 143.914 161.803 142.451C161.425 140.988 159.989 140.095 158.596 140.457C157.202 140.82 156.379 142.3 156.757 143.763C157.135 145.226 158.571 146.119 159.965 145.757Z"
      fill="#C0CCF8"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M177.002 158.728C176.54 157.897 176.31 156.849 176.35 155.759C176.364 155.228 176.459 154.67 176.717 154.18C176.975 153.69 177.436 153.281 177.884 153.281C178.292 153.281 178.59 153.581 178.848 153.894C179.201 154.357 179.473 154.888 179.663 155.473C179.771 155.814 179.866 156.168 179.853 156.563C179.853 157.067 179.663 157.584 179.5 158.074"
      fill="white"
    />
    <path
      d="M177.002 158.728C176.54 157.897 176.31 156.849 176.35 155.759C176.364 155.228 176.459 154.67 176.717 154.18C176.975 153.69 177.436 153.281 177.884 153.281C178.292 153.281 178.59 153.581 178.848 153.894C179.201 154.357 179.473 154.888 179.663 155.473C179.771 155.814 179.866 156.168 179.853 156.563C179.853 157.067 179.663 157.584 179.5 158.074"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M162.693 182.968C163.589 183.281 164.458 183.907 165.15 184.752C165.49 185.16 165.788 185.637 165.924 186.181C166.06 186.726 165.978 187.339 165.653 187.638C165.354 187.911 164.92 187.897 164.526 187.815C163.956 187.707 163.399 187.489 162.87 187.175C162.557 186.998 162.259 186.781 162.014 186.481C161.689 186.1 161.471 185.596 161.268 185.119"
      fill="white"
    />
    <path
      d="M162.693 182.968C163.589 183.281 164.458 183.907 165.15 184.752C165.49 185.16 165.788 185.637 165.924 186.181C166.06 186.726 165.978 187.339 165.653 187.638C165.354 187.911 164.92 187.897 164.526 187.815C163.956 187.707 163.399 187.489 162.87 187.175C162.557 186.998 162.259 186.781 162.014 186.481C161.689 186.1 161.471 185.596 161.268 185.119"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
)

export default CoolKidsLivingRoom
