import React from 'react'

interface PlayIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string
  width?: string | number
  height?: string | number
}

export const PlayIcon: React.FC<PlayIconProps> = ({
  color = '#2D54E8',
  width = '12',
  height = '15',
  style,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...props}
    >
      <path d="M0.5 0.731689V14.7317L11.5 7.73169L0.5 0.731689Z" fill={color} />
    </svg>
  )
}
