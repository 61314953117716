import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import {
  BasicsPage,
  SuccessPage,
  PersonaPage,
  SchedulePage,
  ScheduleSuccessPage,
  PaymentPage,
  PlanPage
} from './components'
import { useHistory } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useMutation } from 'react-query'
import { endpoints } from '@api'
import { Box, Text } from '@chakra-ui/react'
import Cookies from 'js-cookie'

export const Signup = () => {
  const history = useHistory()

  const { search } = useLocation()
  const values = queryString.parse(search)
  const preSelectedProduct = values.product

  const { formData, persona, hubspotBridgeId } = useStoreState(
    state => state.clinicianSignup
  )
  const { setFormData, setHubspotBridgeId } = useStoreActions(
    actions => actions.clinicianSignup
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isError, setIsError] = useState('')

  const SIGNUP_ERROR_TEXT = (
    <Text>
      Oops, there was an unexpected error while signing up. Please try again or
      review this help article for next steps:&nbsp;
      <a
        href="https://help.blueprint.ai/en/articles/10165377-troubleshooting-sign-up-errors-on-blueprint"
        target="_blank"
        rel="noreferrer"
      >
        Troubleshooting Sign-Up Errors on Blueprint
      </a>
    </Text>
  )

  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hs-scripts.com/5546569.js'
    script.async = true
    script.defer = true
    script.id = 'hs-script-loader'
    script.type = 'text/javascript'
    document.body.appendChild(script)
  })

  const resetFormError = async () => {
    setIsError(false)
  }

  const storeDemoFormData = async data => {
    submitHubspotDemoLead({ data })
  }

  const handleSubmitBasics = async values => {
    values.hubspotutk = Cookies.get('hubspotutk')
    values.referralId = Cookies.get('bp_referral') ?? ''
    values.facebookClickId = Cookies.get('_fbc')
    values.facebookPixelId = Cookies.get('_fbp')
    values.pageUri = window.location.href
    values.trialType =
      formData.trialType || Cookies.get('trialType') || 'gatedTrial'

    const data = {
      ...values,
      ...persona,
      ...formData
    }

    setFormData(data)

    setIsSubmitting(true)

    if (data?.trialType !== 'freemium') {
      await submitHubspotTrialLead({
        data
      })
    }

    if (values.trialType === 'gatedTrial') {
      history.push('/signup/card')
    } else {
      await createAccount({
        data
      })
    }

    setIsSubmitting(false)
  }

  const handleSelectPlan = async data => {
    setFormData(data)
    history.push('/signup/basic')
  }

  const storePaymentAndCreateAccount = async (_, paymentMethodId) => {
    setIsError(false)
    const data = {
      ...formData,
      paymentMethodId,
      hubspotBridgeId
    }
    await createAccount({
      data
    })
  }

  const { mutateAsync: createAccount } = useMutation(
    endpoints.postSelfServeSignup.request,
    {
      onSuccess() {
        setIsSubmitting(false)
        setIsError(false)

        const query = queryString.stringify({
          email: formData.email,
          inviter: 'The Blueprint'
        })

        history.push(`/accept-invite?${query}`)
      },
      onError(error) {
        setIsError(SIGNUP_ERROR_TEXT)
        setIsSubmitting(false)
      }
    }
  )

  const { mutateAsync: submitHubspotTrialLead, error } = useMutation(
    endpoints.postHubspotTrialLead.request,
    {
      onSuccess(data) {
        setHubspotBridgeId(data.hubspotBridgeId)
        setIsError(false)
      },
      onError() {
        setIsError(SIGNUP_ERROR_TEXT)
        console.error(error)
      }
    }
  )

  const { mutateAsync: submitHubspotDemoLead } = useMutation(
    endpoints.postHubspotDemoLead.request
  )

  return (
    <Box w="100%">
      <Switch>
        <Redirect
          exact
          from="/signup"
          to={{
            pathname: '/signup/you',
            search // pass through query params
          }}
        />
        <Route
          path="/signup/you"
          render={() => <PersonaPage onSelect={handleSelectPlan} />}
        />
        <Route
          path="/signup/schedule"
          render={() => <SchedulePage storeDemoFormData={storeDemoFormData} />}
        />
        <Route
          path="/signup/plan"
          render={() => <PlanPage onSelect={handleSelectPlan} />}
        />
        <Route
          path="/signup/basic"
          render={() => (
            <BasicsPage
              isSubmitting={isSubmitting}
              isError={isError}
              onSubmit={handleSubmitBasics}
            />
          )}
        />
        <Route
          path="/signup/card"
          render={() => (
            <PaymentPage
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              isError={isError}
              resetFormError={resetFormError}
              storePaymentAndCreateAccount={storePaymentAndCreateAccount}
            />
          )}
        />
        <Route path="/signup/success" render={() => <SuccessPage />} />
        <Route
          path="/signup/schedule-success"
          render={() => <ScheduleSuccessPage />}
        />
      </Switch>
    </Box>
  )
}
