import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Box,
  Flex,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Divider,
  Link,
  Button,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import {
  useSessionControllerGetCompletedSessionDetails,
  useSessionControllerGetProgressNotesForSession,
  getSessionControllerGetProgressNotesForSessionQueryKey,
  useSessionControllerTrackEvent,
  getSessionControllerGetCompletedSessionDetailsQueryKey,
  useSessionControllerPostSession
} from '~/clinician-api'
import { copyToClipboard } from '@utilities'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { endpoints } from '@api'
import { useExperienceManager, useExtension } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useQueryParams } from '../../hooks/use-query-params'
import { UploadedAlert } from './components/TreatmentPlan'
import Sidebar from './components/sidebar'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'

import _ from 'lodash'
import { TreatmentPlanProvider } from '@context'

import {
  Details,
  ProgressNoteGenerationSteps,
  DocSnippets,
  ProgressNote,
  PrivateNotes,
  SessionSummary,
  Transcript,
  ProgressNoteFooter,
  TreatmentPlan
} from './components'
import AssistPanel from './components/assist-panel'
import * as clinicianTracking from '../../lib/clinician-tracking'
import AssistOverlay from '@handlers/sessions/components/shared/assist/overlay'

import { useStoreState } from 'easy-peasy'
import { useComponentRenderTracker } from '@hooks'

import { ChangeNoteTypeModalV2 } from './components/change-note-type-modal-v2'
import { NoteSettingsDrawerV2 } from './components/note-settings-drawer-v2'
import { StoreModel } from 'src/store/types'

const FeatureRequests = () => {
  return (
    <Link
      textDecoration="none"
      _hover={{ cursor: 'pointer' }}
      href="https://blueprint-health.canny.io/ai-notetaker"
      isExternal
      _focus={{ outline: 'none' }}
    >
      <Flex
        p="16px"
        border="1px solid"
        borderColor="pale_gray"
        borderRadius="8px"
        gap="16px"
        bg="white"
        boxShadow="0px 2px 8px 0px #00000014"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          h="44px"
          w="44px"
          bg="primary"
          borderRadius="8px"
        >
          <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.04 2.5L19.45 3.91L17.66 5.71L16.25 4.3L18.04 2.5ZM10 3H12V0H10V3ZM19 12H22V10H19V12ZM10 14.99V17.95H12V14.99L13 14.41C14.23 13.69 15 12.37 15 10.95C15 8.74 13.21 6.95 11 6.95C8.79 6.95 7 8.74 7 10.95C7 12.37 7.76 13.69 9 14.41L10 14.99ZM14 19.95L8 19.95V16.14C6.21 15.1 5 13.17 5 10.95C5 7.64 7.69 4.95 11 4.95C14.31 4.95 17 7.64 17 10.95C17 13.17 15.79 15.1 14 16.14V19.95ZM0 12H3L3 10H0V12ZM3.97 2.49L5.76 4.29L4.36 5.69L2.56 3.9L3.97 2.49ZM8 23.0001C8 23.5501 8.45 24.0001 9 24.0001H13C13.55 24.0001 14 23.5501 14 23.0001V22.0001H8V23.0001Z"
              fill="white"
            />
          </svg>
        </Flex>
        <Flex flexDirection="column">
          <Text>Have a feature request?</Text>
          <Text color="primary">Submit your idea</Text>
        </Flex>
      </Flex>
    </Link>
  )
}

const Overview = ({
  session,
  refetchSession,
  client,
  progressNote,
  sessionType
}: any) => {
  const [parentWidth, setParentWidth] = useState(0)
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateWidth = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth)
      }
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return (
    <Flex gap="24px" flexDirection="column" width="100%" ref={parentRef}>
      <Details
        session={session}
        progressNote={progressNote}
        client={client}
        refresh={refetchSession}
        sessionType={sessionType}
      />
    </Flex>
  )
}

export function SessionNotesV2() {
  useComponentRenderTracker('Viewed Completed Session Page')
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const organizationId = user?.clinic?.organization?.id

  const {
    sessionId,
    id: clientId
  }: { sessionId: string; id: string } = useParams()
  const queryParams = useQueryParams()
  const queryClient = useQueryClient()

  const {
    isOpen: isNoteSettingsDrawerOpen,
    onOpen: openNoteSettingsDrawer,
    onClose: closeNoteSettingsDrawer
  } = useDisclosure()

  const isDemo = queryParams.get('isDemo') === 'true'
  const isTreatmentPlanUploaded =
    queryParams.get('treatmentPlanUploadSuccess') === 'true'
  const history = useHistory()
  const [selectedProgressNoteId, setSelectedProgressNoteId] = useState('')
  const [isChangeNoteTypeModalOpen, setIsChangeNoteTypeModalOpen] = useState(
    false
  )
  const [demoGenerationStepStatuses, setDemoGenerationStepStatuses] = useState([
    { step: 'PROCESS_AUDIO', status: 'STARTED' },
    { step: 'TRANSCRIBING_AUDIO', status: 'NOT_STARTED' },
    { step: 'GENERATE_NOTE', status: 'NOT_STARTED' }
  ])
  const [assistOverlayOpen, setAssistOverlayOpen] = useState(false)

  const toast = useToast()
  const {
    isEvidenceBasedCareEnabled,
    isDocumentationAutomationEnabled,
    isExtension,
    isWidget,
    isPlusPlanEnabled
  } = useExperienceManager()

  const isIntegratedUI = isWidget || isExtension

  const { settings } = useExtension()

  const [tabIndex, setTabIndex] = useState<number>(0)

  const showNotePreferencesText = useBreakpointValue({ base: false, md: true })
  const isMobileView = useBreakpointValue({ base: true, md: false })

  const isOverviewTabVisible = isExtension
    ? true
    : isWidget
    ? false
    : isMobileView

  const buttonSpacing = useBreakpointValue({ base: '8px', sm: '16px' })

  const { mutateAsync: createSessionDraft } = useSessionControllerPostSession()

  const hasBeenCalledRef = useRef(false)
  const newWindowRef = useRef<Window | null>(null)

  // Safari security settings prevent multiple calls to window.open for a given page
  const onStartNewDictationSessionClick = useCallback(async () => {
    if (hasBeenCalledRef.current) {
      if (newWindowRef.current) newWindowRef.current.focus()
      return
    }

    hasBeenCalledRef.current = true

    const sessionDraft = await createSessionDraft({
      data: {
        patientId: clientId,
        isChime: false,
        isExtension,
        extensionInitiatedFrom: isExtension ? document.referrer : null,
        resumeInProgress: false,
        isDictation: true,
        isAssistEnabled: isPlusPlanEnabled
      }
    })

    newWindowRef.current = window.open(
      `/patient/${clientId}/dictate/session/${sessionDraft.id}`,
      '_blank'
    )
  }, [clientId, isExtension, isPlusPlanEnabled, createSessionDraft])

  useEffect(() => {
    if (isOverviewTabVisible) {
      setTabIndex(prevIndex => prevIndex + 1)
    } else if (!isWidget) {
      setTabIndex(prevIndex => prevIndex - 1)
    }
  }, [isOverviewTabVisible, isIntegratedUI, isExtension, isWidget])

  const {
    data: treatmentPlanData,
    isLoading: isTreatmentPlanLoading
  } = useQuery([endpoints.getClientTreatmentPlan.getCacheId(), clientId], () =>
    endpoints.getClientTreatmentPlan.request({ clientId })
  )

  const { data: noteSettings } = useQuery<any>(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () =>
      endpoints.getProgressNoteSettings.request({ organizationId }) as Promise<
        any
      >
  )

  const {
    data: session,
    isLoading: isSessionLoading,
    refetch: refetchSession
  } = useSessionControllerGetCompletedSessionDetails(sessionId)

  const {
    data: sessionTranscriptData,
    isLoading: sessionTranscriptLoading
  } = useQuery<any>(
    [endpoints.getSessionTranscript.getCacheId(), sessionId],
    () => endpoints.getSessionTranscript.request({ sessionId }) as Promise<any>
  )

  const {
    data: progressNoteData,
    isLoading: isProgressNotesLoading
  } = useSessionControllerGetProgressNotesForSession(sessionId, {
    query: {
      refetchInterval: (data: any) => {
        if (data?.sessionProgressNotes.some((pn: any) => pn.note?.isLoading)) {
          return 5000
        }

        return false
      }
    }
  })

  const {
    mutateAsync: generateProgressNote,
    isLoading: isGeneratingProgressNote
  } = useMutation(endpoints.generateProgressNote.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        getSessionControllerGetProgressNotesForSessionQueryKey(sessionId)
      )
    }
  })

  const isAnyNoteLoading = useMemo(
    () =>
      progressNoteData?.sessionProgressNotes?.some(
        (pn: any) => pn.note?.isLoading
      ),
    [progressNoteData?.sessionProgressNotes]
  )

  useEffect(() => {
    const isFirstNote =
      selectedProgressNoteId === progressNoteData?.sessionProgressNotes[0]?.id
    if (selectedProgressNoteId && !isAnyNoteLoading && isFirstNote) {
      // eslint-disable-next-line
      parent.postMessage({ type: 'BP_NOTE_GENERATED', sessionId }, '*')
    }
  }, [isAnyNoteLoading])

  useEffect(() => {
    if (!isDemo) return

    const timeToLoad = 1500
    setTimeout(() => {
      setDemoGenerationStepStatuses([
        { step: 'PROCESS_AUDIO', status: 'COMPLETED' },
        { step: 'TRANSCRIBING_AUDIO', status: 'STARTED' },
        { step: 'GENERATE_NOTE', status: 'NOT_STARTED' }
      ])
      setTimeout(() => {
        setDemoGenerationStepStatuses([
          { step: 'PROCESS_AUDIO', status: 'COMPLETED' },
          { step: 'TRANSCRIBING_AUDIO', status: 'COMPLETED' },
          { step: 'GENERATE_NOTE', status: 'STARTED' }
        ])
        setTimeout(() => {
          setDemoGenerationStepStatuses([
            { step: 'PROCESS_AUDIO', status: 'COMPLETED' },
            { step: 'TRANSCRIBING_AUDIO', status: 'COMPLETED' },
            { step: 'GENERATE_NOTE', status: 'COMPLETED' }
          ])
          setTimeout(() => {
            history.replace(
              `/patient/${clientId}/completed-session/${sessionId}`
            )
          }, 500)
        }, timeToLoad)
      }, timeToLoad)
    }, timeToLoad)
  }, [isDemo])

  useEffect(() => {
    if (isAnyNoteLoading) {
      const loadingNote = progressNoteData?.sessionProgressNotes?.find(
        (pn: any) => pn.note?.isLoading
      )
      setSelectedProgressNoteId(loadingNote?.id)
    }
  }, [isAnyNoteLoading, progressNoteData?.sessionProgressNotes])

  const progressNote = useMemo(
    () =>
      progressNoteData?.sessionProgressNotes?.find(
        (pn: any) => pn.id === selectedProgressNoteId
      ) || progressNoteData?.sessionProgressNotes[0],
    [progressNoteData, selectedProgressNoteId]
  )

  const { mutate: trackEvent } = useSessionControllerTrackEvent()

  const {
    data: account,
    isLoading: isAccountLoading,
    refetch: refetchAccount
  } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const {
    mutateAsync: changeNoteType,
    isLoading: isChangeNoteTypeLoading
  } = useMutation(endpoints.postChangeNoteType.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        getSessionControllerGetProgressNotesForSessionQueryKey(sessionId)
      )
      await queryClient.invalidateQueries(
        getSessionControllerGetCompletedSessionDetailsQueryKey(sessionId)
      )
      setIsChangeNoteTypeModalOpen(false)
    }
  })

  const { data: client, isLoading: isClientLoading }: any = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), clientId],
    () => endpoints.getClinicianUserAccount.request({ id: clientId })
  )

  const emptyNote = _.isEmpty(progressNote?.note)
  const isNoteNull = !progressNote?.note

  if (isProgressNotesLoading || isSessionLoading || isClientLoading) return null

  const noteType = progressNote?.template?.displayShort || 'soap'
  const sessionType = progressNote?.template?.sessionType || 'individual'

  const handleTrackEvent = (event: string, payload: object) => {
    trackEvent({ id: sessionId, data: { eventType: event, payload } })
  }

  const handleChangeNoteTypeClick = () => {
    setIsChangeNoteTypeModalOpen(true)
    clinicianTracking.trackEvent('Clicked Change Note Type', {
      noteId: progressNote?.id
    })
  }

  const handleChangeNoteType = async (
    noteType: string,
    treatmentApproach: string
  ) => {
    clinicianTracking.trackEvent('Changed Note Type', {
      noteType,
      noteId: progressNote?.id
    })
    await changeNoteType({
      progressNoteId: progressNote.id,
      noteType,
      treatmentApproach: treatmentApproach === 'none' ? null : treatmentApproach
    })
  }

  const handleCopyAllToClipboard = ({ isNext = false }) => {
    let values: string[] = []

    if (isNext) {
      progressNote?.template?.sections.forEach(
        ({ key, displayName }: { key: string; displayName: string }) => {
          if (progressNote?.nextNote[key]) {
            values.push(`${displayName}\n${progressNote?.nextNote[key]}`)
          }
        }
      )
    } else {
      progressNote?.template?.sections.forEach(
        ({ key, displayName }: { key: string; displayName: string }) => {
          if (progressNote?.note[key]) {
            values.push(`${displayName}\n${progressNote?.note[key]}`)
          }
        }
      )
    }

    const value = values.join('\n\n')

    copyToClipboard(value)
    // eslint-disable-next-line
    parent.postMessage({ type: 'BP_NOTE', note: value, sessionId }, '*')
    clinicianTracking.trackEvent('Copied Progress Note', {
      noteType,
      noteId: progressNote?.id
    })
    handleTrackEvent('copy_all_to_clipboard_clicked', {
      noteType
    })
    toast({
      title: 'Copied to clipboard!',
      status: 'success',
      duration: 1500
    })
  }

  const isTxPlanVisible =
    (isOverviewTabVisible || isIntegratedUI) &&
    isDocumentationAutomationEnabled &&
    !settings?.hideTreatmentPlan
  const assistIsVisible =
    isOverviewTabVisible &&
    isDocumentationAutomationEnabled &&
    (!isEvidenceBasedCareEnabled || isPlusPlanEnabled) &&
    !isIntegratedUI
  const psychotherapyNoteVisible =
    !isIntegratedUI &&
    (isEvidenceBasedCareEnabled || !!session?.psychotherapyNote)
  const docSnippetsVisible = !isIntegratedUI && isEvidenceBasedCareEnabled

  const showTxPlanIndicator =
    !isTreatmentPlanLoading &&
    !(treatmentPlanData as any)?.treatmentPlan?.isLoading &&
    (treatmentPlanData as any)?.treatmentPlan &&
    !(treatmentPlanData as any)?.treatmentPlan?.acceptedAt

  let TabConfig = [
    ...(isOverviewTabVisible ? [{ name: 'Overview' }] : []),
    ...(!isNoteNull ? [{ name: 'Note' }] : []),
    ...(!isNoteNull && isDocumentationAutomationEnabled
      ? [{ name: 'Summary' }]
      : []),
    ...(!isNoteNull && isDocumentationAutomationEnabled
      ? [{ name: 'Transcript' }]
      : []),
    ...(!isNoteNull && isTxPlanVisible
      ? [{ name: 'Treatment Plan', showIndicator: showTxPlanIndicator }]
      : []),
    ...(!isNoteNull && assistIsVisible ? [{ name: 'Assist' }] : []),
    ...(psychotherapyNoteVisible ? [{ name: 'Psychotherapy note' }] : []),
    ...(docSnippetsVisible ? [{ name: 'Doc snippets' }] : [])
  ]

  const transcriptionError =
    progressNoteData?.generationStepStatuses?.some(
      ({ step, status }: any) =>
        step === 'TRANSCRIBING_AUDIO' && status === 'ERROR'
    ) && !client.is_demo

  const renderDeletedNote = () => {
    return (
      <>
        <Flex
          p="16px"
          border="1px solid"
          height="200px"
          borderColor="pale_gray"
          borderRadius="8px"
          justifyContent="center"
          alignItems="center"
          color="#757575"
          mt="32px"
          mx={{
            base: '16px',
            sm: '16px',
            md: '32px'
          }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Text>
              Notes for this session have been deleted and are no longer
              available.
            </Text>
            <Button
              onClick={() => setIsChangeNoteTypeModalOpen(true)}
              mt="small"
            >
              Generate Note
            </Button>
          </Flex>
        </Flex>
      </>
    )
  }

  const buildHeader = () => {
    return noteType
  }

  const buildSubHeader = () => {
    let header = `${sessionType}`
    if (!!session?.treatmentApproach && session?.treatmentApproach !== 'none') {
      const treatmentApproachLabel = noteSettings?.preferenceOptions?.treatmentApproach?.options?.find(
        (o: any) => o.value === session?.treatmentApproach
      )?.label
      header += `, ${treatmentApproachLabel}`
    }
    return header
  }

  const toggleAssistOverlay = () => {
    setAssistOverlayOpen(prev => !prev)
  }

  const isOverviewSectionVisible = !isIntegratedUI && !isMobileView

  return (
    <Flex
      height={
        !isIntegratedUI
          ? showNotePreferencesText
            ? `calc(100vh - 81px)`
            : '100%'
          : `100%`
      }
      overflowY="hidden"
    >
      {isOverviewSectionVisible && (
        <Flex
          display={{
            base: 'none',
            sm: 'none',
            md: 'block'
          }}
          paddingTop="24px"
          bg="#FCFCFC"
          height="100%"
          borderRight="1px solid"
          borderColor="pale_gray"
          width="350px"
          maxW="350px"
          minW="350px"
        >
          <Flex
            h="100%"
            flexDirection="column"
            px="32px"
            justifyContent="space-between"
          >
            <Flex>
              <Overview
                session={session}
                refetchSession={refetchSession}
                client={client}
                progressNote={progressNote}
                sessionType={progressNote?.template?.sessionType || 'Unknown'}
              />
            </Flex>
            <Box position="sticky" bottom="24px">
              <FeatureRequests />
            </Box>
          </Flex>
        </Flex>
      )}
      <Flex
        display={{ base: 'block', sm: 'block', md: 'flex' }}
        flexDirection="column"
        justifyContent="space-between"
        flex="1"
        width="100%"
      >
        <Tabs
          variant="line"
          isLazy
          lazyBehavior="keepMounted"
          index={tabIndex}
          onChange={index => setTabIndex(index)}
          {...(isIntegratedUI ? { backgroundColor: 'white' } : {})}
        >
          <Flex
            flexDirection="column"
            {...(!showNotePreferencesText
              ? { height: '50px', maxHeight: '50px', minHeight: '50px' }
              : {})}
            {...(!isIntegratedUI
              ? { paddingTop: !isMobileView ? '16px' : '0px' }
              : {})}
          >
            <Container
              sx={{
                '::after': {
                  marginRight: '3px !important' // Override margin-right on ::after
                }
              }}
              {...(isIntegratedUI && { justifyContent: 'center' })}
            >
              <TabList
                sx={{
                  display: 'flex',
                  textWrap: 'nowrap'
                }}
                {...(!showNotePreferencesText
                  ? { height: '50px', maxHeight: '50px', minHeight: '50px' }
                  : {})}
                gap="24px"
                borderBottom="none"
                overflowX="auto"
                overflowY="hidden"
              >
                {TabConfig.map(tab => (
                  <Tab
                    key={tab.name}
                    justifyContent="flex-start"
                    px="0"
                    color="dark_gray"
                    fontWeight="normal"
                    borderBottom="6px solid"
                    whiteSpace="nowrap"
                    position="relative"
                    _focus={{ outline: 'none' }}
                    _selected={{
                      color: '#282828',
                      borderBottomColor: 'primary'
                    }}
                    _last={{
                      paddingRight: 'small'
                    }}
                  >
                    {tab.name}
                    <Box
                      h="8px"
                      w="8px"
                      bg="primary"
                      borderRadius="50%"
                      mb="auto"
                      display={tab.showIndicator ? 'block' : 'none'}
                    />
                  </Tab>
                ))}
              </TabList>
            </Container>
            <Divider color="#E4E5E6" />
          </Flex>
          <Flex
            flexDirection="column"
            boxSizing="border-box"
            width="100%"
            {...(isIntegratedUI && { maxWidth: '750px', margin: '0 auto' })}
          >
            <TabPanels flexGrow={1} maxWidth="none">
              {isOverviewTabVisible && (
                <TabPanel padding={0}>
                  <Container pt="16px">
                    <Flex flexDirection="column" w="100%">
                      <Overview
                        session={session}
                        refetchSession={refetchSession}
                        client={client}
                        progressNote={progressNote}
                        sessionType={sessionType}
                      />
                      <Box mt="24px">
                        <Link
                          textDecoration="none"
                          _hover={{ cursor: 'pointer' }}
                          href="https://blueprint-health.canny.io/ai-notetaker"
                          isExternal
                          _focus={{ outline: 'none' }}
                        >
                          <Flex
                            p="16px"
                            border="1px solid"
                            borderColor="pale_gray"
                            borderRadius="8px"
                            gap="16px"
                            bg="white"
                            boxShadow="0px 2px 8px 0px #00000014"
                          >
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              h="44px"
                              w="44px"
                              bg="primary"
                              borderRadius="8px"
                            >
                              <svg
                                width="22"
                                height="24"
                                viewBox="0 0 22 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M18.04 2.5L19.45 3.91L17.66 5.71L16.25 4.3L18.04 2.5ZM10 3H12V0H10V3ZM19 12H22V10H19V12ZM10 14.99V17.95H12V14.99L13 14.41C14.23 13.69 15 12.37 15 10.95C15 8.74 13.21 6.95 11 6.95C8.79 6.95 7 8.74 7 10.95C7 12.37 7.76 13.69 9 14.41L10 14.99ZM14 19.95L8 19.95V16.14C6.21 15.1 5 13.17 5 10.95C5 7.64 7.69 4.95 11 4.95C14.31 4.95 17 7.64 17 10.95C17 13.17 15.79 15.1 14 16.14V19.95ZM0 12H3L3 10H0V12ZM3.97 2.49L5.76 4.29L4.36 5.69L2.56 3.9L3.97 2.49ZM8 23.0001C8 23.5501 8.45 24.0001 9 24.0001H13C13.55 24.0001 14 23.5501 14 23.0001V22.0001H8V23.0001Z"
                                  fill="white"
                                />
                              </svg>
                            </Flex>
                            <Flex flexDirection="column">
                              <Text>Have a feature request?</Text>
                              <Text color="primary">Submit your idea</Text>
                            </Flex>
                          </Flex>
                        </Link>
                      </Box>
                    </Flex>
                  </Container>
                </TabPanel>
              )}
              {!isNoteNull && (
                <TabPanel padding={0}>
                  {isAnyNoteLoading || isDemo ? (
                    <Container pt="32px">
                      <ProgressNoteGenerationSteps
                        progressNoteGenerationStepStatuses={
                          isDemo
                            ? demoGenerationStepStatuses
                            : progressNoteData?.generationStepStatuses || []
                        }
                      />
                      <Box h="24px" />
                    </Container>
                  ) : (
                    <Box>
                      {progressNote?.deletedAt ? (
                        <Box>{renderDeletedNote()}</Box>
                      ) : (
                        <Flex flexDirection="column">
                          <ProgressNote
                            onStartNewDictationSessionClick={
                              onStartNewDictationSessionClick
                            }
                            showNotePreferencesText={showNotePreferencesText}
                            buildHeader={buildHeader}
                            buildSubHeader={buildSubHeader}
                            isDocumentationAutomationEnabled={
                              isDocumentationAutomationEnabled
                            }
                            isTranscriptTooShort={
                              progressNoteData?.isTranscriptTooShort
                            }
                            sessionTranscriptLoading={sessionTranscriptLoading}
                            sessionTranscriptData={sessionTranscriptData}
                            handleChangeNoteTypeClick={
                              handleChangeNoteTypeClick
                            }
                            openNoteSettingsDrawer={openNoteSettingsDrawer}
                            buttonSpacing={buttonSpacing}
                            isIntegratedUI={isIntegratedUI}
                            progressNote={progressNote}
                            handleTrackEvent={handleTrackEvent}
                            emptyNote={emptyNote}
                            transcriptionError={transcriptionError}
                            uploadAlert={() => (
                              <UploadedAlert
                                onGenerate={async () =>
                                  await generateProgressNote({
                                    sessionId,
                                    noteType: progressNote?.noteType
                                  })
                                }
                                isGenerating={isGeneratingProgressNote}
                                isVisible={
                                  isTreatmentPlanUploaded &&
                                  !transcriptionError &&
                                  !!sessionTranscriptData?.sessionTranscript?.id
                                }
                              />
                            )}
                          />
                        </Flex>
                      )}
                    </Box>
                  )}
                </TabPanel>
              )}
              {isDocumentationAutomationEnabled && !isNoteNull && (
                <TabPanel padding={0}>
                  <SessionSummary
                    isSessionTab={
                      TabConfig.findIndex(tab => tab.name === 'Summary') ===
                      tabIndex
                    }
                    trackEvent={handleTrackEvent}
                  />
                </TabPanel>
              )}
              {isDocumentationAutomationEnabled && !isNoteNull && (
                <TabPanel padding={0}>
                  <Transcript sessionId={sessionId} client={client} />
                </TabPanel>
              )}
              {isTxPlanVisible && !isNoteNull && (
                <TabPanel padding={0}>
                  <Container pt="16px" justifyContent="center" w="100%">
                    <TreatmentPlan client={client} />
                  </Container>
                </TabPanel>
              )}
              {assistIsVisible && !isNoteNull && (
                <TabPanel padding={0}>
                  <TreatmentPlanProvider client={client} toastsEnabled={false}>
                    {assistOverlayOpen ? (
                      <AssistOverlay
                        toggleAssistOverlay={toggleAssistOverlay}
                        client={client}
                        account={account}
                        session={session}
                      />
                    ) : (
                      <Container pt="16px" w="100%" justifyContent="center">
                        <AssistPanel
                          account={account}
                          session={session}
                          sessionId={sessionId}
                          client={client}
                          toggleOverlay={toggleAssistOverlay}
                        />
                      </Container>
                    )}
                  </TreatmentPlanProvider>
                </TabPanel>
              )}
              {psychotherapyNoteVisible && (
                <TabPanel padding={0}>
                  <PrivateNotes
                    sessionId={sessionId}
                    psychotherapyNote={session?.psychotherapyNote}
                    refresh={refetchSession}
                  />
                </TabPanel>
              )}
              {docSnippetsVisible && (
                <TabPanel padding={0}>
                  <DocSnippets
                    session={session}
                    handleTrackEvent={handleTrackEvent}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Flex>
        </Tabs>
        {tabIndex === (isOverviewTabVisible ? 1 : 0) &&
          !emptyNote &&
          !isAnyNoteLoading && (
            <ProgressNoteFooter
              sessionId={sessionId}
              handleCopyAllToClipboard={handleCopyAllToClipboard}
              progressNotes={progressNoteData.sessionProgressNotes}
              noteType={noteType}
              progressNoteId={progressNote?.id}
              setSelectedProgressNoteId={setSelectedProgressNoteId}
              selectedProgressNoteId={selectedProgressNoteId}
              openSettings={openNoteSettingsDrawer}
            />
          )}
      </Flex>
      {!isIntegratedUI && client && !client.is_archived && (
        <Sidebar
          assistOverlayOpen={assistOverlayOpen}
          toggleAssistOverlay={toggleAssistOverlay}
          isNoteNull={isNoteNull}
          sessionId={sessionId}
          client={client}
          account={account}
          session={session}
        />
      )}
      {!isNoteNull && (
        <>
          <ChangeNoteTypeModalV2
            isOpen={isChangeNoteTypeModalOpen}
            onClose={() => setIsChangeNoteTypeModalOpen(false)}
            initialValue={progressNote.noteType}
            onSubmit={handleChangeNoteType}
            treatmentApproach={session?.treatmentApproach || null}
          />
          <NoteSettingsDrawerV2
            isOpen={isNoteSettingsDrawerOpen}
            onClose={closeNoteSettingsDrawer}
            progressNoteId={progressNote?.id}
            sessionId={sessionId}
            progressNote={progressNote}
          />
        </>
      )}
    </Flex>
  )
}
