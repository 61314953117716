import React from 'react'
import {
  SymptomTracker,
  HighRisk,
  Inventory,
  LightBulb,
  Wait,
  Clipboard,
  EditPencilOutline
} from '@components/icons'
import { openLinkInNewTab, buildAssessmentTakeNowUrl } from '@utilities'
import { ClientT, ItemT, ActionConfigT } from './types'
import { SessionPanelTab } from '@handlers/sessions/types'

export const SymptomTrackerIcon: React.VoidFunctionComponent = () =>
  React.createElement(SymptomTracker)
export const HighRiskIcon: React.VoidFunctionComponent = () =>
  React.createElement(HighRisk, { fill: '#EB5164' })
export const InventoryIcon: React.VoidFunctionComponent = () =>
  React.createElement(Inventory)
export const LightBulbIcon: React.VoidFunctionComponent = () =>
  React.createElement(LightBulb, { fill: '#2D54E8' })
export const WaitIcon: React.VoidFunctionComponent = () =>
  React.createElement(Wait)
export const ClipboardIcon: React.VoidFunctionComponent = () =>
  React.createElement(Clipboard)
export const EditPencilIcon: React.VoidFunctionComponent = () =>
  React.createElement(EditPencilOutline, { fill: '#2D54E8' })


export const ActionConfig: { [key: string]: ActionConfigT } = {
  REVIEW_ASSESSMENT_RESULTS: {
    backgroundColor: '#F6F7FF',
    subtextColor: 'dark_gray',
    icon: SymptomTrackerIcon,
    buttonText: 'Review',
    text: ({ client }: { client: ClientT }) => 'Review assessment results',
    subtext: ({ client, item }: { client: ClientT; item: ItemT }) =>
      `${client.first_name} scored ${item?.data?.completedAssessment?.score} out of ${item?.data?.completedAssessment?.totalScore} on the ${item?.data?.completedAssessment?.name}`,
    handleClick: ({
      setOverlayPanel,
      switchTab,
      data
    }: {
      setOverlayPanel: Function
      switchTab: Function
      data: ItemT['data']
    }) => {
      const { assessmentId, scoreId } = data.completedAssessment || {}
      setOverlayPanel('ASSESSMENT_RESULTS', {
        assessmentId,
        assessmentScoreId: scoreId
      })
    }
  },
  VIEW_PATIENT_HIGH_RISK_DETAILS: {
    backgroundColor: '#FDEDEF',
    textColor: '#EB5164',
    subtextColor: '#EB5164',
    icon: HighRiskIcon,
    buttonText: 'Review',
    text: ({ client }: { client: ClientT }) =>
      `Assess ${client.first_name}'s level of risk`,
    subtext: ({ client, item }: { client: ClientT; item: ItemT }) =>
      `${client.first_name} indicated elevated risk for suicidal ideation based on the ${item?.data?.highRiskAssessmentTrigger?.name}`,
    handleClick: ({
      setOverlayPanel,
      switchTab,
      data
    }: {
      setOverlayPanel: Function
      switchTab: Function
      data: ItemT['data']
    }) => {
      const { assessment_id, score_id } =
        data.highRiskDetails?.metadata?.triggers?.[0] || {}
      setOverlayPanel('ASSESSMENT_RESULTS', {
        assessmentId: assessment_id,
        scoreId: score_id
      })
    }
  },
  VIEW_TREATMENT_PLAN: {
    backgroundColor: '#F6F7FF',
    subtextColor: 'dark_gray',
    icon: InventoryIcon,
    buttonText: 'Review & accept',
    text: ({ client }: { client: ClientT }) => 'Accept treatment plan',
    subtext: ({ client }: { client: ClientT }) =>
      `Review and accept the generated treatment plan for ${client.first_name}`,
    handleClick: ({
      setOverlayPanel,
      switchTab
    }: {
      setOverlayPanel: Function
      switchTab: Function
    }) => {
      switchTab(SessionPanelTab.TREATMENT_PLAN)
    }
  },
  VIEW_ASSESSMENT_SUGGESTIONS: {
    backgroundColor: '#F6F7FF',
    subtextColor: 'dark_gray',
    icon: LightBulbIcon,
    buttonText: 'View suggestions',
    text: ({ client }: { client: ClientT }) =>
      'Consider assessment suggestions',
    subtext: ({ client, item }: { client: ClientT; item: ItemT }) =>
      `${item?.data?.suggestedAssessments?.length} assessments for ${client.first_name}`,
    handleClick: ({
      setOverlayPanel,
      switchTab
    }: {
      setOverlayPanel: Function
      switchTab: Function
    }) => {
      switchTab(SessionPanelTab.ASSESSMENTS)
    }
  },
  TAKE_OVERDUE_ASSESSMENTS: {
    backgroundColor: '#F6F7FF',
    subtextColor: 'dark_gray',
    icon: WaitIcon,
    buttonText: 'Take now',
    text: ({ client }: { client: ClientT }) => 'Complete overdue assessments',
    subtext: ({ client, item }: { client: ClientT; item: ItemT }) =>
      `${client.first_name} has ${item?.data?.overdueAssessments?.length} overdue assessments`,
    handleClick: ({
      setOverlayPanel,
      switchTab,
      data
    }: {
      setOverlayPanel: Function
      switchTab: Function
      data: ItemT['data']
    }) => {
      openLinkInNewTab(
        buildAssessmentTakeNowUrl(
          data.overdueAssessments?.[0]?.assignee_user_id,
          data.overdueAssessments?.map(a => a.assessmentId)
        )
      )
    }
  },
  VIEW_WORKSHEET_SUGGESTIONS: {
    backgroundColor: '#F6F7FF',
    subtextColor: 'dark_gray',
    icon: LightBulbIcon,
    buttonText: 'View suggestions',
    text: () => 'Consider worksheet suggestions',
    subtext: ({ client, item }: { client: ClientT; item: ItemT }) =>
      `${item?.data?.suggestedWorksheets?.length} worksheets to consider for ${client.first_name}`,
    handleClick: ({
      switchTab
    }: {
      setOverlayPanel: Function
      switchTab: Function
    }) => {
      switchTab(SessionPanelTab.WORKSHEETS)
    }
  },
  REVIEW_WORKSHEET_RESULTS: {
    backgroundColor: '#F6F7FF',
    subtextColor: 'dark_gray',
    icon: SymptomTrackerIcon,
    buttonText: 'Review',
    text: () => 'Review completed worksheets',
    subtext: ({ item }: { client: ClientT; item: ItemT }) => {
      return `${item?.data?.completedWorksheets?.length} completed since last session`
    },
    handleClick: ({
      setOverlayPanel
    }: {
      setOverlayPanel: Function
      switchTab: Function
      data: ItemT['data']
    }) => {
      setOverlayPanel('ALL_WORKSHEET_RESULTS')
    }
  },
  VIEW_SAVED_INTERVENTIONS: {
    backgroundColor: '#F6F7FF',
    subtextColor: 'dark_gray',
    icon: ClipboardIcon,
    buttonText: 'View',
    text: () => 'Start interventions',
    subtext: ({ item }: { client: ClientT; item: ItemT }) => {
      return `You saved ${item?.data?.savedInterventions?.length} interventions`
    },
    handleClick: ({
      setOverlayPanel,
      switchTab
    }: {
      setOverlayPanel: Function
      switchTab: Function
      data: ItemT['data']
    }) => {
      switchTab(SessionPanelTab.INTERVENTIONS)
    }
  },
  VIEW_INTERVENTION_SUGGESTIONS: {
    backgroundColor: '#F6F7FF',
    subtextColor: 'dark_gray',
    icon: LightBulbIcon,
    buttonText: 'View suggestions',
    text: () => 'Consider intervention suggestions',
    subtext: ({ client, item }: { client: ClientT; item: ItemT }) =>
      `${item?.data?.suggestedInterventions?.length} interventions to consider for ${client.first_name}`,
    handleClick: ({
      switchTab
    }: {
      setOverlayPanel: Function
      switchTab: Function
    }) => {
      switchTab(SessionPanelTab.INTERVENTIONS)
    }
  },
  RESUME_INTERVENTION: {
    backgroundColor: '#F6F7FF',
    subtextColor: 'dark_gray',
    icon: EditPencilIcon,
    buttonText: 'Resume',
    text: () => 'Resume intervention',
    subtext: ({ item }: { client: ClientT; item: ItemT }) => {
      return `Continue ${item.data.startedIntervention?.intervention?.name} intervention`
    },
    handleClick: ({
      setOverlayPanel,
      switchTab,
      data
    }: {
      setOverlayPanel: Function
      switchTab: Function
      data: ItemT['data']
    }) => {
      setOverlayPanel('INTERVENTION_ACTIVITY', {
        interventionId: data?.startedIntervention?.intervention?.id,
        interventionName: data?.startedIntervention?.intervention?.name,
        patientInterventionId: data?.startedIntervention?.id,
        isReadOnly: false
      })
    }
  },
}
