import React from 'react'
import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react'

interface BeforeYouBeginItemProps {
  textColor: string | undefined
  subtextColor: string | undefined
  backgroundColor: string
  icon: JSX.Element
  buttonText: string
  text: string
  subtext: string
  handleClick: () => void
}

const BeforeYouBeginItem: React.FC<BeforeYouBeginItemProps> = ({
  backgroundColor,
  textColor,
  subtextColor,
  icon,
  buttonText,
  text,
  subtext,
  handleClick
}) => {
  return (
    <HStack
      justifyContent="space-between"
      py="12px"
      px="16px"
      borderRadius="8px"
      bg={backgroundColor}
      spacing="12px"
    >
      <HStack gap="8px" flex="1" minWidth="0">
        <Box flexShrink={0}>{icon}</Box>
        <Stack spacing="0" flex="1" minWidth="0">
          <Text fontWeight="medium" color={textColor} noOfLines={2}>
            {text}
          </Text>
          <Text fontSize="12px" color={subtextColor} noOfLines={2}>
            {subtext}
          </Text>
        </Stack>
      </HStack>
      <Button
        variant="link"
        onClick={handleClick}
        textDecoration="none"
        color={textColor}
        _focus={{ outline: 'none' }}
        flexShrink={0}
        minW="fit-content"
      >
        {buttonText}
      </Button>
    </HStack>
  )
}

export default BeforeYouBeginItem
