import React, { useState, useEffect } from 'react'
import { endpoints } from '@api'
import { useQuery } from 'react-query'
import {
  VStack,
  HStack,
  Box,
  Text,
  Heading,
  Link,
  Select
} from '@chakra-ui/react'
import { Formik, Form, FastField } from 'formik'
import { Button, TextField } from '@blueprinthq/joy'
import * as Yup from 'yup'
import { useStoreState } from 'easy-peasy'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import config from '../../../../config'

export const Basics = ({ onSubmit, isError, isSubmitting }) => {
  const history = useHistory()
  const [signupError, setSignupError] = useState(null)
  const { persona } = useStoreState(state => state.clinicianSignup)

  useEffect(() => {
    if (!persona || isEmpty(persona)) {
      history.push('/signup')
    }
  })

  const { data: states } = useQuery(
    [endpoints.getStates.getCacheId()],
    endpoints.getStates.request,
    {
      initialData: []
    }
  )

  return (
    <div>
      <Formik
        style={{ width: '100%' }}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          organizationName: '',
          number_of_clinicians: persona.persona === 'solo' ? '1' : '',
          state: null
        }}
        onSubmit={async (values, actions) => {
          await onSubmit(values)
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Invalid email')
            .required('Email is required'),
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          organizationName: Yup.string().required(
            'Organization name is required'
          ),
          state: Yup.object()
            .shape({
              id: Yup.string().required('State is required')
            })
            .required('State is required'),
          number_of_clinicians: Yup.string().required(
            '# of clinicians is required'
          )
        })}
        validate={() => setSignupError(null)}
      >
        {({ errors, touched }) => (
          <Form style={{ width: '100%' }}>
            <Box pb={'medium'} w="100%">
              <Heading w="100%" mb="4px" size="lg">
                Create your free account
              </Heading>
              <Text>We just need a few things to get your account set up.</Text>
            </Box>
            <VStack gap={'small'} mt={'0px'}>
              <HStack
                w="100%"
                gap={'small'}
                style={{ marginTop: 0 }}
                pb={
                  ((errors.firstName && touched.firstName) ||
                    (errors.lastName && touched.lastName)) &&
                  'small'
                }
              >
                <Box w="100%">
                  <FastField name="firstName">
                    {({ field }) => (
                      <TextField
                        {...field}
                        w="100%"
                        isRequired
                        label="First Name"
                        isInvalid={errors.firstName && touched.firstName}
                        errorText={errors.firstName}
                      />
                    )}
                  </FastField>
                </Box>
                <Box
                  style={{ marginInlineStart: 0, WebkitMarginStart: 0 }}
                  w="100%"
                >
                  <FastField name="lastName">
                    {({ field }) => (
                      <TextField
                        {...field}
                        w="100%"
                        isRequired
                        label="Last Name"
                        isInvalid={errors.lastName && touched.lastName}
                        errorText={errors.lastName}
                      />
                    )}
                  </FastField>
                </Box>
              </HStack>
              <Box
                w="100%"
                style={{ marginTop: 0 }}
                pb={errors.email && touched.email && 'small'}
              >
                <FastField name="email">
                  {({ field }) => (
                    <TextField
                      {...field}
                      w="100%"
                      isRequired
                      label="Work email"
                      isInvalid={errors.email && touched.email}
                      errorText={errors.email}
                    />
                  )}
                </FastField>
              </Box>
              <Box
                w="100%"
                style={{ marginTop: 0 }}
                pb={
                  errors.organizationName && touched.organizationName && 'small'
                }
              >
                <FastField name="organizationName">
                  {({ field }) => (
                    <TextField
                      {...field}
                      w="100%"
                      isRequired
                      label="Organization name"
                      isInvalid={
                        errors.organizationName && touched.organizationName
                      }
                      errorText={errors.organizationName}
                    />
                  )}
                </FastField>
              </Box>
              <Box
                w="100%"
                style={{ marginTop: 0 }}
                pb={
                  errors.number_of_clinicians &&
                  touched.number_of_clinicians &&
                  'small'
                }
              >
                <FastField name="number_of_clinicians">
                  {({ field }) => (
                    <TextField
                      {...field}
                      w="100%"
                      isRequired
                      label="# of clinicians in your organization"
                      type="number"
                      isInvalid={
                        errors.number_of_clinicians &&
                        touched.number_of_clinicians
                      }
                      errorText={errors.number_of_clinicians}
                    />
                  )}
                </FastField>
              </Box>
              <Box
                w="100%"
                style={{ marginTop: 0 }}
                pb={errors.state && touched.state && 'xsmall'}
              >
                <FastField name="state">
                  {({ field, form }) => (
                    <Select
                      {...field}
                      placeholder="Select state"
                      sx={{
                        // Apply styles to the select itself
                        color: field.value ? 'black' : 'gray.500' // Default color for options
                      }}
                      onChange={event => {
                        const selectedState = states.find(
                          state => state.id === event.target.value
                        )
                        form.setFieldValue('state', selectedState || null)
                      }}
                      value={field.value ? field.value.id : ''}
                      style={{
                        marginTop: 0,
                        border: '1px solid #C9C9C9',
                        borderRadius: '6px',
                        height: '48px'
                      }}
                    >
                      {states &&
                        states.map(state => (
                          <option
                            key={state.id}
                            style={{ color: 'black' }}
                            value={state.id}
                          >
                            {state.display}
                          </option>
                        ))}
                    </Select>
                  )}
                </FastField>

                {errors.state && touched.state && (
                  <Text
                    sx={{
                      textAlign: 'left',
                      width: '100%',
                      display: 'block',
                      paddingLeft: '16px',
                      fontSize: '12px'
                    }}
                    color="error"
                    style={{ marginTop: 0 }}
                  >
                    State is required
                  </Text>
                )}
              </Box>

              {signupError && <Text color="error">{signupError}</Text>}
              <Box style={{ marginTop: 0 }} w="100%">
                {isError && (
                  <div
                    className="errorMessage"
                    role="alert"
                    style={{ paddingBottom: 10 }}
                  >
                    {isError}
                  </div>
                )}
                <Button
                  size="lg"
                  isFullWidth
                  isLoading={isSubmitting}
                  margin={0}
                  type="submit"
                >
                  Create account
                </Button>
              </Box>
              <Text style={{ marginTop: 0 }} fontSize="md">
                By signing up, you agree to our{' '}
                <Link
                  sx={{ color: 'primary' }}
                  href={config.privacyPolicyURL}
                  rel="noopener noreferrer"
                  isExternal
                >
                  Privacy Policy,
                </Link>{' '}
                <Link
                  sx={{ color: 'primary' }}
                  href={config.termsAndConditionsURL}
                  rel="noopener noreferrer"
                  isExternal
                >
                  Terms
                </Link>
                {', and '}
                <Link
                  sx={{ color: 'primary' }}
                  href={config.baaURL}
                  rel="noopener noreferrer"
                  isExternal
                >
                  BAA.
                </Link>
              </Text>
            </VStack>
          </Form>
        )}
      </Formik>
    </div>
  )
}
