import React from 'react'
import { Text, Flex, Box, Heading, VStack, Divider } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import {
  Button,
  NewTabIcon,
  IconButton,
  CloseIcon,
  InterventionIcon
} from '@blueprinthq/joy'
import { useStoreActions } from 'easy-peasy'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import { 
  useSessionControllerStartInterventions, 
  useSessionControllerGetStartedInterventions,
  useSessionControllerDeleteIntervention,
} from '~/clinician-api'

import { endpoints } from '@api'

import { InterventionRecommendations } from './intervention-recommendations-sessions'

interface DeliverInterventionsProps {
  clientId: string
  sessionId: string
}

export const DeliverInterventions = ({
  clientId,
  sessionId
}: DeliverInterventionsProps) => {
  // @ts-ignore
  const openInterventionListModal = useStoreActions(
    // @ts-ignore
    actions => actions.modals.interventionList.openModal
  )
  const queryClient = useQueryClient()

  const {
    data: interventions,
    refetch: refetchInterventions
  } = useSessionControllerGetStartedInterventions(sessionId)

  const {
    mutate: startInterventions
  } = useSessionControllerStartInterventions({
    mutation: {
      onSuccess() {
        refetchInterventions()
      }
    }
  })

  const {
    mutate: deleteClientIntervention
  } = useSessionControllerDeleteIntervention({
    mutation: {
      onSuccess() {
        refetchInterventions()
      }
    }
  })

  const removeIntervention = (i: any, id: string) => {
    deleteClientIntervention({
      patientInterventionId: i.id,
      id: sessionId,
    })
    readdInterventionRecommendation({
      clientId,
      interventionId: i.intervention.id
    })
  }

  const {
    mutate: readdInterventionRecommendation
  }: { mutate: Function } = useMutation(
    // @ts-ignore
    endpoints.readdInterventionRecommendation.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          endpoints.getInterventionRecommendations.getCacheId()
        )
      }
    }
  )

  const _openModal = () => {
    openInterventionListModal({
      clientId,
      selectedInterventionIds: interventions?.map((i: any) => i.intervention.id),
      // @ts-ignore
      onAssign: interventionIds => {
        // Interventions to deliver
        startInterventions({
          id: sessionId,
          data: {
            interventionIds,
            patientId: clientId,
          }
        })
      }
    })
  }

  return (
    <Box w="100%">
      <Text mt="xxsmall" color="gray">
        Tell us which intervention(s) you completed in session today.
      </Text>

      <Box my="small">
        {interventions?.length ? (
          <Box border="1px solid" borderColor="pale_gray" borderRadius="8px">
            <VStack
              w="100%"
              divider={<Divider color="pale_gray" />}
              spacing="none"
            >
              {interventions?.map(i => (
                <Flex
                  w="100%"
                  align="center"
                  justify="space-between"
                  py="small"
                  px="small"
                  key={i.id}
                >
                  <Flex flex={1} align="center">
                    <Box mr="small" bg="pale_gray" p="3px" borderRadius="50%">
                      <Flex
                        align="center"
                        justify="center"
                        width="24px"
                        height="24px"
                        borderRadius="50%"
                      >
                        <InterventionIcon />
                      </Flex>
                    </Box>
                    <Text>{i.intervention.name}</Text>
                  </Flex>
                  <Flex
                    alignItems="center"
                    justify="flex-end"
                    w="200px"
                    ml="large"
                  >
                    <Box mr="small">
                      {i.intervention.isGuided ? (
                        i.isComplete ? (
                          <Link
                            target="_blank"
                            to={{
                              pathname: `/patient/${clientId}/interventions/${i.intervention.id}/form`,
                              search: queryString.stringify({
                                clientInterventionId: i.id
                              })
                            }}
                          >
                            <Button
                              m="0px"
                              variant="outline"
                              rightIcon={<NewTabIcon size="sm" />}
                            >
                              View Completed
                            </Button>
                          </Link>
                        ) : (
                          <Link
                            target="_blank"
                            to={{
                              pathname: `/patient/${clientId}/interventions/${i.intervention.id}/form`,
                              search: queryString.stringify({
                                clientInterventionId: i.id
                              })
                            }}
                          >
                            <Button
                              m="0px"
                              rightIcon={<NewTabIcon fill="white" size="sm" />}
                            >
                              Start
                            </Button>
                          </Link>
                        )
                      ) : null}
                    </Box>
                    {/* Do not show x for completed interventions */}
                    {((i.intervention.isGuided && !i.isComplete) ||
                      !i.intervention.isGuided) && (
                      <IconButton
                        variant="ghost"
                        size="small"
                        aria-label="close"
                        icon={<CloseIcon />}
                        onClick={() => removeIntervention(i, clientId)}
                      />
                    )}
                  </Flex>
                </Flex>
              ))}
            </VStack>
          </Box>
        ) : (
          <Box
            w="100%"
            p="small"
            borderRadius="8px"
            border="1px solid"
            borderColor="pale_gray"
          >
            <Text color="dark_gray">No interventions planned for today</Text>
          </Box>
        )}
      </Box>

      <Button variant="link" color="primary" onClick={_openModal}>
        Add Intervention
      </Button>

      <InterventionRecommendations
        clientId={clientId}
        sessionId={sessionId}
        startInterventions={startInterventions}
      />
    </Box>
  )
}
