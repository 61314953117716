import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { find, uniqBy, filter, orderBy, times, set, groupBy, map } from 'lodash'
import { HEX_CODES } from '../../../../../../constants'
import EmptyGraph from '../empty-graph'
import ChartInfo from '../chart-info'
import {
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  Label
} from 'recharts'
import Paper from '@material-ui/core/Paper'
import styles from './outcome-measures-section-styles'
import { withStyles } from '@material-ui/core/styles'
import {
  Text,
  Tooltip as ChakraTooltip,
  Box,
  Link,
  Stack,
  Button
} from '@chakra-ui/react'
import { useLocation, useHistory, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { useStoreActions } from 'easy-peasy'
import BlurSection from '../blur-section'
import {
  dedupeAssessmentScores,
  capitalizeFirstLetter,
  formatScoreToInterpretation,
  copyToClipboard
} from '@utilities'
import PillTabs from '@components/pill-tabs'
import { DateTime } from 'luxon'

const OutcomeMeasuresSection = ({ allAssessmentScores, classes, patient }) => {
  const [section, setSection] = useState('-1')
  const { openModal } = useStoreActions(
    actions => actions.modals.assessmentResult
  )

  const setSnackbarMessage = useStoreActions(
    actions => actions.snackbar.setMessage
  )

  const modalElement = useRef()

  const location = useLocation()
  const history = useHistory()

  const queryParams = useMemo(() => queryString.parse(location.search), [
    location.search
  ])

  const handleModal = useCallback(
    (assessment_id, score_id) => {
      openModal({
        clientId: patient.id,
        preselectedAssessmentId: assessment_id,
        preselectedAssessmentScoreId: score_id
      })

      window.analytics.track('View All Modal Opened - progress', {
        platform: 'clinician_app'
      })
    },
    [modalElement]
  )

  const { baselineScores, scoresGroupedByDay } = useMemo(() => {
    const assessmentScores = allAssessmentScores.map(dedupeAssessmentScores)
    return {
      baselineScores: assessmentScores.map(score => score.scores[0]),
      scoresGroupedByDay: assessmentScores
    }
  }, [allAssessmentScores])

  const [checked, setChecked] = useState(scoresGroupedByDay)
  const [masterChecked, setMasterChecked] = useState(scoresGroupedByDay)
  const [loading, setLoading] = useState(false)

  const handleChartClick = useCallback(
    e => {
      if (e) {
        const payload = e.activePayload

        // Get day from tooltip payload
        let date = payload[0].payload.day

        // Get click coordinates from tooltip payload
        let estimated_score = 1 - e.chartY / 315

        // Compare estimated score with actual scores in payload and grab the assessment name of closest match
        let closest_distance = 999
        let closest_name = ''
        for (let i = 0; i < payload.length; i++) {
          let value = payload[i].value
          let new_closest
          if (
            value !== undefined &&
            (new_closest = Math.abs(estimated_score - value)) < closest_distance
          ) {
            closest_distance = new_closest
            closest_name = payload[i].name
          }
        }

        // Find match between assessment name in state and assessment name from payload
        const assessment_object = find(scoresGroupedByDay, function(o) {
          return o.assessment_id === closest_name.trim()
        })

        // Match the date from payload with the assessment dates
        const payload_scores = find(assessment_object.scores, function(o) {
          return o.day === date
        })

        // Fetch scores id
        const scores_id = payload_scores.id
        const assessment_id = payload_scores.assessment_id

        handleModal(assessment_id, scores_id)
      }
    },
    [scoresGroupedByDay, handleModal]
  )

  useEffect(() => {
    if (queryParams.view_outcome_measure !== null) {
      for (let i = 0; i < scoresGroupedByDay.length; i++) {
        const scoreDay = scoresGroupedByDay[i]
        for (let j = 0; j < scoreDay.scores.length; j++) {
          const score = scoreDay.scores[j]
          if (score.id === queryParams.view_outcome_measure) {
            handleModal(score.assessment_id, score.id)
            break
          }
        }
      }
    }
  }, [modalElement, queryParams, scoresGroupedByDay, handleModal])

  const generateProgressNoteCopy = useCallback(() => {
    // We only want the latest "batch" of scores, we use this to keep track of
    // the most recent day with assessment scores. The data is preset far
    // in the past so we dont screw up the data as the default is now()
    let latestDayWithScores = new Date(2000, 0, 1, 0, 0, 0, 0)

    // grab the neccessary data to generate progress notes for the latest
    // score of each assessment
    const latestScoreDataByAssessment = scoresGroupedByDay.map(assessment => {
      const latestScore = assessment.scores.at(-1)
      const latestScoreDay = new Date(latestScore.day)
      if (latestScoreDay.getTime() > latestDayWithScores.getTime())
        latestDayWithScores = latestScoreDay

      // we should be able to leverage the pointChange data to create this info but
      // pointChange is unrelaible when an assessment has 2+ completions in 1 day
      const baselineScore = assessment.scores.at(0)
      const lastScore = assessment.scores.at(-2)

      return {
        assessmentName: assessment.full_name,
        assessmentShortHand: assessment.short_name,
        domain: assessment.disorder,
        day: new Date(latestScore.day),
        // assessment.score.day as returned from the server is UTC;
        // it should stay that way so the grouping by day calculations are consistent.
        // However we need a browser-local version of the day for the progress note.
        dayInLocalTime: moment(latestScore.created_at).format('ddd MMM D YYYY'),
        // scores need to be rounded
        score: Math.round(latestScore.total_score * 100) / 100,
        baselineScore:
          baselineScore && Math.round(baselineScore.total_score * 100) / 100,
        lastScore: lastScore && Math.round(lastScore.total_score * 100) / 100,
        interpretation: formatScoreToInterpretation(
          assessment,
          latestScore.total_score
        ),
        isMultiple: latestScore.additional_data.length > 1,
        isBaseline: assessment.scores.length === 1
      }
    })

    // now we take out assessments WITHOUT scores on the latest day with scores
    const latestScoreDataOnLatestDayWithScores = latestScoreDataByAssessment.filter(
      assessment => {
        return assessment.day.getTime() === latestDayWithScores.getTime()
      }
    )

    // build progress notes, seperated into paragraphs
    const progressNotes = latestScoreDataOnLatestDayWithScores
      .map(noteData => buildProgressNote(noteData))
      .join('\n\n')

    return progressNotes
  }, [scoresGroupedByDay])

  const decreaseOrIncrease = (baseScore, otherScore) =>
    baseScore === otherScore
      ? 'no change in score'
      : baseScore > otherScore
      ? `an increase from a score of ${otherScore}`
      : `a decrease from a score of ${otherScore}`

  const buildProgressNote = data => {
    let note =
      `${data.assessmentName} (${data.assessmentShortHand}) was completed ${
        data.isMultiple ? 'multiple times ' : ''
      }on ${data.dayInLocalTime}. ` +
      `${data.isMultiple ? 'An average score' : 'A score'} of ${
        data.score
      } on the ${data.assessmentShortHand} indicates a ${
        data.interpretation
      } ` +
      `response with respect to ${data.domain}`

    if (!data.isBaseline) {
      note +=
        `, ${decreaseOrIncrease(
          data.score,
          data.lastScore
        )} since last assessment and ` +
        `${decreaseOrIncrease(data.score, data.baselineScore)} since baseline.`
    } else {
      note += '.'
    }

    return note
  }

  const handleCopyResults = useCallback(
    async e => {
      e.preventDefault()

      // for some reason clipboard.writeText() was returning undefined (despite working)
      // so i couldnt leverage async/await, this should do tho
      try {
        copyToClipboard(generateProgressNoteCopy())
        setSnackbarMessage({
          variant: 'success',
          message: 'Latest Assessment results copied to clipboard!'
        })
      } catch (e) {
        setSnackbarMessage({
          variant: 'error',
          message:
            "We're sorry, something went wrong, please refresh and try again"
        })
      }
    },
    [generateProgressNoteCopy]
  )
  const updateChart = (data, id) => {
    setLoading(true)
    if (data) {
      const indexM = masterChecked.findIndex(item => item.assessment_id === id)
      masterChecked[indexM].removed = false
      const index = checked.findIndex(item => item.assessment_id === id)
      checked[index].removed = false
      setTimeout(() => {
        setLoading(false)
      }, 2)
    } else {
      const indexM = masterChecked.findIndex(item => item.assessment_id === id)
      masterChecked[indexM].removed = true
      const index = checked.findIndex(item => item.assessment_id === id)
      checked[index].removed = true
      setTimeout(() => {
        setLoading(false)
      }, 2)
    }
  }

  const deselect = () => {
    const nonSelected = checked.map(item => ({ ...item, removed: true }))
    const nonSelectedMaster = masterChecked.map(item => ({
      ...item,
      removed: true
    }))
    setMasterChecked(nonSelectedMaster)
    setChecked(nonSelected)
    setTimeout(() => {
      setLoading(false)
    }, 2)
  }

  const selectAll = () => {
    const selected = checked.map(item => ({ ...item, removed: false }))
    const selectedMaster = masterChecked.map(item => ({
      ...item,
      removed: false
    }))
    setMasterChecked(selectedMaster)
    setChecked(selected)
    setTimeout(() => {
      setLoading(false)
    }, 2)
  }
  function filterByDate(inputDateString) {
    const inputDate = moment(inputDateString)
    const now = moment()

    return scoresGroupedByDay
      .map(user => {
        return {
          ...user,
          scores: user.scores.filter(score => {
            const scoreDate = moment(score.created_at)
            return (
              scoreDate.isSameOrAfter(inputDate) &&
              scoreDate.isSameOrBefore(now)
            )
          })
        }
      })
      .filter(user => user.scores.length > 0)
  }

  const filterData = timescale => {
    if (!scoresGroupedByDay) return []

    const date =
      parseInt(timescale.value, 10) < 0
        ? moment(patient.created_at)
        : moment().subtract(parseInt(timescale.value, 10), timescale.unit)
    return filterByDate(date)
  }
  const tabs = [
    {
      value: '1',
      unit: 'months',
      display: '1 month'
    },
    {
      value: '2',
      unit: 'months',
      display: '2 months'
    },
    {
      value: '3',
      unit: 'months',
      display: '3 months'
    },
    {
      value: '6',
      unit: 'months',
      display: '6 months'
    },
    {
      value: '12',
      unit: 'months',
      display: '1 year'
    },
    {
      value: '-1',
      unit: 'alltime',
      display: 'All Time'
    }
  ]

  function mergeWithRetention(oldArray, newArray) {
    return newArray.map(newItem => {
      const oldItem = oldArray.find(
        item => item.assessment_id === newItem.assessment_id
      )

      if (oldItem && oldItem.hasOwnProperty('removed')) {
        newItem.removed = oldItem.removed
      }

      return newItem
    })
  }

  const handleTimeScaleChange = timescale => {
    setSection(timescale)
    const filteredTabs = tabs.filter(e => e.value === timescale)
    setLoading(true)
    const filteredData = filterData(filteredTabs[0])
    const mergedData = mergeWithRetention(masterChecked, filteredData)
    setTimeout(() => {
      setChecked(mergedData)
      setLoading(false)
    }, 10)
  }

  useEffect(() => {
    // The 'listen' method allows you to listen for changes to the current location.
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        window.location.reload()
      }
    })

    // Cleanup on unmount.
    return () => {
      unlisten()
    }
  }, [history])

  return (
    <div
      id="outcome-measures-section"
      className={`${classes.section} summary-section`}
      style={{
        borderRadius: '8px',
        border: '1px solid #E4E5E6',
        padding: '24px 32px 24px 32px',
        height: '100%'
      }}
    >
      <Stack mb={'16px'} flex="1">
        <Stack
          justify={{
            base: 'start',
            md: 'start'
          }}
          direction={{
            base: 'row',
            md: 'row'
          }}
        >
          <Box w={{ base: '35%', md: '55%' }}>
            <Text textStyle="lg" fontWeight="bold">
              Assessments
            </Text>
          </Box>
          <Box
            className="copy_latest_progress_note_container"
            w={{ base: '50%', md: '45%' }}
          >
            {scoresGroupedByDay.length > 0 && (
              <Link
                className="copy_latest_progress_note_link"
                textAlign={'end'}
                onClick={e => {
                  handleCopyResults(e)
                }}
                color={'#2D54E8'}
              >
                <Text fontSize={'16px'} fontWeight={'430'}>
                  Copy latest Assessment results
                </Text>
              </Link>
            )}
          </Box>
          <Box w={{ base: '15%', md: '15%' }}>
            {scoresGroupedByDay.length > 0 && (
              <Link
                className="progress_note_view_all_link"
                textAlign={'end'}
                onClick={() => {
                  handleModal()
                }}
                color={'#2D54E8'}
              >
                <Text fontSize={'16px'} fontWeight={'430'}>
                  View all
                </Text>
              </Link>
            )}
          </Box>
        </Stack>
        <Stack
          direction={{
            base: 'row',
            md: 'row'
          }}
        >
          <Box w={'100%'}>
            <Text fontSize={'16px'} fontWeight={'430'}>
              Generated from assigned weekly assessments/symptom rating scales.
            </Text>
          </Box>
        </Stack>
      </Stack>
      {scoresGroupedByDay.length > 0 ? (
        <>
          <Grid item sm={12}>
            <Grid container>
              <PillTabs
                tabs={tabs}
                selected={section}
                onChange={e => handleTimeScaleChange(e)}
              />
            </Grid>
            <Grid item style={{ marginBottom: '-1%' }} sm={12}>
              <Chart
                data={checked}
                patient={patient}
                renderLines={renderLines}
                isPdf={false}
                handleClick={handleChartClick}
                shortChart={false}
                loading={loading}
              />
            </Grid>
            <Grid item sm={12}>
              <ChartInfo
                checked={checked}
                deselect={() => deselect()}
                selectAll={() => selectAll()}
                changeChecked={(e, id) => updateChart(e, id)}
                client={patient}
                data={checked}
                baseline_scores={baselineScores}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <BlurSection
          patient={patient}
          sectionName="outcomeMeasures"
          copy={`${patient.first_name} has not completed any assessments`}
          action={() =>
            history.push(`${location.pathname}/settings/outcome_measures`)
          }
          buttonTitle={`View ${patient.first_name}'s assessments`}
        />
      )}
    </div>
  )
}

function Chart(props) {
  const { data, patient, handleClick } = props

  const pointsByUnixDay = useMemo(() => {
    const _points = []
    data.forEach((assessment, i) => {
      assessment.scores.forEach(score => {
        const unix = parseInt(
          DateTime.fromISO(score.created_at)
            .startOf('day')
            .toFormat('X')
        )
        const maxScore =
          assessment.max_score === 0 ? 0.01 : assessment.max_score
        _points.push({
          // X Axis
          created_at_unix: unix,
          // Y Axis + line rendering
          value: score.total_score / maxScore, // standardize score,
          score,
          assessment
        })
      })
    })

    const groupedPoints = groupBy(_points, 'created_at_unix')

    return groupedPoints
  }, [data])

  const groupedXAxisPoints = useMemo(() => {
    const resultArray = map(pointsByUnixDay, (group, created_at_unix) => {
      const obj = {
        created_at_unix: parseInt(created_at_unix),
        day: group[0].score.day
      }

      group.forEach(p => {
        obj[p.score.assessment_id] = p.value
      })

      return obj
    })

    return resultArray
  }, [pointsByUnixDay])

  if (!groupedXAxisPoints || !groupedXAxisPoints.length) {
    return <EmptyGraph variant={'tall'} />
  }

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart data={groupedXAxisPoints} onClick={handleClick}>
        <XAxis
          tickFormatter={formatDateTimeFromUnix}
          padding={{ left: 20, right: 10 }}
          dataKey="created_at_unix"
          type="number"
          domain={['dataMin', () => moment().unix()]}
        />

        <YAxis
          width={30}
          ticks={[0, 0.25, 0.5, 0.75, 1]}
          tickFormatter={formatSeverityScore}
          padding={{ top: 20 }}
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          wrapperStyle={{ zIndex: 1 }}
          content={<CustomTooltipProgressWrapper data={pointsByUnixDay} />}
        />

        {patient.is_archived && (
          <ReferenceLine
            x={moment.utc(patient.archive_date).format('X')}
            strokeDasharray="3 3"
            label={
              <Label
                offset={0}
                position="insideTop"
                content={({ viewBox }) => (
                  <DischargeLabel
                    viewBox={viewBox}
                    dischargeDate={patient.archive_date}
                  />
                )}
              />
            }
          />
        )}
        {renderLines(data)}
      </LineChart>
    </ResponsiveContainer>
  )
}

function DischargeLabel({ viewBox, dischargeDate }) {
  // viewBox.x has the x-coordinate of the Discharge reference line. Subtract
  // half the width of the SVG to center above the line.
  const xAnchor = viewBox.x - 9
  const MAX_WIDTH = '248px'
  const MAX_HEIGHT = '114px'

  return (
    <ChakraTooltip
      backgroundColor="white"
      label={<DischargeTooltipContent dischargeDate={dischargeDate} />}
      placement="right"
      border="1px solid #C9C9C9"
      padding="medium"
      maxW={MAX_WIDTH}
      maxH={MAX_HEIGHT}
      color="#282828"
    >
      <svg
        width="18"
        height="22"
        viewBox="0 0 18 22"
        x={xAnchor}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14.93C0 15.62 0.35 16.23 0.88 16.59L9 22L17.11 16.59C17.64 16.23 17.99 15.62 17.99 14.93L18 2C18 0.9 17.1 0 16 0ZM7 15L2 10L3.41 8.59L7 12.17L14.59 4.58L16 6L7 15Z"
          fill="#282828"
        />
      </svg>
    </ChakraTooltip>
  )
}

function DischargeTooltipContent({ dischargeDate }) {
  const formattedDischargeDate = moment.utc(dischargeDate).format('MM/DD/YYYY')

  return (
    <>
      <Text fontWeight="bold">Discharged</Text>
      <Text>This client was discharged on {formattedDischargeDate}</Text>
    </>
  )
}

function generateAssigneeUserName(assignee_user) {
  return (
    assignee_user &&
    `${assignee_user.info.firstName} ${
      assignee_user.info.lastName
    } (${capitalizeFirstLetter(
      assignee_user.type === 'patient' ? 'client' : assignee_user.type
    )})`
  )
}

function CustomTooltipProgress(props) {
  const { active, payload, label, data } = props
  if (active && payload.length && label) {
    return (
      <Paper className="tooltip" elevation={1}>
        <h5 className="date">{formatDateTimeFromUnix(label)}</h5>
        {payload.map((p, index) => {
          const scoreGroup = data[p.payload.created_at_unix]
          const val = scoreGroup.find(s => s.score.assessment_id === p.dataKey)

          if (!val) {
            return null
          }

          const raw_value = Math.round(val.score.total_score)
          const display_name = val.score.name
          const assessment_id = val.score.assessment_id
          const domain = val.assessment.disorder
          const max_score = val.assessment.max_score
          const tooltip_data = val.score.additional_data
          const is_reverse_valence = val.assessment.is_reverse_valence

          return (
            <React.Fragment key={index}>
              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span
                  style={{ backgroundColor: p.stroke }}
                  className="dot"
                ></span>
                <span
                  style={{ fontSize: '16px' }}
                >{`${domain} (${display_name})`}</span>
              </div>
              {tooltip_data.map((tt, index) => {
                return (
                  <div key={index}>
                    <div style={{ paddingLeft: '14px' }}>
                      <span style={{ color: '#282828', fontSize: '12px' }}>
                        {`${formatScoreToInterpretationWrapper(
                          data,
                          assessment_id,
                          tt.score || raw_value
                        )} (${tt.score} out of ${max_score})`}
                      </span>
                    </div>
                    <div>
                      <span style={{ paddingLeft: '14px', fontSize: '12px' }}>
                        <PointChangeIndicator
                          value={tt.point_change.sinceLast}
                          isOpposite={is_reverse_valence}
                        />
                        {' since last | '}
                        <PointChangeIndicator
                          value={tt.point_change.sinceBaseline}
                          isOpposite={is_reverse_valence}
                        />
                        {' since baseline'}
                      </span>
                    </div>
                    <div style={{ paddingLeft: '14px' }}>
                      <span style={{ color: '#757575', fontSize: '12px' }}>
                        {generateAssigneeUserName(tt.assignee_user)}
                      </span>
                    </div>
                    {tooltip_data.length > 1 &&
                      index !== tooltip_data.length - 1 && (
                        <div
                          style={{
                            marginTop: '10px',
                            marginBottom: '8px',
                            borderBottom: '1px solid #c7c7c7'
                          }}
                        />
                      )}
                  </div>
                )
              })}
            </React.Fragment>
          )
        })}
      </Paper>
    )
  } else {
    return null
  }
}

function getPointChangeColor(value, isOpposite) {
  if (!value) return 'black'

  if (isOpposite) {
    return value < 0 ? 'pink' : 'success'
  }

  return value < 0 ? 'success' : 'pink'
}

function getPointChangeText(value, isOpposite) {
  if (!value) return '-'

  if (isOpposite) {
    return value < 0 ? `↓ ${Math.abs(value)} pts` : `↑ ${Math.abs(value)} pts`
  }

  return value < 0 ? `↓ ${Math.abs(value)} pts` : `↑ ${Math.abs(value)} pts`
}

const PointChangeIndicator = ({ value, isOpposite }) => {
  return (
    <Text
      as="span"
      fontSize="sm"
      color={getPointChangeColor(value, isOpposite)}
    >
      {getPointChangeText(value, isOpposite)}
    </Text>
  )
}

const CustomTooltipProgressWrapper = withStyles(styles)(CustomTooltipProgress)

function renderLines(data) {
  if (!data || !data.length) {
    return null
  }

  return data.map((assessment_group, index) => {
    return (
      (!assessment_group.removed && (
        <Line
          key={assessment_group.assessment_id}
          type="monotone"
          dataKey={assessment_group.assessment_id}
          strokeWidth="2"
          stroke={HEX_CODES[index]}
          isAnimationActive={false}
          dot={{
            strokeDasharray: '0',
            stroke: HEX_CODES[index],
            strokeWidth: 6,
            r: 0.5
          }}
          connectNulls
        />
      )) ||
      null
    )
  })
}

function formatScoreToInterpretationWrapper(assessments, assessment_id, score) {
  const assessment = find(assessments, { assessment_id: assessment_id })
  if (assessment) return formatScoreToInterpretation(assessment, score)
  else return ''
}

function formatSeverityScore(val) {
  switch (val) {
    case 0:
      return 'Low'
    case 1:
      return 'High'
    default:
      return ''
  }
}

function formatDateTimeFromUnix(unix) {
  return moment.unix(unix).format('MM-DD-YYYY') ===
    moment().format('MM-DD-YYYY')
    ? 'Today'
    : moment.unix(unix).format('ddd M/D')
}

export default withStyles(styles)(withRouter(OutcomeMeasuresSection))
