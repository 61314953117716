import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import {
  Box,
  VStack,
  Text,
  GridItem,
  InputGroup,
  InputLeftElement,
  Flex,
  Link,
  Input,
  Tooltip
} from '@chakra-ui/react'
import { Link as RLink } from 'react-router-dom'
import { LayoutGrid, SearchIcon } from '@blueprinthq/joy'
import { endpoints } from '@api'
import { useExperienceManager } from '@hooks'
import { useSessionControllerPostSession } from '~/clinician-api'
import { throttle } from 'lodash'
import { Loading } from '@components'
import { PatientOption } from './components/patient-option'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useComponentRenderTracker } from '@hooks'
import { useExtension } from '@hooks'
import { trackEvent } from '@lib/clinician-tracking'
import { CircleQuestion } from '@components/icons'
import { StoreModel } from 'src/store/types'

interface SelectClientProps {
  isDictation?: boolean
}

const SelectClient: React.FC<SelectClientProps> = ({ isDictation = false }) => {
  useComponentRenderTracker('Viewed Sessions Select Client Page')
  const [searchInput, setSearchInput] = useState('')
  const [isDefaultListLoading, setIsDefaultListLoading] = useState(true)
  const history = useHistory()
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const { mutateAsync: createSessionDraft } = useSessionControllerPostSession()
  const {
    isDocumentationAutomationEnabled,
    isExtension,
    isPlusPlanEnabled,
    isSessionRecordingV2Enabled,
    isWidget,
    isIntegratedUI,
    showRulaUI
  } = useExperienceManager()
  const { ehrClientNames, settings } = useExtension()

  const isInternalDemoOrg = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_IS_DEMO_CLIENTS_IN_START_SESSION
  )

  const textColor = isSessionRecordingV2Enabled ? '#282828' : 'white'
  const patientReference = settings?.patientReference?.toLowerCase() || 'client'

  useEffect(() => {
    const selectClient = (event: MessageEvent) => {
      handleClick(event.data.clientId, true)
    }

    window.addEventListener('message', event => {
      if (event.data.type === 'BP_SELECT_CLIENT') selectClient(event)
    })

    return () => {
      window.removeEventListener('message', selectClient)
    }
  }, [])

  const {
    data: preferredClientList,
    isLoading: isPreferredClientListLoading
  } = useQuery(
    [endpoints.getPreferredClientList.getCacheId(), ehrClientNames],
    () =>
      endpoints.getPreferredClientList.request({
        clinicianId: user!.id,
        preferredClientInfo: {
          clientNames: ehrClientNames
        }
      }),
    {
      enabled: !!user?.id,
      initialData: { clientsFromEHR: [], clientsWithSessionLastWeek: [] },
      select: data => ({
        clientsFromEHR: data.clientsFromEHR.filter(
          n => !n.isDemo || isInternalDemoOrg || showRulaUI
        ),
        clientsWithSessionLastWeek: data.clientsWithSessionLastWeek.filter(
          n =>
            (!n.isDemo || isInternalDemoOrg || showRulaUI) &&
            !data.clientsFromEHR.map(c => c.id).includes(n.id)
        )
      })
    }
  )

  const { data: defaultList } = useQuery(
    [endpoints.getClientList.getCacheId(), user?.id],
    () =>
      endpoints.getClientList.request({
        limit: 50,
        status: 'active',
        clinicianId: user!.id
      }),
    {
      enabled: !!user?.id,
      initialData: [],
      select: data => {
        return data
          .map(n => ({
            ...n,
            id: n.id,
            first_name: n.firstName,
            last_name: n.lastName,
            is_demo: n.isDemo,
            date_of_birth: n.dateOfBirth
          }))
          .filter(n => !n.isDemo || isInternalDemoOrg || showRulaUI)
      },
      onSuccess: data => {
        if (
          user &&
          (data.length === 0 || (data.length === 1 && data[0].isDemo)) &&
          user.organization.canEnrollClients && !showRulaUI 
        ) {
          history.push('/start-session/new-client?signup=true')
        }
        setIsDefaultListLoading(false)
      },
      onError: () => {
        setIsDefaultListLoading(false)
      }
    }
  )

  const { refetch, data: searchResults, isLoading: isSearchLoading } = useQuery(
    [
      endpoints.getPatientSearch.getCacheId(),
      'session_client_search',
      searchInput
    ],
    () =>
      endpoints.getPatientSearch.request({
        search: searchInput,
        include_discharged: false
      }),
    {
      enabled: false,
      initialData: [],
      select: data => {
        return data
          .map(p => ({
            ...p,
            id: p.id,
            first_name: p.first_name,
            last_name: p.last_name
          }))
          .filter(n => !n.is_demo || isInternalDemoOrg || showRulaUI)
      }
    }
  )

  useEffect(() => {
    if (searchInput.length >= 1) {
      throttledSearch()
    }
  }, [searchInput])

  const throttledSearch = throttle(() => refetch(), 150)

  const handleClick = async (
    patientId: string,
    includePartnerError?: boolean
  ): Promise<void> => {
    const sessionDraft = await createSessionDraft(
      {
        data: {
          patientId,
          isExtension,
          isChime: false,
          extensionInitiatedFrom: isExtension ? document.referrer : null,
          isDictation,
          resumeInProgress: true,
          isAssistEnabled: isPlusPlanEnabled
        }
      },
      {
        onError: () => {
          if (includePartnerError) {
            console.error(
              `Blueprint: selectClient failed. Please ensure client with id ${patientId} is valid.`
            )
          }
        }
      }
    )

    if (isExtension)
      return history.push(
        `/extension/patient/${patientId}/session/${sessionDraft.id}`
      )

    if (isWidget)
      return history.push(
        `/widget/patient/${patientId}/session/${sessionDraft.id}`
      )

    if (isDictation)
      return history.push(
        `/patient/${patientId}/dictate/session/${sessionDraft.id}`
      )

    history.push(`/patient/${patientId}/session/${sessionDraft.id}`)
  }

  if (isDefaultListLoading || isPreferredClientListLoading) {
    return (
      <Box h="70vh" color="white">
        <Loading />
      </Box>
    )
  }

  const patients = searchInput.length
    ? searchResults
    : defaultList?.filter(
        // don't show clients that are in the preferred client list
        p =>
          !preferredClientList?.clientsFromEHR.map(c => c.id).includes(p.id) &&
          !preferredClientList?.clientsWithSessionLastWeek
            .map(c => c.id)
            .includes(p.id)
      )

  const addClientLink = isDocumentationAutomationEnabled
    ? isDictation
      ? '/start-session/dictate/new-client'
      : '/start-session/new-client'
    : '/new-client'

  return (
    <LayoutGrid>
      <GridItem
        colSpan={{ base: 12, md: 6 }}
        colStart={{ base: 1, md: 4 }}
        p={{ base: 4, md: 0 }}
        {...(isIntegratedUI ? {} : { mb: '60px' })}
      >
        <>
          <Text
            fontSize={isSessionRecordingV2Enabled ? '24px' : '32px'}
            color={textColor}
            fontWeight={isSessionRecordingV2Enabled ? 'normal' : 'bold'}
            textAlign="center"
            mb={6}
          >
            {`Choose a ${patientReference} to ${
              isSessionRecordingV2Enabled ? 'start' : 'begin'
            } a session`}
          </Text>
          <Box>
            <InputGroup>
              <InputLeftElement
                h="100%"
                left={isSessionRecordingV2Enabled ? 2 : 4}
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input
                placeholder={`Search for a ${patientReference}...`}
                bg="white"
                h={['40px', '64px']}
                border={
                  isSessionRecordingV2Enabled
                    ? '1px solid #E4E5E6 !important'
                    : 'none'
                }
                onChange={e => setSearchInput(e.target.value)}
                pl={isSessionRecordingV2Enabled ? '64px' : 20}
              />
            </InputGroup>
          </Box>
          {isSearchLoading ? (
            <Box h="300px" color="white">
              <Loading />
            </Box>
          ) : (
            <>
              <VStack mt={[3, 6]} spacing={1} id="bp-extension-loaded">
                {preferredClientList?.clientsFromEHR.length &&
                  !searchInput.length && (
                    <>
                      <Flex alignSelf="flex-start">
                        <Text
                          alignSelf="flex-start"
                          color={textColor}
                          mr="xxsmall"
                        >
                          {`Suggested ${patientReference}s`}
                        </Text>
                        <Tooltip
                          hasArrow
                          label={`Based on recent ${patientReference}s you viewed in an EHR`}
                          maxW="200px"
                        >
                          <Flex alignItems="center">
                            <CircleQuestion fill="white" />
                          </Flex>
                        </Tooltip>
                      </Flex>
                      {preferredClientList?.clientsFromEHR.map((p, i) => (
                        <PatientOption
                          key={`${p.id}-${i}`}
                          firstName={p.firstName}
                          lastName={p.lastName}
                          onClick={() => {
                            trackEvent(
                              `Session -> Selected Client - Suggested Client ${i +
                                1}`
                            )
                            handleClick(p.id)
                          }}
                          dateOfBirth={p.dateOfBirth}
                          isDemo={p.isDemo}
                        />
                      ))}
                    </>
                  )}
                {preferredClientList?.clientsWithSessionLastWeek.length &&
                  !searchInput.length && (
                    <>
                      <Box />
                      <Flex alignSelf="flex-start">
                        <Text
                          alignSelf="flex-start"
                          color={textColor}
                          mr="xxsmall"
                        >
                          {`${settings?.patientReference ||
                            'Client'}s you met last week`}
                        </Text>
                        <Tooltip
                          hasArrow
                          label={`Based on ${patientReference}s you saw on this day last week`}
                          maxW="200px"
                        >
                          <Flex alignItems="center">
                            <CircleQuestion
                              fill={
                                isSessionRecordingV2Enabled
                                  ? '#282828'
                                  : 'white'
                              }
                            />
                          </Flex>
                        </Tooltip>
                      </Flex>
                      {preferredClientList?.clientsWithSessionLastWeek.map(
                        (p, i) => (
                          <PatientOption
                            key={`${p.id}-${i}`}
                            firstName={p.firstName}
                            lastName={p.lastName}
                            onClick={() => {
                              trackEvent(
                                'Session -> Selected Client - Session Last Week'
                              )
                              handleClick(p.id)
                            }}
                            dateOfBirth={p.dateOfBirth}
                            isDemo={p.isDemo}
                          />
                        )
                      )}
                    </>
                  )}
                {(preferredClientList?.clientsFromEHR.length ||
                  preferredClientList?.clientsWithSessionLastWeek.length) &&
                  !searchInput.length && (
                    <>
                      <Box />
                      <Text alignSelf="flex-start" color={textColor}>
                        {`All ${patientReference}s`}
                      </Text>
                    </>
                  )}
                {patients?.map((p, i) => (
                  <PatientOption
                    key={`${p.id}-${i}`}
                    firstName={p.first_name}
                    lastName={p.last_name}
                    onClick={() => {
                      trackEvent('Session -> Selected Client - All Clients')
                      handleClick(p.id)
                    }}
                    dateOfBirth={p.date_of_birth}
                    isDemo={p.is_demo}
                  />
                ))}
              </VStack>
              {user?.organization?.canEnrollClients && (
                <Flex py={6} color={textColor} justifyContent="center">
                  <Text>
                    {isSessionRecordingV2Enabled
                      ? `Someone missing? `
                      : `Missing a ${patientReference}? `}
                    <Link
                      as={RLink}
                      to={addClientLink}
                      color={
                        isSessionRecordingV2Enabled ? 'primary' : undefined
                      }
                      fontWeight={
                        isSessionRecordingV2Enabled ? 'bold' : undefined
                      }
                    >
                      {`Add a new ${patientReference}`}
                    </Link>
                  </Text>
                </Flex>
              )}
            </>
          )}
        </>
      </GridItem>
    </LayoutGrid>
  )
}

export default SelectClient
