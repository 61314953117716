import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const EmptyState = () => {
  return (
    <Box
      border="1px #E4E5E6 solid"
      h={{ base: '250px', md: '400px' }}
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      w="100%"
      justifyContent="center"
      alignItems="center"
    >
      <svg
        width="285"
        height="162"
        viewBox="0 0 285 162"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_4151_105609)">
          <path
            d="M224.445 109.96C223.391 111.149 221.921 111.888 220.496 112.59C219.505 111.275 218.008 110.437 216.692 109.437C215.375 108.438 214.131 107.068 214.059 105.42C214.05 105.222 214.068 105.006 214.167 104.834C214.284 104.654 214.492 104.546 214.69 104.465C215.186 104.267 215.727 104.177 216.196 103.934C216.664 103.69 217.079 103.213 217.025 102.681C216.971 102.15 216.484 101.79 216.06 101.465C215.231 100.844 214.474 100.132 213.788 99.3576C213.274 98.7721 212.806 97.8713 213.31 97.2858C213.951 96.5471 215.799 96.9074 216.61 97.1326C219.469 97.9433 220.659 100.691 222.669 102.6C222.768 102.709 222.868 102.808 222.976 102.898C223.877 103.717 224.959 104.438 225.446 105.555C226.086 107.014 225.509 108.78 224.445 109.96Z"
            fill="#CFDCEF"
          />
          <path
            d="M220.181 106.157C220.803 100.104 228.854 98.1673 229.08 91.8348C229.017 91.2312 229.431 90.5286 229.945 91.1051C232.199 94.1768 230.306 98.2124 229.071 101.284C230.36 100.374 235.13 97.771 234.129 101.239C233.209 104.239 229.134 104.977 229.215 108.4C229.413 110.706 228.313 111.958 226.754 112.472C223.436 113.562 220.145 110.814 220.127 107.319C220.127 106.923 220.145 106.536 220.181 106.157Z"
            fill="white"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M222.056 111.917C223.237 109.521 224.22 106.386 226.303 104.711"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M141.181 35.6918L154.867 15.9375L168.563 35.6918"
            fill="white"
          />
          <path
            d="M141.181 35.6918L154.867 15.9375L168.563 35.6918"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M184.982 32.0391H121.679V83.3211H184.982V32.0391Z"
            fill="white"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M179.635 37.5547H127.034V77.82H179.635V37.5547Z"
            fill="#F6F7FE"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M130.911 69.5061C138.62 64.6418 146.942 60.7414 155.607 57.9219C156.616 57.5976 157.419 58.7867 156.734 59.5974C155.345 61.2458 154.101 63.0204 153.01 64.876C152.559 65.6507 153.244 66.5965 154.119 66.4254L164.488 64.3806C165.281 64.2274 165.948 64.9931 165.678 65.7588C165.615 65.9389 165.561 66.1281 165.515 66.3173C165.317 67.173 166.192 67.8666 166.985 67.4973C169.708 66.2362 172.683 66.1281 174.351 67.3622"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M141.478 55.2214C147.384 51.9155 153.686 49.3393 160.223 47.5647C160.89 47.3845 161.377 48.1953 160.899 48.6997C160.89 48.7087 160.872 48.7267 160.863 48.7357C160.412 49.2132 160.818 49.9878 161.467 49.8887L163.73 49.5194C164.28 49.4293 164.704 49.9968 164.452 50.5013C164.425 50.5643 164.388 50.6274 164.361 50.6814C164.1 51.2309 164.641 51.8164 165.218 51.6093C167.202 50.9066 169.266 50.9877 170.393 51.9155"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M133.607 63.4649C140.856 58.5285 148.89 54.7542 157.32 52.3221C158.086 52.1059 158.798 52.7815 158.627 53.5562C158.528 54.0066 158.465 54.466 158.42 54.9254C158.357 55.664 159.042 56.2315 159.754 56.0334C160.719 55.7631 161.693 55.5469 162.684 55.3758C163.559 55.2226 164.235 56.1595 163.784 56.9251C163.784 56.9341 163.766 56.9522 163.766 56.9612C163.334 57.7088 163.965 58.6366 164.821 58.5105C166.724 58.2313 168.653 58.0781 170.583 58.0511C171.34 58.0421 171.854 58.8168 171.556 59.5104L170.988 60.8526C170.682 61.5642 171.241 62.3569 172.016 62.3029L173.891 62.1858"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M139.585 48.6886C141.686 48.6886 143.39 46.9867 143.39 44.8873C143.39 42.7879 141.686 41.0859 139.585 41.0859C137.484 41.0859 135.78 42.7879 135.78 44.8873C135.78 46.9867 137.484 48.6886 139.585 48.6886Z"
            fill="white"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M154.867 17.4257C155.689 17.4257 156.355 16.7603 156.355 15.9394C156.355 15.1186 155.689 14.4531 154.867 14.4531C154.046 14.4531 153.38 15.1186 153.38 15.9394C153.38 16.7603 154.046 17.4257 154.867 17.4257Z"
            fill="white"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M241.549 125.539H166.643V131.511H241.549V125.539Z"
            fill="white"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M191.176 156.487C191.176 142.696 196.955 131.508 204.096 131.508C211.228 131.508 217.016 142.687 217.016 156.487"
            fill="white"
          />
          <path
            d="M191.176 156.487C191.176 142.696 196.955 131.508 204.096 131.508C211.228 131.508 217.016 142.687 217.016 156.487"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M191.176 156.487C191.176 142.696 196.955 131.508 204.096 131.508C211.228 131.508 217.016 142.687 217.016 156.487"
            fill="white"
          />
          <path
            d="M191.176 156.487C191.176 142.696 196.955 131.508 204.096 131.508C211.228 131.508 217.016 142.687 217.016 156.487"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M230.531 125.538H216.187L214.032 111.062H232.686L230.531 125.538Z"
            fill="white"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M156.247 160.874L139.459 102.953H101.158L86.7048 160.874"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M148.34 133.469H46.826"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M71.9632 124.2H110.49L82.8548 61.7031H44.3285C40.6139 61.7031 38.9369 64.7118 40.5778 68.414L62.2708 117.48C63.9118 121.191 68.2486 124.191 71.9632 124.191V124.2Z"
            fill="white"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M79.0229 72.1163C81.5654 77.8723 87.0112 82.5294 91.1857 82.5294C95.3603 82.5294 96.6766 77.8633 94.134 72.1163C91.5915 66.3692 86.1457 61.7031 81.9712 61.7031C77.7967 61.7031 76.4803 66.3692 79.0229 72.1163Z"
            fill="#F6F7FE"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M88.2284 92.9522C90.771 98.7083 96.2168 103.365 100.391 103.365C104.566 103.365 105.882 98.6992 103.34 92.9522C100.797 87.2052 95.3513 82.5391 91.1767 82.5391C87.0022 82.5391 85.6859 87.2052 88.2284 92.9522Z"
            fill="#F6F7FE"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M97.443 113.78C99.9856 119.536 105.431 124.193 109.606 124.193C113.78 124.193 115.097 119.527 112.554 113.78C110.012 108.033 104.566 103.367 100.391 103.367C96.2168 103.367 94.9005 108.033 97.443 113.78Z"
            fill="#F6F7FE"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M68.5 112.5L109.462 123.649L82.3139 62.2422H47.8432L68.5 112.5Z"
            fill="#F6F7FE"
          />
          <path
            d="M54.598 82.9453C58.7725 82.9453 60.179 78.4683 57.7356 72.9375C55.2922 67.4066 49.9276 62.9297 45.7531 62.9297"
            fill="white"
          />
          <path
            d="M54.598 82.9453C58.7725 82.9453 60.179 78.4683 57.7356 72.9375C55.2922 67.4066 49.9276 62.9297 45.7531 62.9297"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M63.4429 102.953C67.6174 102.953 69.024 98.4761 66.5806 92.9453C64.1372 87.4144 58.7725 82.9375 54.598 82.9375"
            fill="white"
          />
          <path
            d="M63.4429 102.953C67.6174 102.953 69.024 98.4761 66.5806 92.9453C64.1372 87.4144 58.7725 82.9375 54.598 82.9375"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <ellipse
            cx="68.6302"
            cy="113.243"
            rx="5.38572"
            ry="8.78131"
            transform="rotate(-37.683 68.6302 113.243)"
            fill="white"
          />
          <path
            d="M75.4254 112.961C72.982 107.439 67.6174 102.953 63.4429 102.953Z"
            fill="white"
          />
          <path
            d="M75.4254 112.961C72.982 107.439 67.6174 102.953 63.4429 102.953"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M75.4346 124.205H147.645C150.197 124.205 152.271 122.133 152.271 119.584V117.98C152.271 115.431 150.197 113.359 147.645 113.359H75.4346"
            fill="white"
          />
          <path
            d="M75.4346 124.205H147.645C150.197 124.205 152.271 122.133 152.271 119.584V117.98C152.271 115.431 150.197 113.359 147.645 113.359H75.4346"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M108.975 160.874L94.5218 102.953H56.2119L39.4327 160.874"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
          <path
            d="M77.4451 82.5312H58.0242"
            stroke="#CFDCEF"
            stroke-miterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_4151_105609">
            <rect
              width="203"
              height="147"
              fill="white"
              transform="translate(39 14)"
            />
          </clipPath>
        </defs>
      </svg>
      <Text color="#A1A1A1" mt="14px">
        No sessions recorded
      </Text>
    </Box>
  )
}

export default EmptyState
