import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Container, Flex, Textarea } from '@chakra-ui/react'
import _ from 'lodash'
import { useSessionControllerUpdatePsychotherapyNote } from '~/clinician-api'

const PrivateNotes = ({ sessionId, psychotherapyNote, refresh, top }: any) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [text, setText] = useState<string>(
    psychotherapyNote ? psychotherapyNote : ''
  )
  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
    }
  }

  React.useEffect(resizeTextArea, [text])

  const { mutate: updateNote } = useSessionControllerUpdatePsychotherapyNote()

  useEffect(() => {
    return () => {
      refresh()
    }
  }, [])

  const _throttledUpdateNote = useMemo(
    () => _.throttle(updateNote, 750, { trailing: true }),
    []
  )

  const onUpdatePsychotherapyNote = (val: string) => {
    setText(val)

    _throttledUpdateNote({
      data: {
        note: val
      },
      id: sessionId
    })
  }
  return (
    <Container height={`calc(100vh - 141px)`} overflowY="scroll">
      <Flex flexDirection="column" my="16px" flex="1">
        <Flex bgColor="#FEFAF3">
          <Textarea
            ref={textAreaRef}
            value={text}
            placeholder="Add your notes here..."
            border="1px solid"
            borderColor="pale_gray"
            borderRadius="8px"
            minHeight="300px"
            overflowY="hidden"
            resize="none"
            _focus={{
              outline: 'none'
            }}
            onChange={e => onUpdatePsychotherapyNote(e.target.value)}
          />
        </Flex>
        <Flex pb="16px" />
      </Flex>
    </Container>
  )
}

export default PrivateNotes
