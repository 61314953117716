import React from 'react'

const AssistPanelEmptyCouch = () => (
  <svg width="299" height="114" viewBox="0 0 299 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4761_7100)">
      <path d="M284.863 81.0322H291.106V98.704C291.106 100.413 289.706 101.799 287.985 101.799C286.26 101.799 284.863 100.411 284.863 98.704V81.0322Z" fill="white" stroke="#161515" strokeMiterlimit="10"/>
      <path d="M284.863 91.4165H241.349V94.83H284.863V91.4165Z" fill="white" stroke="#161515" strokeMiterlimit="10"/>
      <path d="M235.752 81.0322H241.995V98.704C241.995 100.413 240.595 101.799 238.873 101.799C237.149 101.799 235.752 100.411 235.752 98.704V81.0322Z" fill="white" stroke="#161515" strokeMiterlimit="10"/>
      <path d="M298.349 50.3052H228.508V86.9854H298.349V50.3052Z" fill="#649EE0"/>
      <path d="M267.3 47.1509H235.626V50.1274H267.3V47.1509Z" fill="#A4BCE0"/>
      <path d="M74.4437 81.0317H80.6866V98.7035C80.6866 100.413 79.2866 101.798 77.5651 101.798C75.8411 101.798 74.4437 100.41 74.4437 98.7035V81.0317Z" fill="white" stroke="#161515" strokeMiterlimit="10"/>
      <path d="M90.2529 81.0317H96.4958V98.7035C96.4958 100.413 95.0958 101.798 93.3743 101.798C91.6503 101.798 90.2529 100.41 90.2529 98.7035V81.0317Z" fill="white" stroke="#161515" strokeMiterlimit="10"/>
      <path d="M181.063 81.0317H187.306V98.7035C187.306 100.413 185.906 101.798 184.185 101.798C182.46 101.798 181.063 100.41 181.063 98.7035V81.0317Z" fill="white" stroke="#161515" strokeMiterlimit="10"/>
      <path d="M196.875 81.0317H203.118V98.7035C203.118 100.413 201.718 101.798 199.996 101.798C198.272 101.798 196.875 100.41 196.875 98.7035V81.0317Z" fill="white" stroke="#161515" strokeMiterlimit="10"/>
      <path d="M13.013 75.6925C13.3474 77.5278 14.2859 79.0932 15.6755 80.3424C16.6296 81.1984 17.734 81.8796 18.8722 82.4656C19.624 82.8537 20.4018 83.1956 21.1873 83.5118C21.7136 83.7226 23.1992 83.9616 23.5414 84.3626C27.5988 89.1256 18.8618 94.7318 18.9862 100.636C18.9992 101.292 19.134 101.95 19.4477 102.61H30.9614C30.9302 102.466 30.8551 102.327 30.6736 102.209C30.2328 101.443 29.8621 100.713 29.551 100.022C23.6373 86.8611 38.5965 86.5141 35.3843 77.1088C32.2058 74.4201 29.704 71.351 30.0021 67.0507C30.1006 65.6395 30.3521 64.2412 30.2069 62.8249C30.1395 62.1823 30.0747 61.0281 29.5614 60.7403C29.3073 60.5963 28.1017 60.7146 27.7336 60.64C26.9532 60.4832 26.1806 60.2724 25.4184 60.0411C24.6692 59.8123 23.9199 59.5527 23.2718 59.1209C20.4822 57.2625 19.5877 53.178 17.5344 50.6281C15.3385 47.9009 12.1496 46.0605 8.71965 45.333C8.69372 45.3279 8.67039 45.3228 8.64447 45.3176C5.98707 44.7881 2.54414 45.7674 1.07933 48.1605C-0.587696 50.8801 0.843405 53.7281 2.82673 55.8127C4.93967 58.031 7.14855 58.8099 9.76446 60.0257C14.9289 62.4265 12.217 71.3253 13.0104 75.6925H13.013Z" fill="#161515"/>
      <path d="M62.9899 23.1501C62.1136 17.6108 66.4121 12.5985 72.0665 12.5599C102.091 12.3568 184.322 11.8376 201.664 12.1666C204.36 12.218 206.795 13.912 207.614 16.4567C207.936 17.4566 207.982 18.4899 207.508 19.4975C207.179 28.5429 205.716 37.6782 205.462 46.7159C207.018 47.2146 208.926 47.4485 210.318 48.451C213.059 49.6462 216.649 53.4453 217.373 57.2393C218.417 63.9533 218.41 74.0166 215.324 79.196C212.162 83.4604 206.484 82.9565 200.78 83.0208C168.837 83.0311 96.6934 82.972 67.8328 83.0208C67.0162 66.7088 63.6691 27.435 62.9899 23.1501Z" fill="#CFDCEF"/>
      <path d="M28.0082 60.4862C28.1301 62.7662 26.0093 67.2568 27.4845 70.3336C28.8949 73.2793 33.7119 76.5592 33.4397 80.0061C33.2374 82.5432 30.7382 84.5532 29.2086 86.3294C25.4312 90.712 25.0994 96.0868 27.7567 101.143C28.0627 101.721 28.4775 102.143 28.9804 102.449C29.0349 102.505 29.0893 102.559 29.1464 102.611H54.2814C54.6443 102.467 54.9995 102.302 55.3392 102.104C55.8136 101.69 56.2336 100.989 56.5966 100.251C56.9595 99.5106 57.2654 98.7344 57.5195 98.1637C58.108 96.8451 58.5773 95.475 58.9143 94.0716C59.6662 90.9356 59.7336 87.5195 58.3491 84.6021C57.4314 82.664 55.8888 80.8647 54.2684 79.4561C52.7751 78.1554 50.8903 77.1067 50.0607 75.2226C49.5033 73.9579 49.537 72.5133 49.7392 71.1458C49.9881 69.4391 50.154 67.722 50.3873 66.0127C50.657 64.0617 51.261 62.2521 50.8462 60.2292C50.2603 57.3631 48.0385 54.1629 45.6714 52.4073C43.8463 51.0553 41.7618 50.1376 39.9885 48.7367C37.8107 47.0146 36.8178 44.7346 35.5915 42.3312C34.3885 39.969 32.6023 37.743 30.3727 36.2675C28.4412 34.99 25.7112 34.2703 23.4297 34.9155C21.1638 35.5607 18.942 37.7481 18.7683 40.1283C18.6646 41.5652 19.1131 42.9995 19.8131 44.259C22.5483 49.1763 32.3767 53.8802 28.0082 60.4837V60.4862Z" fill="#91C95B"/>
      <path d="M71.2571 47.6313H89.247C97.297 47.6313 103.607 54.4893 102.874 62.4371L100.963 83.1086" stroke="white" strokeMiterlimit="10"/>
      <path d="M111.476 66.9863H196.784" stroke="white" strokeMiterlimit="10"/>
      <path d="M185.448 66.9275C178.272 65.4521 171.373 63.1593 164.749 60.0516C163.377 59.3987 162.454 57.602 162.732 56.1163C164.005 49.5514 166.128 43.2718 169.102 37.2724C169.784 35.9203 171.611 35.0387 173.102 35.3368C180.278 36.8123 187.177 39.1051 193.801 42.2128C195.173 42.8657 196.096 44.6624 195.818 46.1481C194.545 52.713 192.422 58.9926 189.448 64.992C188.767 66.3441 186.939 67.2257 185.448 66.9275Z" fill="white"/>
      <path d="M29.4758 44.1128C29.4758 44.1128 43.4912 60.2731 43.4912 102.613" stroke="white" strokeMiterlimit="10"/>
      <path d="M272.219 50.1271H244.693L250.513 31.2729H278.041L272.219 50.1271Z" fill="#CFDCEF"/>
      <path d="M266.613 40.7062C266.211 42.2125 264.661 43.4335 263.147 43.4335C261.633 43.4335 260.733 42.2125 261.135 40.7062C261.537 39.2 263.087 37.979 264.601 37.979C266.115 37.979 267.015 39.2 266.613 40.7062Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_4761_7100">
        <rect width="298" height="114" fill="white" transform="translate(0.347656)"/>
      </clipPath>
    </defs>
  </svg>
)

export default AssistPanelEmptyCouch

