import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Image,
  useBreakpointValue
} from '@chakra-ui/react'

type MicrophonePermissionsModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const MicrophonePermissionsModal: React.FC<MicrophonePermissionsModalProps> = ({
  isOpen,
  onClose
}) => {
  const PermissionsEnablementImage = () => (
    <Box width="100%" display="flex" justifyContent="center" maxWidth="352px" mx="auto">
      <Image src="https://res.cloudinary.com/hellojoy/image/upload/f_auto,q_auto/mic_permissions_browser" alt="Mic Permissions" />
    </Box>
  )

  const isMobile = useBreakpointValue({ base: true, sm: false })

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={isMobile ? "full" : "md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid #E4E5E6"
          textAlign="center"
          fontSize="20px"
          padding="16px"
        >
          Enable your microphone
        </ModalHeader>
        <ModalBody paddingLeft="48px" paddingRight="48px" display="flex" flexDirection="column" justifyContent="center" height="100%">
          <PermissionsEnablementImage />
          <Text fontSize="20px" fontWeight="430" lineHeight="24px" textAlign="center">
            Allow your browser to use your microphone so we can record audio
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button 
            width="100%" 
            variant="outline" 
            height="48px" 
            onClick={onClose}
            _focus={{
              boxShadow: 'none',
              outline: 'none',
            }}>
            Got it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
