import React from 'react'
import {
  Text,
  Link,
  Box,
  Flex,
  Container,
  GridItem,
  VStack,
  HStack
} from '@chakra-ui/react'
import { LayoutGrid, Button, NewTabIcon } from '@blueprinthq/joy'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useQuery } from 'react-query'
import { MarkdownRenderer } from '@blueprinthq/joy-business'
import { Link as RouterLink } from 'react-router-dom'

import { Loading } from '@components'
import { endpoints } from '@api'
import { snakeToCapital } from '@utilities'
import { ClientInterventionBox, HomeworkSection } from './components'

const TRIGGER_DISPLAY_MAP = {
  high_risk: 'High-Risk'
}

export function InterventionSummary() {
  const history = useHistory()

  let match = useRouteMatch(
    '/patient/:clientId/interventions/:interventionId/summary'
  )
  const searchParams = new URLSearchParams(history.location.search)
  const isPlus = searchParams.get('isPlus')
  const showPlusVersion = isPlus === 'true'

  const { isLoading: isClientLoading, data: client } = useQuery(
    [endpoints.getClinicianUserAccount.getCacheId(), match.params.clientId],
    () =>
      endpoints.getClinicianUserAccount.request({ id: match.params.clientId }),
    {
      onError: ({ error }) => {
        if (error.code === 401) {
          history.replace('/patients/active', {
            reason: "You do not have access to that client's profile"
          })
        }
        console.error(error.message) //TODO
      }
    }
  )

  const { data: intervention, isLoading: isInterventionLoading } = useQuery(
    [
      endpoints.getInterventionOverview.getCacheId(),
      {
        clientId: match.params.clientId,
        interventionId: match.params.interventionId
      }
    ],
    () =>
      endpoints.getInterventionOverview.request({
        clientId: match.params.clientId,
        interventionId: match.params.interventionId
      })
  )

  if (isClientLoading || isInterventionLoading) {
    return <Loading />
  }

  return (
    <Container>
      <Flex w="100%" h="100%" direction="column">
        {!showPlusVersion && (
          <Box my="small">
            <Text as="span" color="primary">
              ←{' '}
            </Text>
            <Link
              color="primary"
              textDecoration="underline"
              onClick={() => {
                if (
                  history.location.state &&
                  history.location.state.prevLocation
                ) {
                  history.goBack()
                } else {
                  history.push(`/patient/${match.params.clientId}`)
                }
              }}
            >
              Back to Profile
            </Link>
          </Box>
        )}
        <LayoutGrid w="100%" h="100%">
          <GridItem
            colStart={{
              base: 1,
              sm: 1,
              md: 4
            }}
            colEnd={{
              base: 5,
              sm: 9,
              md: 10
            }}
          >
            <Box w="100%" h="100%">
              {isInterventionLoading || isClientLoading ? (
                <Loading />
              ) : (
                <Box
                  id="pendo-intervention-summary-tag"
                  pb="xxlarge"
                  mb="xxlarge"
                >
                  {/* Commented out in case of future use */}
                  {/* <Box>
                    {intervention.submissions.map(ci => (
                      <ClientInterventionBox
                        key={ci.id}
                        clientIntervention={ci}
                        client={client}
                        intervention={intervention}
                      />
                    ))}
                  </Box> */}
                  <VStack align="left" spacing="xxsmall" pl="0" mb="medium">
                    <Text as="h1" fontWeight="bold" fontSize="xl" my="small">
                      {intervention.name}
                    </Text>
                    <HStack>
                      <Text fontWeight="bold">Type</Text>
                      <Text>{snakeToCapital(intervention.type)}</Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight="bold">Treatment Modality</Text>
                      <Text>
                        {snakeToCapital(intervention.treatment_modality)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight="bold">Population</Text>
                      <Text>
                        {intervention.population
                          .map(p => snakeToCapital(p))
                          .join(', ')}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontWeight="bold">Trigger</Text>
                      {intervention.triggers.length > 0 ? (
                        intervention.triggers.map((trigger, idx) => (
                          <Text key={idx}>
                            {TRIGGER_DISPLAY_MAP[trigger] || trigger}
                          </Text>
                        ))
                      ) : (
                        <Text>None</Text>
                      )}
                    </HStack>
                  </VStack>
                  <MarkdownRenderer>
                    {intervention.instructions}
                  </MarkdownRenderer>
                  {!showPlusVersion && (
                    <Box id="pendo-intervention-doc-snippet-tag">
                      <Text mt="small" fontWeight="bold">
                        Documentation Snippet
                      </Text>
                      <Text
                        p="small"
                        bg="rgba(45, 84, 232, 0.04)"
                        my="small"
                        borderRadius="4px"
                      >
                        {intervention.documentation_snippet}
                      </Text>
                    </Box>
                  )}
                  {intervention.homework.length > 0 && !showPlusVersion && (
                    <HomeworkSection
                      homeworkModules={intervention.homework}
                      client={client}
                      interventionId={match.params.interventionId}
                    />
                  )}
                </Box>
              )}
            </Box>
          </GridItem>
        </LayoutGrid>
        <Box
          position="fixed"
          bottom="0"
          left="0"
          w="100%"
          h="96px"
          bg="#ffffff"
          boxShadow="0 0 8px rgba(0, 0, 0, 0.08)"
        >
          <Flex h="100%" align="center" justify="center">
            {showPlusVersion ? (
              <>
                <Link
                  as={RouterLink}
                  to={{
                    pathname: `/patient/${match.params.clientId}/interventions/${match.params.interventionId}/form`,
                    search: `?isPlus=true&name=${encodeURIComponent(
                      intervention.name
                    )}`
                  }}
                  _hover={{
                    textDecoration: 'none'
                  }}
                >
                  <Button
                    id="pendo-intervention-start-activity-tag"
                    size="lg"
                    w={{ base: '350px', sm: '300px' }}
                  >
                    Start Activity
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Link
                  as={RouterLink}
                  to={{
                    pathname: `/patient/${match.params.clientId}/interventions/${match.params.interventionId}/form`
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  _hover={{
                    textDecoration: 'none'
                  }}
                >
                  <Button
                    id="pendo-intervention-start-activity-tag"
                    size="lg"
                    w={{ base: '350px', sm: '300px' }}
                  >
                    Start Activity
                    <Box ml="xsmall">
                      <NewTabIcon fill="white" size="sm" />
                    </Box>
                  </Button>
                </Link>
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </Container>
  )
}
