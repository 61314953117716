import React from 'react'

const PersonDictate = ({ width = '14', height = '14', fill = '#627FEE' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75004 2.91406C6.36888 2.91406 6.96237 3.15989 7.39996 3.59748C7.83754 4.03506 8.08337 4.62856 8.08337 5.2474C8.08337 5.86623 7.83754 6.45973 7.39996 6.89731C6.96237 7.3349 6.36888 7.58073 5.75004 7.58073C5.1312 7.58073 4.53771 7.3349 4.10013 6.89731C3.66254 6.45973 3.41671 5.86623 3.41671 5.2474C3.41671 4.62856 3.66254 4.03506 4.10013 3.59748C4.53771 3.15989 5.1312 2.91406 5.75004 2.91406ZM5.75004 8.7474C7.30754 8.7474 10.4167 9.52906 10.4167 11.0807V12.2474H1.08337V11.0807C1.08337 9.52906 4.19254 8.7474 5.75004 8.7474ZM10.2767 3.12406C11.455 4.4074 11.455 6.18656 10.2767 7.3649L9.29671 6.37906C9.78671 5.69073 9.78671 4.79823 9.29671 4.1099L10.2767 3.12406ZM12.2075 1.16406C14.5 3.52656 14.4825 7.06156 12.2075 9.33073L11.2567 8.3799C12.8725 6.5249 12.8725 3.87656 11.2567 2.1149L12.2075 1.16406Z"
        fill={fill}
      />
    </svg>
  )
}

export default PersonDictate
