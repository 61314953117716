import React, { useState, useRef, useEffect } from 'react'
import {
  Flex,
  Text,
  Button,
  useToast,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
  Link,
  useBreakpointValue,
  Container
} from '@chakra-ui/react'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { Link as RouterLink } from 'react-router-dom'
import { endpoints } from '@api'
import { useStoreState } from 'easy-peasy'
import {
  getSessionControllerGetProgressNotesForSessionQueryKey,
  useSessionControllerUploadNoteToEhr
} from '~/clinician-api'
import ProgressNoteFeedback from './progress-note-feedback-v2'
import { TrashIcon, MagicEditIcon, CopyNoteIcon } from '@icons'
import { useExperienceManager, useExtension } from '@hooks'
import { DeleteConfirmation } from '@components'
import { MagicEditDrawer } from './magic-edit-drawer'
import { StoreModel } from 'src/store/types'

interface SessionTranscriptResponse {
  sessionTranscript: {
    id: string
  } | null
}

export const ProgressNoteFooter = ({
  sessionId,
  handleCopyAllToClipboard,
  progressNotes,
  noteType,
  progressNoteId,
  selectedProgressNoteId,
  setSelectedProgressNoteId,
  openSettings
}: {
  sessionId: string
  handleCopyAllToClipboard: any
  progressNotes: any
  noteType: string
  progressNoteId: string
  setSelectedProgressNoteId: any
  selectedProgressNoteId: string
  openSettings: () => void
}) => {
  const [isMagicEditDrawerOpen, setIsMagicEditDrawerOpen] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  )
  const {
    isAdmin,
    isDocumentationAutomationEnabled,
    isExtension,
    isWidget,
    outboundEhrSettings
  } = useExperienceManager()
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const [organizationId, setOrganizationId] = useState(
    user?.clinic?.organization?.id
  )

  const { settings } = useExtension()

  const toast = useToast()
  const queryClient = useQueryClient()
  const buttonsHaveTextBreakpoint = useBreakpointValue({
    base: false,
    sm: true
  })
  let buttonSpacing = useBreakpointValue({ base: '0px', sm: '0px' })

  if (isWidget || isExtension) {
    buttonSpacing = '8px'
  }
  const [parentWidth, setParentWidth] = useState(0)
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateWidth = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth)
      }
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  const {
    mutateAsync: updloadNoteToEhr,
    isLoading: isUploadingNoteToEhr
  } = useSessionControllerUploadNoteToEhr()

  const handleCopyButtonClicked = async () => {
    if (outboundEhrSettings?.copyNoteButtonText) {
      updloadNoteToEhr(
        { id: sessionId, noteId: selectedProgressNoteId || progressNotes[0].id },
        {
          onSuccess: () => {
            toast({
              description: 'Note sent!',
              status: 'success',
              isClosable: true,
              duration: 2000
            })
          },
          onError: () => {
            toast({
              description: 'Error sending note.',
              status: 'error',
              isClosable: true,
              duration: 2000
            })
          }
        }
      )
    } else {
      handleCopyAllToClipboard({})
    }
  }

  const overrideButtonsHaveText = !parentRef?.current || parentWidth > 540
  const buttonsHaveText = buttonsHaveTextBreakpoint && overrideButtonsHaveText

  const overrideCopyNoteHasText = !parentRef?.current || parentWidth > 300
  const copyNoteHasText = buttonsHaveTextBreakpoint && overrideCopyNoteHasText

  const { data: sessionTranscriptData } = useQuery<SessionTranscriptResponse>(
    [endpoints.getSessionTranscript.getCacheId(), sessionId],
    () =>
      endpoints.getSessionTranscript.request({ sessionId }) as Promise<
        SessionTranscriptResponse
      >
  )

  const {
    data: progressNoteSettingsData,
    isLoading: progressNoteSettingsIsLoading
  } = useQuery<{
    transcriptsEnabled: boolean
    preferences: Record<string, any>
  }>(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () =>
      endpoints.getProgressNoteSettings.request({ organizationId }) as Promise<{
        transcriptsEnabled: boolean
        preferences: Record<string, any>
      }>
  )

  const {
    mutate: deleteSessionProgressNotes,
    isLoading: isDeletingSessionProgressNotes
  } = useMutation(endpoints.deleteSessionProgressNotes.request, {
    onSuccess: async () => {
      setIsDeleteConfirmationOpen(false)
      toast({
        description: 'Notes deleted!',
        status: 'success',
        isClosable: true,
        duration: 2000
      })
      await queryClient.invalidateQueries(
        getSessionControllerGetProgressNotesForSessionQueryKey(sessionId)
      )
    }
  })

  const transcriptsEnabled = progressNoteSettingsData?.transcriptsEnabled

  const isMagicEditEnabled =
    !!sessionTranscriptData?.sessionTranscript?.id &&
    isDocumentationAutomationEnabled

  const hasMultipleVersions = progressNotes?.length > 1

  const renderMagicEditPopoverBody = () => {
    if (transcriptsEnabled) {
      return ''
    }

    if (isAdmin) {
      return (
        <Flex>
          <Text>
            Turn on transcript retention in{' '}
            <Link
              as={RouterLink}
              to="/settings/progress-notes"
              color="primary"
              _hover={{ color: 'primary' }}
              target="_blank"
              _focus={{ outline: 'none' }}
            >
              settings
            </Link>
            .
          </Text>
        </Flex>
      )
    }

    return <Text>Ask your admin to turn on transcript retention.</Text>
  }

  const renderMagicEditButton = () => (
    <Button
      id={`pendo-magic-edit-button`}
      borderRadius="4px"
      onClick={() => setIsMagicEditDrawerOpen(true)}
      variant="outline"
      m={`0 0 0 ${buttonSpacing}`}
      h="35px"
      borderColor="pale_gray"
      {...(!buttonsHaveText ? { w: '35px', minW: '35px', maxW: '35px' } : {})}
      disabled={!isMagicEditEnabled}
      _hover={{ opacity: isMagicEditEnabled ? 0.7 : 'none' }}
      _focus={{ outline: 'none' }}
    >
      <Flex align="center" justify="space-between">
        <MagicEditIcon />
        {buttonsHaveText && <Text ml="5px">Magic edit</Text>}
      </Flex>
    </Button>
  )

  const progressNote = progressNotes.find((p: any) => p.id === progressNoteId)

  const isIntegratedUI = isExtension || isWidget

  return (
    <Container
      position={'sticky'}
      bottom={0}
      left={0}
      right={0}
      zIndex={1000}
      bg="white"
      borderTop="1px solid"
      borderColor="pale_gray"
    >
      <Flex
        id="progress-note-footer"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        {...(!isIntegratedUI
          ? { gap: '16px' }
          : { maxWidth: '750px', margin: '0 auto' })}
        ref={parentRef}
        py={isIntegratedUI ? '10px' : '16px'}
      >
        <ProgressNoteFeedback
          sessionId={sessionId}
          parentRef={parentRef}
          parentWidth={parentWidth}
          hasMultipleVersions={hasMultipleVersions}
          progressNotes={progressNotes}
          selectedProgressNoteId={selectedProgressNoteId}
          setSelectedProgressNoteId={setSelectedProgressNoteId}
        />
        {isIntegratedUI && (
          <Button
            id={`pendo-copy-all-button`}
            borderRadius="4px"
            {...(isWidget ? {} : { margin: `0 0 0 ${buttonSpacing}` })}
            h="35px"
            onClick={handleCopyAllToClipboard}
            padding="8px"
            isFullWidth
          >
            {!settings?.copyNoteButtonText && <CopyNoteIcon />}
            <Text ml="xxsmall">
              {settings?.copyNoteButtonText
                ? settings.copyNoteButtonText
                : copyNoteHasText
                ? 'Copy note'
                : 'Copy'}
            </Text>
          </Button>
        )}
        <Flex {...(!isIntegratedUI && { gap: '16px' })} alignItems="center">
          {!isIntegratedUI && (
            <Button
              id={`pendo-copy-all-button`}
              borderRadius="4px"
              m={`0 0 0 ${buttonSpacing}`}
              h="35px"
              {...(buttonsHaveText ? {} : { w: '100%' })}
              onClick={handleCopyButtonClicked}
              isLoading={isUploadingNoteToEhr}
            >
              <CopyNoteIcon />
              <Text ml="xxsmall">
                {outboundEhrSettings?.copyNoteButtonText
                  ? outboundEhrSettings.copyNoteButtonText
                  : copyNoteHasText
                  ? 'Copy note'
                  : 'Copy'}
              </Text>
            </Button>
          )}
          {isMagicEditEnabled
            ? renderMagicEditButton()
            : isDocumentationAutomationEnabled && (
                <Popover trigger="hover" placement="top">
                  <PopoverContent
                    _focus={{ outline: 'none' }}
                    borderColor="pale_gray"
                  >
                    <PopoverArrow />
                    <PopoverBody>
                      Magic Edit requires a transcript.
                      {renderMagicEditPopoverBody()}
                    </PopoverBody>
                  </PopoverContent>
                  <PopoverTrigger>
                    <div>{renderMagicEditButton()}</div>
                  </PopoverTrigger>
                </Popover>
              )}
          <Button
            variant="outline"
            borderRadius="4px"
            m={`0 0 0 ${buttonSpacing}`}
            h="35px"
            borderColor="pale_gray"
            {...(isIntegratedUI && { ml: 'xxsmall' })}
            {...(!buttonsHaveText
              ? { w: '35px', minW: '35px', maxW: '35px' }
              : {})}
            onClick={() => setIsDeleteConfirmationOpen(true)}
            {...(isIntegratedUI ? { padding: '8px' } : {})}
          >
            <Flex align="center" justify="space-between" gap="8px">
              <TrashIcon fill="#EB5164" />
              {buttonsHaveText && <Text>Delete</Text>}
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <MagicEditDrawer
        isOpen={isMagicEditDrawerOpen}
        closeDrawer={() => setIsMagicEditDrawerOpen(false)}
        progressNoteId={progressNoteId}
        openSettings={openSettings}
        preferences={{
          ...(progressNoteSettingsData?.preferences || {}),
          ...(progressNote?.preferences || {})
        }}
      />
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        isConfirming={isDeletingSessionProgressNotes}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => deleteSessionProgressNotes({ id: sessionId })}
        header="Delete this note?"
        text="This note (and all associated versions of the note) will be deleted."
        confirmText="Delete note"
      />
    </Container>
  )
}
