import React, { useEffect } from 'react'
import { Flex, Box, Link, Text, Center } from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import { useQueryParams } from '../../../hooks/use-query-params'
import { useHistory } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { StoreModel } from 'src/store/types'

const SessionNotesRemainingBanner = () => {
  const {
    documentationAutomationSessionsRemaining,
    documentationAutomationSessionsUsed,
    documentationAutomationSessionsAllotted,
    isDocumentationAutomationFreePlan,
    isDocumentationAutomationEnabled,
    isAdmin,
    isEvidenceBasedCareEnabled
  } = useExperienceManager()

  const history = useHistory()

  const { user } = useStoreState((state: StoreModel) => state.auth)

  const openPlansModal = useStoreActions(
    (actions: any) => actions.modals.manageScribePlan.openModal
  )

  const queryParams = useQueryParams()
  const upgrade = queryParams.get('upgrade') === 'true'

  useEffect(() => {
    if (upgrade) {
      if (isEvidenceBasedCareEnabled) {
        openPlansModal({
          clinicId: user!.clinic_id
        })
        return
      }

      history.push('/settings/plans')
    }
  }, [upgrade, isEvidenceBasedCareEnabled])

  const sessionLimitReached = documentationAutomationSessionsRemaining < 1

  if (!isDocumentationAutomationFreePlan || !isDocumentationAutomationEnabled)
    return null

  const onUpgradeClick = () => {
    if (isEvidenceBasedCareEnabled) {
      openPlansModal({
        clinicId: user!.clinic_id
      })
      return
    }
    history.push('/settings/plans')
  }

  return (
    <>
      <Flex
        bg="#EAEEFD"
        color="#282828"
        p="8px 16px"
        borderRadius="6px"
        justifyContent="center"
      >
        <Text>
          {documentationAutomationSessionsUsed}/
          {documentationAutomationSessionsAllotted} sessions used.
        </Text>
        {isAdmin ? (
          <Link
            variant="link"
            pl="8px"
            color="primary"
            textDecoration="none"
            onClick={onUpgradeClick}
            data-click-event-name="Session Notes Remaining Banner -> Clicked Upgrade"
          >
            Upgrade Plan
          </Link>
        ) : (
          <Text pl="8px" fontStyle="italic">
            {sessionLimitReached ? 'Contact your admin' : ''}
          </Text>
        )}
      </Flex>
    </>
  )
}

export default SessionNotesRemainingBanner
