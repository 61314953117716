import {
  Box,
  CloseButton,
  IconButton,
  Tooltip,
  Text,
  Icon,
  useBreakpointValue,
  Button
} from '@chakra-ui/react'
import { EndSession, Pause, PlayIcon } from '@components/icons'
import { SettingsOutlineIcon } from '@blueprinthq/joy'
import { trackEvent } from '@lib/clinician-tracking'
import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import { useAudioCapture } from '@hooks'
import { motion } from 'framer-motion'
import { css } from '@emotion/react'

type SessionControlsFooterProps = {
  isMuted: boolean
  onMuteToggle: () => void
  onSettingsClick: () => void
  onEndSession: () => void
}

const toggleButtonStyles = css`
  width: 72px;
  transition: all 300ms cubic-bezier(0.57, 0.1, 0, 1);

  &.expanded {
    width: auto;
    padding: 0 40px 0 16px;
    background-color: white !important;

    .icon {
      color: #2d54e8;
      margin-right: 4px;
    }

    .text {
      color: #2d54e8;
      display: inline;
    }

    &:hover {
      background-color: #f6f7fe !important;
    }

    &:active {
      background-color: #eaeefd !important;
    }
  }

  .icon {
    color: white;
    transition: color 300ms cubic-bezier(0.57, 0.1, 0, 1);
  }

  .text {
    display: none;
    font-size: 16px;
    transition: color 300ms cubic-bezier(0.57, 0.1, 0, 1);
  }
`

export const SessionControlsFooter: React.FC<SessionControlsFooterProps> = ({
  isMuted,
  onMuteToggle,
  onSettingsClick,
  onEndSession
}) => {
  const { showMicWarning, setShowMicWarning } = useAudioCapture()

  const isMobileBreakpoint = useBreakpointValue({ base: true, md: false })

  const tooltipContent = showMicWarning ? (
    <Box
      py={2}
      pl={2}
      pr={1}
      display="flex"
      width="100%"
      data-testid="mic-warning-tooltip-content"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Icon as={WarningIcon} color="red.500" mr="16px" boxSize={6} />
        <Text fontSize="16px">
          Hey, <b>we're not hearing any audio</b> from your microphone. Take a
          moment to check your settings.
        </Text>
        <CloseButton
          onClick={e => {
            e.stopPropagation()
            setShowMicWarning(false)
          }}
          size="sm"
          ml={1}
          color="#757575"
        />
      </Box>
    </Box>
  ) : (
    'Settings'
  )

  const onClickFooter = () => {
    setShowMicWarning(false)
  }

  return (
    <Box
      as={motion.div}
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        type: 'spring',
        duration: '0.4',
        ease: 'easeInOut'
      }}
      data-testid="session-controls"
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      width="100%"
      gap={isMobileBreakpoint && isMuted ? '8px' : isMuted ? '16px' : '24px'}
      zIndex="docked"
      flex={isMobileBreakpoint ? '7' : '1'}
      paddingBottom="24px"
      onClick={onClickFooter}
    >
      <Tooltip
        isOpen={showMicWarning ? true : undefined}
        hasArrow={showMicWarning ? true : undefined}
        placement="top"
        bg={showMicWarning ? '#FFF2F2' : undefined}
        color={showMicWarning ? '#282828' : undefined}
        border={showMicWarning ? '1px solid #2828280D' : undefined}
        borderRadius={showMicWarning ? '8px' : undefined}
        maxWidth={showMicWarning ? '350px' : undefined}
        mx={showMicWarning ? '16px' : undefined}
        label={tooltipContent}
      >
        <IconButton
          backgroundColor="transparent"
          icon={<SettingsOutlineIcon fill="primary" />}
          colorScheme="primary"
          variant="solid"
          aria-label="Call Settings"
          w="72px"
          h="72px"
          borderRadius="50%"
          border="1px solid #373C4123"
          onClick={() => {
            trackEvent('Session -> Clicked Settings')
            onSettingsClick()
          }}
          margin="0"
        />
      </Tooltip>
      <Tooltip label={isMuted ? undefined : 'Pause'}>
        <Button
          css={toggleButtonStyles}
          className={isMuted ? 'expanded' : ''}
          onClick={onMuteToggle}
          aria-label={isMuted ? 'Resume recording' : 'Pause recording'}
          height="72px"
          backgroundColor="#2D54E8"
          borderRadius="36px"
          padding="0"
          margin="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="none"
          _hover={{
            backgroundColor: '#4265EA'
          }}
          _active={{
            backgroundColor: '#627FEE'
          }}
          _focus={{
            outline: 'none',
            boxShadow: 'none'
          }}
          sx={{
            WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '36px',
              background: 'currentColor',
              opacity: 0,
              transition: 'opacity 0.2s'
            },
            '&:active::before': {
              opacity: 0.1
            }
          }}
        >
          <span
            className="icon"
            style={isMuted ? { marginLeft: '16px', marginRight: '36px' } : {}}
          >
            {isMuted ? (
              <PlayIcon fill="currentColor" />
            ) : (
              <Pause fill="currentColor" />
            )}
          </span>
          <span className="text">Resume</span>
        </Button>
      </Tooltip>
      <Tooltip label="End Session">
        <IconButton
          backgroundColor="#EB5164"
          icon={<EndSession fill="white" />}
          colorScheme="white"
          variant="solid"
          aria-label="End session"
          id="pendo-end-session"
          w="72px"
          h="72px"
          borderRadius="50%"
          margin="0"
          onClick={() => {
            trackEvent('Session -> Clicked End Session')
            onEndSession()
          }}
        />
      </Tooltip>
    </Box>
  )
}
