import React from 'react'
import { useExperienceManager } from '@hooks'
import ManualAudioCaptureProvider from './manual-audio-capture-provider'
import ManualAudioCaptureProviderV2 from './manual-audio-capture-provider-v2'

export type DeviceType = {
  deviceId: string
  label: string
}

interface AudioCaptureContextType {
  isReady: boolean
  audioInputs: DeviceType[]
  selectedAudioInput: string
  setSelectedAudioInput: (deviceId: string) => void
  testAudioInputs: () => Promise<void>
  hasMicAccess: boolean
  setHasMicAccess: React.Dispatch<React.SetStateAction<boolean>>
  promptForDevicePermissions: () => void
  isMuted: boolean
  toggleMute: () => void
  disableEchoCancelation: React.Dispatch<React.SetStateAction<boolean>>
  isRecording: boolean
  isRecordingLoading: boolean
  startRecording: ({
    isTelehealth,
    isUsingHeadphones,
    noteType
  }: {
    isTelehealth: boolean
    isUsingHeadphones: boolean
    noteType: string
  }) => Promise<void>
  stopRecording: () => Promise<void>
  startContentShare: () => Promise<void>
  stopContentShare: () => void
  logData: object
  isUsingHeadphones: boolean
  setIsUsingHeadphones: React.Dispatch<React.SetStateAction<boolean>>
  isTelehealth: boolean | null
  setIsTelehealth: React.Dispatch<React.SetStateAction<boolean | null>>
  hasDetectedAudio: boolean
  showMicWarning: boolean
  setShowMicWarning: React.Dispatch<React.SetStateAction<boolean>>
  isUploadComplete: boolean
  setRecordingCutoffTime: (timestamp: string) => void
  selectedAudioInputLabel: string | undefined
  isSettingsOpen: boolean
  setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>
  stopRecordingAfter: string
  setStopRecordingAfter: React.Dispatch<React.SetStateAction<string>>
  isStopRecordingAfterEnabled: boolean
  setIsStopRecordingAfterEnabled: (enabled: boolean) => void
  hasResolvedInputDevicePermissions: boolean
  permissionStatus: string | null
  uploadQueueSize: number
}

export const AudioCaptureContext = React.createContext<
  AudioCaptureContextType | undefined
>(undefined)

interface AudioCaptureProviderProps {
  sessionId?: string
  refetchRecap?: () => void
  children: React.ReactNode
}

export const AudioCaptureProvider: React.FC<AudioCaptureProviderProps> = ({
  sessionId,
  refetchRecap,
  children
}) => {
  const { isAudioCaptureV2Enabled } = useExperienceManager()

  if (isAudioCaptureV2Enabled) {
    return (
      <ManualAudioCaptureProviderV2
        sessionId={sessionId}
        refetchRecap={refetchRecap}
      >
        {children}
      </ManualAudioCaptureProviderV2>
    )
  } else {
    return (
      <ManualAudioCaptureProvider
        sessionId={sessionId}
        refetchRecap={refetchRecap}
      >
        {children}
      </ManualAudioCaptureProvider>
    )
  }
}

export default AudioCaptureProvider
