import React from 'react'
import { Box, Text, VStack, HStack, Divider, Button } from '@chakra-ui/react'
import { Clipboard } from '@components/icons'

interface InProgressProps {
  interventions: any[]
  onResume: (patientIntervention: any) => void
  onDiscard: (patientInterventionId: string) => void
}

export const InProgress = ({
  interventions,
  onResume,
  onDiscard
}: InProgressProps) => {
  if (!interventions.length) {
    return null
  }

  return (
    <Box>
      <Text fontWeight="bold" mb="xsmall">
        In Progress
      </Text>
      <Box border="1px solid" borderColor="pale_gray" rounded="md">
        <VStack
          flex={1}
          alignItems="flex-start"
          divider={
            <Divider
              color="pale_gray"
              mt="0px !important"
              mb="0px !important"
            />
          }
        >
          {interventions.map(i => (
            <HStack
              key={i.id}
              px="small"
              py="xsmall"
              alignItems="center"
              justify="space-between"
              w="100%"
            >
              <HStack>
                <Clipboard fill="black" />
                <Text fontWeight="bold">{i.name}</Text>
              </HStack>
              <HStack spacing={3}>
                <Text
                  fontSize={12}
                  color="#C6162C"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => onDiscard(i.id)}
                >
                  Discard
                </Text>
                <Button
                  textDecoration="none"
                  borderRadius="4px"
                  size="sm"
                  m="0"
                  _focus={{ outline: 'none' }}
                  onClick={() => onResume(i)}
                >
                  Resume
                </Button>
              </HStack>
            </HStack>
          ))}
        </VStack>
      </Box>
    </Box>
  )
}
