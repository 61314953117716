import React from 'react'
import { Flex } from '@chakra-ui/react'

const Panel = ({
  children,
  id
}: {
  children: React.ReactNode
  id?: string
}) => {
  return (
    <Flex
      data-testid="panel-flex"
      bg="white"
      borderRadius="8px"
      border="1px solid #E4E5E6"
      overflow="hidden"
      flexDirection="column"
      id={id}
      w={{
        base: 'auto',
        sm: 'auto',
        md: '690px'
      }}
    >
      {children}
    </Flex>
  )
}

export default Panel
