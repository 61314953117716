import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Select, Flex } from '@chakra-ui/react'
import { endpoints } from '@api'

const ClinicianOverride = ({
  user,
  onChange,
  isSuperAdmin,
  canViewOrgDropdown,
  allowAllClinicians = false,
  hideIfOnlyOneClinician = false,
  containerProps = {}
}) => {
  const [clinicDisplayId, setClinicDisplayId] = useState(user.clinic.display_id)
  const [clinicianId, setClinicianId] = useState(user.id)
  const [clinicList, setClinicList] = useState([])

  const {
    data: { clinics },
    isLoading: clinicsLoading
  } = useQuery(
    [endpoints.getAllClinics.getCacheId(), clinicDisplayId],
    () => endpoints.getAllClinics.request(),
    {
      initialData: { clinics: [] },
      enabled: canViewOrgDropdown || isSuperAdmin
    }
  )

  useEffect(() => {
    const clinic = clinicList.find(c => c.display_id === clinicDisplayId)
    const clinicianIdToPass = clinicianId === '' ? null : clinicianId
    if (clinic) {
      onChange(clinicianIdToPass, clinic.id)
    } else {
      onChange(clinicianIdToPass, user.clinic.id)
    }
  }, [clinicianId, clinicDisplayId])

  const clinicianQuery = {
    clinicDisplayId: encodeURIComponent(clinicDisplayId),
    showVisibleOnly: true
  }

  const {
    data: { clinicians },
    isLoading: cliniciansLoading
  } = useQuery(
    [endpoints.getClinicClinicians.getCacheId(), clinicianQuery],
    () => endpoints.getClinicClinicians.request(clinicianQuery),
    {
      initialData: { clinicians: [] },
      onSuccess: data => {
        const { clinicians } = data

        if (clinicDisplayId !== user.clinic.display_id && clinicians.length) {
          setClinicianId(clinicians[0].id)
        }

        if (
          allowAllClinicians &&
          clinicDisplayId === user.clinic.display_id &&
          clinicians.find(c => c.id === user.id) === undefined
        ) {
          setClinicianId('')
        }
      }
    }
  )

  const { data: orgClinicList } = useQuery(
    [endpoints.getOrganizationClinics.getCacheId(), clinicDisplayId],
    () =>
      endpoints.getOrganizationClinics.request({
        organizationId: user.clinic.organization_id
      }),
    {
      initialData: [],
      select: data =>
        data.map(c => ({
          id: c.id,
          name: c.name,
          display_id: c.display_id
        })),
      enabled: canViewOrgDropdown && !isSuperAdmin
    }
  )

  useEffect(() => {
    if (canViewOrgDropdown && !isSuperAdmin) {
      setClinicList(orgClinicList)
    } else {
      setClinicList(clinics)
    }
  }, [canViewOrgDropdown, isSuperAdmin, orgClinicList, clinics])

  if (clinicsLoading && cliniciansLoading) return null

  if (
    hideIfOnlyOneClinician &&
    clinicList.length <= 1 &&
    clinicians.length <= 1
  )
    return null

  return (
    <Flex w="100%" {...containerProps}>
      {(isSuperAdmin || canViewOrgDropdown) && clinicList.length > 1 && (
        <Select
          mr="16px"
          value={clinicDisplayId}
          onChange={e => setClinicDisplayId(e.target.value)}
          borderColor="gray.300"
        >
          {clinicList.map(clinic => (
            <option key={clinic.id} value={clinic.display_id}>
              {clinic.name}
            </option>
          ))}
        </Select>
      )}
      <Select
        value={clinicianId}
        onChange={e =>
          setClinicianId(e.target.value === '' ? null : e.target.value)
        }
        borderColor="gray.300"
      >
        {allowAllClinicians && (
          <option key="all" value="">
            All Clinicians
          </option>
        )}
        {clinicians.map(clinician => (
          <option key={clinician.id} value={clinician.id}>
            {clinician.first_name} {clinician.last_name}
          </option>
        ))}
      </Select>
    </Flex>
  )
}

export default ClinicianOverride
