import React from 'react'

const CoolKidsStudy = () => (
  <svg
    width="209"
    height="162"
    viewBox="0 0 209 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="209" height="162" fill="white" />
    <g clip-path="url(#clip0_12_4933)">
      <path
        d="M119.653 52.0324C124.432 54.0962 129.98 51.8975 132.045 47.1215C134.11 42.3454 131.91 36.8007 127.131 34.7369C122.352 32.673 116.804 34.8717 114.739 39.6478C112.674 44.4238 114.874 49.9686 119.653 52.0324Z"
        fill="#C0CCF8"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M121.279 48.2715C123.979 49.4378 127.114 48.1953 128.281 45.4963C129.448 42.7974 128.205 39.664 125.505 38.4977C122.804 37.3314 119.669 38.5739 118.502 41.2729C117.335 43.9719 118.578 47.1053 121.279 48.2715Z"
        fill="white"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M87.0637 133.243C110.833 143.508 138.427 132.572 148.698 108.818C158.969 85.0633 148.027 57.4851 124.258 47.2204C100.489 36.9556 72.8945 47.8913 62.6236 71.6458C52.3526 95.4004 63.2949 122.979 87.0637 133.243Z"
        fill="#2D54E8"
        stroke="#2D54E8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M87.3464 127.349C111.115 137.614 138.71 126.678 148.981 102.923C159.252 79.1687 148.31 51.5906 124.541 41.3259C100.772 31.0611 73.1772 41.9967 62.9063 65.7513C52.6354 89.5059 63.5776 117.084 87.3464 127.349Z"
        fill="#F6F7FE"
        stroke="#2D54E8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M91.6155 117.475C109.652 125.264 130.592 116.966 138.386 98.9402C146.18 80.9144 137.876 59.9872 119.84 52.198C101.803 44.4087 80.8634 52.7071 73.0695 70.7329C65.2756 88.7586 73.5789 109.686 91.6155 117.475Z"
        fill="white"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M138.386 98.9402C130.593 116.962 109.654 125.265 91.6155 117.475C73.5769 109.685 98.9887 105.526 106.784 87.4978C114.578 69.47 101.807 44.4103 119.84 52.198C137.873 59.9857 146.181 80.9125 138.386 98.9402Z"
        fill="#F6F7FE"
      />
      <path
        d="M117.82 56.8686L116.384 60.1912"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M95.0713 109.482L93.6347 112.805"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M133.712 96.922L130.388 95.4862"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M81.0729 74.1893L77.7427 72.7511"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M116.97 113.159L115.631 109.796"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M95.8295 59.879L94.4907 56.5163"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M77.3903 96.0665L80.7581 94.7364"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M130.702 74.9391L134.07 73.609"
        stroke="#C0CCF8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <path
        d="M94.0907 111.751L115.568 89.0864L117.365 57.9225L95.8869 80.5868L94.0907 111.751ZM104.199 80.9837C106.337 80.1364 108.735 81.1719 109.583 83.3088C110.431 85.4457 109.395 87.842 107.256 88.6894C105.118 89.5367 102.72 88.5012 101.873 86.3643C101.025 84.2274 102.061 81.8311 104.199 80.9837Z"
        fill="#C0CCF8"
      />
      <path
        d="M94.0908 111.751L115.569 89.0863L117.365 57.9225L103.458 83.8562L94.0908 111.751Z"
        fill="#2D54E8"
      />
      <path
        d="M91.9825 117.533C110.019 125.323 130.811 117.367 138.422 99.764C146.033 82.161 137.581 61.5765 119.545 53.7872C101.508 45.998 80.7167 53.9536 73.1056 71.5566C65.4944 89.1596 73.9459 109.744 91.9825 117.533Z"
        stroke="#C0CCF8"
        stroke-width="5.74"
        stroke-miterlimit="10"
      />
      <path
        d="M120.57 50.5087C138.992 58.4642 147.54 79.6537 139.684 97.8223C131.829 115.991 110.53 124.291 92.1085 116.335C73.6869 108.38 65.1389 87.1903 72.9946 69.0218C80.8502 50.8532 102.149 42.5531 120.57 50.5087ZM121.942 47.3367C101.812 38.6432 78.4267 47.748 69.821 67.6512C61.2153 87.5545 70.6067 110.814 90.737 119.507C110.867 128.201 134.252 119.096 142.858 99.1928C151.464 79.2895 142.072 56.0301 121.942 47.3367Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M92.2244 116.066C110.261 123.855 131.201 115.557 138.995 97.5313C146.789 79.5055 138.485 58.5783 120.449 50.789C102.412 42.9998 81.4723 51.2981 73.6784 69.3239C65.8845 87.3497 74.1878 108.277 92.2244 116.066Z"
        stroke="#2D54E8"
        stroke-width="0.82"
        stroke-miterlimit="10"
      />
      <ellipse cx="103" cy="82" rx="4" ry="4" fill="#2D54E8" />
      <ellipse cx="105" cy="84" rx="4" ry="4" fill="#F6F7FE" />
    </g>
    <path
      d="M105.693 18.8902L104.079 19.6271C103.997 19.6639 103.916 19.6639 103.828 19.6271L102.213 18.8828C101.97 18.7723 101.719 19.0228 101.822 19.2734L102.567 20.8872C102.604 20.9683 102.604 21.0493 102.567 21.1378L101.83 22.7516C101.719 22.9948 101.97 23.2454 102.22 23.1422L103.835 22.4053C103.916 22.3684 103.997 22.3684 104.086 22.4053L105.701 23.1496C105.944 23.2601 106.195 23.0096 106.092 22.759L105.347 21.1451C105.31 21.0641 105.31 20.983 105.347 20.8946L106.084 19.2807C106.195 19.0376 105.944 18.787 105.693 18.8902Z"
      fill="#C0CCF8"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M177.822 83.5032L174.253 82.1767C174.076 82.1031 173.951 81.9778 173.877 81.8009L172.557 78.2121C172.358 77.6742 171.591 77.6742 171.377 78.2121L170.058 81.8009C169.984 81.9778 169.858 82.1031 169.682 82.1767L166.113 83.5032C165.574 83.7022 165.574 84.4686 166.113 84.6896L169.682 86.0161C169.858 86.0898 169.984 86.2151 170.058 86.3919L171.377 89.9807C171.577 90.5187 172.343 90.5187 172.557 89.9807L173.877 86.3919C173.951 86.2151 174.076 86.0898 174.253 86.0161L177.822 84.6896C178.36 84.4907 178.36 83.7243 177.822 83.5032Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M37.0966 84.4391L36.4625 82.302C36.433 82.1915 36.4477 82.0883 36.5067 81.9925L37.7234 80.1134C37.9077 79.8333 37.6349 79.4722 37.3104 79.5607L35.1647 80.2092C35.0541 80.2386 34.9509 80.2239 34.855 80.1649L32.9747 78.9638C32.6946 78.7795 32.3332 79.0522 32.4217 79.3838L33.0559 81.5209C33.0854 81.6314 33.0706 81.7346 33.0116 81.8304L31.795 83.7095C31.6106 83.9895 31.8835 84.3506 32.2079 84.2622L34.3536 83.6137C34.4642 83.5842 34.5674 83.599 34.6633 83.6579L36.5436 84.8591C36.8238 85.0433 37.1851 84.7707 37.0966 84.4391Z"
      fill="white"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M159.565 22.7369L156.962 21.7715C156.829 21.7199 156.741 21.6241 156.689 21.4989L155.723 18.8828C155.583 18.4922 155.023 18.4922 154.86 18.8828L153.894 21.4989C153.843 21.6315 153.754 21.7199 153.622 21.7715L151.019 22.7369C150.628 22.8843 150.628 23.4443 151.019 23.5991L153.622 24.5644C153.754 24.616 153.843 24.7118 153.894 24.8371L154.86 27.4532C155 27.8437 155.561 27.8437 155.723 27.4532L156.689 24.8371C156.741 24.7045 156.829 24.616 156.962 24.5644L159.565 23.5991C159.955 23.4517 159.955 22.8916 159.565 22.7369Z"
      fill="#F6F7FE"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <path
      d="M55.9542 64.0982L54.4426 62.7128C54.3689 62.6391 54.332 62.5507 54.332 62.4475L54.3984 60.3915C54.4057 60.082 54.0223 59.9272 53.8085 60.1557L52.4222 61.6811C52.3485 61.7548 52.26 61.7916 52.1568 61.7916L50.1069 61.7327C49.7972 61.7253 49.6424 62.1085 49.871 62.3222L51.3826 63.7076C51.4563 63.7813 51.4932 63.8698 51.4932 63.9729L51.4268 66.0289C51.4194 66.3384 51.8029 66.4932 52.0167 66.2647L53.4029 64.7393C53.4767 64.6656 53.5651 64.6288 53.6684 64.6288L55.7182 64.6877C56.0279 64.6951 56.1828 64.3119 55.9542 64.0982Z"
      fill="#C0CCF8"
    />
    <path
      d="M104.5 155C128.524 155 148 152.09 148 148.5C148 144.91 128.524 142 104.5 142C80.4756 142 61 144.91 61 148.5C61 152.09 80.4756 155 104.5 155Z"
      fill="#F6F7FE"
    />
    <path
      d="M71 145.58C83.6453 145.337 96.6538 145.081 109.316 145.014C119.85 144.913 137.673 145.358 117.459 146.422C109.291 146.847 98.0307 147.144 90.3016 147.427C88.401 147.521 78.4841 147.804 81.2801 148.276C84.6083 148.64 88.8909 148.721 93.7987 148.896C104.603 149.219 116.809 149.543 127.646 149.954C155.285 150.971 137.386 152.077 120.812 153"
      stroke="white"
      stroke-width="3"
      stroke-miterlimit="10"
    />
    <path
      d="M64 144.653C76.6453 144.38 89.6538 144.092 102.316 144.016C112.85 143.902 130.673 144.402 110.459 145.6C102.291 146.078 91.0307 146.412 83.3016 146.73C81.401 146.836 71.4841 147.155 74.2801 147.685C77.6083 148.095 81.8909 148.186 86.7987 148.383C97.6025 148.747 109.809 149.111 120.646 149.573C148.285 150.718 130.386 151.961 113.812 153"
      stroke="#C0CCF8"
      stroke-miterlimit="10"
    />
    <defs>
      <clipPath id="clip0_12_4933">
        <rect
          width="96.4424"
          height="109.275"
          fill="white"
          transform="matrix(0.918049 0.396467 -0.396868 0.917876 85.085 13.6949)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default CoolKidsStudy
